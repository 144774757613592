export const LEADER_BOARD_API_CALL_INTERVAL = 10000
export const NEXT_NOTIFICATION_PERMISSION_REQUEST_DAYS = 14
export const LEADER_BOARD_COLUMN_NAME_TO_CONTEST_CRITERIA_MAPPER = {
  SALES: 'GAMIFICATION.TOTAL_TRANSACTIONS',
  ORDERS: 'GAMIFICATION.TOTAL_ORDERS',
  PRODUCTS: 'GAMIFICATION.TOTAL_PRODUCTS'
}
export const PRODUCTS_WITH_INQUIRY_DETAILS = ['BPJS', 'ELECTRICITY_CREDIT', 'TV_KABEL_POSTPAID', 'TELKOM', 'INDIHOME_POSTPAID', 'ELECTRICITY_POSTPAID', 'WATER_BILL', 'MULTI_FINANCE', 'INDIHOME_PREPAID', 'PHONE_CREDIT', 'DATA_PACKAGE', 'GAME_VOUCHER_TOPUP', 'GAME_VOUCHER', 'ZAKAT', 'PAY_LATER', 'EMONEY', 'MONEY_TRANSFER']
export const ORDER_HISTORY_PRODUCTS_WITH_IMAGE_CARD = ['TV_KABEL_PREPAID', 'TV_KABEL_POSTPAID', 'ZAKAT', 'TELKOM', 'INDIHOME_PREPAID', 'INDIHOME_POSTPAID']
export const TELKOM_ADD_ON_INDIHOME_BLIBLI_FAQ_URL = 'https://www.blibli.com/faq/topic/produk-digital/add-on-indihome/'
export const READ_THE_FAQ_FOR_MORE_URL = 'https://www.blibli.com/faq/status-pesanan/mengapa-pesanan-saya-dibatalkan/'
export const AXIOS_REQUEST_CANCELLATION_ERROR = 'CANCELLING_PREVIOUS_REQUEST'
export const DEFAULT_AVATAR_SIVA_URL = {
  MALE: 'https://www.static-src.com/siva/asset//06_2020/avatar-male2.png',
  FEMALE: 'https://www.static-src.com/siva/asset/06_2020/avatar-female8.png',
  INCOMPLETE_MEMBER: 'https://www.static-src.com/siva/asset/06_2020/avatar-blibli1.png'
}

export const BILL_REVAMPED_PRODUCTS = ['PHONE_CREDIT', 'DATA_PACKAGE', 'ELECTRICITY_CREDIT', 'ELECTRICITY_POSTPAID', 'BPJS', 'WATER_BILL', 'TELKOM', 'ZAKAT', 'EMONEY', 'MONEY_TRANSFER', 'RETAIL', 'MULTI_FINANCE', 'GAME_VOUCHER', 'GAME_VOUCHER_TOPUP', 'TV_KABEL_POSTPAID']

export const DUMMY_MISSION_DETAIL_FOR_ONBOARDING = {
  contestName: '(Contoh) Transaksi Pulsa Rp1 Juta',
  contestId: 'MISSION_ONBOARDING_CONTEST',
  contestType: 'MILESTONE',
  prizeMode: 'LOYALTY',
  totalPrizeMoneyAmount: 100,
  missionTasks: [
    {
      taskName: '(Contoh) Transaksi Pulsa senilai Rp1.000.000',
      taskDescription: 'Transaksi Pulsa senilai Rp1.000.000 untuk semua operator',
      productType: ['PHONE_CREDIT'],
      contestCriteriaResponse: {
        contestCriteriaValue: 1000000
      },
      joinScore: 500000
    }
  ],
  score: 50,
  aggregateScore: 50,
  contestTnC: [
    'Misi hanya berlaku di aplikasi Blibli Mitra dan mitra.blibli.com',
    'Nilai transaksi yang harus dicapai untuk berhasil menyelesaikan misi ini adalah Rp1.000.000,- untuk transaksi pulsa ke semua operator',
    'Mitra yang berhasil menyelesaikan misi sebelum periode berakhir akan mendapatkan 100 poin mitra,- (Tidak berlaku kelipatan)',
    'Reward poin akan dikirimkan ke akun mitra maksimal 1 jam setelah misi berhasil terselesaikan',
    'Dengan mengikuti promo ini, Mitra dianggap mengerti dan menyetujui semua syarat ketentuan berlaku',
    'Blibli.com berhak secara sepihak membatalkan pesanan, subsidi, cashback saldo atau benefit lainnya apabila ditemukan adanya indikasi kecurangan/pelanggaran yang merugikan pihak Blibli.com',
    'Blibli.com berhak mengubah syarat & ketentuan promo tanpa pemberitahuan sebelumnya'
  ]
}

export const EMONEY_OPERATOR_CHARGES = {
  'OVO': '1.500',
  'LinkAja': '1.000'
}
export const MAX_QUANTITY_FOR_VOLUMETRIC_DISCOUNT = 99999

export const RAFFLE_PRODUCT_KEY = 'RAFFLE'

export const MITRA_TEMPO_REGISTRATION_LABELS_FOR_GA = {
  step1: 'MitraTempoRegistrationPersonalData',
  step2: 'MitraTempoRegistrationPersonalInfo',
  step3: 'MitraTempoRegistrationSupportingDetails',
  step4: 'MitraTempoRegistrationAddressDetails',
  step5: 'MitraTempoRegistrationEmergencyDetails'
}

export const DIGITAL_PRODUCT_PAYMENT_LABELS = {
  'PHONE_CREDIT': 'MitraPulsaPayment',
  'DATA_PACKAGE': 'MitraDataPackagePayment',
  'ELECTRICITY_CREDIT': 'MitraElectricityPrePaidPayment',
  'ELECTRICITY_POSTPAID': 'MitraElectricityPostPaidPayment',
  'EMONEY': 'MitraEWalletPayment',
  'BPJS': 'MitraBpjsPayment',
  'TELKOM': 'MitraTelkomPayment',
  'MULTI_FINANCE': 'MitraMultiFinancePayment',
  'TV_KABEL_POSTPAID': 'MitraTvKabelPayment',
  'WATER_BILL': 'MitraWaterBillPayment',
  'MONEY_TRANSFER': 'MitraMoneyTransferPayment',
  'GAME_VOUCHER': 'MitraGameVoucherPayment',
  'GAME_VOUCHER_TOPUP': 'MitraGameVoucherTopUpPayment',
  'ZAKAT': 'MitraZakatPayment',
  'PAY_LATER': 'MitraPaylaterPayment'
}

export const DIGITAL_PRODUCT_ORDER_LABELS = {
  'PHONE_CREDIT': 'MitraPhoneCreditOrderStatus',
  'DATA_PACKAGE': 'MitraDataPackageOrderStatus',
  'ELECTRICITY_CREDIT': 'MitraElectricityPrePaidOrderStatus',
  'ELECTRICITY_POSTPAID': 'MitraElectricityPostPaidOrderStatus',
  'EMONEY': 'MitraEWalletOrderStatus',
  'BPJS': 'MitraBpjsOrderStatus',
  'TELKOM': 'MitraTelkomOrderStatus',
  'MULTI_FINANCE': 'MitraMultiFinanceOrderStatus',
  'TV_KABEL_POSTPAID': 'MitraTvKabelOrderStatus',
  'WATER_BILL': 'MitraWaterBillOrderStatus',
  'MONEY_TRANSFER': 'MitraMoneyTransferOrderStatus',
  'GAME_VOUCHER': 'MitraGameVoucherOrderStatus',
  'GAME_VOUCHER_TOPUP': 'MitraGameVoucherTopUpOrderStatus'
}
export const MILLI_SECONDS_IN_A_SECOND = 1000;
export const MILLI_SECONDS_IN_A_MINUTE = MILLI_SECONDS_IN_A_SECOND * 60;
export const MILLI_SECONDS_IN_AN_HOUR = MILLI_SECONDS_IN_A_MINUTE * 60;
export const MILLI_SECONDS_IN_A_DAY = MILLI_SECONDS_IN_AN_HOUR * 24;

export const MONTH_LABELS_SHORT = {
  id: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
  en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
}

export const MONTH_LABELS_LONG = {
  id: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}

export const EVENTES_MAPPING_BY_ROUTES = {
  '/retail-checkout': {
    'gaEventName': 'MitraHomeIsiStokClick',
    'appsflyerEventName': 'af_mitrahomeisistokclick'
  },
  '/digital/pulsa': {
    'gaEventName': 'MitraHomePhoneCreditClick',
    'appsflyerEventName': 'af_mitrahomephonecreditclick'
  },
  '/digital/paket-data': {
    'gaEventName': 'MitraHomeDataPackageClick',
    'appsflyerEventName': 'af_mitrahomedatapackageclick'
  },
  '/digital/token-listrik': {
    'gaEventName': 'MitraHomeElectricityCreditClick',
    'appsflyerEventName': 'af_mitrahomeelectricitycreditclick'
  },
  '/digital/pdam': {
    'gaEventName': 'MitraHomeWaterBillClick',
    'appsflyerEventName': 'af_mitrahomewaterbillclick'
  },
  '/digital/bpjs': {
    'gaEventName': 'MitraHomeBpjsClick',
    'appsflyerEventName': 'af_mitrahomebpjsclick'
  },
  '/digital/multi-finance': {
    'gaEventName': 'MitraHomeMultiFinanceClick',
    'appsflyerEventName': 'af_mitrahomemultifinanceclick'
  },
  '/digital/game-voucher': {
    'gaEventName': 'MitraHomeGameVoucherClick',
    'appsflyerEventName': 'af_mitrahomegamevoucherclick'
  },
  '/digital/telkom': {
    'gaEventName': 'MitraHomeTelkomClick',
    'appsflyerEventName': 'af_mitrahometelkomclick'
  },
  '/digital/zakat': {
    'gaEventName': 'MitraHomeZakatClick',
    'appsflyerEventName': 'af_mitrahomezakatclick'
  },
  '/digital/tv-kabel': {
    'gaEventName': 'MitraHomeTvKabelClick',
    'appsflyerEventName': 'af_mitrahometvkabelclick'
  },
  '/dropship': {
    'gaEventName': 'MitraDropshipClick',
    'appsflyerEventName': 'af_mitrahomedropshipclick'
  },
  '/digital/money-transfer': {
    'gaEventName': 'MitraHomeMoneyTransferClick',
    'appsflyerEventName': 'af_mitrahomemoneytransferclick'
  },
  '/digital/topup': {
    'gaEventName': 'MitraHomeEMoneyClick',
    'appsflyerEventName': 'af_mitrahomeemoneyClick'
  },
  '/loyalty': {
    'gaEventName': 'MitraHomeLoyaltyPointsClick'
  },
  '/notifications': {
    'gaEventName': 'MitraHomeNotificationBellClick'
  },
  '/blipay/cashout': {
    'gaEventName': 'MitraHomeWithdrawClick'
  },
  '/wallet-transactions': {
    'gaEventName': 'MitraHomeTransactionClick'
  },
  '/pnl': {
    'gaEventName': 'MitraHomeCashbookClick'
  },
  '/contests': {
    'gaEventName': 'MitraHomeChallengeClick'
  },
  '/credit-ledger': {
    'gaEventName': 'MitraHomeDebtClick'
  },
  '/paylater': {
    'gaEventName': 'MitraHomePaylaterClick'
  },
  '/account': {
    'gaEventName': 'MitraAccountClick'
  },
  '/member-account': {
    'gaEventName': 'MitraAccountClick'
  },
  '/help': {
    'gaEventName': 'MitraHelpClick'
  },
  '/order': {
    'gaEventName': 'MitraOrderHistoryClick'
  },
  '/home': {
    'gaEventName': 'MitraHomeClick'
  },
  '/engagement/raffle': {
    'gaEventName': 'MitraRaffleAboutPageClick'
  }
}
