<template>
  <div>
    <BliModal
      :active="isServerOverloaded"
      @blur="checkAgain()"
    >
      <BliModalHeader @close="checkAgain()" />
      <BliModalBody>
        <div class="server-overload-wrapper">
          <img
            src="@vue3/assets/server-errors-icons/server-overloaded.svg"
            alt="Offline"
          >
          <div class="header">
            {{ $i18n('SERVER_OVERLOADED.HEADER') }}
          </div>
          <div class="content">
            {{ $i18n('SERVER_OVERLOADED.DESCRIPTION') }}
          </div>
        </div>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          full-width
          @click="checkAgain()"
        >
          {{ $i18n('SERVER_OVERLOADED.TRY_AGAIN_BUTTON') }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./server-overload.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.server-overload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .header {
    font-size: $blu-font-size-50;
    color: $blu-color-neutral-text-high;
    font: $blu-text-subtitle-2;
    line-height: 24px;
  }
  .content {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: $blu-font-size-30;
    font: $blu-text-body-2;
    line-height: 18px;
  }
}
</style>
