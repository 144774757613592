import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getRegisterationDetails (payload, callback, fail) {
    apiCall.makePostRequest(
      apiUrls.api.getFlipDetails(),
      callback,
      fail,
      payload
    );
  },
  updateRegisterationDetails (payload, callback, fail) {
    apiCall.makePutRequest(
      apiUrls.api.updateFlipDetails(),
      callback,
      fail,
      payload
    );
  },
  saveRegisterationDetails (payload, callback, fail) {
    apiCall.makePostRequest(
      apiUrls.api.saveFlipApi(),
      callback,
      fail,
      payload
    );
  },
  getOcrDetails (payload, params, callback, fail) {
    apiCall.uploadFile(apiUrls.api.getFlipOcrDetails(), callback, fail, payload, params)
  },
  getProvince (params, callback, fail) {
    apiCall.makeGetRequest(
      apiUrls.api.getFlipProvinceList,
      callback,
      fail,
      params
    );
  },
  getCity (params, callback, fail, provinceId) {
    apiCall.makeGetRequest(
      apiUrls.api.getFlipCityList(provinceId),
      callback,
      fail,
      params
    );
  },
  getDistricts (params, callback, fail, cityId) {
    apiCall.makeGetRequest(
      apiUrls.api.getFlipDistrictList(cityId),
      callback,
      fail,
      params
    );
  },
  uploadSupportDocument (payload, callback, fail) {
    apiCall.makePostRequest(
      apiUrls.api.uploadSupportDocumentApi(),
      callback,
      fail,
      payload
    );
  }
}
