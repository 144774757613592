import apiUrls from './apiUrls'
import apiCall from './apiUtils/makeApiCall'

export default {
  getAllContests (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.getAllContests(), callback, fail, payload, params)
  },
  getContestDetail (callback, fail, payload, contestId) {
    apiCall.makePostRequest(apiUrls.api.getContestDetail(contestId), callback, fail, payload)
  },
  getLeaderBoard (callback, fail, payload, contestId, cancelToken) {
    apiCall.makePostRequest(apiUrls.api.getLeaderboard(contestId), callback, fail, payload, null, cancelToken)
  }
}
