import { mapGetters } from 'vuex'
import Transition from '@/components/web/Transition.vue'
import BliIconHome from '@blibli/blue-icon/dist/icons/Home.js'
import BliIconOrderCurrent from '@blibli/blue-icon/dist/icons/OrderCurrent.js'
import BliIconOrder from '@blibli/blue-icon/dist/icons/Order.js'
import BliIconChatCs from '@blibli/blue-icon/dist/icons/ChatCs.js'
import BliIconProfile from '@blibli/blue-icon/dist/icons/Profile.js'
import OnboardingCard from '@/components/web/OnboardingCard.vue'
import FeatureMixin from '@/mixins/feature-mixin'
import RetailCheckoutMixin from '@/mixins/RetailCheckoutMixin'
import Modals from '@/components/home/Modals.vue'

export default {
  name: 'NavigationBar',
  mixins: [
    FeatureMixin,
    RetailCheckoutMixin
  ],
  data () {
    return {
      items: [
        {
          name: 'Home',
          idx: 0,
          url: '/mitra-home',
          label: 'HOME',
          alt: 'Home',
          icon: BliIconHome
        },
        {
          name: 'IsiStok',
          idx: 1,
          url: '/retail-checkout',
          label: 'ISI_STOK',
          alt: 'Isi stok',
          icon: BliIconOrderCurrent
        },
        {
          name: 'OrderHistory',
          idx: 2,
          url: '/order',
          label: 'ORDER',
          alt: 'Order',
          icon: BliIconOrder
        },
        {
          name: 'Help',
          idx: 3,
          url: '/help',
          label: 'HELP',
          alt: 'Help',
          icon: BliIconChatCs
        },
        {
          name: 'MemberAccount',
          idx: 4,
          url: '/member-account',
          label: 'ACCOUNT',
          alt: 'Profile',
          icon: BliIconProfile
        }
      ]
    }
  },
  components: {
    Transition,
    OnboardingCard,
    Modals
  },
  computed: {
    ...mapGetters('homepageStore', ['getOnboardingStatus', 'getConfigs']),
    ...mapGetters('retailCheckoutStore', ['getWholesaleOnboardingSku', 'getCartDetails']),
    ...mapGetters({
      navTabs: 'getNavigationBarStatus'
    }),
    shouldDisplayModalsInNavigationBar () {
      const routeName = this.$route.name
      return routeName !== 'Home' && routeName !== 'Account'
    },
    path () {
      return this.$route.path
    },
    isWidthGreaterThan499 () {
      return window.innerWidth > 499
    },
    appWidth () {
      let appWidth = window.innerWidth
      if (this.isWidthGreaterThan499) {
        appWidth = 420
      }
      return appWidth
    },
    offsetDistance () {
      let offset = 0
      if (this.isWidthGreaterThan499) {
        offset = (window.innerWidth - this.appWidth) / 2 // distance from left most point in screen to navigation bar start point
      }
      return offset
    },
    onboardingTabStyle () {
      const sizeOfEachNavTab = this.appWidth / 5
      return `left: ${sizeOfEachNavTab + this.offsetDistance}px; width: ${sizeOfEachNavTab}px;`
    },
    onboardingCardStyle () {
      return `left: ${this.offsetDistance + 16}px;`
    },
    isIsiStokPage () {
      return this.$route.name === 'RetailCheckout'
    },
    doesRetailCartHasItems () {
      return this.isIsiStokPage && this.isCartHasItems
    },
    isCartCountGreaterThan99 () {
      return this.getTotalQuantity > 99
    },
    numberOFCartIterms () {
      return this.isCartCountGreaterThan99 ? '99+' : this.getTotalQuantity
    },
    isQuickOrderConfigEnabled () {
      return !!this.getConfigs.shouldEnableQuickOrder
    },
    isStep2HomeOnBoardingStatus () {
      const status = this.getOnboardingStatus === 'STEP2'
      if (status) {
        this.$trackComponentImpression('MitraOnboarding_StockReplenishment_InstructionImpression')
        this.$trackPageImpression('MitraOnboarding_StockReplenishment_InstructionImpression')
      }
      return status
    },
    getOnboardingTitle () {
      return this.isMemberUnderNewRegistration ? this.$i18n('HOME_PAGE.ONBOARDING.STEP2_NEW_TITLE') : this.$i18n('HOME_PAGE.ONBOARDING.STEP2_TITLE')
    },
    getOnboardingDescription () {
      return this.isMemberUnderNewRegistration ? this.$i18n('HOME_PAGE.ONBOARDING.STEP2_NEW_DESC') : this.$i18n('HOME_PAGE.ONBOARDING.STEP2_DESC')
    }
  },
  methods: {
    selectThisTab (menuItem) {
      let route = menuItem.url
      if (this.path === route) {
        return
      }
      const isProduct = route === '/retail-checkout'
      this.onFeatureClick({ url: route }, isProduct)
    },
    isActive (v) {
      return this.path.indexOf(v.url) === 0
    },
    showSkipOnboardingModal () {
      this.$trackClickEvent('MitraOnboarding_StockReplenishment_InstructionClose')
      this.$trackButtonClick('MitraOnboarding_StockReplenishment_InstructionClose')
      this.$store.dispatch('homepageStore/SET_MODAL_INFO', {
        key: 'HOME_ONBOARDING_SKIP_MODAL',
        properties: {
          currentOnboardingStep: this.getOnboardingStatus
        }
      })
      this.$store.dispatch('homepageStore/SET_HOME_ONBOARDING_STATUS', 'SKIP')
    },
    goToOnboardingStep3 () {
      this.$trackClickEvent('MitraOnboarding_StockReplenishment_InstructionContinue')
      this.$trackButtonClick('MitraOnboarding_StockReplenishment_InstructionContinue')
      this.$store.dispatch('homepageStore/SET_HOME_ONBOARDING_STATUS', 'TEMPORARY_PAUSE')
      setTimeout(() => {
        this.$store.dispatch('homepageStore/SET_HOME_ONBOARDING_STATUS', 'STEP3')
      }, 600)
    }
  }
}
