import { enUS, id } from 'date-fns/locale';
import lang from '@/utils/language';
import { parseISO, format, getMonth } from 'date-fns';
import { MONTH_LABELS_SHORT, MONTH_LABELS_LONG } from '@/constant/contants'

const locale = lang.getUserLanguage();
const options = { locale: lang.getUserLanguage() === 'en' ? enUS : id };

const getDate = ((value, dateFormat) => {
  const monthIndex = getMonth(value, dateFormat, options)
  const date = format(value, dateFormat, options)
  const dateArray = date.split(' ');
  const dateFormatArray = dateFormat.split(' ')
  let position = dateFormatArray.findIndex(item => item.indexOf('MMMM') > -1);
  if (position > -1) {
    const indexPosition = dateFormatArray[position].indexOf('MMMM');
    const replacementMonth = MONTH_LABELS_LONG[locale][monthIndex];
    dateArray.splice(position, 1, getReplacementMonth(indexPosition, dateArray[position], replacementMonth))
  } else {
    position = dateFormatArray.findIndex(item => item.indexOf('MMM') > -1);
    if (position > -1) {
      const indexPosition = dateFormatArray[position].indexOf('MMM');
      const replacementMonth = MONTH_LABELS_SHORT[locale][monthIndex];
      dateArray.splice(position, 1, getReplacementMonth(indexPosition, dateArray[position], replacementMonth))
    }
  }
  return dateArray.join(' ')
})

const getReplacementMonth = ((index, Actual, replacement) => {
  return Actual.substring(0, index) + replacement + Actual.substring(index + replacement.length);
})

export default {
  date (value, dateFormat = 'dd MMM yyyy, HH.mm') {
    return getDate(value, dateFormat)
  },
  time (value, dateFormat = 'HH.mm') {
    return getDate(value, dateFormat)
  },
  day (value, dateFormat = 'EEEE, dd MMM yyyy') {
    return getDate(value, dateFormat)
  },
  customTimeStamp (value, dateFormat = 'dd MMM yyyy') {
    value = parseISO(value)
    if (new Date(value).toDateString() === new Date().toDateString()) {
      dateFormat = 'HH.mm'
    }
    return getDate(value, dateFormat)
  }
}
