import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import { getMemberID } from '@vue3/utils/helpers'
import { homepageStore } from '@vue3/store/homepage-store'
import { profileStore } from '@vue3/store/profile-store'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'Modals',
  components: {
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter,
    BliButton
  },
  data () {
    return {
      isModalVisible: false,
      currentModalProperties: {},
      modalList: {}
    }
  },
  created () {
    this.modalList = {
      NO_ACCESS_MODAL_FOR_REJECTED_USER: {
        status: 'info',
        maskClick: this.closeNoAccessModalForRejectedUser,
        close: this.closeNoAccessModalForRejectedUser,
        controls: [{ label: this.$i18n('OK'), handler: this.closeNoAccessModalForRejectedUser }],
        headerText: this.$i18n('HOME_PAGE.MODALS.REJECTED_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.MODALS.REJECTED_DESCRIPTION')
      },
      NO_ACCESS_MODAL_FOR_SUSPENDED_USER: {
        status: 'danger',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('HOME_PAGE.MODALS.VIEW_DETAILS'),
            handler: this.hanldeMitraCenterSuspensionReasonClick,
            prominence: 'primary'
          },
          {
            label: this.$i18n('HOME_PAGE.MODALS.CANCEL'),
            handler: this.handleCloseNoAccessModalForSuspendedUser,
            prominence: 'tertiary'
          }
        ],
        headerText: this.$i18n('HOME_PAGE.MODALS.MITRA_CENTER_SUSPENSION_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.MODALS.MITRA_CENTER_SUSPENSION_DESCRIPTION')
      },
      ONE_HOUR_WALLET_SUSPENDED_USER: {
        status: 'alert',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('HOME_PAGE.MODALS.VIEW_DETAILS'),
            handler: this.hanldeWalletSuspensionReasonClick,
            prominence: 'primary'
          },
          {
            label: this.$i18n('HOME_PAGE.MODALS.CANCEL'),
            handler: this.closeModal,
            prominence: 'tertiary'
          }
        ],
        headerText: this.$i18n('HOME_PAGE.MODALS.ONE_HOUR_WALLET_SUSPENDED_USER_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.MODALS.WALLET_SUSPENSION_BODY')
      },
      ONE_DAY_WALLET_SUSPENDED_USER: {
        status: 'alert',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('HOME_PAGE.MODALS.VIEW_DETAILS'),
            handler: this.hanldeWalletSuspensionReasonClick,
            prominence: 'primary'
          },
          {
            label: this.$i18n('HOME_PAGE.MODALS.CANCEL'),
            handler: this.closeModal,
            prominence: 'tertiary'
          }
        ],
        headerText: this.$i18n('HOME_PAGE.MODALS.ONE_DAY_WALLET_SUSPENDED_USER_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.MODALS.WALLET_SUSPENSION_BODY')
      },
      NO_ACCESS_MODAL: {
        status: 'info',
        maskClick: this.closeNoAccessModal,
        close: this.closeNoAccessModal,
        controls: [{ label: this.$i18n('OK'), handler: this.closeNoAccessModal }],
        headerText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_TITLE'),
        bodyText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_DESC')
      },
      NO_ACCESS_MODAL_WITH_HOME_REDIRECTION: {
        status: 'info',
        maskClick: this.closeNoAccessModal,
        close: this.closeNoAccessModal,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.goToHomePage,
            prominence: 'primary'
          }
        ],
        headerText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_TITLE'),
        bodyText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_DESC')
      },
      DROPSHIP_NOT_AVAILABLE_MODAL: {
        status: 'info',
        maskClick: this.closeDropshipNotAvailablePopup,
        close: this.closeDropshipNotAvailablePopup,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.closeDropshipNotAvailablePopup,
            prominence: 'primary'
          }
        ],
        headerText: this.$i18n('DROPSHIP_NOT_AVAILABLE_HEADER'),
        bodyText: this.$i18n('DROPSHIP_NOT_AVAILABLE_BODY')
      },
      MEMBER_WITH_NO_STORE_MODAL: {
        status: 'info',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('HOME_PAGE.ADD_BUSINESS_ADDRESS'),
            handler: this.handleCompleteRegistrationWithoutStorePopupBtnClick,
            prominence: 'primary'
          },
          {
            label: this.$i18n('BACK'),
            handler: this.closeModal,
            prominence: 'tertiary'
          }
        ],
        headerText: this.$i18n('HOME_PAGE.NO_STORE_MODAL_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.NO_STORE_MODAL_BODY')
      },
      MAINTENANCE_MODAL: {
        status: 'info',
        maskClick: this.closeMaintenanceModal,
        close: this.closeMaintenanceModal,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.closeMaintenanceModal
          }
        ]
      }
    }
    this.addMoreAdditionalModals()
  },
  watch: {
    modalInfo (modalInfo) {
      if (modalInfo.key) {
        this.isModalVisible = true
        this.currentModalProperties = {
          ...this.modalList[modalInfo.key],
          ...modalInfo.properties
        }
        this.trackModelImpression(modalInfo.key)
      } else {
        this.isModalVisible = false
        setTimeout(() => {
          if (!this.isModalVisible) {
            // if no other modal is shown within 500ms
            this.currentModalProperties = {}
          }
          // 500ms timeout to sync with BliModal's 500ms fade-out effect
        }, 500)
      }
    }
  },
  computed: {
    ...mapState(homepageStore, ['modalInfo']),
    popupKey () {
      return this.modalInfo.key
    }
  },
  methods: {
    ...mapActions(homepageStore, ['SET_HOME_ONBOARDING_STATUS', 'SET_POPUP_INFO', 'SET_MODAL_INFO', 'SET_FULL_SCREEN_POPUP_INFO']),
    ...mapActions(profileStore, ['GET_MEMBER_DETAILS', 'UPDATE_MEMBER_ONBOARDING_STATUS']),
    addMoreAdditionalModals () {
      const moreModals = {
        HOME_ONBOARDING_SKIP_STEP_0_MODAL: {
          status: 'info',
          maskClick: this.continueHomeOnboarding,
          close: this.continueHomeOnboarding,
          controls: [
            {
              label: this.$i18n('HOME_PAGE_ONBOARDING.YES_LATER'),
              handler: this.skipHomeOnboarding,
              prominence: 'primary'
            },
            {
              label: this.$i18n('HOME_PAGE_ONBOARDING.LEARN_NOW'),
              handler: this.continueHomeOnboarding,
              prominence: 'tertiary'
            }
          ],
          headerText: this.$i18n('HOME_PAGE_ONBOARDING.SKIP_STEP_0_HEADER'),
          bodyText: this.$i18n('HOME_PAGE_ONBOARDING.SKIP_STEP_0_DESCRIPTION')
        },
        HOME_ONBOARDING_SKIP_MODAL: {
          status: 'info',
          maskClick: this.continueHomeOnboarding,
          close: this.continueHomeOnboarding,
          controls: [
            {
              label: this.$i18n('HOME_PAGE_ONBOARDING.OKAY_CLOSE'),
              handler: this.skipHomeOnboarding,
              prominence: 'primary'
            },
            {
              label: this.$i18n('HOME_PAGE_ONBOARDING.CONTINUE_ONBOARDING'),
              handler: this.continueHomeOnboarding,
              prominence: 'tertiary'
            }
          ],
          headerText: this.$i18n('HOME_PAGE_ONBOARDING.SKIP_ONBOARDING_TITLE'),
          bodyText: this.$i18n('HOME_PAGE_ONBOARDING.SKIP_ONBOARDING_DESC')
        },
        SURVEY_REJECTED_OR_SUSPENDED_MODAL: {
          status: 'alert',
          maskClick: this.closeModal,
          close: this.closeModal,
          controls: [
            {
              label: this.$i18n('CLOSE'),
              handler: this.hanldeSurveyModalClick,
              prominence: 'primary'
            }
          ],
          headerText: this.$i18n('HOME_PAGE.MICRO_SURVEY.UNABLE_TO_COMPLETE_SURVEY'),
          bodyText: this.$i18n('HOME_PAGE.MICRO_SURVEY.ACCOUNT_INACTIVE_OR_CLOSED')
        },
        NO_DRP_AND_MITRA_ID_MODAL: {
          status: 'info',
          maskClick: this.closeNoDrpAndMitraIdModal,
          close: this.closeNoDrpAndMitraIdModal,
          controls: [
            {
              label: this.$i18n('OK'),
              handler: this.closeNoDrpAndMitraIdModal,
              prominence: 'primary'
            }
          ],
          headerText: this.$i18n('HOME_PAGE.NO_DRP_OR_MITRA_ID_MODAL_HEADER'),
          bodyText: this.$i18n('HOME_PAGE.NO_DRP_OR_MITRA_ID_MODAL_BODY')
        },
        OUTSIDE_ZONE_MODAL: {
          status: 'info',
          maskClick: this.closeOutsideZoneModal,
          close: this.closeOutsideZoneModal,
          controls: [
            {
              label: this.$i18n('OK'),
              handler: this.closeOutsideZoneModal,
              prominence: 'primary'
            }
          ],
          headerText: this.$i18n('HOME_PAGE.OUTSIDE_ZONE_MODAL_HEADER'),
          bodyText: this.$i18n('HOME_PAGE.OUTSIDE_ZONE_MODAL_BODY')
        }
      }
      this.modalList = { ...this.modalList, ...moreModals }
    },
    closeModal () {
      this.SET_MODAL_INFO({ key: '' })
    },
    closeDropshipNotAvailablePopup () {
      this.closeModal()
    },
    onIncompleteRegistrationMaskClick () {
      this.closeModal()
    },
    closeUnderVerificationPopup () {
      this.closeModal()
    },
    skipHomeOnboarding () {
      this.SET_HOME_ONBOARDING_STATUS('')
      this.closeModal()
      let skipCount
      if (!localStorage.getItem('homeOnboardingSkipTime')) {
        skipCount = 1
        localStorage.setItem('homeOnboardingSkipTime', new Date().getTime())
      } else {
        skipCount = 2
        this.updateHomeOnboardingStatus()
      }
      this.$trackButtonClick('MitraHomeOnboardingSkipClick', {
        cp1: this.currentModalProperties.currentOnboardingStep,
        cp2: skipCount
      })
    },
    updateHomeOnboardingStatus () {
      this.UPDATE_MEMBER_ONBOARDING_STATUS({
        payload: { memberId: getMemberID() },
        success: this.onSuccessOfUpdateHomeOnboardingStatus,
        params: {
          workFlow: 'HOME_REVAMP_1'
        }
      })
    },
    onSuccessOfUpdateHomeOnboardingStatus () {
      localStorage.setItem('homeOnboardingStatus', true)
    },
    continueHomeOnboarding () {
      const currentOnboardingStep = this.currentModalProperties.currentOnboardingStep
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.SET_HOME_ONBOARDING_STATUS(currentOnboardingStep)
        this.closeModal()
        if (currentOnboardingStep === 'STEP0') {
          this.SET_POPUP_INFO({ key: 'HOME_ONBOARDING_POPUP' })
        }
      }, 20)
    },
    closeNoAccessModal () {
      this.closeModal()
    },
    closeNoAccessModalForRejectedUser () {
      this.closeModal()
    },
    goToHomePage () {
      this.GET_MEMBER_DETAILS({
        payload: { memberId: getMemberID() }
      })
      this.$router.push('/mitra-home')
    },
    hanldeSurveyModalClick () {
      this.closeModal()
      window.location.reload()
    },
    handleCompleteRegistrationWithoutStorePopupBtnClick () {
      this.$trackButtonClick('MitraStoreRegistrationClick')
      this.$router.push('/member-account/edit/store')
    },
    closeNoDrpAndMitraIdModal () {
      this.closeModal()
    },
    closeOutsideZoneModal () {
      this.closeModal()
    },
    goToEditStoreDetailsPage () {
      this.$router.push('/member-account/edit/store')
    },
    hanldeMitraCenterSuspensionReasonClick () {
      this.closeModal()
      this.$trackButtonClick('MitraAccountDeactivatedPopUpClick', { cp1: 'see detail' })
      this.SET_FULL_SCREEN_POPUP_INFO({ key: 'MITRA_CENTER_SUSPENSION_POPUP' })
    },
    handleCloseNoAccessModalForSuspendedUser () {
      this.$trackButtonClick('MitraAccountDeactivatedPopUpClick', { cp1: 'return' })
      this.closeModal()
    },
    hanldeWalletSuspensionReasonClick () {
      this.closeModal()
      this.SET_FULL_SCREEN_POPUP_INFO({ key: 'WALLET_SUSPENSION_POPUP' })
    },
    closeMaintenanceModal () {
      this.closeModal()
    },
    trackModelImpression (key) {
      if (key === 'NO_ACCESS_MODAL_FOR_SUSPENDED_USER') {
        this.$trackSectionView('MitraAccountDeactivatedPopUp')
      } else if (key === 'MEMBER_WITH_NO_STORE_MODAL') {
        this.$trackSectionView('MitraStoreNotRegisteredImpression')
      } else if (key === 'OUTSIDE_ZONE_MODAL') {
        this.$trackSectionView('MitraStoreOutsideZoneImpression')
      } else if (key === 'NO_DRP_AND_MITRA_ID_MODAL') {
        this.$trackSectionView('MitraNoDRPImpression')
      } else if (key === 'DROPSHIP_NOT_AVAILABLE_MODAL') {
        this.$trackSectionView('MitraDropshipNoAccessImpression')
      }
    }
  }
}
