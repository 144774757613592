import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    productData: null,
    productErrors: null,
    isFetchingProducts: false
  },
  mutations: {
    setProduct (state, productResponse) {
      state.productData = productResponse;
    },
    setProductErrors (state, productErrors) {
      state.productErrors = productErrors;
    },
    setIsFetchingProducts (state, flag) {
      state.isFetchingProducts = flag
    }
  },
  getters: {
    productData (state) {
      return state.productData;
    },
    productErrors (state) {
      return state.productErrors;
    },
    getIsFetchingProducts (state) {
      return state.isFetchingProducts;
    }
  },
  actions: {
    GET_PRODUCTS ({ commit, dispatch }, { payload, success, fail }) {
      commit('setIsFetchingProducts', true);
      digitalApi.getProducts(
        response => {
          commit('setIsFetchingProducts', false);
          if (response.data.errors) {
            commit('setProductErrors', response.data.errors);
            commit('setProduct', null);
            fail && fail(response.data.errors)
          } else {
            commit('setProduct', response.data.data);
            commit('setProductErrors', null);
            success && success(response.data.data)
          }
        },
        error => {
          console.log('[error]', error);
          fail && fail()
          errorHandler.handleErrors(dispatch, error);
        },
        'PHONE_CREDIT',
        payload
      );
    },
    RESET_PRODUCT ({ commit }) {
      commit('setProduct', null);
    },
    RESET_PULSA_ERROR ({ commit }) {
      commit('setProductErrors', null)
    }
  },
  namespaced: true
};
