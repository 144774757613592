import Vue from 'vue2'
import Router from 'vue2-router';
import { afterEach } from '@/router/interceptors'
import { addCollabRouter, propagateToMainGenerator } from '@/router/hooks'
import { migratedPages } from '@/router/helpers/migrated-routes'
import { checkAccessOfUser, checkIfMaintenanceElseGoToFeature, checkAccessForProfilePage,
  checkAccessForWalletUrls, checkAccessForReferral, validateUrlForDigitalProducts } from './routeMiddleware.js'
const Maintenance = () => import('@/pages/Maintenance.vue');
const Avatars = () => import('@/pages/profile/Avatars.vue');
const MemberAccount = () => import('@/pages/profile/Account.vue');
const MemberAvatars = () => import('@/pages/profile/Avatars.vue');
const StoreDetail = () => import('@/pages/profile/StoreDetail.vue');
const StoreDetailForNewRegistration = () => import('@/pages/profile/StoreDetail.vue');
const RetailCheckout = () => import('@/pages/RetailCheckout.vue');
const OrderHistory = () => import('@/pages/OrderHistory.vue');
const SettingPin = () => import('@/pages/SettingPin.vue');
const PushNotification = () => import('@/pages/PushNotification.vue');
const ForgotPin = () => import('@/pages/ForgotPin.vue');
const RetailOrderDetail = () => import('@/pages/RetailOrderDetail.vue');
const DigitalOrderDetail = () => import('@/pages/DigitalOrderDetail.vue');
const Pulsa = () => import('@/pages/Pulsa.vue');
const ElectricityToken = () => import('@/pages/ElectricityToken.vue');
const PackageData = () => import('@/pages/PackageData.vue');
const Pdam = () => import('@/pages/Pdam.vue');
const Bpjs = () => import('@/pages/Bpjs.vue');
const GameVoucher = () => import('@/pages/GameVoucher.vue');
const EWalletTopup = () => import('@/pages/EWalletTopup.vue');
const MoneyTransfer = () => import('@/pages/MoneyTransfer.vue');
const BlipayPin = () => import('@/pages/BlipayPin.vue');
const DigitalThankyouPage = () => import('@/pages/DigitalThankyouPage.vue');
const Bill = () => import('@/pages/Bill.vue');
const WalletTransactions = () => import('@/pages/WalletTransactions.vue');
const RetailPin = () => import('@/pages/RetailPin.vue');
const RetailThankyouPage = () => import('@/pages/RetailThankyouPage.vue');
const Help = () => import('@/pages/Help.vue');
const NotFound = () => import('@/pages/NotFound.vue');
const MultiFinance = () => import('@/pages/MultiFinance.vue');
const TvKabel = () => import('@/pages/TvKabel.vue');
const Notifications = () => import('@/pages/Notifications.vue');
const Zakat = () => import('@/pages/Zakat.vue');
const Telkom = () => import('@/pages/Telkom.vue');
const ZakatCalculator = () => import('@/components/zakat/ZakatCalculator.vue');
const Loyalty = () => import('@/pages/Loyalty.vue');
const LoyaltyTransactions = () => import('@/components/loyalty/LoyaltyTransactions.vue');
const Contests = () => import('@/pages/gamification/Contests.vue');
const ContestDetail = () => import('@/pages/gamification/ContestDetail.vue');
const RetailPromotion = () => import('@/pages/RetailPromotion.vue');
const Checkout = () => import('@/pages/checkout/Checkout.vue');
const Dropship = () => import('@/pages/dropship/Dropship.vue')
const DropshipProductList = () => import('@/pages/dropship/DropshipProductList.vue');
const SharedProductList = () => import('@/pages/dropship/SharedProductList.vue');
const ShareProduct = () => import('@/pages/dropship/ShareProduct.vue');
const DropshipProductDetails = () => import('@/components/dropship/product-details/ProductDetails.vue');
const Instructions = () => import('@/pages/camera-engine/Instructions.vue')
const Capture = () => import('@/pages/camera-engine/Capture.vue')
const CaptureImage = () => import('@/components/camera-engine/CaptureImage.vue')
const ProductList = () => import('@/pages/AllProducts.vue')
const RaffleAboutPage = () => import('@/pages/raffle/AboutPage.vue')
const RaffleRoundsPage = () => import('@/pages/raffle/RoundsPage.vue')
const FlipRegistrationPage = () => import('@/pages/FlipRegistration.vue')
const MemberProfile = () => import('@/pages/new-registration/MemberProfile.vue')
const PinCreationInfo = () => import('@/pages/PinCreationInfo.vue');
const Referral = () => import('@/pages/profile/Referral.vue');
const ReferralTnc = () => import('@/pages/profile/ReferralTnc.vue');
const ReferralHistory = () => import('@/pages/profile/ReferralHistory.vue');
Vue.use(Router)

const allRoutes = [
  // Start - Migrated Pages
  ...migratedPages,
  // End - Migrated Pages
  {
    path: '/',
    redirect: '/mitra-home'
  },
  {
    path: '/home',
    redirect: '/mitra-home'
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/products',
    name: 'ProductList',
    component: ProductList
  },
  {
    path: '/referral',
    name: 'Referral',
    component: Referral,
    beforeEnter: (to, from, next) => {
      checkAccessForReferral(to, from, next)
    }
  },
  {
    path: '/referral/tnc',
    name: 'ReferralTnc',
    component: ReferralTnc,
    beforeEnter: (to, from, next) => {
      checkAccessForReferral(to, from, next)
    }
  },
  {
    path: '/referral/history',
    name: 'ReferralHistory',
    component: ReferralHistory,
    beforeEnter: (to, from, next) => {
      checkAccessForReferral(to, from, next)
    }
  },
  {
    path: '/member-account',
    name: 'MemberAccount',
    component: MemberAccount
  },
  {
    path: '/account/edit/avatar',
    name: 'Avatars',
    component: Avatars,
    redirect: '/member-profile/edit/avatar'
  },
  {
    path: '/member-profile/edit/avatar',
    name: 'MemberAvatars',
    component: MemberAvatars
  },
  {
    path: '/account/edit/store',
    name: 'StoreDetail',
    component: StoreDetail,
    redirect: '/member-account/edit/store'
  },
  {
    path: '/member-account/edit/store',
    name: 'StoreDetailForNewRegistration',
    component: StoreDetailForNewRegistration,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/retail-checkout',
    name: 'RetailCheckout',
    component: RetailCheckout,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/order',
    name: 'OrderHistory',
    component: OrderHistory,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/confirm-order',
    name: 'Checkout',
    component: Checkout,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/setting-pin',
    name: 'SettingPin',
    component: SettingPin,
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/push-notification',
    name: 'PushNotification',
    component: PushNotification,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/forgot-pin',
    name: 'ForgotPin',
    component: ForgotPin,
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/order/retail/:orderId',
    name: 'RetailOrderDetail',
    component: RetailOrderDetail
  },
  {
    path: '/order/digital/:orderId',
    name: 'DigitalOrderDetail',
    component: DigitalOrderDetail
  },
  {
    path: '/digital/pulsa',
    name: 'Pulsa',
    component: Pulsa,
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/digital/token-listrik',
    name: 'ElectricityToken',
    component: ElectricityToken,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/paket-data',
    name: 'PackageData',
    component: PackageData,
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/digital/pdam',
    name: 'Pdam',
    component: Pdam,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/bpjs',
    name: 'Bpjs',
    component: Bpjs,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/game-voucher',
    name: 'GameVoucher',
    component: GameVoucher,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/blipay',
    redirect: '/mitra/digital/blipay'
  },
  {
    path: '/digital/topup',
    name: 'EWalletTopup',
    component: EWalletTopup,
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/digital/money-transfer',
    name: 'MoneyTransfer',
    component: MoneyTransfer,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/order/payment/:orderId',
    name: 'BlipayPin',
    component: BlipayPin,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/digital/order/thank-you/:orderId',
    name: 'digitalThankyou',
    component: DigitalThankyouPage,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/bills/:productType/:orderId',
    name: 'bills',
    component: Bill,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/blipay/cashout',
    redirect: '/mitra/digital/blipay/cashout'
  },
  {
    path: '/wallet-transactions',
    name: 'WalletTransactions',
    component: WalletTransactions,
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/retail/order/payment/:orderId',
    name: 'RetailPin',
    component: RetailPin,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/retail/order/thank-you/:orderId',
    name: 'RetailThankyouPage',
    component: RetailThankyouPage,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/mitra-pwa',
    redirect: '/mitra-home'
  },
  {
    path: '/digital/multi-finance',
    name: 'MultiFinance',
    component: MultiFinance,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/tv-kabel',
    name: 'TvKabel',
    component: TvKabel,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/digital/zakat',
    name: 'Zakat',
    component: Zakat,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/telkom',
    name: 'Telkom',
    component: Telkom,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/digital/zakat/calculator',
    name: 'ZakatCalculator',
    component: ZakatCalculator,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/contests',
    name: 'Contests',
    component: Contests,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/contests/:contestType/:id',
    name: 'ContestDetail',
    component: ContestDetail,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/retail/promotion',
    name: 'RetailPromotion',
    component: RetailPromotion,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/loyalty',
    name: 'Loyalty',
    component: Loyalty
  },
  {
    path: '/loyalty-transactions',
    name: 'LoyaltyTransactions',
    component: LoyaltyTransactions,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/dropship',
    name: 'Dropship',
    component: Dropship,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/dropship/products',
    name: 'DropshipProductList',
    component: DropshipProductList,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/dropship/share-options',
    name: 'DropshipShareProduct',
    component: ShareProduct,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/dropship/shared-products',
    name: 'DropshipSharedProductList',
    component: SharedProductList,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/dropship/product-details',
    name: 'DropshipProductDetails',
    component: DropshipProductDetails,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/capture',
    name: 'Capture',
    component: Capture,
    children: [
      {
        path: 'instructions',
        name: 'Instructions',
        component: Instructions
      },
      {
        path: 'image',
        name: 'CaptureImage',
        component: CaptureImage
      }
    ]
  },
  {
    path: '/engagement/raffle',
    name: 'EngagementRaffle',
    component: RaffleAboutPage,
    beforeEnter: (to, from, next) => {
      checkIfMaintenanceElseGoToFeature(to, from, next)
    }
  },
  {
    path: '/engagement/raffle/rounds',
    name: 'EngagementRaffleRounds',
    component: RaffleRoundsPage,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/digital/money-transfer/flip-registration',
    name: 'FlipRegistration',
    component: FlipRegistrationPage,
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/member-profile',
    name: 'MemberProfile',
    component: MemberProfile,
    beforeEnter: (to, from, next) => {
      checkAccessForProfilePage(to, from, next)
    }
  },
  {
    path: '/pin-creation-info',
    name: 'PinCreationInfo',
    component: PinCreationInfo,
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
});

router.afterEach((to, from) => {
  console.log(from)
  if (window.newrelic) {
    const i = window.newrelic.interaction()
    window.newrelic.setPageViewName(window.location.href)
    i.save(() => {
      i.createTracer(to.path, function () {
        console.log('Event ended')
      })
    })
  }
})

router.onError(error => {
  console.log('router on error hook:', error);
  const err = error.message; // 'Loading chunk WEBPACK_CHUNK_NAME failed.' eg: Loading chunk p-store failed.
  const msg = err.toLowerCase();
  const extractedKey = msg.split(' ').filter((e, i) => i !== 2).join('_');
  const pattern = /(failed_to_dynamically_imported_module|loading_chunk_failed)/i;

  if (pattern.test(extractedKey)) {
    window.location.reload();
  }
});

router.afterEach(afterEach)
// add collab router hook
window.addCollabRouter = addCollabRouter
window.propagateToMain = propagateToMainGenerator(router)

export default router;
