import axios from 'axios';
import { serializeQueryParams } from './apiUtils';
import customHeader from './params';
let headers = {};
Object.assign(headers, customHeader.headerParameters);
import { userAuthStore } from '@vue3/store/auth-store'
import { initializeInterceptor } from './axiosInterceptor';

// To invoke axios interceptor for maintenace page redirect
initializeInterceptor()

function handleCallback (response, callback) {
  const isConfigApi = response.config.url.startsWith('/backend/content')
  if (isConfigApi) {
    return callback(response);
  }
  const { code, data } = response.data;
  const isSuccessful = code === 200;
  let responseObj = {};
  try {
    responseObj = data && typeof data === 'string' ? JSON.parse(data) : responseObj
  } catch (e) {
    console.log(e)
  }
  if (isSuccessful && !(responseObj.code >= 400)) {
    userAuthStore().SET_LOGIN_STATUS(true)
  }
  callback(response);
}

export default {
  makeGetRequest (path, callback, fail, params, cancelToken) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios
      .get(path, { withCredentials: true, headers, cancelToken: cancelToken })
      .then((response) => { handleCallback(response, callback) })
      .catch(fail);
  },
  makePostRequest (path, callback, fail, payload, params, cancelToken) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios
      .post(path, payload, { withCredentials: true, headers, cancelToken: cancelToken })
      .then(callback)
      .catch(fail);
  },
  makeDeleteRequest (path, callback, fail, params) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios
      .delete(path, { withCredentials: true, headers })
      .then(callback)
      .catch(fail);
  },
  makePutRequest (path, callback, fail, payload, params) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios
      .put(path, payload, { withCredentials: true, headers })
      .then(callback)
      .catch(fail);
  },
  uploadFile (path, callback, fail, payload, params) {
    headers.Accept = 'application/json;charset=UTF-8';
    headers['Content-Type'] = 'multipart/form-data';
    path += serializeQueryParams(params);
    axios
      .post(path, payload, { withCredentials: true, headers })
      .then(callback)
      .catch(fail);
  },
  getImageFromBlob (path, callback, fail, params) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios.get(path, { responseType: 'arraybuffer', withCredentials: true, headers }).then(callback).catch(fail)
  },
  imageFromBlob (path, callback, fail, payload, params) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    path += serializeQueryParams(params);
    axios.post(path, payload, { responseType: 'arraybuffer', withCredentials: true, headers }).then(callback).catch(fail)
  }
};
