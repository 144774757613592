import { defineStore } from 'pinia'
import api from '@vue3/api/homepageApis';
import cmsApi from '@vue3/api/cms-api';
import { isEmpty, setAppConfiguration,
  getAppConfiguration, getCondensedBannersConfigResponse,
  getCondensedProductsConfigResponse } from '@/utils/helpers';
import errorHandler from '@vue3/api/apiUtils/errorHandler';
import { root } from '@vue3/store/root'

export const homepageStore = defineStore('homepageStore', {
  state: () => {
    return {
      banners: [],
      products: [],
      walletRequest: {},
      isWalletRequestFetched: false,
      configs: {},
      walletTransactions: [],
      whatsNewInfo: {},
      npsDetails: {},
      autoSuggestedProducts: [],
      frequentlyAskedQuestions: null,
      avatarsConfig: null,
      memberBanners: null,
      vaInstruction: null,
      vaPaymentInstruction: null,
      referralFAQ: null,
      deleteAccountConfig: null,
      twaVersion: null,
      onboardingStatus: '',
      modalInfo: {},
      popupInfo: {},
      fullScreenPopupInfo: {},
      isRaffleSpecialProgramSectionDisplayed: false,
      createObserverIfApplicable: false,
      npsRating: 0,
      contestsDashboard: {},
      maintainencePopup: {},
      walletRequestError: false,
      walletRequestLoader: false,
      quickOrderDetails: [],
      displayWalletInfoPopup: false,
      mitraReactiveConfig: {}
    }
  },
  actions: {
    GET_BANNERS_AND_SERVICES () {
      api.getServicesPromotion(
        response => {
          if (response.data.code === 200) {
            const mainResponse = response.data.data[0];
            const bannerConfig = getCondensedBannersConfigResponse(mainResponse.blocks[0].components)
            const productConfig = getCondensedProductsConfigResponse(mainResponse.blocks[1].components)
            this.banners = bannerConfig
            this.products = productConfig
            setAppConfiguration('mitraProductsConfig', JSON.stringify(productConfig))
            setAppConfiguration('mitraBannersConfig', JSON.stringify(bannerConfig))
          } else {
            const getProductsConfig = getAppConfiguration('mitraProductsConfig')
            const getBannersConfig = getAppConfiguration('mitraBannersConfig')
            if (!getProductsConfig || !getBannersConfig) {
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            } else {
              this.SET_CONFIGS({ typeOfConfiguration: 'mitraBannersConfig', configs: JSON.parse(getBannersConfig) })
              this.SET_CONFIGS({ typeOfConfiguration: 'mitraProductsConfig', configs: JSON.parse(getProductsConfig) })
            }
          }
        },
        error => {
          const getProductsConfig = getAppConfiguration('mitraProductsConfig')
          const getBannersConfig = getAppConfiguration('mitraBannersConfig')
          if (!getProductsConfig || !getBannersConfig) {
            errorHandler.handleErrors(error)
          } else {
            this.SET_CONFIGS({ typeOfConfiguration: 'mitraBannersConfig', configs: JSON.parse(getBannersConfig) })
            this.SET_CONFIGS({ typeOfConfiguration: 'mitraProductsConfig', configs: JSON.parse(getProductsConfig) })
          }
        }
      );
    },
    GET_MEMBER_BANNERS ({ payload }) {
      api.getMemberBanners(
        response => {
          if (response.data.code === 200) {
            this.memberBanners = response.data.data
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        payload
      );
    },
    GET_APP_CONFIGURATION ({ typeOfConfiguration, success, fail }) {
      cmsApi.getConfig(response => {
        if (response.data.code === 200) {
          if (typeOfConfiguration === 'mitraWhatsNew') {
            this.whatsNewInfo = JSON.parse(response.data.data)
          } else if (typeOfConfiguration === 'mitraOrdersList') {
            window.localStorage.setItem('orderList', JSON.stringify(response.data.data));
          } else if (typeOfConfiguration === 'mitraFaqList') {
            this.frequentlyAskedQuestions = response.data.data
          } else if (typeOfConfiguration === 'mitraAvatarsConfig') {
            this.avatarsConfig = JSON.parse(response.data.data)
          } else if (typeOfConfiguration === 'mitraMaintenanceConfig') {
            window.localStorage.setItem('maintenanceConfig', response.data.data);
          } else if (typeOfConfiguration === 'mitraVaInstruction') {
            this.vaInstruction = JSON.parse(response.data.data)
          } else if (typeOfConfiguration === 'mitraPwaConfiguration' || typeOfConfiguration === 'mitraCashoutTerms') {
            this.configs = Object.assign({}, this.configs, JSON.parse(response.data.data))
          } else if (typeOfConfiguration === 'mitraVaPaymentInstruction') {
            this.vaPaymentInstruction = JSON.parse(response.data.data)
          } else if (typeOfConfiguration === 'mitraReferralFAQ') {
            this.referralFAQ = JSON.parse(response.data.data)
          } else if (typeOfConfiguration === 'mitraRegistrationTnc') {
            window.localStorage.setItem('mitraRegistrationTnc', response.data.data);
          } else if (typeOfConfiguration === 'mitraRegistrationPolicy') {
            window.localStorage.setItem('mitraRegistrationPolicy', response.data.data);
          } else if (typeOfConfiguration === 'mitraReferralConfig') {
            setAppConfiguration('referralConfig', (response.data.data))
          } else if (typeOfConfiguration === 'mitraCheckinMissionConfig') {
            window.localStorage.setItem('mitraCheckinMissionConfig', (response.data.data))
          } else if (typeOfConfiguration === 'mitraAccountDeleteConfig') {
            window.localStorage.setItem('mitraDeleteAccountConfig', response.data.data)
          }
          success && success(response.data.data)
        }
      },
      error => {
        errorHandler.handleErrors(error)
        fail && fail()
      },
      typeOfConfiguration
      )
    },
    GET_REACTIVE_APP_CONFIG ({ typeOfConfiguration }) {
      cmsApi.getConfig(response => {
        if (response.data.code === 200) {
          this.mitraReactiveConfig = JSON.parse(response.data.data)
        }
      },
      error => {
        errorHandler.handleErrors(error, true)
      },
      typeOfConfiguration
      )
    },
    GET_WALLET_REQUEST ({ payload }) {
      this.walletRequestLoader = true
      this.isWalletRequestFetched = false
      api.getWalletRequestData(
        response => {
          this.walletRequestError = false
          this.walletRequestLoader = false
          this.isWalletRequestFetched = true
          if (response.data.code === 200) {
            this.walletRequest = response.data.data
            window.dispatchEvent(new CustomEvent('update.vuex.state.from.pinia', { detail: { eventType: 'WALLET_DETAILS', data: this.walletRequest } }))
          } else if (response.data.code !== 200) {
            this.walletRequestError = true
          } else {
            if (isEmpty(this.walletRequest)) {
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          }
        },
        error => {
          this.isWalletRequestFetched = true
          this.walletRequestLoader = false
          this.walletRequest = {}
          window.dispatchEvent(new CustomEvent('update.vuex.state.from.pinia', { detail: { eventType: 'WALLET_DETAILS', data: this.walletRequest } }))
          this.walletRequestError = true
          errorHandler.handleErrors(error, true)
        },
        payload
      );
    },
    SET_CONFIGS ({ typeOfConfiguration, configs }) {
      if (typeOfConfiguration === 'mitraPwaConfiguration' || typeOfConfiguration === 'mitraCashoutTerms') {
        this.configs = configs
      } else if (typeOfConfiguration === 'mitraVaInstruction') {
        this.vaInstruction = configs
      } else if (typeOfConfiguration === 'mitraProductsConfig') {
        this.products = configs
      } else if (typeOfConfiguration === 'mitraBannersConfig') {
        this.banners = configs
      } else if (typeOfConfiguration === 'mitraVaPaymentInstruction') {
        this.vaPaymentInstruction = configs
      }
    },
    SET_POPUP_INFO (popupInfo) {
      const { key, properties = {} } = popupInfo
      this.popupInfo = {
        key,
        class: '',
        title: '',
        ...properties
      }
    },
    SET_MODAL_INFO (modalInfo) {
      const { key, properties = {} } = modalInfo
      this.modalInfo = {
        key,
        properties
      }
    },
    SET_WALLET_INFO (value) {
      this.displayWalletInfoPopup = value
    },
    SET_WALLET_REQUEST (walletResponse) {
      this.walletRequest = walletResponse
      window.dispatchEvent(new CustomEvent('update.vuex.state.from.pinia', { detail: { eventType: 'WALLET_DETAILS', data: this.walletRequest } }))
    },
    SET_HOME_ONBOARDING_STATUS (status) {
      this.onboardingStatus = status
    },
    GET_QUICK_ORDER_DETAILS (params) {
      api.getQuickOrderDetails(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            this.quickOrderDetails = response.data.data.orders
          }
        },
        error => {
          errorHandler.handleErrors(error, true)
        },
        params
      );
    },
    GET_WALLET_TRANSACTIONS ({ payload, success, fail }) {
      api.getWalletTransaction(response => {
        if (response.data.code === 200) {
          this.walletTransactions = response.data.data
          success()
        } else {
          if (fail) {
            fail()
          }
          root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
          setTimeout(() => {
            root().RESET_ERROR_TOAST()
          }, 3000)
        }
      }, error => {
        fail();
        errorHandler.handleErrors(error)
      }, payload)
    },
    GET_AUTO_SUGGESTED_PRODUCTS (params) {
      api.getAutoSuggestedProducts(
        response => {
          if (response.data.code === 200) {
            this.autoSuggestedProducts = response.data.data.orderItems
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error, true)
        },
        params
      );
    },
    GET_NPS ({ payload }) {
      api.getNPS(
        response => {
          if (response.data.code === 200) {
            this.npsDetails = response.data.data
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error, true)
        },
        payload
      );
    },
    SUBMIT_NPS ({ payload, success, fail }) {
      api.submitNPS(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success && success(response.data.data);
            } else {
              fail && fail()
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          } else {
            fail && fail()
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error)
          fail && fail()
        },
        payload
      );
    },
    GET_CONTESTS_DASHBOARD ({ payload }) {
      api.getContestsDashboard(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            this.contestsDashboard = response.data.data
          }
        },
        error => {
          errorHandler.handleErrors(error, true)
        },
        payload
      );
    },
    RESET_AUTO_SUGGESTED_PRODUCTS () {
      this.autoSuggestedProducts = []
    },
    SET_RAFFLE_SPECIAL_PROGRAM_SECTION_DISPLAYED () {
      this.isRaffleSpecialProgramSectionDisplayed = true
    },
    SET_TWA_VERSION (version) {
      this.twaVersion = version
    },
    SAVE_INSTALLED_APPS_DATA ({ payload, success }) {
      api.saveInstalledAppData(response => {
        if (response.data.code === 200) {
          success(response.data.data)
        }
      }, error => {
        console.log(error)
      },
      payload
      );
    },
    SET_NPS_RATING (value) {
      this.npsRating = value
    },
    SET_FULL_SCREEN_POPUP_INFO (fullScreenPopupInfo) {
      const { key, properties = {} } = fullScreenPopupInfo
      this.fullScreenPopupInfo = {
        key,
        class: '',
        title: '',
        ...properties
      }
    },
    UPDATE_MEMBER_DETAILS ({ payload, success, fail }) {
      api.updateMemberDetails(
        response => {
          const responseData = response.data
          if (responseData.code === 200) {
            success(responseData.data);
          } else if (responseData.errors && responseData.errors.verificationStatus) {
            fail && fail()
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error)
        },
        payload
      );
    },
    SET_MAINTAINENCE_POPUP (maintainencePopup) {
      const { isMaintainencePopupVisible, date, time } = maintainencePopup
      this.maintainencePopup = {
        isMaintainencePopupVisible,
        date,
        time
      }
    },
    CREATE_OBSERVER (value) {
      this.createObserverIfApplicable = value
    },
    SET_WALLET_REQUEST_ERROR (value) {
      this.walletRequestError = value;
    },
    SET_WALLET_REQUEST_FROM_VUEX (value) {
      this.walletRequest = value
    }
  }
})
