import retailCheckoutApi from '@vue3/api/retailCheckoutApis';
import errorHandler from '@vue3/api/apiUtils/errorHandler';
import { defineStore } from 'pinia'
import { root } from '@vue3/store/root'

export const retailCheckoutStore = defineStore('retailCheckoutStore', {
  state: () => {
    return {
      cartDetails: {},
      productList: [],
      categories: [],
      paging: null,
      isFetchingList: true,
      subCategories: [],
      lastOrderItems: [],
      subCategoryList: [],
      isAddingItemToCart: false,
      orderDetail: {},
      vouchersList: [],
      voucherDetails: {},
      isFetchingProductList: false,
      cartItemsBySKU: {},
      wholesaleOnboardingSku: '',
      headerInfo: {
        title: '',
        checkoutSteps: null
      },
      retailCartOnboardingStatus: '',
      isRetailCartOnboardingInProgress: false,
      itemToScroll: '',
      isPaymentScreen: false,
      isUserFromPaymentScreen: false,
      checkoutTab: '',
      isCancelAllVoucherInProgress: false,
      isShippingMethodUpdateInProgress: false,
      isCartApiCalledFromHomePage: false,
      maxDiscountedValue: 0,
      showMFDProductModal: false
    }
  },
  actions: {
    GET_CART_ITEMS ({ params, success, fail } = {}) {
      this.isFetchingList = true
      retailCheckoutApi.getCartItems(
        response => {
          this.isFetchingList = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              this.SET_CART_DETAILS(response.data.data)
              success && success(response.data.data)
            } else {
              if (fail) {
                fail(response.data.errors)
              } else {
                root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
                setTimeout(() => {
                  root().RESET_ERROR_TOAST()
                }, 3000)
              }
            }
          }
        },
        error => {
          this.isFetchingList = false
          if (!this.isCartApiCalledFromHomePage) {
            errorHandler.handleErrors(error)
          }
          this.isCartApiCalledFromHomePage = false
        },
        params
      );
    },
    SET_IS_CART_API_CALLED_FROM_HOME_PAGE (value) {
      this.isCartApiCalledFromHomePage = value
    },
    ADD_TO_CART ({ payload, params, success, fail, hideErrorPopup } = {}) {
      this.isAddingItemToCart = true
      retailCheckoutApi.addItemToCart(
        response => {
          this.isAddingItemToCart = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              this.SET_CART_DETAILS(response.data.data)
              success(response.data.data)
            } else {
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          } else {
            fail && fail()
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          fail && fail()
          this.isAddingItemToCart = false
          errorHandler.handleErrors(error, hideErrorPopup)
          if (hideErrorPopup) {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        payload,
        params
      )
    },
    SET_CART_DETAILS (cartResponse) {
      this.cartDetails = cartResponse
      let cartItems = {}
      if (cartResponse.items) {
        cartResponse.items.reduce((accumulator, item) => {
          accumulator[item.itemSku] = item
          return accumulator
        }, cartItems)
      }
      this.cartItemsBySKU = cartItems
    },
    UPDATE_CART_ITEM ({ success, params, payload, pathVariables, fail } = {}) {
      this.isFetchingList = true
      retailCheckoutApi.itemCheckboxUpdate(
        response => {
          this.isFetchingList = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
                  response.data.errors === undefined
            ) {
              this.SET_CART_DETAILS(response.data.data)
              success && success(response.data.data);
            }
          } else {
            fail()
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          this.isFetchingList = false
          fail && fail();
          errorHandler.handleErrors(error)
        },
        pathVariables,
        params,
        payload
      )
    },
    SET_CHECKOUT_TAB_TYPE (status) {
      this.checkoutTab = status
    },
    SET_HEADER_INFO (retailCheckoutHeaderInfo) {
      this.headerInfo = retailCheckoutHeaderInfo
    },
    GET_PRODUCTS_LIST ({ success, params, payload, pathVariables, fail } = {}) {
      this.isFetchingProductList = true
      retailCheckoutApi.getProductsList(
        response => {
          this.isFetchingProductList = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (params.page === 0) {
                this.productList = response.data.data
              } else {
                this.productList = [...this.productList, ...response.data.data]
              }
              this.paging = response.data.paging
              if (success) {
                success(response.data.data);
              }
            } else {
              fail(response.data.errors);
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          this.isFetchingProductList = false
          errorHandler.handleErrors(error)
        },
        pathVariables,
        params,
        payload
      )
    },
    GET_FLASH_SALE_PRODUCT_LIST ({ success, params, payload, pathVariables, fail } = {}) {
      this.isFetchingProductList = true
      retailCheckoutApi.getProductsList(
        response => {
          this.isFetchingProductList = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (params.page === 0) {
                this.productList = response.data.data.filter(item => !item.outOfStock)
              } else {
                const filteredProducts = response.data.data.filter(item => !item.outOfStock)
                this.productList = [...this.productList, ...filteredProducts]
              }
              this.paging = response.data.paging
              if (success) {
                success(response.data.data);
              }
            } else {
              fail(response.data.errors);
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          this.isFetchingProductList = false
          errorHandler.handleErrors(error, true)
        },
        pathVariables,
        params,
        payload
      )
    },
    UPDATE_ITEM_QUANTITY ({ payload, params, success, fail, hideErrorPopup } = {}) {
      this.isAddingItemToCart = true
      retailCheckoutApi.updateItemQuantity(
        response => {
          this.isAddingItemToCart = false
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              this.SET_CART_DETAILS(response.data.data)
              success(response.data.data)
            } else {
              fail(response.data.errors)
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          } else {
            fail(response.data.errors)
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          this.isAddingItemToCart = false
          fail(error)
          errorHandler.handleErrors(error, hideErrorPopup)
          if (hideErrorPopup) {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        payload,
        params
      );
    },
    SUBSCRIBE_NOTIFICATION ({ success, params, payload, pathVariables, hideErrorPopup } = {}) {
      retailCheckoutApi.subscribeForNotification(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success(response.data.data)
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error, hideErrorPopup)
        },
        pathVariables,
        params,
        payload
      );
    },
    DEL_CART_ITEM ({ payload, params, success, hideErrorPopup } = {}) {
      this.isAddingItemToCart = true
      root().SET_LOADER(true)
      retailCheckoutApi.deleteCartItem(
        response => {
          this.isAddingItemToCart = false
          root().SET_LOADER(false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              this.SET_CART_DETAILS(response.data.data)
              success && success(response.data.data);
            } else {
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          root().SET_LOADER(false)
          this.isAddingItemToCart = false
          errorHandler.handleErrors(error, hideErrorPopup)
        },
        payload,
        params
      );
    }
  }
})
