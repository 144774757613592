const API_BASE_PATH = '/api/';
const CMS_API_PATH = '/backend/content/';
const X_REGION_BASE_PATH = '/backend/common/'
const services = '/backend/content/pages/mitra/sections?display=normal';


export default {
  api: {
    getPnLData () {
      return `${API_BASE_PATH}mitra/pnl/V2`
    },
    fetchDigitalCart: `${API_BASE_PATH}digital/carts/`,
    getVouchersDetailsDigital (promoCode) {
      return `${API_BASE_PATH}digital/promo-codes/${promoCode}`;
    },
    getDigitalProducts (productType) {
      return `${API_BASE_PATH}digital/products/${productType}`;
    },
    getVouchersListDigital: `${API_BASE_PATH}digital/promo-codes/_contextual_adjustment`,
    applyVoucherDigital: `${API_BASE_PATH}digital/promo-codes/_apply`,
    removeVoucherDigital: `${API_BASE_PATH}digital/promo-codes/_unapply`,
    addCartDigital: `${API_BASE_PATH}digital/carts`,
    setNumberDigital: `${API_BASE_PATH}digital/carts/_customer-number`,
    payDigital: `${API_BASE_PATH}digital/orders`,
    digitalInquiry: `${API_BASE_PATH}digital/carts/_customer-number-inquiry`,
    pollMoneyTransferStatus: `${API_BASE_PATH}digital/carts/bankInquiryStatus`,
    getMemberDetails () {
      return `${API_BASE_PATH}mitra/members/get/details`;
    },
    getRejectedReasons () {
      return `${API_BASE_PATH}mitra/members/questions`;
    },
    getGenerateOtp () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin/member`;
    },
    generateOtp () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin`;
    },
    getVerifyOtpApi () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin/member/_confirm`;
    },
    getVerifyKtpApi () {
      return `${API_BASE_PATH}mitra/members/verify-ktp`;
    },
    getSignedUrlToUpload () {
      return `${API_BASE_PATH}mitra/members/image`;
    },
    getProfileKtpUploadApi () {
      return `${API_BASE_PATH}mitra/members/upload/image/v2`;
    },
    getDownloadKTP_API () {
      return `${API_BASE_PATH}mitra/members/download/image`;
    },
    getStoreTypeList: API_BASE_PATH + 'mitra/store/types',
    getMemberInProgressApi () {
      return `${API_BASE_PATH}mitra/members/inprogress`;
    },
    getSaveMemberDetailApi () {
      return `${API_BASE_PATH}mitra/members/addMemberDetails`;
    },
    getTvKabelProducts (productType) {
      return `${API_BASE_PATH}digital/products/${productType}`
    },
    getBlipayProducts: API_BASE_PATH + 'v1/mitra/wallet/products',
    digitalOperator (productType) {
      return `${API_BASE_PATH}digital/products/${productType}/operator`;
    },
    payOrderWithWallet: '/api/mitra/wallet/purchase/_verify',
    getDigitalOrderDetail (orderId) {
      return `/api/digital/orders/${orderId}`;
    },
    cancelDigitalOrder: '/api/digital/orders/_cancelPayment',
    repayDigitalOrder (orderId) {
      return `/api/digital/orders/repay/${orderId}`
    },
    getOrderListApi: API_BASE_PATH + 'mitra/member/getProductDisplaySequence',
    addBankAccount: API_BASE_PATH + 'bank-accounts',
    cashout: API_BASE_PATH + 'wallet/cashouts/081234567890',
    showTransactionHistory:
      API_BASE_PATH +
      'wallet/transactions/081234567890/_get-by-transaction-type',
    getBankList: API_BASE_PATH + 'mitra/wallet/banks',
    getAddBankAccount () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member`;
    },
    getCashoutApi () {
      return `${API_BASE_PATH}mitra/wallet/cashouts/member`;
    },
    getTransactionHistory () {
      return `${API_BASE_PATH}mitra/wallet/transactions/member`;
    },
    getMemberBankList () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member`;
    },
    getDeleteUpdateMemberBankAccount () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member/bank-accounts`;
    },
    getCartDigital: `${API_BASE_PATH}digital/carts/payments`,
    setDigitalPayment: `${API_BASE_PATH}digital/carts/_payment`,
    digitalOrders: `${API_BASE_PATH}digital/orders/_histories`,
    getCartItems: API_BASE_PATH + 'retail/carts',
    getServiceAndPromotion: services,
    deleteCartItem: API_BASE_PATH + 'retail/carts/item/_delete',
    updateItemQuantity: API_BASE_PATH + 'retail/carts/item',
    addItemToCart: API_BASE_PATH + 'retail/carts/item',
    changePaymentType: API_BASE_PATH + 'retail/carts/changePayment',
    checkboxUpdate: API_BASE_PATH + 'retail/carts/checkboxUpdate',
    getQuickOrderDetails: API_BASE_PATH + 'retail/orders/getQuickOrderDetails',
    getIndihomePackages (productType) {
      return `${API_BASE_PATH}digital/products/${productType}`
    },
    getProductsList (memberId) {
      return `${API_BASE_PATH}retail/search/${memberId}`
    },
    verifyUserPin (memberId) {
      return `${API_BASE_PATH}mitra/blipaypin/${memberId}/_verify`;
    },
    createOrder: API_BASE_PATH + 'retail/orders',
    getOrderByOrderId: `${API_BASE_PATH}retail/orders`,
    getWalletRequests () {
      return `${API_BASE_PATH}mitra/wallet/member`;
    },
    getCategories: `${API_BASE_PATH}retail/search/categories`,
    getLastOrder: `${API_BASE_PATH}retail/orders/last-paid`,
    getConfig (configName) {
      return `${CMS_API_PATH}configs/${configName}`
    },
    digitalConfig: `${API_BASE_PATH}digital/config`,
    getWhatsAppOptApi () {
      return `${API_BASE_PATH}mitra/members/opt-whatsapp`;
    },
    getOrderHistory: `${API_BASE_PATH}retail/orders/history`,
    trackOrderItem (orderId, orderItemId) {
      return `${API_BASE_PATH}retail/orders/${orderId}/item/${orderItemId}/logistics-info`;
    },
    getMitraSessionInfo: API_BASE_PATH + 'mitra/session',
    logoutUrl: API_BASE_PATH + 'authentication/destroy',
    getAddressDetailsApi: API_BASE_PATH + 'mitra/region/subdistricts',
    getReferralDetailsApi: API_BASE_PATH + 'mitra/referral/getReferral',
    getReferralEligibleFlagApi: API_BASE_PATH + 'mitra/referral/getEligibleFlag',
    getReferralRewardDetails: API_BASE_PATH + 'mitra/referral/getCashBackAndLoyaltyPoints',
    getReferralRewardHistory: API_BASE_PATH + 'mitra/referral/history',
    clearCart: API_BASE_PATH + 'retail/carts',
    getOrderDetail (orderId) {
      return `${API_BASE_PATH}retail/orders/history/${orderId}`
    },
    repayOrder (orderId) {
      return `${API_BASE_PATH}retail/carts/repay/${orderId}`
    },
    cancelOrder: `${API_BASE_PATH}retail/carts/cancelPayment`,
    getGameList: `${API_BASE_PATH}digital/operators/GAME_VOUCHER_TOPUP`,
    updateMemberDetails () {
      return `${API_BASE_PATH}mitra/members/edit/details`;
    },
    updateMemberDetailsNewApi () {
      return `${API_BASE_PATH}mitra/member/register/edit`;
    },
    getDigitalOperators (productType) {
      return `${API_BASE_PATH}digital/operators/${productType}`
    },
    getAllInAppNotification () {
      return `${API_BASE_PATH}mitra/notifications/member`
    },
    getNotificationDashboard () {
      return `${API_BASE_PATH}mitra/notifications/dashboard`
    },
    getContestsDashboard () {
      return `${API_BASE_PATH}mitra/members/contests/dashboard`
    },
    updateStatusOfInAppNotification () {
      return `${API_BASE_PATH}mitra/notifications/updateTokenTime`
    },
    sendFcmTokenToServer () {
      return `${API_BASE_PATH}mitra/notifications/token`
    },
    deleteToken () {
      return `${API_BASE_PATH}mitra/notifications/token`
    },
    getVouchersList: `${API_BASE_PATH}retail/vouchers`,
    getRetailMaxDiscountedValue: `${API_BASE_PATH}retail/vouchers/maxPromoValue`,
    getVouchersDetails (code) {
      return `${API_BASE_PATH}retail/vouchers/${code}`
    },
    getDigitalMaxDiscountedValue: `${API_BASE_PATH}digital/promo-codes/getMaxPromoCode`,
    deletePaymentSpecificVouchersdigital: `${API_BASE_PATH}digital/promo-codes/_removeAll`,
    applyVoucher: `${API_BASE_PATH}retail/vouchers/apply`,
    removeVoucher: `${API_BASE_PATH}retail/vouchers/unapply`,
    deletePaymentSpecificVouchers: `${API_BASE_PATH}retail/vouchers/delete`,
    getAutoSuggestedProducts: `${API_BASE_PATH}retail/orders/last-paid`,
    getAllStation: `${API_BASE_PATH}travel/rail/station`,
    getAllPopularStation: `${API_BASE_PATH}travel/rail/station/popular`,
    searchTrains: `${API_BASE_PATH}travel/rail/search/train`,
    addTrainsToCart: `${API_BASE_PATH}travel/rail/cart/item`,
    getTrainCart: `${API_BASE_PATH}travel/rail/cart`,
    addPassengerToCart: `${API_BASE_PATH}travel/rail/cart/passenger`,
    checkoutCart: `${API_BASE_PATH}travel/rail/cart/checkout`,
    bookCart: `${API_BASE_PATH}travel/rail/book`,
    checkoutCurrentOrder: `${API_BASE_PATH}travel/rail/order/checkout`,
    trainOrderHistoryAndDetail: `${API_BASE_PATH}travel/rail/order`,
    trainPayOrder: `${API_BASE_PATH}travel/rail/order/pay`,
    pollTrainOrderStatus (orderId) {
      return `${API_BASE_PATH}travel/rail/order/${orderId}/status`
    },
    trainOrderDetailWithBookingCode (orderId) {
      return `${API_BASE_PATH}travel/rail/order/${orderId}`
    },
    trainCancelOrder (orderId) {
      return `${API_BASE_PATH}travel/rail/order/${orderId}/cancel`
    },
    trainGetSeatLayout (orderId, railIndex) {
      return `${API_BASE_PATH}travel/rail/order/${orderId}/seatMap/${railIndex}`
    },
    trainChangeSeat (orderId, railIndex) {
      return `${API_BASE_PATH}travel/rail/order/${orderId}/seat/${railIndex}`
    },
    getTrainPromoList (orderId) {
      return `${API_BASE_PATH}travel/rail/promo/${orderId}`
    },
    getTrainPromoDetails: `${API_BASE_PATH}travel/rail/promo`,
    trainApplyPromo (orderId) {
      return `${API_BASE_PATH}travel/rail/promo/apply/${orderId}`
    },
    trainUnapplyPromo (orderId) {
      return `${API_BASE_PATH}travel/rail/promo/unapply/${orderId}`
    },
    getAllContests () {
      return `${API_BASE_PATH}mitra/members/contests`
    },
    getContestDetail (contestId) {
      return `${API_BASE_PATH}mitra/members/contests/${contestId}`
    },
    getLeaderboard (contestId) {
      return `${API_BASE_PATH}mitra/members/leaderboard/${contestId}`
    },
    getNPS () {
      return `${API_BASE_PATH}mitra/nps/choiceChip`
    },
    submitNPS: `${API_BASE_PATH}mitra/nps/v2/feedback`,
    saveAppsData () {
      return `${API_BASE_PATH}mitra/iam`
    },
    subscribeForNotification (memberId) {
      return `${API_BASE_PATH}mitra/products/subscribe/${memberId}`
    },
    markAsRead () {
      return `${API_BASE_PATH}mitra/notifications/markAsRead`
    },
    getMemberBanners () {
      return `${API_BASE_PATH}mitra/banners/v3/member/banners`
    },
    getAllProvinces: `${X_REGION_BASE_PATH}region/provinces`,
    getCities (provinceId) {
      return `${X_REGION_BASE_PATH}region/provinces/${provinceId}/cities`
    },
    getDistricts (cityId) {
      return `${X_REGION_BASE_PATH}region/cities/${cityId}/districts`
    },
    getSubdisticts (districtId) {
      return `${X_REGION_BASE_PATH}region/districts/${districtId}/subdistricts`
    },
    getPointInfo: `${API_BASE_PATH}mitra/loyalty/config/point`,
    levelBenefits: `${API_BASE_PATH}mitra/loyalty/config/level`,
    getLoyaltyMember () {
      return `${API_BASE_PATH}mitra/loyalty/member`;
    },
    getAllGifts: `${API_BASE_PATH}mitra/loyalty/get/gift`,
    redeemGift: `${API_BASE_PATH}mitra/loyalty/gift`,
    loyaltyPointsHistory: `${API_BASE_PATH}mitra/loyalty/member/point`,
    getAllRedeemedGifts: `${API_BASE_PATH}mitra/loyalty/gift/redeemed`,
    checkStockAvailability: `${API_BASE_PATH}mitra/loyalty/checkStock/gift`,
    setDeviceDetails () {
      return `${API_BASE_PATH}mitra/members/devices`
    },
    memberOnboardingStatus () {
      return `${API_BASE_PATH}mitra/onBoarding/members`
    },
    getMemberPaylaterDetail: `${API_BASE_PATH}mitra/paylater/user/credit-limit`,
    submitPaylaterApplication: `${API_BASE_PATH}mitra/member/paylater`,
    getPaylaterTransactionHistory: `${API_BASE_PATH}mitra/paylater/user/contracts`,
    getPaylaterSubscriptionDetails: `${API_BASE_PATH}mitra/paylater/user/subscription`,
    getSharedProductDetails (memberId) {
      return `${API_BASE_PATH}mitra/sharedProducts/${memberId}`
    },
    getSharedProductsList (memberId) {
      return `${API_BASE_PATH}mitra/sharedProducts/${memberId}/list`
    },
    getMemberStatus () {
      return `${API_BASE_PATH}mitra/members/memberStatus`
    },
    paylaterDocumentsUpload () {
      return `${API_BASE_PATH}mitra/members/paylater/image`
    },
    paylaterDocumentsDownload () {
      return `${API_BASE_PATH}mitra/members/paylater/getImage`
    },
    paylaterDocumentsReupload () {
      return `${API_BASE_PATH}mitra/member/paylater/uploadKtpAndProfile`
    },
    getRaffleDetails () {
      return `${API_BASE_PATH}mitra/raffle/member`
    },
    getSurveyDetails () {
      return `${API_BASE_PATH}mitra/survey/member`
    },
    submitSurvey () {
      return `${API_BASE_PATH}mitra/survey/answer`
    },
    updateShippingOption: `${API_BASE_PATH}retail/carts/changeShipping`,
    onGoingMissionAndNotifications () {
      return `${API_BASE_PATH}mitra/members/contests/missionNotifications`
    },
    appsFlyerApi () {
      return `${API_BASE_PATH}mitra/appsflyer/postEvent`
    },
    getFlipProvinceList: `${API_BASE_PATH}mitra/member/flip/getProvinceList/1`,
    getFlipDetails () {
      return `${API_BASE_PATH}mitra/member/flip/getRegisterDetail`
    },
    updateFlipDetails () {
      return `${API_BASE_PATH}mitra/member/flip/register`
    },
    getFlipCityList (provinceId) {
      return `${API_BASE_PATH}mitra/member/flip/getCityList/${provinceId}`
    },
    getFlipDistrictList (cityId) {
      return `${API_BASE_PATH}mitra/member/flip/getDistrictList/${cityId}`
    },
    getFlipOcrDetails () {
      return `${API_BASE_PATH}mitra/member/flip/image`
    },
    saveFlipApi () {
      return `${API_BASE_PATH}mitra/member/flip/register`
    },
    uploadSupportDocumentApi () {
      return `${API_BASE_PATH}mitra/member/flip/uploadSupportDocument`
    },
    memberRegistration () {
      return `${API_BASE_PATH}mitra/member/register`
    },
    walletRegistration () {
      return `${API_BASE_PATH}mitra/mitraPay`
    },
    verifyPinForAccountDeletion: `${API_BASE_PATH}mitra/deletion/validatePin`,
    accountDeletionDetails: `${API_BASE_PATH}mitra/deletion/get/details`,
    generateOtpForAccountDeletion: `${API_BASE_PATH}mitra/deletion/generateOtp`,
    accountDeletionRequest: `${API_BASE_PATH}mitra/deletion/save`
  }
};
