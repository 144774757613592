import axios from 'axios';

function redirectToHomeOrMaintenancePage (response) {

  let unauthorized = false
  try {
    const data = response.data;
    unauthorized = data && data.code === 200 && typeof data.data === 'string' && data.data.includes('UNAUTHORIZED') && data.data.includes('401')
  } catch (e) {
    console.log(e)
  }

  const apiUrl = response.config.url
  const currentPathName = location.pathname
  const maintenanceLink = response.headers.maintenance || '';
  const isMaintenancePage = currentPathName.includes('/maintenance')
  const isAppNotInMaintenancePage = maintenanceLink && !maintenanceLink.includes(currentPathName);
  const isAppMaintenanceDisabled = !maintenanceLink && apiUrl.includes('/api/mitra/session') && isMaintenancePage
  const isUserInHomePage = currentPathName === '/mitra-home'
  if (isAppNotInMaintenancePage) {
    location.href = maintenanceLink
  } else if (isAppMaintenanceDisabled && !isUserInHomePage) {
    location.href = '/mitra-home'
  } else if (unauthorized) {
    const isAppUnderMaintenance = maintenanceLink && isMaintenancePage
    if (!isUserInHomePage && !isAppUnderMaintenance) {
      location.href = '/mitra-home'
    }
  }
}

export const initializeInterceptor = () => {
  axios.interceptors.response.use(
    response => {
      redirectToHomeOrMaintenancePage(response)
      return response
    },
    error => {
      redirectToHomeOrMaintenancePage(error.response)
      return Promise.reject(error);
    }
  );
}


