<template>
  <div>
    <NetPromoterScoreDetails
      v-if="popupKey === 'NPS_DETAILS_POPUP'"
      :enable-popup="popupKey === 'NPS_DETAILS_POPUP'"
    />
    <WhatsNewPopup
      v-else-if="popupKey === 'WHATS_NEW_MODAL'"
      :enable-popup="popupKey === 'WHATS_NEW_MODAL'"
      @closeWhatsNewPopup="updateWhatsNewVersion"
    />
    <AutoReplenishment
      v-else-if="popupKey === 'AUTO_REPLENISHMENT_MODAL'"
      :enable-popup="popupKey === 'AUTO_REPLENISHMENT_MODAL'"
      @closeAutoReplenishmentPopup="handleCloseAutoReplenishmentPopup"
    />
    <FlashImage
      v-else-if="popupKey === 'FLASH_IMAGE'"
      @closeFlashImage="handleCloseFlashImagePopup"
    />
    <SuspensionPopup
      v-else-if="popupKey === 'MITRA_CENTER_SUSPENSION_POPUP'"
      :enable-popup="popupKey === 'MITRA_CENTER_SUSPENSION_POPUP'"
      @closeSuspensionPopup="handleCloseSuspensionPopup"
    />
    <WalletSuspensionPopup
      v-else-if="popupKey === 'WALLET_SUSPENSION_POPUP'"
      :enable-popup="popupKey === 'WALLET_SUSPENSION_POPUP'"
      @closeWalletSuspensionPopup="handleCloseWalletSuspensionPopup"
    />
  </div>
</template>
<script src="./full-screen-popup-wrapper.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
</style>
