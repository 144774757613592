import getUserLanguage from './language';
import { enUS, id } from 'date-fns/locale'
import format from 'date-fns/format'
import lang from '@/utils/language'

export const getI18nText = (en, id) => {
  return getUserLanguage.getUserLanguage() === 'en' ? en : id;
};

export function overFlowBody (overflow) {
  if (overflow) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
}

export function getMemberID () {
  console.log('Helper[MemberId]', window.sessionStorage.getItem('memberId'))
  return window.sessionStorage.getItem('memberId');
}

export function fromAppsFlyer () {
  return window.localStorage.getItem('appsFlyer');
}

export function clearSessionStorage () {
  // session keys which need to be excluded from clearing
  const sessionKeysToPreserve = ['paramIdSession']
  const preservedSessionData = {}
  sessionKeysToPreserve.forEach(key => {
    const value = sessionStorage.getItem(key)
    if (value) {
      preservedSessionData[key] = value
    }
  })
  window.sessionStorage.clear()
  Object.keys(preservedSessionData).forEach(key => {
    sessionStorage.setItem(key, preservedSessionData[key])
  })
}

export function setOnboardingStatus (value) {
  window.localStorage.setItem('onboarding', value);
}

export function getOnboardingStatus () {
  return window.localStorage.getItem('onboarding');
}

export function isEmpty (obj) {
  return obj !== null && typeof (obj) === 'object' && Object.keys(obj).length === 0;
}

export function isFCMTokenSet () {
  return !!window.localStorage.getItem('currentToken');
}

export function getFCMToken () {
  return window.localStorage.getItem('currentToken');
}

export function getNumberOfDaysDiff (timestamp1, timestamp2) {
  return (timestamp2 - timestamp1) / (1000 * 60 * 60 * 24)
}

export function setTokenTimeStamp (value) {
  window.localStorage.setItem('tokenSetAt', value);
}

export function getTokenTimeStamp () {
  return window.localStorage.getItem('tokenSetAt');
}

export const interpolateText = ({ text, placeholders, values }) => {
  let interpolatedText = text;
  placeholders.forEach((placeholder, index) => {
    if (interpolatedText.includes(placeholder)) {
      interpolatedText = interpolatedText.replace(placeholder, values[index])
    }
  });
  return interpolatedText
};

export function keretaApiTermsConditionsUrl () {
  return 'https://www.blibli.com/faq/topic/blibli-com-travel/tiket-kereta-api/'
}

export function getKaiExtendedTermsUrl () {
  return 'https://kai.id/corporate/passenger_services/1'
}

export function getOrderList () {
  return window.localStorage.getItem('orderList')
}

export function getOrderListVersion () {
  return window.localStorage.getItem('orderListVersion')
}

export function setOrderListVersion (orderListVersion) {
  window.localStorage.setItem('orderListVersion', orderListVersion)
}

export function getFlashImageVersion () {
  return window.localStorage.getItem('flashImageVersion')
}

export function setFlashImageVersion (flashImageVersion) {
  window.localStorage.setItem('flashImageVersion', flashImageVersion)
}

export function getRintisValues (cart = {}, key) {
  let values = cart.inquiryInfo.additionalData.FREE_TEXT ? cart.inquiryInfo.additionalData.FREE_TEXT.filter(val => val.includes(key)) : []
  if (key !== 'TOTAL DENDA') {
    return values.length > 0 ? values[0].split(':').length > 1 ? values[0].split(':')[1] : null : null
  }
  else if (key === 'TOTAL DENDA') {
    return values.length > 0 ? values[0].split(':').length > 1 ? values[0].split(':')[1].trim() !== 'RP 0' ? values[0].split(':')[1] : null : null : null
  }
}
export function checkInternalServerError (cartError) {
  if (cartError && cartError.System && cartError.System.includes('INTERNAL_SERVER_ERROR')) return true
  return false
}

export function getFormattedDuration (train) {
  if (train.duration < 60) {
    return `${Math.ceil(train.duration % 60)}${getI18nText('minutes', 'menit')}`
  } else {
    return `0${Math.floor(train.duration / 60)}${getI18nText('hours', 'jam')} 
                ${train.duration % 60 >= 10 ? `${train.duration % 60}${getI18nText('minutes', 'menit')}` :
    `0${train.duration % 60}${getI18nText('minutes', 'menit')}`
}`;
  }
}

export function getFormattedCurrencyInUnits (amount) {
  const ONE_THOUSAND = 1000
  const ONE_MILLION = 1000000
  if (amount >= ONE_MILLION) {
    return `Rp${Number((amount / ONE_MILLION).toFixed(2))} jt`.replace('.', ',')
  } else if (amount >= ONE_THOUSAND) {
    return `Rp${Number((amount / ONE_THOUSAND).toFixed(2))} rb`.replace('.', ',')
  } else {
    return `Rp${amount}`
  }
}

export function setIamTimeStamp (value) {
  window.localStorage.setItem('iamSetAt_V2', value);
  if (window.localStorage.getItem('iamSetAt')) {
    window.localStorage.removeItem('iamSetAt');
  }
}

export function getIamTimeStamp () {
  return window.localStorage.getItem('iamSetAt_V2');
}

export function removeIamTimeStamp () {
  window.localStorage.removeItem('iamSetAt_V2');
}

export function routerCallback () {
  return null
}

export function clearStoreNamePopupInfo () {
  if (window.localStorage.getItem('storeNamePopupInfo')) {
    window.localStorage.removeItem('storeNamePopupInfo')
  }
}

export function getMaintenanceConfigVersion () {
  return window.localStorage.getItem('maintenanceConfigVersion')
}

export function setMaintenanceConfigVersion (configVersion) {
  window.localStorage.setItem('maintenanceConfigVersion', configVersion)
}

export function splitProductTypesIntoCategories (productTypes) {
  const ALL_TRAVEL_PRODUCTS = ['RAIL']
  const ALL_RETAIL_PRODUCTS = ['replenishment_products']
  const DIGITAL_PRODUCTS = []
  const RETAIL_PRODUCTS = []
  const TRAVEL_PRODUCTS = []
  productTypes.forEach(productType => {
    if (ALL_TRAVEL_PRODUCTS.includes(productType)) {
      TRAVEL_PRODUCTS.push(productType)
    } else if (ALL_RETAIL_PRODUCTS.includes(productType)) {
      RETAIL_PRODUCTS.push(productType)
    } else {
      DIGITAL_PRODUCTS.push(productType)
    }
  })
  return {
    DIGITAL: DIGITAL_PRODUCTS,
    RETAIL: RETAIL_PRODUCTS,
    TRAVEL: TRAVEL_PRODUCTS
  }
}

export function setAppConfiguration (typeOfConfiguration, config) {
  window.localStorage.setItem(typeOfConfiguration, config)
}

export function getAppConfiguration (typeOfConfiguration) {
  return window.localStorage.getItem(typeOfConfiguration)
}

export function checkIfConfigNotAvailable (typeOfConfiguration) {
  return window.localStorage.getItem(typeOfConfiguration) === null
}

export function getCondensedBannersConfigResponse (response) {
  return response.map(component => {
    const bannerProperties = component.parameters[0]
    return {
      parameters: [
        {
          id: bannerProperties.id,
          image: bannerProperties.image,
          url: bannerProperties.url
        }
      ]
    }
  })
}

export function getCondensedProductsConfigResponse (response) {
  return [
    {
      parameters: response[0].parameters.map(parameter => {
        return {
          image: parameter.image,
          title: parameter.title,
          url: parameter.url
        }
      })
    }
  ]
}

const twaPackageId = 'blibli.instore.mitra'

export function openAppInPlaystore () {
  window.open(`https://play.google.com/store/apps/details?id=${twaPackageId}`, '_blank')
}

export function getNormalisedProductType (productType) {
  const mapSimilarProductTypeNamesToSameProduct = {
    TV_KABEL_POSTPAID: 'TV_KABEL',
    TV_KABEL_PREPAID: 'TV_KABEL',
    ELECTRICITY_POSTPAID: 'ELECTRICITY_CREDIT',
    GAME_VOUCHER_TOPUP: 'GAME_VOUCHER',
    INDIHOME_POSTPAID: 'TELKOM',
    INDIHOME_PREPAID: 'TELKOM',
    PHONE_POSTPAID: 'PHONE_CREDIT'
  }
  return mapSimilarProductTypeNamesToSameProduct[productType] || productType
}

export function setStockAllocatedTime () {
  let stockAllocatedTime = new Date().getTime()
  window.localStorage.setItem('stockAllocatedTime', stockAllocatedTime)
}

export function getIsCheckoutValue () {
  const stockAllocatedTime = window.localStorage.getItem('stockAllocatedTime')
  if (stockAllocatedTime) {
    const stockExpiryTime = Number(stockAllocatedTime) + 15 * 60 * 1000
    const presentTime = new Date().getTime()
    if (presentTime < stockExpiryTime) {
      return true
    }
  }
  return false
}

export function isElementVisibleInViewport (element) {
  const bounding = element.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
      bounding.left >= 0 &&
      !isElementPresentBelowTheViewport(null, bounding) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function isElementPresentBelowTheViewport (element, bounding) {
  const boundingClientRect = bounding || element.getBoundingClientRect()
  return boundingClientRect.bottom > (window.innerHeight || document.documentElement.clientHeight)
}

function getQueryObjectFromQueryString (queryString) {
  if (queryString[0] == '?') {
    let currentQueryKey = ''
    const queryObject = {}
    for (let index = 1; index < queryString.length; index++) {
      if (queryString[index] != '=') {
        currentQueryKey += queryString[index]
      } else {
        let currentQueryValue = ''
        index++
        for (; index < queryString.length; index++) {
          if (queryString[index] != '&') {
            currentQueryValue += queryString[index]
          } else {
            queryObject[currentQueryKey] = decodeURIComponent(currentQueryValue)
            currentQueryKey = ''
            break
          }
        }
        if (currentQueryKey) {
          queryObject[currentQueryKey] = decodeURIComponent(currentQueryValue)
          currentQueryKey = ''
        }
      }
    }
    return queryObject
  }
  return {}
}

export function getPWADisplayMode () {
  const routeQueryParams = getQueryObjectFromQueryString(window.location.search)
  const isDisplayModeBrowser = window.matchMedia('(display-mode: browser)').matches
  const idParam = routeQueryParams.id || sessionStorage.getItem('paramIdSession')
  if (idParam) {
    // for twa andorid version >= 1.1.0
    if (!isDisplayModeBrowser) {
      sessionStorage.setItem('paramIdSession', idParam)
      const previousIdParam = localStorage.getItem('paramId')
      if (previousIdParam && previousIdParam !== idParam) {
      // mitra twa android app updated or competitor's app installed/updated
        sessionStorage.setItem('paramIdUpdate', true)
      }
      localStorage.setItem('paramId', idParam)
      if (routeQueryParams.ad) {
        localStorage.setItem('appsFlyer', routeQueryParams.ad)
      }
      parseAppsFlyerInfo(routeQueryParams)
      return 'twa'
    }
    // app url but opened from browsing history
    return 'browser'
  }
  if (sessionStorage.getItem('referrer').startsWith(`android-app://${twaPackageId}`) || localStorage.getItem('oldestAppVersion')) {
    // for twa andorid version 1.0.0
    if (!localStorage.getItem('paramId')) {
      localStorage.setItem('oldestAppVersion', true)
    }
    return 'twa'
  }
  if (routeQueryParams.launch === 'pwa' && !isDisplayModeBrowser) {
    // for PWA - launch param configured in manifest.json
    return 'standalone'
  }
  if (isDisplayModeBrowser) {
    return 'browser'
  }
  // can't accurately determine access mode
  return 'unspecified'
}

export function checkAndSetUserMedia () {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {

      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
}

export function convertCurrencyStringToNumber (value) {
  return value.replace(/Rp|\./g, '').trim()
}

export function getOcrObject (resp) {
  // function which converts ocr api response to the format/constraints of the UI
  const ocrObj = {
    ktpNumber: '',
    firstName: '',
    lastName: '',
    fullName: '',
    placeOfBirth: '',
    dateOfBirth: '',
    gender: ''
  }
  if (resp.ktpNumber) {
    Object.assign(ocrObj, { ktpNumber: resp.ktpNumber })
  }
  if (resp.firstName) {
    Object.assign(ocrObj, { firstName: resp.firstName })
  }
  if (resp.lastName) {
    Object.assign(ocrObj, { lastName: resp.lastName })
  }
  if (resp.firstName || resp.lastName) {
    let fullName = getFullName(resp.firstName, resp.lastName)
    if (fullName.length > 40) {
      fullName = fullName.slice(0, 40)
    }
    Object.assign(ocrObj, { fullName })
  }
  let placeOfBirth = resp.placeOfBirth
  if (placeOfBirth) {
    if (placeOfBirth.length > 40) {
      placeOfBirth = placeOfBirth.slice(0, 20)
    }
    Object.assign(ocrObj, { placeOfBirth })
  }
  let dateOfBirth = resp.dateOfBirth
  if (dateOfBirth) {
    if (dateOfBirth.indexOf('T') >= 0) {
      const dob = dateOfBirth.split('T')[0]
      const options = { locale: lang.getUserLanguage() === 'en' ? enUS : id }
      const year = parseInt(dob.split('-')[0], 10)
      const month = parseInt(dob.split('-')[1], 10) - 1
      const date = parseInt(dob.split('-')[2], 10)
      dateOfBirth = format(new Date(year, month, date), 'dd/MM/yyyy', options)
    }
    Object.assign(ocrObj, { dateOfBirth })
  }
  if (resp.gender) {
    const genderText = resp.gender.toLowerCase()
    Object.assign(ocrObj, { gender: genderText })
  }
  return ocrObj
}

export function getFullName (firstName, lastName) {
  if (firstName && !lastName) {
    return firstName
  } else if (!firstName && lastName) {
    return lastName
  } else if (firstName && lastName) {
    return `${firstName} ${lastName}`
  } else {
    return ''
  }
}

export function getFloatingButtonStatus () {
  return window.localStorage.getItem('floatingButtonClicked')
}

export function setFloatingButtonStatus () {
  window.localStorage.setItem('floatingButtonClicked', true)
}

export function isHavingInternalLink (link) {
  if (!link) {
    return false;
  }
  const { hostname } = new URL(link);
  const activeURL = new URL(window.location.href);
  /**
   * The below if block will work for the following environments: localhost, qa1, qa2, preprod, prod
   * else block - external websites and blibli.com
   * (item.link.indexOf('.com/') === 29) -> as we mapped qa urls in localhost this check has been added.
   */
  return (hostname.toLowerCase() === activeURL.hostname.toLowerCase()) || ((activeURL.hostname.indexOf('.com') < 0) && (link.indexOf('.com/') === 29))
}

/**
 * This function returns an intersection observer object which can be used to observe an element viewport.
 * Threshold 1.0 means maximum part of the element has to be intersected.
 * For more info, refer https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
export function getIntersectionObserver (callBack) {
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }
  let observer = new IntersectionObserver(callBack, options);
  return observer
}

export function setRetailCartDeleteAll (value) {
  window.localStorage.setItem('clearAllRetailItems', value);
}

export function getRetailCartDeleteAll () {
  return window.localStorage.getItem('clearAllRetailItems');
}

function parseAppsFlyerInfo (queryParams) {
  if (queryParams.advertising_id) {
    localStorage.setItem('advertising_id', queryParams.advertising_id)
  }
  if (queryParams.appsflyer_id) {
    localStorage.setItem('appsflyer_id', queryParams.appsflyer_id)
  }
  if (queryParams.install_type) {
    localStorage.setItem('install_type', queryParams.install_type)
  }
}

function getMitraPwaConfiguration () {
  return getAppConfiguration('mitraPwaConfiguration') || {}
}


function getRegistrationConfig () {
  let pwaConfiguration = {}
  try {
    pwaConfiguration = JSON.parse(getMitraPwaConfiguration())
  } catch (e) {
    pwaConfiguration = {}
  }
  return pwaConfiguration.registration || {}
}


export function isNewRegistrationFeatureEnabled () {
  return !!getRegistrationConfig().enableFeature
}

export function isNewRegistrationFeatureEnabledForWhiteListUsers () {
  return isNewRegistrationFeatureEnabled() && getRegistrationConfig().enableOnlyForWhiteListUser
}

export function isMemberWhitelistedForNewRegistration (memberId) {
  const whitelistUsers = getRegistrationConfig().whitelistUsers || []
  return whitelistUsers.includes(memberId)
}

export function isNewOnboardingEnabled () {
  return getRegistrationConfig().showNewOnBoarding
}

export function getReferralConfigVersion () {
  return window.localStorage.getItem('referralConfigVersion')
}

export function setReferralConfigVersion (value) {
  return window.localStorage.setItem('referralConfigVersion', value)
}

export function removeModalScroll () {
  const bodyElement = document.body
  bodyElement.classList.remove('b-lock-scroll')
}
