import Transition from '@/components/web/Transition.vue'
import StatusBar from '@/components/status-bar/StatusBar.vue'

export default {
  name: 'NewOnboarding',
  components: {
    Transition,
    StatusBar
  },
  data () {
    return {
      isStepOneActive: true,
      isStepTwoActive: false,
      progressCounter: 0,
      stopAutoNavigation: false
    };
  },
  mounted () {
    this.setTimerForProgress()
    this.trackEvents(false)
  },
  methods: {
    skipOnboarding () {
      this.stopAutoNavigation = true
      this.$trackClickEvent('Mitra_Registration_Onboarding_AdvanceButtonClick')
      this.$trackButtonClick('Mitra_Registration_Onboarding_AdvanceButtonClick')
      this.$emit('onSkipClick')
    },
    goToNext (isUserNavigation) {
      if (this.isStepOneActive) {
        this.isStepOneActive = false
        this.isStepTwoActive = true
      } else if (this.isStepTwoActive) {
        this.isStepOneActive = false
        this.isStepTwoActive = false
      } else {
        this.isStepOneActive = true
      }
      this.progressCounter = 0
      this.trackEvents(isUserNavigation)
    },
    goToPrev (isUserNavigation) {
      if (!this.isStepOneActive && !this.isStepTwoActive) {
        this.isStepOneActive = false
        this.isStepTwoActive = true
      } else {
        this.isStepOneActive = true
        this.isStepTwoActive = false
      }
      this.progressCounter = 0
      this.trackEvents(isUserNavigation)
    },
    setTimerForProgress () {
      setTimeout(() => {
        if (this.progressCounter < 100) {
          this.progressCounter = this.progressCounter + 1
        } else {
          this.goToNext(false)
        }
        if (!this.stopAutoNavigation) {
          this.setTimerForProgress()
        }
      }, 50)
    },
    trackEvents (isUserNavigation) {
      let currentStep = 0
      if (this.isStepOneActive) {
        currentStep = 1
      } else if (this.isStepTwoActive) {
        currentStep = 2
      } else {
        currentStep = 3
      }
      const eventName = `Mitra_Registration_Onboarding_${currentStep}`
      if (isUserNavigation) {
        this.$trackClickEvent(eventName)
        this.$trackButtonClick(eventName)
      } else {
        this.$trackNonInteractionEvent(eventName)
        this.$trackPageImpression(eventName)
      }
    }
  }
}
