import currency from './currency';
import date from './date';
import stringFormat from './stringFormat'
import currenyInUnits from './currenyInUnits';
export default {
  install (Vue) {
    Vue.filter('currency', currency);
    Vue.filter('date', date.date);
    Vue.filter('time', date.time);
    Vue.filter('day', date.day);
    Vue.filter('customTimeStamp', date.customTimeStamp);
    Vue.filter('title', stringFormat.title);
    Vue.filter('currencyTitle', stringFormat.currencyTitle)
    Vue.filter('currencyInUnits', currenyInUnits.currencyInUnits)
  }
};
