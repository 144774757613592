import { createApp } from 'vue'
import { $i18n } from '@vue3/i18n/lang'
import App3 from '@vue3/App.vue'
import router from '@vue3/router/index.js'
import pinia from '@vue3/store/init'
import '@blibli/blue-font/dist/_blue-font-blibli.scss'
import '@vue3/assets/scss/index.scss'
import '@vue3/assets/scss/global.scss'
import '@blibli/blue-dls-utils/dist/scss/_box-sizing-helper.scss'
import '@blibli/blue-font/dist/_blue-font-blibli.scss'
import TrackerMixins from '@vue3/mixins/tracker-mixins'
import HelperMixin from '@vue3/mixins/helper-mixin'
import GA4TrackerMixins from '@vue3/mixins/gafour-tracker-mixins'
import AppsFlyerTrackerMixins from '@vue3/mixins/appsflyer-tracker-mixin'
import BliToast from '@blibli/blue.toast/dist/toast/blue/toast-index'

export function start () {
  const app = createApp(App3)
  app.mixin({
    methods: {
      $i18n
    }
  })
  app.mixin(TrackerMixins)
  app.mixin(GA4TrackerMixins)
  app.mixin(AppsFlyerTrackerMixins)
  app.mixin(HelperMixin)
  app.use(router)
  app.use(pinia)
  app.mount('#app3')
  app.config.globalProperties.$toast = BliToast;
}
