import ProgressBar from '@/components/web/ProgressBar.vue'

export default {
  name: 'StepsBar',
  props: {
    lastCompletedStep: {
      type: Number,
      default: 1
    },
    totalSteps: {
      type: Number,
      default: 1
    }
  },
  components: {
    ProgressBar
  },
  data () {
    return {
      progressData: []
    }
  },
  created () {
    this.initialiseProgressData()
  },
  methods: {
    initialiseProgressData () {
      this.progressData = []
      const progressData = {
        borderStyle: '',
        width: '',
        backgroundColor: ''
      }
      for (let step = 1; step <= this.lastCompletedStep; step++) {
        progressData.width = 100
        progressData.backgroundColor = '#8bc63f'
        this.progressData.push({ ...progressData })
      }
      if (this.lastCompletedStep < this.totalSteps) {
        progressData.width = 100
        progressData.backgroundColor = '#daf3ff'
        this.progressData.push({ ...progressData })
      }
      for (let step = this.lastCompletedStep + 2; step <= this.totalSteps; step++) {
        progressData.width = 0
        progressData.backgroundColor = 'grey'
        this.progressData.push({ ...progressData })
      }
      this.progressData[0].borderStyle = 'border-top-left-radius: 100px; border-bottom-left-radius: 100px;'
      this.progressData[this.progressData.length - 1].borderStyle = 'border-top-right-radius: 100px; border-bottom-right-radius: 100px;'
    },
    updateProgressData (newStep) {
      this.progressData[newStep - 1].backgroundColor = '#8bc63f'
      if (newStep < this.progressData.length) {
        this.progressData[newStep].backgroundColor = '#daf3ff'
        this.progressData[newStep].width = 100
      }
    }
  },
  watch: {
    lastCompletedStep (newStep) {
      if (!newStep) {
        this.initialiseProgressData()
      } else {
        this.updateProgressData(newStep)
      }
    }
  }
}
