const API_BASE_PATH = '/api/';
const CMS_API_PATH = '/backend/content/';
// const X_REGION_BASE_PATH = '/backend/common/'
const services = '/backend/content/pages/mitra/sections?display=normal'


export default {
  api: {
    getStoreTypeList: API_BASE_PATH + 'mitra/store/types',
    getServiceAndPromotion: services,
    getMemberPaylaterDetail: `${API_BASE_PATH}mitra/paylater/user/credit-limit`,
    logoutUrl: API_BASE_PATH + 'authentication/destroy',
    getMitraSessionInfo: API_BASE_PATH + 'mitra/session',
    getQuickOrderDetails: API_BASE_PATH + 'retail/orders/getQuickOrderDetails',
    getAutoSuggestedProducts: `${API_BASE_PATH}retail/orders/last-paid`,
    addCartDigital: `${API_BASE_PATH}digital/carts`,
    submitNPS: `${API_BASE_PATH}mitra/nps/v2/feedback`,
    verifyPinForAccountDeletion: `${API_BASE_PATH}mitra/deletion/validatePin`,
    accountDeletionDetails: `${API_BASE_PATH}mitra/deletion/get/details`,
    generateOtpForAccountDeletion: `${API_BASE_PATH}mitra/deletion/generateOtp`,
    accountDeletionRequest: `${API_BASE_PATH}mitra/deletion/save`,
    digitalConfig: `${API_BASE_PATH}digital/config`,
    getCartDigital: `${API_BASE_PATH}digital/carts/payments`,
    setDigitalPayment: `${API_BASE_PATH}digital/carts/_payment`,
    payDigital: `${API_BASE_PATH}digital/orders`,
    getBlipayProducts: API_BASE_PATH + 'v1/mitra/wallet/products',
    payOrderWithWallet: '/api/mitra/wallet/purchase/_verify',
    getBankList: API_BASE_PATH + 'mitra/wallet/banks',
    getCartItems: API_BASE_PATH + 'retail/carts',
    addItemToCart: API_BASE_PATH + 'retail/carts/item',
    checkboxUpdate: API_BASE_PATH + 'retail/carts/checkboxUpdate',
    updateItemQuantity: API_BASE_PATH + 'retail/carts/item',
    deleteCartItem: API_BASE_PATH + 'retail/carts/item/_delete',
    subscribeForNotification (memberId) {
      return `${API_BASE_PATH}mitra/products/subscribe/${memberId}`
    },
    getProductsList (memberId) {
      return `${API_BASE_PATH}retail/search/${memberId}`
    },
    setNumberDigital: `${API_BASE_PATH}digital/carts/_customer-number`,
    getDigitalMaxDiscountedValue: `${API_BASE_PATH}digital/promo-codes/getMaxPromoCode`,
    fetchDigitalCart: `${API_BASE_PATH}digital/carts/`,
    cancelDigitalOrder: '/api/digital/orders/_cancelPayment',
    getAddBankAccount () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member`;
    },
    getCashoutApi () {
      return `${API_BASE_PATH}mitra/wallet/cashouts/member`;
    },
    getDeleteUpdateMemberBankAccount () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member/bank-accounts`;
    },
    getDigitalOrderDetail (orderId) {
      return `/api/digital/orders/${orderId}`
    },
    getNPS () {
      return `${API_BASE_PATH}mitra/nps/choiceChip`
    },
    getMemberDetails () {
      return `${API_BASE_PATH}mitra/members/get/details`
    },
    getConfig (configName) {
      return `${CMS_API_PATH}configs/${configName}`
    },
    memberRegistration () {
      return `${API_BASE_PATH}mitra/member/register`
    },
    getWalletRequests () {
      return `${API_BASE_PATH}mitra/wallet/member`
    },
    getMemberBanners () {
      return `${API_BASE_PATH}mitra/banners/v3/member/banners`
    },
    getLoyaltyMember () {
      return `${API_BASE_PATH}mitra/loyalty/member`
    },
    getNotificationDashboard () {
      return `${API_BASE_PATH}mitra/notifications/dashboard`
    },
    getMemberInProgressApi () {
      return `${API_BASE_PATH}mitra/members/inprogress`
    },
    sendFcmTokenToServer () {
      return `${API_BASE_PATH}mitra/notifications/token`
    },
    deleteToken () {
      return `${API_BASE_PATH}mitra/notifications/token`
    },
    getDownloadKTP_API () {
      return `${API_BASE_PATH}mitra/members/download/image`
    },
    getProfileKtpUploadApi () {
      return `${API_BASE_PATH}mitra/members/upload/image/v2`
    },
    walletRegistration () {
      return `${API_BASE_PATH}mitra/mitraPay`
    },
    getRejectedReasons () {
      return `${API_BASE_PATH}mitra/members/questions`
    },
    generateOtp () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin`
    },
    getGenerateOtp () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin/member`
    },
    getVerifyOtpApi () {
      return `${API_BASE_PATH}mitra/wallet/blipaypin/member/_confirm`
    },
    getVerifyKtpApi () {
      return `${API_BASE_PATH}mitra/members/verify-ktp`;
    },
    onGoingMissionAndNotifications () {
      return `${API_BASE_PATH}mitra/members/contests/missionNotifications`
    },
    memberOnboardingStatus () {
      return `${API_BASE_PATH}mitra/onBoarding/members`
    },
    getSurveyDetails () {
      return `${API_BASE_PATH}mitra/survey/member`
    },
    getCheckinDetails () {
      return `${API_BASE_PATH}mitra/checkInMission/member/getProgramDetail`
    },
    getEligibleCheckin () {
      return `${API_BASE_PATH}mitra/checkInMission/member/getEligibleFlag`
    },
    submitCheckin () {
      return `${API_BASE_PATH}mitra/checkInMission/member/checkIn`
    },
    submitSurvey () {
      return `${API_BASE_PATH}mitra/survey/answer`
    },
    getTransactionHistory () {
      return `${API_BASE_PATH}mitra/wallet/transactions/member`
    },
    getRaffleDetails () {
      return `${API_BASE_PATH}mitra/raffle/member`
    },
    saveAppsData () {
      return `${API_BASE_PATH}mitra/iam`
    },
    appsFlyerApi () {
      return `${API_BASE_PATH}mitra/appsflyer/postEvent`
    },
    getContestsDashboard () {
      return `${API_BASE_PATH}mitra/members/contests/dashboard`
    },
    updateMemberDetails () {
      return `${API_BASE_PATH}mitra/member/register/edit`
    },
    updateStatusOfInAppNotification () {
      return `${API_BASE_PATH}mitra/notifications/updateTokenTime`
    },
    getDigitalProducts (productType) {
      return `${API_BASE_PATH}digital/products/${productType}`
    },
    getMemberBankList () {
      return `${API_BASE_PATH}mitra/wallet/member-bank-accounts/member`
    },
    getVouchersListDigital: `${API_BASE_PATH}digital/promo-codes/_contextual_adjustment`,
    applyVoucherDigital: `${API_BASE_PATH}digital/promo-codes/_apply`,
    removeVoucherDigital: `${API_BASE_PATH}digital/promo-codes/_unapply`,
    getVouchersDetailsDigital (promoCode) {
      return `${API_BASE_PATH}digital/promo-codes/${promoCode}`;
    },
    getDigitalOperators (productType) {
      return `${API_BASE_PATH}digital/operators/${productType}`
    }
  }
};
