import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getPnLData (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getPnLData(),
      callback,
      fail,
      payload
    );
  },
  getVouchersDetails (callback, fail, params, pathVariables) {
    apiCall.makeGetRequest(
      apiUrls.api.getVouchersDetailsDigital(pathVariables.code),
      callback,
      fail,
      params
    )
  },
  fetchDigitalCart (callback, fail) {
    apiCall.makeGetRequest(
      apiUrls.api.fetchDigitalCart,
      callback,
      fail
    );
  },
  getVouchersList (callback, fail) {
    apiCall.makeGetRequest(
      apiUrls.api.getVouchersListDigital,
      callback,
      fail
    );
  },
  getDigitalMaxDiscountedValue (callback, fail) {
    apiCall.makeGetRequest(
      apiUrls.api.getDigitalMaxDiscountedValue,
      callback,
      fail
    );
  },
  applyVoucher (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.applyVoucherDigital,
      callback,
      fail,
      payload,
      params
    );
  },
  removeVoucher (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.removeVoucherDigital,
      callback,
      fail,
      payload,
      params
    );
  },
  deletePaymentSpecificVouchersdigital (callback, fail) {
    apiCall.makeDeleteRequest(
      apiUrls.api.deletePaymentSpecificVouchersdigital,
      callback,
      fail
    );
  },
  getProducts (callback, fail, productType, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getDigitalProducts(productType),
      callback,
      fail,
      payload
    );
  },
  setCustomerNumber (callback, fail, request) {
    apiCall.makePostRequest(
      apiUrls.api.setNumberDigital,
      callback,
      fail,
      request
    );
  },
  addCart (callback, fail, request) {
    apiCall.makePostRequest(
      apiUrls.api.addCartDigital,
      callback,
      fail,
      request
    );
  },
  pay (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.payDigital, callback, fail, payload);
  },
  getOperator (callback, fail, payload) {
    apiCall.makeGetRequest(
      apiUrls.api.digitalOperator(payload),
      callback,
      fail
    );
  },
  getCart (callback, fail) {
    apiCall.makeGetRequest(apiUrls.api.getCartDigital, callback, fail);
  },
  getOrders (callback, fail, payload, cancelToken) {
    apiCall.makeGetRequest(apiUrls.api.digitalOrders, callback, fail, payload, cancelToken);
  },
  cancelDigitalOrder (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.cancelDigitalOrder, callback, fail, payload, params);
  },
  repayDigitalOrder (callback, fail, pathVariables) {
    apiCall.makePostRequest(apiUrls.api.repayDigitalOrder(pathVariables.orderId), callback, fail);
  },
  getOrderListApi (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.getOrderListApi, callback, fail, payload);
  },
  changePayment (callback, fail, payload) {
    apiCall.makePutRequest(apiUrls.api.setDigitalPayment, callback, fail, payload)
  },
  getConfig (callback, fail, configNames) {
    apiCall.makeGetRequest(apiUrls.api.digitalConfig, callback, fail, { configs: configNames })
  },
  getGameList (callback, fail) {
    apiCall.makeGetRequest(apiUrls.api.getGameList, callback, fail)
  },
  getDigitalOperators (callback, fail, productType) {
    apiCall.makeGetRequest(apiUrls.api.getDigitalOperators(productType), callback, fail)
  },
  getTvKabelProducts (callback, fail, { productType, operatorName }) {
    apiCall.makeGetRequest(apiUrls.api.getTvKabelProducts(productType), callback, fail, { operatorName })
  },
  getIndihomePackages (callback, fail, { productType, customerNumber }) {
    apiCall.makeGetRequest(apiUrls.api.getIndihomePackages(productType), callback, fail, { customerNumber })
  },
  fetchProductDetails (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.digitalInquiry, callback, fail, payload)
  },
  pollMoneyTransferCurrentStatus (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.pollMoneyTransferStatus, callback, fail, payload);
  }
};
