import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import { userAuthStore } from '@vue3/store/auth-store'
import { mapActions } from 'pinia'

export default {
  name: 'ServerOverload',
  components: {
    BliButton,
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter
  },
  props: {
    isServerError: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$trackTrackError('MitraUndermaintenanceError')
  },
  methods: {
    ...mapActions(userAuthStore, ['SET_SERVER_ERROR']),
    removeServerErrorScreen () {
      if (window.location.href.includes('/mitra-home')) {
        window.location.reload()
      } else {
        this.$router.go(-1);
      }
      this.SET_SERVER_ERROR(false)
    }
  }
}
