import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler';
export default {
  state: {
    PnLData: {},
    isFetchingData: false
  },
  mutations: {
    setPnLData (state, data) {
      state.PnLData = data
    },
    setIsFetchingData (state, flag) {
      state.isFetchingData = flag
    }
  },
  getters: {
    getPnLData (state) {
      return state.PnLData
    },
    getIsFetchingData (state) {
      return state.isFetchingData
    }
  },
  actions: {
    RESET_PNL_DATA ({ commit }) {
      commit('setPnLData', {})
    },
    GET_PNL_DATA ({ commit, dispatch }, { payload }) {
      commit('setIsFetchingData', true);
      digitalApi.getPnLData(
        response => {
          commit('setIsFetchingData', false);
          if (response.data.code === 200) {
            if (
              !response.data.errors
            ) {
              commit('setPnLData', response.data.data);

            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingData', false);
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    }
  },
  namespaced: true
};
