import NetPromoterScoreDetails from '@vue3/components/popups/net-promoter-score-details/index.vue'
import WhatsNewPopup from '@vue3/components/popups/whats-new-popup/index.vue'
import AutoReplenishment from '@vue3/components/popups/auto-replenishment/index.vue'
import FlashImage from '@vue3/components/popups/flash-image/index.vue'
import SuspensionPopup from '@vue3/components/popups/suspension-popup/index.vue'
import WalletSuspensionPopup from '@vue3/components/popups/wallet-suspension-popup/index.vue'
import { homepageStore } from '@vue3/store/homepage-store'
import { profileStore } from '@vue3/store/profile-store'
import { mapActions, mapState } from 'pinia'
import { getMemberID } from '@vue3/utils/helpers'

export default {
  name: 'FullScreenPopupWrapper',
  components: {
    NetPromoterScoreDetails,
    AutoReplenishment,
    SuspensionPopup,
    WalletSuspensionPopup,
    WhatsNewPopup,
    FlashImage
  },
  computed: {
    ...mapState(homepageStore, ['fullScreenPopupInfo']),
    popupKey () {
      return this.fullScreenPopupInfo.key
    }
  },
  methods: {
    ...mapActions(homepageStore, ['SET_FULL_SCREEN_POPUP_INFO', 'UPDATE_MEMBER_DETAILS']),
    ...mapActions(profileStore, ['GET_MEMBER_DETAILS', 'GET_MEMBER_ONBOARDING_STATUS']),
    closeModal () {
      this.SET_FULL_SCREEN_POPUP_INFO({ key: '' })
    },
    updateWhatsNewVersion () {
      this.UPDATE_MEMBER_DETAILS({
        payload: {
          memberId: getMemberID(),
          whatsNewVersionNumber: this.fullScreenPopupInfo.whatsNewVersionNumber
        },
        success: this.updateMemberSuccess
      })
      this.closeModal()
    },
    updateMemberSuccess () {
      this.GET_MEMBER_DETAILS({
        payload: {
          memberId: getMemberID()
        }
      })
    },
    handleCloseFlashImagePopup () {
      this.closeModal()
      localStorage.setItem('flashMessageShown', true)
    },
    handleCloseAutoReplenishmentPopup () {
      this.closeModal()
    },
    handleCloseSuspensionPopup () {
      this.closeModal()
    },
    handleCloseWalletSuspensionPopup () {
      this.closeModal()
    }
  }
}
