import { setStockAllocatedTime, getIsCheckoutValue } from '@vue3/utils/helpers'
import { SameDayConfigMixin, InstantConfigMixin } from '@vue3/mixins/shipping-mixin'
import { getDateRange } from '@vue3/utils/calendar'
import { retailCheckoutStore } from '@vue3/store/retail-checkout-store'
import { mapActions, mapState } from 'pinia'

export default {
  data () {
    return {
      isRetailCartOnboardingInProgress: false,
      nonBuyableItems: []
    }
  },
  mixins: [SameDayConfigMixin, InstantConfigMixin],
  computed: {
    ...mapState(retailCheckoutStore, ['cartDetails', 'headerInfo', 'checkoutTab']),
    totalProductsCount () {
      return this.cartDetails && this.cartDetails.items && this.cartDetails.items.length
    },
    isCartHasItems () {
      return !!this.totalProductsCount
    },
    regularProducts () {
      return this.cartDetails.items.filter(item => item.productType === 'REGULAR')
    },
    regularProductsCost () {
      return this.regularProducts.reduce((total, item) => (total + item.offerPrice * item.quantity), 0) - Math.abs(this.cartDetails.totalOrderAdjustment)
    },
    isMovMsgVisible () {
      return (this.isCartHasItems && this.cartDetails.mov > this.regularProductsCost) && (this.numberOfBigProductsInCart !== this.totalProductsCount)
    },
    isShippingMovMsgVisible () {
      return (this.isCartHasItems && this.cartDetails.shippingMov > this.regularProductsCost) && (this.numberOfBigProductsInCart !== this.totalProductsCount)
    },
    movAmount () {
      return {
        movAmount: this.formatAmount(this.cartDetails.mov),
        shippingMovAmount: this.formatAmount(this.cartDetails.shippingMov),
        remainingAmount: this.formatAmount(this.cartDetails.mov - this.regularProductsCost)
      }
    },
    shippingMovAmount () {
      return this.formatAmount(this.cartDetails.shippingMov - this.regularProductsCost)
    },
    totalOrderAmount () {
      return (this.cartDetails.totalOrder - Math.abs(this.cartDetails.totalOrderAdjustment)) || 0
    },
    numberOfBigProductsInCart () {
      const { items } = this.cartDetails
      return items.filter(item => item.productType === 'BIG_PRODUCT').length
    },
    getTotalQuantity () {
      if (this.cartDetails.items) {
        return this.cartDetails.items.reduce((totalQauntity, item) => totalQauntity + Number(item.quantity), 0)
      }
      return 0
    },
    voucherDiscount () {
      return Math.abs(this.cartDetails.totalOrderAdjustment)
    },
    isShippingFree () {
      return this.hasMerchantInfoSegmentationList && this.cartDetails.merchantInfoSegmentationList.some(merchantInfo =>
        (merchantInfo.isAllItemEligibleForCheckout && merchantInfo.totalShipping && (merchantInfo.totalShipping + merchantInfo.totalShippingAdjustment) === 0)
      )
    },
    totalShippingAdjustment () {
      return Math.abs(this.cartDetails.totalShippingAdjustment)
    },
    appliedVouchers () {
      return [...this.appliedPromoCode, ...this.appliedCoupons]
    },
    appliedCoupons () {
      return this.cartDetails.appliedCoupons || []
    },
    appliedPromoCode () {
      return this.cartDetails.appliedVouchers || []
    },
    retailCheckoutTitle () {
      return this.headerInfo.title
    },
    retailCheckoutSteps () {
      return this.headerInfo.checkoutSteps
    },
    hasMerchantInfoSegmentationList () {
      return this.cartDetails && this.cartDetails.merchantInfoSegmentationList && this.cartDetails.merchantInfoSegmentationList.length
    },
    isCartHasBuyableItems () {
      var hasBuyableItems = false
      if (this.isCartHasItems) {
        hasBuyableItems = this.cartDetails.merchantInfoSegmentationList.some(merchantInfo => merchantInfo.cartItems.some(this.isBuyableItem))
      }
      return hasBuyableItems
    },
    getBuyableProducts () {
      if (this.isCartHasBuyableItems) {
        var buyableProducts = []
        var cartItem = []
        var merchantItems = {}
        this.cartDetails.merchantInfoSegmentationList.forEach(merchantInfo => {
          cartItem = []
          merchantItems = { ...merchantInfo }
          merchantInfo.cartItems.forEach(item => {
            if (this.isBuyableItem(item)) {
              cartItem.push(item)
            } else {
              merchantItems.weight = merchantItems.weight - item.totalWeight
              merchantItems.weight = Number(merchantItems.weight.toFixed(2))
            }
          })
          if (cartItem.length) {
            delete merchantItems.cartItems
            merchantItems.cartItems = cartItem
            buyableProducts.push(merchantItems)
          }
        })
        return buyableProducts
      }
      return []
    },
    cartHasNonBuyableItems () {
      this.nonBuyableItems = []
      if (this.isCartHasItems) {
        this.cartDetails.items.forEach(item => {
          if (!this.isBuyableItem(item)) {
            this.nonBuyableItems.push(item)
          }
        })
      }
      return !!this.nonBuyableItems.length
    },
    getUnbuyableItemsSKUList () {
      return this.nonBuyableItems.map(item => item.itemSku)
    },
    doesCartHasCoCartError () {
      if (this.isCartHasItems) {
        return this.cartDetails.items.some(item => {
          if (this.doesCartItemHasCoCartError(item)) {
            const { errorCode, values: { remainingQty, maxQty } } = item.errorInfo
            const remainingQtyValue = remainingQty ? remainingQty[0] : Number.MAX_VALUE
            return errorCode === 'CO_PRODUCT_EXCLUSIVE' || item.quantity > maxQty[0] || item.quantity > remainingQtyValue
          }
          return false
        }
        )
      }
      return false
    },
    isAllMerchantMovNotMet () {
      return this.hasMerchantInfoSegmentationList && this.cartDetails.merchantInfoSegmentationList.every(merchantInfo => merchantInfo.errorInfo && merchantInfo.errorInfo.errorCode && merchantInfo.errorInfo.errorCode === 'MOV_VIOLATION')
    },
    isAnyMerchantMovNotMet () {
      return this.hasMerchantInfoSegmentationList && this.cartDetails.merchantInfoSegmentationList.some(merchantInfo => this.isMerchantItemActive(merchantInfo) && this.isMerchantMinimumOrderNotMet(merchantInfo))
    },
    doesCartHasWeightExceedError () {
      if (this.isCartHasItems) {
        return this.cartDetails.items.some(item => this.doesCartItemHasWeightExceededError(item))
      }
      return false
    },
    isAnyItemDisabled () {
      return this.isCartHasItems && this.cartDetails.items.some(item => !item.isItemAllowedForCheckout)
    },
    selectedCartItems () {
      if (this.isCartHasItems) {
        return this.cartDetails.items.filter(item => item.isItemAllowedForCheckout)
      }
      return []
    },
    isAllMerchantHasShippingOption () {
      return this.hasMerchantInfoSegmentationList && this.cartDetails.merchantInfoSegmentationList.every(merchantInfo => merchantInfo.isAllItemEligibleForCheckout ? merchantInfo.selectedShippingOption : true)
    },
    getOrderAdjustment () {
      const totalProductAdjustment = this.cartDetails.totalProductAdjustment || 0
      return Math.abs(totalProductAdjustment)
    },
    doesCartItemHasError () {
      return this.cartDetails.items.some(item => item.errorInfo && item.errorInfo.errorCode)
    }
  },
  methods: {
    ...mapActions(retailCheckoutStore, ['GET_CART_ITEMS', 'UPDATE_CART_ITEM', 'SET_CHECKOUT_TAB_TYPE', 'SET_HEADER_INFO']),
    getAdjustmentPerQuantity (cartitem) {
      const adjustmentPerQuantity = cartitem.adjustmentProductPerQty || 0
      return Math.abs(adjustmentPerQuantity)
    },
    subTotalProductAdjustment (item) {
      const productAdjustment = item && item.reduce((totalProductAdjustment, item) => {
        if (item.productAdjustment) {
          return totalProductAdjustment + item.productAdjustment
        }
        return totalProductAdjustment
      }, 0)
      return Math.abs(productAdjustment) || 0
    },
    getSupplierName (merchantName) {
      return merchantName === 'Blibli' ? 'Blibli' : 'supplier'
    },
    isBlibliSupplier (merchantName) {
      return merchantName === 'Blibli'
    },
    isMerchantFreeShippingMovNotMet (merchantInfo) {
      const merchantFreeShippingMinimumOrderValue = this.isBlibliSupplier(merchantInfo.merchantName) ? merchantInfo.freeShippingAmount : merchantInfo.minimumOrderValue
      return merchantFreeShippingMinimumOrderValue > merchantInfo.totalCost
    },
    merchantSpecificTotalOrderAmount (totalCost, totalOrderAdjustment) {
      return this.$options.filters.currency((totalCost - Math.abs(totalOrderAdjustment)) || 0, { currencySymbol: 'Rp' })
    },
    isMerchantMinimumOrderNotMet (merchantInfo) {
      return merchantInfo.errorInfo && merchantInfo.errorInfo.errorCode && merchantInfo.errorInfo.errorCode === 'MOV_VIOLATION'
    },
    isMerchantItemActive (merchantInfo) {
      return merchantInfo.cartItems.some(item => item.isItemAllowedForCheckout)
    },
    isMerchantLevelCartItemsHasBigProduct (merchantInfo) {
      const { cartItems } = merchantInfo
      return cartItems.some(item => item.productType === 'BIG_PRODUCT')
    },
    doesCartItemHasCoCartError (item) {
      if (item.errorInfo || item.errorCode) {
        const errorCode = item.errorInfo ? item.errorInfo.errorCode : item.errorCode
        const coCartErrorCodes = ['CO_EXCEEDED_QUANTITY_IN_CART_ITEM_EVENT',
          'CO_EXCEEDED_QUANTITY_IN_CART_ITEM_DAILY_EVENT',
          'CO_EXCEEDED_QUANTITY_IN_DAILY_EVENT',
          'CO_EXCEEDED_QUANTITY_IN_EVENT',
          'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_DAILY_EVENT',
          'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_EVENT',
          'CO_EXCEEDED_REMAINING_QUANTITY_ELIGIBLE_EVENT',
          'CO_EXCEEDED_QUANTITY_IN_CART',
          'CO_EXCEEDED_QUANTITY',
          'CO_PRODUCT_EXCLUSIVE']
        return coCartErrorCodes.some(coCartErrorCode => coCartErrorCode === errorCode)
      }
      return false;
    },
    doesCartItemMeetsProductAdjustment (item) {
      if (item.errorInfo || item.errorCode) {
        const errorCode = item.errorInfo ? item.errorInfo.errorCode : item.errorCode
        return errorCode === 'PRM_INSUFFICIENT_ADJUSTMENT_PRODUCT_QUOTA'
      }
      return false
    },
    doesCartItemHasWeightExceededError (item) {
      if (item.errorInfo && item.errorInfo.errorCode) {
        const weightExceededErrorCodes = ['MAXIMUM_WEIGHT_EXCEEDED', 'PACKAGE_WEIGHT_EXCEEDED']
        return weightExceededErrorCodes.some(weightExceededErrorCode => weightExceededErrorCode === item.errorInfo.errorCode)
      }
      return false
    },
    doesCartItemHasOutOfStockError (item) {
      return item.errorInfo && item.errorInfo.errorCode && item.errorInfo.errorCode === 'OOS'
    },
    getErrorMessage (errorInfo) {
      let remainingQty = ''
      let maxQty = ''
      if (errorInfo.values) {
        remainingQty = errorInfo.values.remainingQty ? errorInfo.values.remainingQty[0] : ''
        maxQty = errorInfo.values.maxQty ? errorInfo.values.maxQty[0] : ''
      }
      const errorCodesWithDateRangeAndRemainingQuantity = ['CO_EXCEEDED_QUANTITY_IN_CART_ITEM_DAILY_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_DAILY_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ELIGIBLE_EVENT',
        'CO_EXCEEDED_QUANTITY_IN_CART_ITEM_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_EVENT',
        'CO_EXCEEDED_QUANTITY_IN_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_EVENT',
        'CO_EXCEEDED_QUANTITY_IN_CART_ITEM_EVENT'
      ]
      const errorCodesWithRemainingQuantity = ['CO_EXCEEDED_QUANTITY_IN_DAILY_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ZERO_DAILY_EVENT',
        'CO_EXCEEDED_REMAINING_QUANTITY_ELIGIBLE_EVENT'
      ]
      const errorCode = errorInfo.errorCode
      if (errorCodesWithDateRangeAndRemainingQuantity.some(coCartErrorCode => coCartErrorCode === errorCode)) {
        const dateRange = getDateRange(new Date(Number(errorInfo.values.startDate[0])), new Date(Number(errorInfo.values.endDate[0])))
        return this.$i18n(errorCode, remainingQty, dateRange)
      }
      if (errorCodesWithRemainingQuantity.some(coCartErrorCode => coCartErrorCode === errorCode)) {
        return this.$i18n(errorCode, remainingQty)
      }
      if (errorCode === 'CO_EXCEEDED_QUANTITY') {
        return this.$i18n(errorCode, maxQty)
      }
      return this.$i18n(errorCode)
    },
    makeCheckoutRequestToCheckCOCartError () {
      let isCheckout = getIsCheckoutValue()
      if (!isCheckout) {
        setStockAllocatedTime()
        isCheckout = true
      }
      this.GET_CART_ITEMS({
        params: {
          isCheckout,
          isAutoReplenishment: !!this.$route.query.isAutoReplenishment,
          checkoutTab: this.checkoutTab,
          isWhiteListedForSameDay: this.isMemberWhitelistedForSameDayDelivery,
          isWhiteListedForInstant: this.isMemberWhitelistedForInstantDelivery
        },
        success: () => { this.proceedWithShippingIfNoCoCartError() }
      })
    },
    proceedWithShippingIfNoCoCartError () {
      if (this.doesCartHasCoCartError) {
        this.showToast(this.$i18n('CO_CART_MAX_QUANTITY_REACHED_TOAST_CART'), '')
      }
      if (!this.doesCartItemHasError) {
        this.SET_CHECKOUT_TAB_TYPE('SHIPPING_DETAILS')
        this.SET_HEADER_INFO({
          title: this.$i18n('NOTIFICATIONS.DELIVERY'),
          checkoutSteps: {
            currentStep: 2,
            totalSteps: 3
          }
        })
        if (this.$route.query.step !== '2') {
          this.$router.push({ name: 'Checkout', query: { step: 2 } });
        }
      } else {
        this.$store.dispatch('retailCheckoutStore/SET_CHECKOUT_TAB_TYPE', 'CART')
      }
    },
    excludeMovViolatedCartItems () {
      if (this.isCartHasBuyableItems) {
        let skuList = []
        this.getBuyableProducts.forEach(merchantInfo => {
          if (merchantInfo.errorInfo && merchantInfo.errorInfo.errorCode && merchantInfo.errorInfo.errorCode === 'MOV_VIOLATION') {
            merchantInfo.cartItems.forEach(item => {
              if (item.isItemAllowedForCheckout) {
                skuList.push({
                  itemSku: item.itemSku,
                  isActive: false
                })
              }
            })
          }
        })
        if (skuList.length) {
          this.UPDATE_CART_ITEM({
            payload: skuList,
            params: {
              isCheckout: getIsCheckoutValue(),
              checkoutTab: this.checkoutTab
            },
            success: this.makeCheckoutRequestToCheckCOCartError
          })
        } else {
          this.makeCheckoutRequestToCheckCOCartError()
        }
      }
    },
    isBuyableItem (item) {
      return item.errorInfo && (!item.errorInfo.errorCode || this.doesCartItemHasCoCartError(item) || this.doesCartItemHasOutOfStockError(item) || this.doesCartItemHasWeightExceededError(item) || this.doesCartItemMeetsProductAdjustment(item))
    }
  }
}
