<template>
  <div>
    <BliModal
      mobile-fullscreen
      :active="enablePopup"
      @blur="closePopup"
    >
      <BliModalHeader @close="closePopup">
        <template #title>
          {{ $i18n("HOME_PAGE.WHATS_NEW_HEADER") }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        <p
          v-if="whatsNewInfo.whatsNewDescription"
          class="whats-new-text"
        >
          {{ whatsNewInfo.whatsNewDescription }}
        </p>
        <ul class="list-wrapper">
          <li
            v-for="(list, index) in whatsNewInfo.whatsNewList"
            :key="index"
            class="whats-new-text"
          >
            {{ list.name }}
          </li>
        </ul>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          prominence="primary"
          color="common"
          @click="closePopup"
        >
          {{ $i18n('CLOSE') }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./whats-new-popup.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.whats-new-text {
  font: $blu-text-body-2;
  font-size: $blu-font-size-30;
  color: $blu-color-neutral-text-med;
}
.list-wrapper {
  padding: 0px 18px;
  margin: 0px;
}
</style>
