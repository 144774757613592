import { mapGetters } from 'vuex'
import { utcToZonedTime } from 'date-fns-tz'
import BliIconChat from '@blibli/blue-icon/dist/icons/Chat.js'
import BliIconPhone from '@blibli/blue-icon/dist/icons/Phone.js'

export default {
  name: 'HelpAction',
  components: {
    BliIconChat,
    BliIconPhone
  },
  computed: {
    ...mapGetters('homepageStore', ['getConfigs']),
    ...mapGetters('profileStore', ['getMembersData']),
    getCustomerService () {
      return this.getConfigs ? this.getConfigs.customerServicePhoneNumber : '';
    },
    getLiveChatUrl () {
      return this.getConfigs.liveChatUrl
    },
    isCallCenterActive () {
      const date = new Date();
      const timeZone = 'Asia/Jakarta';
      const currentHour = utcToZonedTime(date, timeZone).getHours();
      return currentHour >= 8 && currentHour < 18
    }
  },
  props: {
    showListView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validateCustomerServiceCall (event) {
      if (!this.isCallCenterActive) {
        event.preventDefault();
      }
    }
  }
}
