const migratedPages = [
  {
    path: '/mitra-home',
    name: 'HomePage'
  },
  {
    path: '/mitra-registration',
    name: 'MitraRegistration'
  },
  {
    path: '/mitra-push-notification',
    name: 'MitraPushNotification'
  },
  {
    path: '/mitra-wallet-registration',
    name: 'MitraWalletRegistration'
  },
  {
    path: '/wallet-details',
    name: 'WalletDetails'
  },
  {
    path: '/mitra-setting-pin',
    name: 'MitraSettingPin'
  },
  {
    path: '/mitra/digital/blipay',
    name: 'MitraBlipayTopup'
  },
  {
    path: '/mitra/digital/blipay/cashout',
    name: 'MitraBlipayCashout'
  },
  {
    path: '/mitra/digital/cart/summary/:product',
    name: 'DigitalCartSummary'
  },
  {
    path: '/mitra/digital/promotion',
    name: 'PromotionPage'
  },
  {
    path: '/mitra-capture',
    name: 'MitraCapture',
    children: [
      {
        path: 'instructions',
        name: 'MitraInstructions'
      },
      {
        path: 'image',
        name: 'MitraCaptureImage'
      }
    ]
  },
  {
    path: '/mitra/all/products',
    name: 'AllProducts'
  },
  {
    path: '/mitra/delete-account',
    name: 'DeleteAccount'
  },
  {
    path: '/mitra/digital/order/thank-you/:orderId',
    name: 'DigitalThankyou'
  },
  {
    path: '/mitra/digital/pulsa',
    name: 'DigitalPulsa'
  },
  {
    path: '/mitra/order/payment/:orderId',
    name: 'MitraBlipayPin'
  },
  {
    path: '/mitra/digital/paket-data',
    name: 'DigitalDataPackage'
  },
  {
    path: '/mitra/digital/ewallet-topup',
    name: 'DigitalEWalletTopup'
  },
  {
    path: '/checkin-mission',
    name: 'CheckinMission'
  },
  {
    path: '/mitra/digital/token-listrik',
    name: 'DigitalTokenListrik'
  }
]
export {
  migratedPages
}
