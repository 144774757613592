import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import { root } from '@vue3/store/root'
import { mapActions } from 'pinia'

export default {
  name: 'PageNotFound',
  components: {
    BliButton
  },
  created () {
    this.$trackTrackError('MitraUrlNotFoundError')
  },
  methods: {
    ...mapActions(root, ['SET_PAGE_NOT_FOUND_INFO']),
    handleReload () {
      this.SET_PAGE_NOT_FOUND_INFO(false)
      this.$router.push('/')
    }
  }
}
