<template>
  <div
    class="b-scrim"
    @click="closePopup"
  >
    <div
      class="image-content"
      @click="(event) => handlePropogation(event)"
    >
      <img
        class="flash-image"
        :src="getImageUrl"
        alt="flash_image"
      >
    </div>
    <div
      class="center-text font-14 font-subtitle-2 padding-8 neutral-text-inv"
    >
      {{ $i18n('FLASH_MESSAGE') }}
    </div>
  </div>
</template>
<script src="./flash-image.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
@import '@blibli/blue-dls-utils/dist/scss/scrim-helper';
.image-content {
  display: flex;
  justify-content: center;
  .flash-image {
    width: 270px;
    aspect-ratio: 9/16;
    }
}
</style>
