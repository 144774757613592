<template>
  <div class="not-found-wrapper">
    <img
      src="@vue3/assets/icons/page-not-found.svg"
      alt="ikon"
    >
    <div class="font-title-2 font-18">
      {{ $i18n('NOT_FOUND_PAGE.PAGE_NOT_FOUND_HEAD') }}
    </div>
    <div class="description font-body-2 font-14">
      {{ $i18n('NOT_FOUND_PAGE.PAGE_NOT_FOUND_TEXT') }}
    </div>
    <BliButton @click="handleReload">
      {{ $i18n("NOT_FOUND_PAGE.RELOAD") }}
    </BliButton>
  </div>
</template>
<script src="./page-not-found.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.not-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  gap: 16px;
  height: 100vh;
  .description {
    text-align: center;
    position: relative;
    padding: 0px 16px;
    color: $blu-color-neutral-text-med;
  }
}
</style>
