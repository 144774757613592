import { addCollab } from '@/router/helpers/collab'
import { futurePathReplace } from '@/router/helpers/post-change'
import { compareVueRoutes } from '@/router/routeUtil.js'
// collab routers init
export function addCollabRouter (r) {
  addCollab(r)
}
export function propagateToMainGenerator (router) {
  return (fullPath, resetScroll = true) => {
    if (compareVueRoutes(router.currentRoute.fullPath, fullPath)) return
    // the path not yet updated
    futurePathReplace(router, fullPath, resetScroll)
  }
}

