import xRegionApis from '@/api/xRegionApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    provinces: null,
    cities: null,
    districts: null,
    subDistricts: null
  },
  getters: {
    getAllProvinces (state) {
      return state.provinces
    },
    getCities (state) {
      return state.cities
    },
    getDistricts (state) {
      return state.districts
    },
    getSubDistricts (state) {
      return state.subDistricts
    }
  },
  mutations: {
    setAllProvinces (state, value) {
      state.provinces = value
    },
    setCities (state, value) {
      state.cities = value
    },
    setDistricts (state, value) {
      state.districts = value
    },
    setSubDistricts (state, value) {
      state.subDistricts = value
    }
  },
  actions: {
    GET_ALL_PROVINCES ({ commit, dispatch }, { success }) {
      xRegionApis.getAllProvinces((response) => {
        if (response.data.code === 200 && response.data.data && response.data.data.length) {
          commit('setAllProvinces', response.data.data)
          success(response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true })
        }
      }, error => errorHandler.handleErrors(dispatch, error))
    },
    GET_CITIES ({ commit, dispatch }, { success, provinceId }) {
      xRegionApis.getCities((response) => {
        if (response.data.code === 200 && response.data.data && response.data.data.length) {
          commit('setCities', response.data.data)
          success(response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true })
        }
      }, error => errorHandler.handleErrors(dispatch, error), provinceId)
    },
    GET_DISTRICTS ({ commit, dispatch }, { success, cityId }) {
      xRegionApis.getDistricts((response) => {
        if (response.data.code === 200 && response.data.data && response.data.data.length) {
          commit('setDistricts', response.data.data)
          success(response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true })
        }
      }, error => errorHandler.handleErrors(dispatch, error), cityId)
    },
    GET_SUBDISTRICTS ({ commit, dispatch }, { success, districtId, handleError = true, fail }) {
      xRegionApis.getSubdistricts((response) => {
        if (response.data.code === 200 && response.data.data && response.data.data.length) {
          commit('setSubDistricts', response.data.data)
          success(response.data.data)
        } else {
          if (handleError) {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true })
          }
          fail && fail()
        }
      }, error => errorHandler.handleErrors(dispatch, error), districtId)
    }
  },
  namespaced: true
};
