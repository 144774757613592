export default {
  state: {
    showBouncingBoxLoader: false,
    pwaDisplayMode: ''
  },
  getters: {
    showBouncingBoxLoader (state) {
      return state.showBouncingBoxLoader
    },
    getPwaDisplayMode (state) {
      return state.pwaDisplayMode
    }
  },
  mutations: {
    setBouncingBoxLoader (state, value) {
      state.showBouncingBoxLoader = value
    },
    setPwaDisplayMode (state, value) {
      state.pwaDisplayMode = value
    }
  },
  actions: {
    SET_BOUNCING_BOX_LOADER ({ commit }, value) {
      commit('setBouncingBoxLoader', value)
    },
    SET_PWA_DISPLAY_MODE ({ commit }, pwaDisplayMode) {
      commit('setPwaDisplayMode', pwaDisplayMode)
    }
  },
  namespaced: false
};
