import digitalApis from '@/api/digitalApis';
import { CancelToken } from 'axios';
import { AXIOS_REQUEST_CANCELLATION_ERROR } from '@/constant/contants';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    orders: [],
    isFetchingOrderHistory: false,
    paging: null,
    filterText: '',
    filterValue: {},
    devicePromise: null,
    orderHistoryCancelToken: null,
    pendingDigitalOrders: [],
    pendingOrderPaging: null,
    isFetchingPendingOrders: false,
    isFetchingOrderList: false
  },
  mutations: {
    setOrders (state, orders) {
      state.orders = orders;
    },
    setIsFetchingOrderHistory (state, isFetching) {
      state.isFetchingOrderHistory = isFetching;
    },
    setPaging (state, paging) {
      state.paging = paging
    },
    setFilterText (state, text) {
      state.filterText = text;
    },
    setFilterValue (state, value) {
      state.filterValue = value;
    },
    setDevicePromise (state, value) {
      state.devicePromise = value
    },
    setOrderHistoryCancelToken (state, cancelToken) {
      state.orderHistoryCancelToken = cancelToken
    },
    setPendingOrder (state, pendingOrders) {
      state.pendingDigitalOrders = pendingOrders;
    },
    setPendingPaging (state, paging) {
      state.pendingOrderPaging = paging;
    },
    setIsFetchingPendingOrders (state, isFetching) {
      state.isFetchingPendingOrders = isFetching;
    },
    setIsFetchingOrderList (state, isFetching) {
      state.isFetchingOrderList = isFetching
    }
  },
  getters: {
    orders (state) {
      return state.orders;
    },
    isFetchingOrderHistory (state) {
      return state.isFetchingOrderHistory;
    },
    paging (state) {
      return state.paging;
    },
    getFilterText (state) {
      return state.filterText;
    },
    getFilterValue (state) {
      return state.filterValue;
    },
    getDevicePromise (state) {
      return state.devicePromise
    },
    getOrderHistoryCancelToken (state) {
      return state.orderHistoryCancelToken
    },
    getPendingOrder (state) {
      return state.pendingDigitalOrders;
    },
    getPendingPaging (state) {
      return state.pendingOrderPaging;
    },
    isFetchingPendingOrders (state) {
      return state.isFetchingPendingOrders;
    },
    getIsFetchingOrderList (state) {
      return state.isFetchingOrderList;
    }
  },
  actions: {
    SET_PROMISE ({ commit }, { value }) {
      commit('setDevicePromise', value)
    },
    GET_ORDERS ({ commit, state }, { payload, success, isNewPage }) {
      // dispatch('CANCEL_ORDER_HISTORY_API_REQUEST')

      if (payload.status === 'PENDING_ORDER') {
        commit('setIsFetchingPendingOrders', true);
      } else {
        commit('setIsFetchingOrderHistory', true);
      }
      commit('setOrderHistoryCancelToken', CancelToken.source());

      digitalApis.getOrders(
        response => {
          if (!response.data.errors) {
            if (payload.status === 'PENDING_ORDER') {
              commit('setIsFetchingPendingOrders', false);
            } else {
              commit('setIsFetchingOrderHistory', false);
            }
            if (isNewPage) {
              if (payload.status === 'PENDING_ORDER') {
                commit('setPendingOrder', response.data.data);
                commit('setPendingPaging', response.data.paging);
              } else {
                commit('setOrders', response.data.data);
                commit('setPaging', response.data.paging);
              }
            } else {
              if (payload.status === 'PENDING_ORDER') {
                commit('setPendingOrder', [...state.pendingDigitalOrders, ...response.data.data]);
                commit('setPendingPaging', response.data.paging);
              } else {
                commit('setOrders', [...state.orders, ...response.data.data]);
                commit('setPaging', response.data.paging);
              }
            }
            success && success(response.data.data);
          } else {
            commit('setIsFetchingPendingOrders', false);
            commit('setIsFetchingOrderHistory', false);
          }
        },
        error => {
          if (error && error.message === AXIOS_REQUEST_CANCELLATION_ERROR) {
            console.log(error)
          } else {
            commit('setIsFetchingOrderHistory', false);
            commit('setIsFetchingPendingOrders', false);
            console.log(error);
          }
        },
        payload,
        state.orderHistoryCancelToken.token
      );
    },
    SET_FILTER ({ commit }, value) {
      commit('setFilterValue', value)
    },
    RESET_ORDERS ({ commit }) {
      commit('setOrders', []);
    },
    RESET_PENDING_ORDERS ({ commit }) {
      commit('setPendingOrder', []);
    },
    CANCEL_ORDER_HISTORY_API_REQUEST ({ state }) {
      if (state.orderHistoryCancelToken) {
        state.orderHistoryCancelToken.cancel(AXIOS_REQUEST_CANCELLATION_ERROR)
      }
    },
    CANCEL_DIGITAL_ORDER ({ dispatch }, { params, payload, success }) {
      digitalApis.cancelDigitalOrder(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data)
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    REPAY_DIGITAL_ORDER ({ commit, dispatch }, { pathVariables, success } = {}) {
      commit('setIsFetchingOrderHistory', true)
      digitalApis.repayDigitalOrder(
        response => {
          commit('setIsFetchingOrderHistory', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
            response.data.errors === undefined
            ) {
              success(response.data.data)
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingOrderHistory', false);
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    GET_ORDER_LIST_API ({ commit, dispatch }, { payload, success, fail }) {
      commit('setIsFetchingOrderList', true)
      digitalApis.getOrderListApi(
        response => {
          commit('setIsFetchingOrderList', false)
          if (response.data.code === 200) {
            success(response.data.data)
          } else {
            fail()
          }
        },
        error => {
          commit('setIsFetchingOrderList', false)
          fail()
          errorHandler.handleErrors(dispatch, error, true);
        },
        payload
      );
    }
  },
  namespaced: true
};
