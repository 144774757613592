import api from '@/api/blipayCashoutApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    bankList: [],
    transactions: [],
    memberBankList: [],
    cashoutDetails: {}
  },
  mutations: {
    setBankList (state, banks) {
      state.bankList = banks;
    },
    setTransactions (state, transactions) {
      state.transactions = transactions;
    },
    setMemberBankList (state, banks) {
      state.memberBankList = banks;
    },
    setCashoutDetails (state, detail) {
      state.cashoutDetails = detail;
    }
  },
  getters: {
    getBankList (state) {
      return state.bankList;
    },
    getTransactions (state) {
      return state.transactions;
    },
    getMemberBankList (state) {
      return state.memberBankList;
    },
    getCashoutDetails (state) {
      return state.cashoutDetails;
    }
  },
  actions: {
    GET_BANK_LIST ({ commit, dispatch }) {
      api.getBanksList(
        response => {
          if (response.data.code === 200) {
            commit('setBankList', response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        }
      );
    },
    ADD_BANK_ACCOUNT (
      { dispatch },
      { payload, params, success }
    ) {
      api.addMyBankAccount(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    CASH_OUT ({ dispatch, commit }, { payload, params, success, fail }) {
      api.cashoutMoney(
        response => {
          if (response.data.code === 200 && response.data.data) {
            success(response.data.data);
            commit('setCashoutDetails', response.data.data)
          } else {
            if (fail) {
              fail(response.data)
            }
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    SHOW_TRANSACTION_HISTORY ({ dispatch, commit }, { payload }) {
      api.showTransactions(
        response => {
          if (response.data.code === 200) {
            commit('setTransactions', response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_MEMBER_BANK_LIST ({ commit, dispatch }, { payload, fail }) {
      api.getMemberBanksList(
        response => {
          if (response.data.code === 200) {
            commit('setMemberBankList', response.data.data);
          } else {
            if (fail) {
              fail(response.data)
            } else {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    DELETE_MEMBER_BANK_ACCOUNT (
      { dispatch },
      { params, success, payload }
    ) {
      api.deleteAccountOfMember(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    EDIT_BANK_ACCOUNT ({ dispatch }, { payload, params, success }) {
      api.editBankAccount(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    }
  },
  namespaced: true
};
