import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('profileStore', ['getMembersData']),
    ...mapGetters('homepageStore', ['getWalletRequest', 'getConfigs']),
    memberData () {
      return this.getMembersData || {}
    },
    getUserName () {
      const memberDetails = this.getMembersData.memberDetails || {}
      if (memberDetails.firstName && memberDetails.lastName) {
        return `${memberDetails.firstName} ${memberDetails.lastName}`
      }
      return `${this.$i18n('HOME_PAGE.BLIBLI_MITRA')}!`
    },
    storeDetails () {
      return this.memberData.storeDetails || {}
    },
    memberDetails () {
      return this.memberData.memberDetails || {}
    },
    addressDetails () {
      return this.memberData.addressDetails || {}
    },
    walletDetails () {
      return this.getWalletRequest || {}
    },
    isMemberRegistered () {
      return this.memberData.registrationStatus === 'REGISTERED'
    },
    isMemberRejected () {
      return this.memberDetails.verificationStatus === 'REJECTED'
    },
    isMemberSuspendedFromCenter () {
      return this.memberDetails.verificationStatus === 'SUSPENDED'
    },
    isZoneIdAvailable () {
      return !!this.memberData.zoneId
    },
    isVerificationStatusApproved () {
      return this.memberDetails.verificationStatus === 'APPROVED'
    },
    isMemberApproved () {
      return this.isMemberInsideZone || this.isMemberOutsideZone
    },
    isMemberInsideZone () {
      return this.isVerificationStatusApproved && this.isZoneIdAvailable
    },
    isMemberPartiallyInsideZone () {
      return this.isMemberInsideZone && this.getMembersData.isPartiallyVerified
    },
    isMitraIdOrDrpNumberAvailable () {
      return this.storeDetails.mitraId || this.storeDetails.partnerNumber
    },
    isMemberOutsideZone () {
      return this.isVerificationStatusApproved && !this.isZoneIdAvailable
    },
    isMemberNeedReview () {
      return this.memberDetails.verificationStatus === 'NEEDS_APPROVAL'
    },
    isMemberRegistrationInProgress () {
      return this.memberData.registrationStatus === 'IN_PROGRESS'
    },
    isStoreUpdateRequestUnderReview () {
      return this.memberDetails.storeUnderApproval === true
    },
    isMemberSuspendedFromWallet () {
      return this.walletDetails && this.walletDetails.suspended === true
    },
    registrationConfig () {
      return this.getConfigs.registration || {}
    },
    isNewRegistrationFeatureEnabled () {
      return this.registrationConfig.enableFeature || false
    },
    isNewRegistrationFeatureEnabledForWhiteListUsers () {
      return this.isNewRegistrationFeatureEnabled && this.registrationConfig.enableOnlyForWhiteListUser
    },
    isMemberWhitelistedForNewRegistration () {
      const whitelistUsers = this.registrationConfig.whitelistUsers || []
      return whitelistUsers.includes(this.memberDetails.memberId)
    },
    isMemberUnderNewRegistration () {
      return !!this.memberDetails.newRegistration
    },
    mitraPayStatus () {
      return (this.isMemberUnderNewRegistration && this.memberDetails.mitraPayRegistrationStatus) || ''
    },
    isMitraPayNotRegistered () {
      return this.mitraPayStatus === 'NOT_REGISTERED'
    },
    isMitraPayUnderReview () {
      return this.mitraPayStatus === 'NEEDS_REVIEW'
    },
    isMitraPayApproved () {
      return this.mitraPayStatus === 'APPROVED'
    },
    isMitraPayRejected () {
      return this.mitraPayStatus === 'REJECTED'
    },
    isMitraPayInProgress () {
      return this.mitraPayStatus === 'IN_PROGRESS'
    },
    isWalletPinRegistered () {
      return this.isMemberUnderNewRegistration && this.isMitraPayApproved && this.walletDetails.pinRegistered === true
    },
    isNewMemberNotRegisteredWalletPin () {
      return this.isMemberUnderNewRegistration && !this.isWalletPinRegistered
    }
  }
}
