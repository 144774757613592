import apiUrls from './apiUrls'
import apiCall from './apiUtils/makeApiCall'

export default {
  getSurveyDetails (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.getSurveyDetails(), callback, fail, payload)
  },
  submitSurvey (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.submitSurvey(), callback, fail, payload)
  }
}
