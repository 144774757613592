export default {
  name: 'ServerError',
  methods: {
    removeServerErrorScreen () {
      if (window.location.href.includes('/mitra-home')) {
        window.location.reload()
      } else {
        this.$router.go(-1);
      }
      this.$store.dispatch('SET_SERVER_ERROR', false, { root: true });
    }
  }
}
