import apiUrls from './apiUrls';
import makeApiCall from './apiUtils/makeApiCall.js';

export default {
  getAllProvinces (callback, fail) {
    makeApiCall.makeGetRequest(apiUrls.api.getAllProvinces, callback, fail)
  },
  getCities (callback, fail, provinceId) {
    makeApiCall.makeGetRequest(apiUrls.api.getCities(provinceId), callback, fail)
  },
  getDistricts (callback, fail, cityId) {
    makeApiCall.makeGetRequest(apiUrls.api.getDistricts(cityId), callback, fail)
  },
  getSubdistricts (callback, fail, districtId) {
    makeApiCall.makeGetRequest(apiUrls.api.getSubdisticts(districtId), callback, fail)
  }
};
