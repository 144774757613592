import StepsBar from '@/components/web/StepsBar.vue'

export default {
  name: 'OnboardingCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    displayCloseBtn: {
      type: Boolean,
      default: true
    },
    closeBtnTextKey: {
      type: String,
      default: 'CLOSE'
    },
    continueBtnTextKey: {
      type: String,
      default: 'DATE_PICKER.CONTINUE'
    }
  },
  components: {
    StepsBar
  }
}
