import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'

export default {
  name: 'SuspensionPopup',
  components: {
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter,
    BliButton
  },
  props: {
    enablePopup: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$trackPageImpression('MitraAccountDeactivatedReasoning')
  },
  methods: {
    closePopup () {
      this.$emit('closeSuspensionPopup')
    },
    redirectToHelpPage () {
      this.closePopup()
      this.$router.push('/help')
    }
  }
}

