import { mapGetters } from 'vuex'
import { getAppConfiguration } from '@/utils/helpers'
import MemberMixins from '@/mixins/member-mixins'
import { EVENTES_MAPPING_BY_ROUTES } from '@/constant/contants'
import { utcToZonedTime } from 'date-fns-tz'

const routesAccessibleForLoggedOutUsers = ['/home', '/help']
const routesAccessibleForAllLoggedInUsers = [
  '/order', '/account', '/help', '/home',
  '/loyalty', '/notifications', '/paylater',
  '/pnl', '/member-account'
]

export default {
  mixins: [
    MemberMixins
  ],
  computed: {
    ...mapGetters('homepageStore', ['getConfigs', 'getWalletRequest']),
    ...mapGetters(['getUserLoginStatus']),
    isDropshipAvailForThisMember () {
      return this.getConfigs.dropshipAvailableZones.includes(this.getMembersData.zoneId)
    },
    isBetaUser () {
      return this.getConfigs.BETA_MITRA_IDS.includes(this.memberDetails.memberId)
    }
  },
  methods: {
    onFeatureClick (feature, isProduct, featureCallback) {
      const { url } = feature
      this.trackFeatureClick(url)

      if (this.getUserLoginStatus) {
        if (routesAccessibleForAllLoggedInUsers.includes(feature.url)) {
          this.$router.push(url)
          return
        }

        if (this.isMemberApproved) {
          if (!this.isMemberUnderNewRegistration && this.getWalletRequest.pinRegistered === false) {
            this.$router.push('/setting-pin')
            return
          }
          if (feature.beta && !this.isBetaUser) {
            this.$store.dispatch('homepageStore/SET_POPUP_INFO', { key: 'COMING_SOON_POPUP' })
            return
          }
          if (this.getWalletRequest.suspended && feature.url !== '/credit-ledger') {
            this.showNoAccessModals()
            return
          }

          if (!isProduct) {
            if (featureCallback) {
              featureCallback()
            } else {
              this.$router.push(feature.url)
            }
            return
          }

          // checks for product features
          const isDropship = url === '/dropship'
          const isRetailProduct = url === '/retail-checkout' || isDropship
          if (isRetailProduct) {
            const hasRetailAccess = this.checkIfUserHasRetailAccessElseShowModals()
            if (hasRetailAccess) {
              if (isDropship) {
                if (!this.isDropshipAvailForThisMember) {
                  this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'DROPSHIP_NOT_AVAILABLE_MODAL' })
                  this.$trackErrorPopupImpression('DROPSHIP_NOT_AVAILABLE_IN_YOUR_LOCATION', 'DROPSHIP_NOT_AVAILABLE_HEADER')
                  this.$trackTrackErrorHandler('DROPSHIP_NOT_AVAILABLE_IN_YOUR_LOCATION', 'DROPSHIP_NOT_AVAILABLE_HEADER')
                  return
                }
              }
              this.checkIfMaintenanceConfigAvailable(url)
            }
          } else {
            this.checkIfMaintenanceConfigAvailable(url)
          }
        } else {
          this.showNoAccessModals()
        }
      } else {
        if (routesAccessibleForLoggedOutUsers.includes(feature.url)) {
          this.$router.push(feature.url)
        } else {
          this.goToLoginPage()
        }
      }
    },
    checkIfMaintenanceConfigAvailable (url) {
      if (!getAppConfiguration('maintenanceConfig')) {
        this.$store.dispatch('homepageStore/GET_APP_CONFIGURATION', {
          typeOfConfiguration: 'mitraMaintenanceConfig',
          success: this.checkIfMaintenanceElseGoToFeature(url)
        });
      } else {
        this.checkIfMaintenanceElseGoToFeature(url)
      }
    },
    showNoAccessModals () {
      if (!this.isMemberRegistered) {
        this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'INCOMPLETE_REGISTRATION_MODAL' })
        this.$trackErrorPopupImpression('COMPLETE_ACCOUNT_DATA_WARNING', 'HOME_PAGE.DATA_INCOMPLETE_HEADING')
        this.$trackTrackErrorHandler('COMPLETE_ACCOUNT_DATA_WARNING', 'HOME_PAGE.DATA_INCOMPLETE_HEADING')
      } else if (this.isMemberNeedReview) {
        this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'NEEDS_APPROVAL_MODAL' })
        this.$trackErrorPopupImpression('UNDER_VERIFICATION', 'VERIFICATION_HEADER')
        this.$trackTrackErrorHandler('UNDER_VERIFICATION', 'VERIFICATION_HEADER')
      } else {
        this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'NO_ACCESS_MODAL' })
        this.$trackErrorPopupImpression('NO_ACCESS_TO_THIS_PRODUCT_OR_FEATURE', 'HOME_PAGE.NO_FEATURE_ACCESS_TITLE')
        this.$trackTrackErrorHandler('NO_ACCESS_TO_THIS_PRODUCT_OR_FEATURE', 'HOME_PAGE.NO_FEATURE_ACCESS_TITLE')
      }
    },
    trackFeatureClick (featureUrl) {
      const eventDetails = EVENTES_MAPPING_BY_ROUTES[featureUrl]
      if (!eventDetails) {
        return;
      }
      this.$trackClickEvent(eventDetails.gaEventName)
      this.$trackButtonClick(eventDetails.gaEventName)
      const { appsflyerEventName } = eventDetails;
      if (appsflyerEventName) {
        this.$sendEventsToAppsFlyer(appsflyerEventName)
      }

    },
    goToLoginPage () {
      this.$store.dispatch('GO_TO_LOGIN_PAGE', this.getConfigs)
    },
    checkIfUserHasRetailAccessElseShowModals () {
      const memberData = this.getMembersData
      const serviceAccess = this.getMembersData.services || []
      if (this.isStoreUpdateRequestUnderReview) {
        this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'STORE_UNDER_REVIEW_MODAL' })
        return false
      }
      if (this.isMemberOutsideZone) {
        if (this.isMemberUnderNewRegistration) {
          if (memberData.addressDetails === null) {
            this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'MEMBER_WITH_NO_STORE_MODAL' })
            return false
          } else {
            this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'OUTSIDE_ZONE_MODAL' })
            return false
          }
        }
        if (memberData.storeDetails === null) {
          this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'NO_STORE_MODAL' })
          return false
        } else {
          this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'OUTSIDE_ZONE_MODAL' })
          this.$trackErrorPopupImpression('OUT_OF_REACH_ADDRESS', 'HOME_PAGE.EDIT_ADDRESS_WARNING_HEADER')
          this.$trackTrackErrorHandler('OUT_OF_REACH_ADDRESS', 'HOME_PAGE.EDIT_ADDRESS_WARNING_HEADER')
          return false
        }
      }
      if (this.isMemberPartiallyInsideZone) {
        if (this.isMitraIdOrDrpNumberAvailable) {
          this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'DRP_OR_MITRA_ID_UNDER_REVIEW_MODAL' })
        } else {
          this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: 'NO_DRP_AND_MITRA_ID_MODAL' })
          this.$trackErrorPopupImpression('OUT_OF_REACH_ADDRESS', 'HOME_PAGE.UNDER_STORE_REVIEW_HEADER')
          this.$trackTrackErrorHandler('OUT_OF_REACH_ADDRESS', 'HOME_PAGE.UNDER_STORE_REVIEW_HEADER')
        }
        return false
      }
      if (!serviceAccess.includes('replenishment_products')) {
        this.showNoAccessModals()
        return false
      }
      return true
    },
    getMaintainencePopupData (productConfig) {
      const maintainencePopup = {
        isMaintainencePopupVisible: true,
        date: productConfig.date,
        time: productConfig.timeSpan
      }
      return maintainencePopup
    },
    getDate (date) {
      return this.$options.filters.date(new Date(date), 'dd MMM yyyy')
    },

    isMaintenanceAvailableForCurrentDate (date, timeSpan) {
      let dates = date.split('-')
      let maintenanceStartDate = new Date(dates[0])
      let maintenanceEndDate = new Date(dates[1])
      if (timeSpan) {
        const time = timeSpan.split('-')
        maintenanceStartDate = new Date(dates[0] + ' ' + time[0])
        maintenanceEndDate = new Date(dates[1] + ' ' + time[1])
      }
      const timeZone = 'Asia/Jakarta'
      const currentDate = new Date()
      const jktCurrentDate = utcToZonedTime(currentDate, timeZone)
      return jktCurrentDate >= maintenanceStartDate && jktCurrentDate <= maintenanceEndDate
    },
    checkIfMaintenanceElseGoToFeature (url) {
      const maintenanceConfig = JSON.parse(getAppConfiguration('maintenanceConfig')) || [];
      const productConfig = maintenanceConfig.find(item => item.url === url) || {}

      if (productConfig.maintenance) {
        if (productConfig.date) {
          if (this.isMaintenanceAvailableForCurrentDate(productConfig.date, productConfig.timeSpan)) {
            const showModal = this.getMaintainencePopupData(productConfig)
            const maintenanceDate = showModal.date.split('-')
            const maintenanceTime = showModal.time.split('-')
            const startDateAndTime = `${this.getDate(maintenanceDate[0])} (${maintenanceTime[0]} WIB)`
            const endDateWithTime = `${this.getDate(maintenanceDate[1])} (${maintenanceTime[1]} WIB)`
            const popupInfo = {
              headerText: this.$i18n('HOME_PAGE.MAINTENANCE_HEADING_WITH_TIME'),
              bodyText: this.$i18n('HOME_PAGE.MAINTENANCE_BODY_WITH_TIME', startDateAndTime + ' - ' + endDateWithTime)
            }
            this.$store.dispatch('homepageStore/SET_MODAL_INFO', {
              key: 'MAINTENANCE_MODAL',
              properties: popupInfo
            })
            this.$trackErrorPopupImpression('UNDER_MAINTENANCE', popupInfo.headerText)
            this.$trackTrackErrorHandler('UNDER_MAINTENANCE', popupInfo.headerText)
          } else {
            this.$router.push(url)
          }
        } else {
          this.$store.dispatch('homepageStore/SET_MODAL_INFO', {
            key: 'MAINTENANCE_MODAL',
            properties: {
              headerText: this.$i18n('HOME_PAGE.MAINTENANCE_HEADING'),
              bodyText: this.$i18n('HOME_PAGE.MAINTENANCE_BODY')
            }
          })
          this.$trackErrorPopupImpression('UNDER_MAINTENANCE', 'HOME_PAGE.MAINTENANCE_HEADING')
          this.$trackTrackErrorHandler('UNDER_MAINTENANCE', 'HOME_PAGE.MAINTENANCE_HEADING')
        }
      } else {
        this.$router.push(url)
      }
    }
  }
}
