import gamificationApis from '@/api/gamificationApis'
import errorHandler from '../api/apiUtils/errorHandler';
import { CancelToken } from 'axios'

export default {
  state: {
    leaderBoardApiCancelToken: null,
    contestCriteria: null,
    lowestWinningRank: -1
  },
  getters: {
    getContestCriteria: state => state.contestCriteria,
    getLowestWinningRank: state => state.lowestWinningRank
  },
  mutations: {
    setLeaderBoardApiCancelToken (state, cancelToken) {
      state.leaderBoardApiCancelToken = cancelToken
    },
    setContestCriteria (state, contestCriteria) {
      state.contestCriteria = contestCriteria
    },
    setLowestWinningRank (state, rank) {
      state.lowestWinningRank = rank
    }
  },
  actions: {
    GET_ALL_CONTESTS ({ dispatch }, { success, payload, params, contestType }) {
      gamificationApis.getAllContests(response => {
        if (response.data.code === 200) {
          success(response.data.data, contestType);
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => errorHandler.handleErrors(dispatch, error), payload, params)
    },
    GET_CONTEST_DETAIL ({ dispatch, commit }, { success, payload, contestId }) {
      gamificationApis.getContestDetail(response => {
        if (response.data.code === 200) {
          success(response.data.data);
          if (response.data.data.contestCriteria) {
            commit('setContestCriteria', response.data.data.contestCriteria.contestCriteriaKey)
          }
          if (response.data.data.prizeMoney) {
            const lowestWinningRank = Object.keys(response.data.data.prizeMoney).sort((a, b) => b - a)[0]
            commit('setLowestWinningRank', lowestWinningRank)
          }
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => errorHandler.handleErrors(dispatch, error), payload, contestId)
    },
    GET_LEADER_BOARD ({ dispatch, commit, state }, { success, payload, contestId, fail }) {
      commit('setLeaderBoardApiCancelToken', CancelToken.source())
      gamificationApis.getLeaderBoard(response => {
        if (response.data.code === 200) {
          success(response.data.data);
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
          fail && fail()
        }
      }, error => {
        errorHandler.handleErrors(dispatch, error)
        fail && fail()
      }, payload, contestId, state.leaderBoardApiCancelToken.token)
    },
    CANCEL_LEADER_BOARD_API_CALL ({ state }) {
      state.leaderBoardApiCancelToken.cancel()
    }
  },
  namespaced: true
}
