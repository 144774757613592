<template>
  <div>
    <BliModal
      mobile-fullscreen
      :active="enablePopup"
      @blur="doNpsLater"
    >
      <BliModalHeader @close="doNpsLater">
        <template #title>
          {{ $i18n('NPS.EVALUATION') }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        <div class="font-title-2 font-18 neutral-text-high">
          {{ $i18n('NPS.HEADER_MSG') }}
        </div>
        <BliRating
          v-model="ratingValue"
          size="large"
          class="margin-top-16"
        />
        <div class="font-14 font-body-2 label-wrapper">
          <div
            v-for="(text, index) in npsDetails.choiceChipResponseMap"
            :key="index"
            class="rating-label"
          >
            {{ text.name }}
          </div>
        </div>
        <div class="options-wrapper">
          <p class="font-subtitle-1 neutral-text-high font-16">
            {{ question }}
          </p>
          <div class="answer-container">
            <div
              v-for="(answer, index) in feedbackOptionAnswers"
              :key="answer"
              :class="['answer-block',{'margin-left-8' : index%2 != 0},{'selected-answer' : !!selectedAnswers[answer]}]"
              @click="toggleAnswerSelect(answer)"
            >
              {{ answer }}
            </div>
          </div>
        </div>
        <div>
          <p class="padding-bottom-12 padding-top-16 font-subtitle-1 neutral-text-high font-16">
            {{ $i18n('NPS.COMMENTS') }}
          </p>
          <BliField
            :label="$i18n('NPS.COMMENT_PLACEHOLDER')"
            help-text-status="error"
          >
            <BliTextArea
              v-model="comment"
              :max-length="500"
              resizable="none"
            />
            <template
              v-if="isCommentReachedMaxLimit"
              #help-text
            >
              {{ $i18n('NPS.COMMENT_ERROR') }}
            </template>
          </BliField>
        </div>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          prominence="primary"
          color="common"
          :disabled="disableSubmitBtn"
          @click="submitNPS"
        >
          {{ $i18n('NPS.SUBMIT') }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./net-promoter-score-details.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.label-wrapper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  .rating-label {
    width: 46px;
    text-align: center;
    text-overflow: ellipsis;
    color: $blu-color-neutral-text-med;
  }
}
:deep .blu-modal__body-content {
  .blu-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
     :deep svg{
      width: 46px;
      text-align: center;
    }
  }
}
.options-wrapper {
  margin-top: 24px;
  .answer-container {
    .answer-block {
      padding: 16px 20px;
      display: inline-block;
      width: calc(50% - 4px);
      border: 1px solid $blu-color-neutral-border-low;
      border-radius: 8px;
      margin-top: 8px;
      vertical-align: top;
      transition: all 0.5s ease-in-out;
      background-color: $blu-color-neutral-background-default;
    }
    .selected-answer {
      background: $blu-color-info-background-default;
      border-color: $blu-color-info-text-default;
    }
  }
}

</style>
