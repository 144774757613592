import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getMemberDetails (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getMemberDetails(),
      callback,
      fail,
      payload
    );
  },
  generateOtp (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getGenerateOtp(),
      callback,
      fail,
      payload,
      params
    );
  },
  verifyOtp (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getVerifyOtpApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  verifyKtp (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getVerifyKtpApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  getStoreTypeList (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getStoreTypeList,
      callback,
      fail,
      params
    );
  },
  getMemberInProgress (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getMemberInProgressApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  saveMemberDetails (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getSaveMemberDetailApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  verifyUserPin (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.payOrderWithWallet,
      callback,
      fail,
      payload
    );
  },
  updatePin (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.generateOtp(),
      callback,
      fail,
      payload,
      params
    );
  },
  uploadKTP (callback, fail, params, payload) {
    apiCall.uploadFile(
      apiUrls.api.getProfileKtpUploadApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  downloadKTP (callback, fail, payload, params) {
    apiCall.imageFromBlob(
      apiUrls.api.getDownloadKTP_API(),
      callback,
      fail,
      payload,
      params
    );
  },
  getSignedUrlForImageUpload (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getSignedUrlToUpload(),
      callback,
      fail,
      payload,
      params
    );
  },
  optWhatsApp (callback, fail, payload) {
    apiCall.makePutRequest(
      apiUrls.api.getWhatsAppOptApi(),
      callback,
      fail,
      payload
    );
  },
  getAddressDetails (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getAddressDetailsApi,
      callback,
      fail,
      params
    );
  },
  getRejectedReasons (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getRejectedReasons(),
      callback,
      fail,
      payload
    );
  },
  getMemberOnboardingStatus (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.memberOnboardingStatus(),
      callback,
      fail,
      payload,
      params
    )
  },
  updateMemberOnboardingStatus (callback, fail, payload, params) {
    apiCall.makePutRequest(
      apiUrls.api.memberOnboardingStatus(),
      callback,
      fail,
      payload,
      params
    )
  },
  getMemberStatus (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getMemberStatus(),
      callback,
      fail,
      payload
    )
  },
  registerForOnGoingMissionAndNotifications (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.onGoingMissionAndNotifications(),
      callback,
      fail,
      payload,
      params
    )
  },
  memberRegistration (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.memberRegistration(),
      callback,
      fail,
      params,
      payload
    );
  },
  walletRegistration (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.walletRegistration(),
      callback,
      fail,
      params,
      payload
    );
  },
  getReferralDetails (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getReferralDetailsApi,
      callback,
      fail,
      params
    );
  },
  getReferralEligibleFlag (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getReferralEligibleFlagApi,
      callback,
      fail,
      payload
    );
  },
  getReferralRewardDetails (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getReferralRewardDetails,
      callback,
      fail,
      payload
    );
  },
  getReferralRewardHistory (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getReferralRewardHistory,
      callback,
      fail,
      payload
    );
  },
  verifyPinForAccountDeletion (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.verifyPinForAccountDeletion,
      callback,
      fail,
      payload
    );
  },
  generateOtpForAccountDeletion (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.generateOtpForAccountDeletion,
      callback,
      fail,
      payload
    );
  },
  accountDeletionDetails (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.accountDeletionDetails,
      callback,
      fail,
      payload
    );
  },
  accountDeletionRequest (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.accountDeletionRequest,
      callback,
      fail,
      payload
    );
  }
};
