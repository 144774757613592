import errorHandler from '../api/apiUtils/errorHandler'
import paylaterApis from '../api/paylaterApis'

export default {
  state: {
    memberPaylaterDetail: null,
    subscriptionDetails: null,
    transactionHistory: null,
    profileImageName: null,
    ktpImageName: null,
    profileImage: null,
    ktpImage: null,
    formData: {},
    ktpLoader: false,
    profileLoader: false,
    paylaterRequestError: false,
    paylaterRequestLoader: false
  },
  getters: {
    getMemberPaylaterDetail (state) {
      return state.memberPaylaterDetail
    },
    getPaylaterSubscriptionDetails (state) {
      return state.subscriptionDetails
    },
    getPaylaterTransactionHistory (state) {
      return state.transactionHistory
    },
    getProfileImageName (state) {
      return state.profileImageName
    },
    getKtpImageName (state) {
      return state.ktpImageName
    },
    ktpImage (state) {
      return state.ktpImage
    },
    profileImage (state) {
      return state.profileImage
    },
    getFormData (state) {
      return state.formData
    },
    ktpLoader (state) {
      return state.ktpLoader
    },
    profileLoader (state) {
      return state.profileLoader
    },
    isPaylaterRequestError (state) {
      return state.paylaterRequestError
    },
    getPaylaterRequestLoader (state) {
      return state.paylaterRequestLoader
    }
  },
  mutations: {
    setMemberPaylaterDetail (state, value) {
      state.memberPaylaterDetail = value
    },
    setSubscriptionDetails (state, value) {
      state.subscriptionDetails = value
    },
    setPaylaterTransactionHistory (state, value) {
      if (!value || !state.transactionHistory) {
        state.transactionHistory = value
      } else {
        state.transactionHistory = {
          ...value,
          content: [
            ...state.transactionHistory.content,
            ...value.content
          ]
        }
      }
    },
    setProfileImageName (state, value) {
      state.profileImageName = value
    },
    setKtpImageName (state, value) {
      state.ktpImageName = value
    },
    setKtpImage (state, value) {
      state.ktpImage = value
    },
    setProfileImage (state, value) {
      state.profileImage = value
    },
    setFormData (state, value) {
      state.formData = {
        ...state.formData,
        ...value
      }
    },
    resetFormData (state) {
      state.formData = {}
      state.profileImageName = null
      state.ktpImageName = null
      state.profileImage = null
      state.ktpImage = null
    },
    setKtpLoader (state, value) {
      state.ktpLoader = value
    },
    setProfileLoader (state, value) {
      state.profileLoader = value
    },
    setPaylaterRequestError (state, value) {
      state.paylaterRequestError = value;
    },
    setPaylaterRequestLoader (state, value) {
      state.paylaterRequestLoader = value;
    }
  },
  actions: {
    GET_MEMBER_PAYLATER_DETAIL ({ commit, dispatch }, { payload, success, fail }) {
      commit('setPaylaterRequestLoader', true);
      paylaterApis.getMemberPaylaterDetail((response) => {
        commit('setPaylaterRequestLoader', false);
        if (response.data.code === 200 && !response.data.errors) {
          commit('setPaylaterRequestError', false);
          window.localStorage.setItem('memberPaylaterStatus', response.data.data.status)
          commit('setMemberPaylaterDetail', response.data.data)
          success && success(response.data.data)
        } else {
          fail && fail()
          commit('setPaylaterRequestError', true);
        }
      },
      error => {
        commit('setPaylaterRequestLoader', false);
        commit('setPaylaterRequestError', true);
        errorHandler.handleErrors(dispatch, error, true)
      },
      payload
      );
    },
    RESET_MEMBER_PAYLATER_DETAIL ({ commit }) {
      commit('setMemberPaylaterDetail', null)
    },
    SUBMIT_PAYLATER_APPLICATION ({ dispatch }, { success, payload, fail }) {
      paylaterApis.submitPaylaterApplication((response) => {
        if (response.data.code === 200 && !response.data.errors) {
          success()
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
          fail()
        }
      }, error => {
        fail()
        errorHandler.handleErrors(dispatch, error)
      }, payload)
    },
    GET_PAYLATER_TRANSACTION_HISTORY ({ dispatch, commit }, { payload, params, success }) {
      paylaterApis.getPaylaterTransactionHistory((response) => {
        if (response.data.code === 200 && !response.data.errors) {
          commit('setPaylaterTransactionHistory', response.data.data)
          success && success(response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => {
        errorHandler.handleErrors(dispatch, error)
      }, payload, params)
    },
    RESET_PAYLATER_TRANSACTION_HISTORY ({ commit }) {
      commit('setPaylaterTransactionHistory', null)
    },
    GET_SUBSCRIPTION_DETAILS ({ commit, dispatch }, { payload }) {
      paylaterApis.getPaylaterSubscriptionDetails((response) => {
        if (response.data.code === 200 && !response.data.errors) {
          commit('setSubscriptionDetails', response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => errorHandler.handleErrors(dispatch, error), payload)
    },
    RESET_SUBSCRIPTION_DETAILS ({ commit }) {
      commit('setSubscriptionDetails', null)
    },
    REUPLOAD_PAYLATER_DOCUMENTS ({ dispatch }, { success, payload }) {
      paylaterApis.reuploadPaylaterDocuments((response) => {
        if (response.data.code === 200 && !response.data.errors) {
          success()
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => {
        errorHandler.handleErrors(dispatch, error)
      }, payload)
    },
    UPLOAD_PAYLATER_DOCUMENTS ({ commit, dispatch }, { success, params, payload, fail }) {
      paylaterApis.uploadPaylaterDocuments((response) => {
        if (response.data.code === 200 && !response.data.errors) {
          params.type === 'profile' ? commit('setProfileImageName', response.data.data) : commit('setKtpImageName', response.data.data)
          success(response.data.data, params.type)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
          fail()
        }
      }, error => {
        errorHandler.handleErrors(dispatch, error)
        fail()
      }, params, payload)
    },
    GET_PAYLATER_DOCUMENTS ({ commit, dispatch }, { payload, params, type, success, fail } = {}) {
      paylaterApis.getPaylaterDocuments(response => {
        // btoa for converting byte stream to string
        var base64 = btoa(new Uint8Array(new Uint8Array(response.data)).reduce(
          function (data, byte) {
            return data + String.fromCharCode(byte);
          },
          ''
        ));
        const base64encoded = `data:${response.headers['content-type']};base64,${base64}`;
        type === 'profile' ? commit('setProfileImage', base64encoded) : commit('setKtpImage', base64encoded)
        success(type)
      }, error => {
        fail(type, false)
        errorHandler.handleErrors(dispatch, error);
      }, payload, params);
    }
  },
  namespaced: true
}
