import api from '../api/profileApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    membersData: {},
    userPin: '',
    ocrDetails: {
      firstName: '',
      lastName: '',
      fullName: '',
      placeOfBirth: '',
      dateOfBirth: '',
      gender: '',
      ktpNumber: ''
    },
    ktpImage: '',
    profileImage: '',
    ktpLoader: false,
    profileLoader: false,
    storeImageLoader: false,
    storeImageUrl: '',
    storeImageFileName: '',
    storeImageChanged: false,
    isMemberFetched: false,
    inProgressMemberData: {},
    rejectionTitleAndReasons: [],
    imageCaptureProps: {},
    isStoreImageUploadedNow: false,
    referralData: {},
    rewardDetails: {},
    referralHistoryList: [],
    isFetchingReferralDetails: false,
    isFetchingRewardDetails: false,
    referralHistoryPaging: null,
    isEligibleforDelete: false
  },
  getters: {
    getMembersData (state) {
      return state.membersData;
    },
    getUserPin (state) {
      return state.userPin;
    },
    getOcrDetails (state) {
      return state.ocrDetails;
    },
    isMemberFetched (state) {
      return state.isMemberFetched;
    },
    getInProgressMemberDetails (state) {
      return state.inProgressMemberData;
    },
    getRejectionTitleAndReasons (state) {
      return state.rejectionTitleAndReasons;
    },
    ktpImage (state) {
      return state.ktpImage
    },
    profileImage (state) {
      return state.profileImage
    },
    storeImageUrl (state) {
      return state.storeImageUrl
    },
    storeImageFileName (state) {
      return state.storeImageFileName
    },
    isNewStoreImageUploaded (state) {
      return state.storeImageChanged
    },
    showKtpLoader (state) {
      return state.ktpLoader
    },
    showProfileLoader (state) {
      return state.profileLoader
    },
    showStoreImageLoader (state) {
      return state.storeImageLoader
    },
    getImageCaptureProps (state) {
      return state.imageCaptureProps
    },
    getIsStoreImageUploadedNow (state) {
      return state.isStoreImageUploadedNow
    },
    getReferralData (state) {
      return state.referralData
    },
    getRewardDetails (state) {
      return state.rewardDetails
    },
    getReferralHistoryList (state) {
      return state.referralHistoryList
    },
    isFetchingReferralDetails (state) {
      return state.isFetchingReferralDetails
    },
    isFetchingRewardDetails (state) {
      return state.isFetchingRewardDetails
    },
    getReferralHistoryPaging (state) {
      return state.referralHistoryPaging
    },
    isEligibleforDeleteAccount (state) {
      return state.isEligibleforDelete
    }
  },
  mutations: {
    setMembersData (state, value) {
      state.membersData = value;
    },
    setUserPin (state, value) {
      state.userPin = value;
    },
    setOcrDetails (state, value) {
      state.ocrDetails = value;
    },
    clearOcrDetails (state, value) {
      state.ocrDetails = value || {};
    },
    setIsMemberFetched (state, fetched) {
      state.isMemberFetched = fetched
    },
    setInProgressMemberDetails (state, value) {
      state.inProgressMemberData = value;
    },
    setRejectionTitleAndReasons (state, value) {
      state.rejectionTitleAndReasons = value;
    },
    setKtpImage (state, value) {
      state.ktpImage = value
    },
    setProfileImage (state, value) {
      state.profileImage = value
    },
    setStoreImageUrl (state, value) {
      state.storeImageUrl = value
    },
    setStoreImageFileName (state, value) {
      state.storeImageFileName = value
    },
    setStoreImageChanged (state, value) {
      state.storeImageChanged = value
    },
    setKtpLoader (state, value) {
      state.ktpLoader = value
    },
    setProfileLoader (state, value) {
      state.profileLoader = value
    },
    setStoreImageLoader (state, value) {
      state.storeImageLoader = value
    },
    setImageCaptureProps (state, value) {
      state.imageCaptureProps = value
    },
    setIsStoreImageUploadedNow (state, value) {
      state.isStoreImageUploadedNow = value
    },
    setReferralData (state, value) {
      state.referralData = value
    },
    setReferralRewardDetails (state, value) {
      state.rewardDetails = value
    },
    setReferralHistoryList (state, value) {
      state.referralHistoryList = value
    },
    setIsFetchingReferralDetails (state, value) {
      state.isFetchingReferralDetails = value
    },
    setIsFetchingRewardDetails (state, value) {
      state.isFetchingRewardDetails = value
    },
    setReferralHistoryPaging (state, value) {
      state.referralHistoryPaging = value
    },
    setAccountEligibleForDelete (state, value) {
      state.isEligibleforDelete = value
    }
  },
  actions: {
    GET_MEMBER_DETAILS (
      { commit, dispatch },
      { success, payload } = {}
    ) {
      commit('setIsMemberFetched', false);
      api.getMemberDetails(
        response => {
          commit('setIsMemberFetched', true);
          if (response.data.code === 200) {
            commit('setMembersData', response.data.data);
            if (response.data.data.memberDetails) {
              const { verificationStatus, memberId, mitraPayRegistrationStatus } = response.data.data.memberDetails;
              if (verificationStatus === 'REJECTED' || mitraPayRegistrationStatus === 'REJECTED') {
                dispatch('GET_REJECTED_REASONS', {
                  payload: {
                    memberId
                  }
                });
              }
              if (success) {
                success(response.data.data);
              }
            }
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsMemberFetched', true);
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GENERATE_OTP ({ dispatch }, { success, params, payload, fail } = {}) {
      api.generateOtp(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response.data.errors);
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          fail(error);
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    UPDATE_PIN ({ dispatch }, { success, params, payload } = {}) {
      api.updatePin(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.error === null ||
              response.data.error === undefined
            ) {
              success(response.data);
            } else {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: { Sorry: [response.data.status] }
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    VERIFY_OTP ({ dispatch }, { success, params, payload, fail } = {}) {
      api.verifyOtp(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response.data.data);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    VERIFY_KTP ({ dispatch }, { success, params, payload } = {}) {
      api.verifyKtp(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.error === null ||
              response.data.error === undefined
            ) {
              success(response.data.data);
            } else {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    GET_STORE_TYPE ({ dispatch }, { params, payload, success } = {}) {
      api.getStoreTypeList(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    GET_MEMBER_IN_PROGRESS (
      { commit, dispatch },
      { success, params, payload } = {}
    ) {
      api.getMemberInProgress(
        response => {
          if (response.data.code === 200) {
            commit('setInProgressMemberDetails', response.data.data);
            success && success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    SAVE_MEMBER_DETAIL (
      { dispatch },
      { success, fail, params, payload } = {}
    ) {
      api.saveMemberDetails(
        response => {
          if (response.data.code === 200) {
            console.log('SAVE_MEMBER_DETAIL', response);
            success(response.data.data);
          } else {
            const errObject = response.data.errors;
            const errKeys = Object.keys(errObject);
            const keyValues = Object.values(errObject);
            if (errKeys.includes('ktpNumber') && keyValues[0].includes('EXISTS')) {
              fail && fail()
            } else if (errKeys.includes('memberId') && keyValues[0].includes('EXISTS')) {
              dispatch('SET_ALREADY_REGISTERED_MESSAGE', {}, { root: true })
            } else {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    VERIFY_USER_PIN (
      { dispatch },
      { success, payload, fail } = {}
    ) {
      api.verifyUserPin(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success(response.data.data);
            }
          } else {
            fail(response.data);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    UPLOAD_KTP_PROFILE (
      { dispatch },
      { success, params, payload, fail } = {}
    ) {
      api.uploadKTP(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    DOWNLOAD_KTP_PROFILE (
      { dispatch },
      { success, payload, params } = {}
    ) {
      api.downloadKTP(
        response => {
          // btoa for converting byte stream to string
          var base64 = btoa(new Uint8Array(new Uint8Array(response.data)).reduce(
            function (data, byte) {
              return data + String.fromCharCode(byte);
            },
            ''
          ));
          const base64encoded = `data:${response.headers['content-type']};base64,${base64}`;
          success(base64encoded);
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_SIGNED_URL (
      { dispatch },
      { success, payload, params, fail } = {}
    ) {
      api.getSignedUrlForImageUpload(
        response => {
          console.log('[GET_SIGNED_URL RESPONSE]', response);
          if (response.data.code === 200) {
            success(response.data.data)
          } else {
            if (response.data.errors.imageUpload[0] === 'NOT_UPLOADED') {
              fail && fail()
              return
            }
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          if (fail) {
            fail(error.response)
          }
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    SUBSCRIBE_WHATSAPP ({ dispatch }, { success, payload, fail }) {
      api.optWhatsApp(response => {
        if (response.data.code === 200) {
          success(response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => {
        if (fail) {
          fail(error.response)
        }
        errorHandler.handleErrors(dispatch, error);
      }, payload)
    },
    GET_ADDRESS_DETAILS ({ dispatch }, { params, success, fail }) {
      api.getAddressDetails(response => {
        if (response.data.code === 200) {
          success(response.data.data)
        } else {
          fail && fail()
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.message
          }, { root: true });
        }
      }, error => {
        fail && fail(error)
        errorHandler.handleErrors(dispatch, error);
      }, params)
    },
    CLEAR_MEMBER_DETAILS ({ commit }) {
      commit('setMembersData', {});
    },
    GET_REJECTED_REASONS ({ commit, dispatch }, { payload, fail } = {}) {
      api.getRejectedReasons(
        response => {
          if (response.data.code === 200) {
            let questionsList = response.data.data;
            const rejectedReasons = questionsList.filter(question => question.answerDetails && question.answerDetails.answerText === 'NO')
              .map(reason => {
                const { answerDetails, questionPwaText, questionPwaTextBahasa, id } = reason;
                return { answerDetails, questionPwaText, questionPwaTextBahasa, id }
              });
            commit('setRejectionTitleAndReasons', rejectedReasons);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.message
            }, { root: true });
          }
        }, error => {
          fail && fail(error)
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      )
    },
    GET_MEMBER_ONBOARDING_STATUS (_, { success, fail, payload, params }) {
      api.getMemberOnboardingStatus((response) => {
        if (response.data.code === 200) {
          success(response.data.data)
        } else {
          fail()
        }
      }, fail, payload, params)
    },
    UPDATE_MEMBER_ONBOARDING_STATUS (_, { success, fail, payload, params }) {
      api.updateMemberOnboardingStatus((response) => {
        if (response.data.code === 200) {
          success && success()
        } else {
          fail && fail()
        }
      }, () => {
        fail && fail()
      }, payload, params)
    },
    GET_MEMBER_STATUS (_, { success, fail, payload }) {
      api.getMemberStatus((response) => {
        if (response.data.code === 200) {
          console.log(response);
          success && success(response.data.data)
        } else {
          fail && fail()
        }
      }, () => {
        fail && fail()
      }, payload)
    },
    SET_IMAGE_CAPTURE_PROPS ({ commit }, { callbacks }) {
      commit('setImageCaptureProps', callbacks)
    },
    ONGOING_MISSION_NOTIFICATIONS_FOR_NEW_USER (_, { success, error, payload } = {}) {
      api.registerForOnGoingMissionAndNotifications(
        success,
        error,
        payload
      );
    },
    REGISTER_MEMBER_DETAIL (
      { dispatch },
      { success, fail, params, payload } = {}
    ) {
      api.memberRegistration(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail && fail(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    WALLET_REGISTRATION (
      { dispatch },
      { success, fail, params, payload } = {}
    ) {
      api.walletRegistration(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail && fail(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_REFERRAL_DETAILS ({ commit, dispatch }, { params, success, fail }) {
      commit('setReferralData', {})
      commit('setIsFetchingReferralDetails', true)
      api.getReferralDetails(response => {
        if (response.data.code === 200) {
          commit('setReferralData', response.data.data)
          commit('setIsFetchingReferralDetails', false)
          success && success(response.data.data)
          return
        }
        commit('setIsFetchingReferralDetails', false)
      }, error => {
        commit('setIsFetchingReferralDetails', false)
        fail && fail(error)
        errorHandler.handleErrors(dispatch, error, true);
      }, params)
    },
    GET_REFERRAL_REWARD_DETAILS ({ commit, dispatch }, { payload, success, fail }) {
      commit('setIsFetchingRewardDetails', true)
      commit('setReferralRewardDetails', {})
      api.getReferralRewardDetails(response => {
        commit('setIsFetchingRewardDetails', false)
        if (response.data.code === 200) {
          commit('setReferralRewardDetails', response.data.data);
          success && success()
          return
        }
        fail && fail()
      }, error => {
        commit('setIsFetchingRewardDetails', false)
        fail && fail(error)
        errorHandler.handleErrors(dispatch, error, true);
      }, payload)
    },
    GET_REFERRAL_HISTORY ({ commit, dispatch, state }, { payload, success, fail, isNewPage }) {
      api.getReferralRewardHistory(response => {
        if (response.data.code === 200) {
          const oldItems = isNewPage ? [] : state.referralHistoryList
          const newItems = oldItems.concat(response.data.data)
          commit('setReferralHistoryPaging', response.data.paging)
          commit('setReferralHistoryList', newItems)
          success && success(response.data)
          return
        }
        fail && fail()
      }, error => {
        fail && fail(error)
        errorHandler.handleErrors(dispatch, error, true);
      }, payload)
    },
    GET_REFERRAL_ELIGIBLE_FLAG ({ dispatch }, { payload, success, fail }) {
      api.getReferralEligibleFlag(response => {
        if (response.data.code === 200) {
          success && success(response.data.data)
          return
        }
      }, error => {
        fail && fail(error)
        errorHandler.handleErrors(dispatch, error, true);
      }, payload)
    },
    ACCOUNT_DELETION_VERIFY_PIN ({ dispatch }, { success, error, payload } = {}) {
      api.verifyPinForAccountDeletion(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else if (response.data.code === 500) {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          } else {
            error && error(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_ACCOUNT_DELETION_DETAILS ({ dispatch, commit }, { payload, success, fail }) {
      api.accountDeletionDetails(response => {
        if (response.data.code === 200) {
          commit('setAccountEligibleForDelete', response.data.data.isEligible)
          success && success(response.data.data)
        } else {
          fail && fail(response.data)
        }
      }, error => {
        fail && fail()
        errorHandler.handleErrors(dispatch, error, true);
      }, payload)
    },
    ACCOUNT_DELETION_REQUEST ({ dispatch }, { payload, success, fail }) {
      api.accountDeletionRequest(response => {
        if (response.data.code === 200) {
          if (!response.data.errors) {
            success && success(response.data.data)
          }
        } else {
          fail && fail(response.data)
        }
      }, error => {
        fail && fail()
        errorHandler.handleErrors(dispatch, error, true);
      }, payload)
    },
    GENERATE_OTP_DELETE_ACCOUNT ({ dispatch }, { success, params, payload, fail } = {}) {
      api.generateOtpForAccountDeletion(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response.data.errors);
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          fail && fail(error);
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    }
  },
  namespaced: true
};
