import digitalApi from '@/api/digitalApis';

export default {
  state: {
    productData: null
  },
  mutations: {
    setProduct (state, productResponse) {
      state.productData = productResponse;
    }
  },
  getters: {
    productData (state) {
      return state.productData;
    }
  },
  actions: {
    GET_PRODUCTS ({ commit }, { payload, success, fail }) {
      digitalApi.getProducts(
        response => {
          success && success(response.data.data)
          commit('setProduct', response.data.data);
        },
        error => {
          fail && fail()
          console.log(error)
        },
        payload,
        {
          operatorName: 'PLN'
        }
      );
    },
    RESET_PRODUCT ({ commit }) {
      commit('setProduct', null);
    }
  },
  namespaced: true
};
