import { clearStoreNamePopupInfo } from '../../utils/helpers';
import { userAuthStore } from '@vue3/store/auth-store'

export default {
  // eslint-disable-next-line no-empty-pattern
  handleErrors (err, shouldNotDisplayErrorInfo) {
    const errObject = err.response ? err.response.data : {};
    const canDisplayErrorInfo = !shouldNotDisplayErrorInfo
    if (err.response) {
      if (
        err.response.status === 401 ||
        err.response.status === '401' ||
        errObject.status === 401 ||
        errObject.code === '401' ||
        errObject.code === 401
      ) {
        clearStoreNamePopupInfo()
        userAuthStore().SET_LOGIN_STATUS(false)
        const maintenanceLink = err.response.headers.maintenance || '';
        if (!maintenanceLink) {
          userAuthStore().GO_TO_HOME_PAGE({
            path: '/mitra-home'
          })
        }
      } else if (canDisplayErrorInfo) {
        if (
          err.response.status === 503 ||
          err.response.status === '503' ||
          errObject.status === 503 ||
          errObject.code === '503' ||
          errObject.code === 503
        ) {
          userAuthStore().SET_SERVER_OVERLOAD(true)
        }
        else if (
          err.response.status === 500 ||
          err.response.status === '500' ||
          errObject.status === 500 ||
          errObject.code === '500' ||
          errObject.code === 500
        ) {
          userAuthStore().SET_SERVER_ERROR(true)
        }
      }
    }
  }
};
