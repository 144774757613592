import Vue from 'vue2'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { $i18n } from '@/i18n/lang'
import Filter from './filter'
import VueClipboard from 'vue-clipboard2'
import VueGtm from 'vue-gtm';
import '@/assets/scss/index.scss'
import Blue from '@blibli/dls'
import '@blibli/dls/dist/blue.min.css'
import './firebase/firebase';
import './sw/companion/v1/companion';
import TrackerMixins from './mixins/tracker-mixins'
import GAFourTrackerMixins from './mixins/gafour-tracker-mixins'
import AppsFlyerTrackerMixins from './mixins/appsflyer-tracker-mixin'
import { start } from '../vue3/src/init.js'

Vue.use(Blue);
Vue.use(Filter);
Vue.use(VueClipboard);
Vue.use(VueGtm, {
  id: 'GTM-KBGMH2B',
  debug: false,
  vueRouter: router
});

Vue.mixin({
  methods: {
    $i18n
  }
});
Vue.mixin(TrackerMixins);
Vue.mixin(GAFourTrackerMixins);
Vue.mixin(AppsFlyerTrackerMixins);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

start()
