import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler'

export default {
  state: {
    operators: [],
    products: [],
    gameList: [],
    gameDetail: {},
    isApiResolved: true,
    errors: null
  },
  mutations: {
    setOperators (state, operators) {
      state.operators = operators;
    },
    setIsApiResolved (state, status) {
      state.isApiResolved = status
    },
    setProducts (state, products) {
      state.products = products;
    },
    setGameList (state, gameList) {
      state.gameList = gameList;
    },
    setTopUpProductDetails (state, gameDetail) {
      state.gameDetail = gameDetail;
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  getters: {
    operators (state) {
      return state.operators;
    },
    products (state) {
      return state.products.filter(product => !product.outOfStock);
    },
    gameList (state) {
      return state.gameList
    },
    getTopUpProductDetails (state) {
      return state.gameDetail
    },
    isApiResolved (state) {
      return state.isApiResolved
    },
    getErrors (state) {
      return state.errors
    }
  },
  actions: {
    GET_OPERATORS ({ commit, dispatch }) {
      digitalApi.getOperator(
        response => commit('setOperators', response.data.data),
        error => {
          console.log(error)
          errorHandler.handleErrors(dispatch, error);
        },
        'GAME_VOUCHER'
      );
    },
    GET_PRODUCTS ({ commit, dispatch }, { payload, fail }) {
      commit('setIsApiResolved', false)
      digitalApi.getProducts(
        response => {
          commit('setIsApiResolved', true)
          if (response.data.errors) {
            console.log('something went wrong...');
            commit('setProducts', []);
            commit('setErrors', response.data.errors)
            if (fail) {
              fail(response)
            }
          } else {
            commit('setProducts', response.data.data.products)
          }
        },
        error => {
          console.log(error)
          errorHandler.handleErrors(dispatch, error);
        },
        'GAME_VOUCHER',
        payload
      );
    },
    GET_TOPUP_PRODUCTS ({ commit, dispatch }, { payload, fail }) {
      commit('setIsApiResolved', false)
      digitalApi.getProducts(
        response => {
          commit('setIsApiResolved', true)
          if (response.data.errors) {
            console.log('something went wrong...');
            commit('setTopUpProductDetails', {});
            commit('setErrors', response.data.errors)
            if (fail) {
              fail(response)
            }
          } else {
            commit('setTopUpProductDetails', response.data.data.gameDetail)
          }
        },
        error => {
          console.log(error)
          errorHandler.handleErrors(dispatch, error);
        },
        'GAME_VOUCHER_TOPUP',
        payload
      );
    },
    GET_GAME_LIST ({ commit, dispatch }) {
      digitalApi.getGameList(
        response => {
          if (response.data.errors) {
            console.log('something went wrong...');
            commit('setProducts', []);
          } else {
            commit('setGameList', response.data.data)
          }
        },
        error => {
          console.log(error)
          errorHandler.handleErrors(dispatch, error);
        },
        'GAME_VOUCHER'
      );
    },
    RESET_PRODUCT ({ commit }) {
      commit('setProducts', [])
    },
    RESET_TOPUP_PRODUCT ({ commit }) {
      commit('setTopUpProductDetails', {})
    },
    RESET_OPERATORS ({ commit }) {
      commit('setOperators', [])
    },
    RESET_API_STATUS ({ commit }) {
      commit('setIsApiResolved', true)
    },
    RESET_ERRORS ({ commit }) {
      commit('setErrors', null)
    }
  },
  namespaced: true
};
