import digitalApi from '@/api/digitalApis';

export default {
  state: {
    operators: [],
    products: []
  },
  mutations: {
    setOperators (state, operators) {
      state.operators = operators;
    },
    resetOperators (state) {
      state.operators = []
    },
    setProducts (state, products) {
      state.products = products.products
    },
    resetProducts (state) {
      state.products = []
    }
  },
  getters: {
    operators (state) {
      return state.operators;
    },
    products (state) {
      return state.products
    }

  },
  actions: {
    GET_OPERATORS ({ commit }, type) {
      commit('resetOperators')
      digitalApi.getDigitalOperators(
        response => {
          commit('setOperators', response.data.data);
        },
        error => console.log(error),
        type
      );
    },
    GET_PRODUCTS ({ commit }, payload) {
      digitalApi.getTvKabelProducts(
        response => {
          commit('setProducts', response.data.data);
        },
        error => console.log(error),
        payload
      );
    },
    RESET_OPERATORS ({ commit }) {
      commit('resetOperators')
    },
    RESET_PRODUCTS ({ commit }) {
      commit('resetProducts')
    }
  },
  namespaced: true
};
