<template>
  <div>
    <BliModal
      mobile-fullscreen
      :active="enablePopup"
      @blur="closePopup"
    >
      <BliModalHeader @close="closePopup">
        <template #title>
          {{ $i18n("HOME_PAGE.AUTO_REPLENISHMENT_HEADER") }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        <p class="font-body-2 font-14 neutral-text-med">
          {{ $i18n("HOME_PAGE.AUTO_REPLENISHMENT_META") }}
        </p>
        <ol class="margin-top-16 padding-left-16">
          <li
            v-for="(list, index) in autoSuggestedProducts"
            :key="index"
          >
            <div class="font-body-2 font-14 neutral-text-med">
              {{ list.name }}
            </div>
          </li>
        </ol>
      </BliModalBody>
      <div class="footer-wrapper">
        <BliButton
          prominence="primary"
          color="common"
          @click="goToCart"
        >
          {{ $i18n("SEE_NOW") }}
        </BliButton>
        <BliButton
          prominence="tertiary"
          color="common"
          @click="closePopup"
        >
          {{ $i18n("LATER") }}
        </BliButton>
      </div>
    </BliModal>
  </div>
</template>
<script src="./auto-replenishment.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.footer-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    margin-top: auto;
}
</style>
