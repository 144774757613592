import { getMemberID, fromAppsFlyer } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import { getBothLanguageCopyText } from '@/i18n/lang'

export default {
  computed: {
    ...mapGetters(['getIsUnAuthenticatedUser', 'getUserLoginStatus']),
    $windowDataLayer () {
      return window.dataLayer || []
    },
    $trackerMemberId () {
      if (this.getIsUnAuthenticatedUser) {
        return 'LOGGED_OUT_USER'
      }
      return getMemberID()
    },
    $pageType () {
      if (this.$route && this.$route.name) {
        return this.$route.name
      }
      return ''
    }
  },
  methods: {
    $trackClickEvent (buttonIconKeyname, optionalEvtDetailObj = {}, eventType = 'clickEvent') {
      this.$pushTrackerInfoToDataLayer(eventType, buttonIconKeyname, optionalEvtDetailObj)
    },
    $trackComponentImpression (componentKeyName, optionalEvtDetailObj = {}, eventType = 'componentImpression') {
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, optionalEvtDetailObj)
    },
    $trackNonInteractionEvent (componentKeyName, optionalEvtDetailObj = {}, eventType = 'nonInteractionEvent') {
      if (!eventType) {
        return;
      }
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, optionalEvtDetailObj)
    },
    $trackSearchEvent (searchKeyName, searchTerm, isSearchResultFound, optionalEventDetails = {}) {
      const eventType = 'searchEvent'
      const trackerData = {
        cd1: searchTerm,
        cd2: isSearchResultFound
      }
      Object.assign(trackerData, optionalEventDetails)
      this.$pushTrackerInfoToDataLayer(eventType, searchKeyName, trackerData)
    },
    $trackErrorPopupImpression (error_type, key, ...params) {
      const errorMessage = getBothLanguageCopyText(key, ...params)
      const eventType = 'errorPopupImpression'
      const componentKeyName = 'MitraErrorImpression'
      const eventDetailObj = {
        cd1: error_type,
        cd2: errorMessage.copyTextInBahasa,
        cd3: errorMessage.copyTextInEnglish || 'NA'
      }
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, eventDetailObj)
    },
    $trackErrorClickEvent (error_type, key, ...params) {
      const errorMessage = getBothLanguageCopyText(key, ...params)
      const eventType = 'errorPopupButtonClick'
      const componentKeyName = 'MitraErrorClick'
      const eventDetailObj = {
        cd1: error_type,
        cd2: errorMessage.copyTextInBahasa,
        cd3: errorMessage.copyTextInEnglish || 'NA'
      }
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, eventDetailObj)
    },
    $trackErrorClickEventWithEventObject (eventDetailObj = {}) {
      const eventType = 'errorPopupButtonClick'
      const componentKeyName = 'MitraErrorClick'
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, eventDetailObj)
    },
    $trackLoadEvent (label, eventDetailsObject, eventName = 'mitraLoadsEvent') {
      this.$pushTrackerInfoToDataLayer(eventName, label, eventDetailsObject)
    },
    $pushTrackerInfoToDataLayer (eventType, labelName, optionalEvtDetailObj) {
      if (!this.getIsUnAuthenticatedUser && !this.getUserLoginStatus) {
        // session api not resolved yet, userID not available
        return
      }
      const trackerData = {
        event: eventType,
        pageType: this.$pageType,
        eventDetails: {
          label: labelName,
          userID: this.$trackerMemberId
        }
      }
      const isFromAppsFlyer = fromAppsFlyer() ;
      if (isFromAppsFlyer !== null) {
        trackerData.eventDetails.appsFlyer = isFromAppsFlyer === 'true' ? 'yes' : 'no'
      }

      console.log(trackerData)
      Object.assign(trackerData.eventDetails, optionalEvtDetailObj)
      this.$windowDataLayer.push(trackerData)
    }
  }
}

