const REPLACE_REGEXES = [
  {
    regEx: /%20/g,
    replace: '+'
  },
  {
    regEx: /%3D/g,
    replace: '='
  }
]
const replaceRegex = (string) => {
  return REPLACE_REGEXES.reduce((acc, { regEx, replace }) => {
    return acc.replace(regEx, replace)
  }, string)
}
export function compareVueRoutes (currentUrl, nextUrl) {
  const currentPath = replaceRegex(currentUrl || '')
  const nextPath = replaceRegex(nextUrl)
  return currentPath === nextPath
}
