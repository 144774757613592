import Overlay from '@/components/web/Overlay.vue'
import Transition from '@/components/web/Transition.vue'

export default {
  name: 'OverlayPopup',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    closePopup: {
      type: Function,
      default: () => {
        // default function if not passed as prop
      }
    },
    isCloseVisible: {
      type: Boolean,
      default: true,
      required: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    Overlay,
    Transition
  },
  methods: {
    overlayClicked (e) {
      if (this.$refs.modal && !this.$refs.modal.contains(e.target)) {
        this.closePopup()
      }
    }
  }
};
