import { defineStore } from 'pinia'
import router from '@vue3/router'
import api from '@vue3/api/authApis.js'
import { clearStoreNamePopupInfo, getFCMToken } from '@vue3/utils/helpers'

export const userAuthStore = defineStore('userAuthStore', {
  state: () => {
    return {
      isUserLoggedIn: false,
      isServerOverloaded: false,
      isServerError: false,
      isUnAuthenticatedUser: false
    }
  },
  actions: {
    GO_TO_LOGIN_PAGE (payload) {
      const loginUrl = payload.loginUrl;
      const callbackUrl = payload.callbackUrl;
      window.location.href = `${loginUrl}?callback=${callbackUrl}`;
    },
    SET_LOGIN_STATUS (value) {
      this.isUserLoggedIn = value
    },
    SET_SERVER_OVERLOAD (value) {
      this.isServerOverloaded = value
    },
    SET_SERVER_ERROR (value) {
      this.isServerError = value
    },
    GO_TO_HOME_PAGE (payload) {
      if (window.location.pathname !== payload.path) {
        router.push(payload.path);
      }
    },
    GET_MITRA_SESSION ({ success, fail }) {
      api.getMitraSession(response => {
        if (response.data.code === 200) {
          const responseData = response.data.data
          if (typeof responseData === 'string' && JSON.parse(responseData).code >= 400) {
            this.isUnAuthenticatedUser = true
            clearStoreNamePopupInfo()
            window.sessionStorage.setItem('memberId', '')
            fail && fail()
          } else {
            const member = response.data.data || '';
            this.isUnAuthenticatedUser = false
            window.sessionStorage.setItem('memberId', member.memberId);
            success();
          }
        } else {
          this.isUnAuthenticatedUser = true
          clearStoreNamePopupInfo()
          window.sessionStorage.setItem('memberId', '')
          fail && fail()
        }
      }, err => {
        this.isUnAuthenticatedUser = true
        clearStoreNamePopupInfo()
        fail && fail()
        console.log(err)
      })
    },
    LOG_OUT_FROM_APP (appHomeUrl) {
      window.localStorage.removeItem('tokenSetAt');
      window.localStorage.removeItem('loyaltyOnboarding');
      window.localStorage.setItem('dAdded', '');
      api.logoutApp(response => {
        if (response.data.code === 200) {
          setTimeout(() => {
            window.location.href = appHomeUrl;
          }, 400)
        }
      }, fail => {
        console.log(fail)
      })
    },
    DELETE_TOKEN_FROM_SERVER ({ params, appHomeUrl }) {
      if (getFCMToken()) {
        api.deleteTokenFromServer(response => {
          // commenting deleteUserToken function to check the behaviour of token
          // global.deleteUserToken();
          window.localStorage.setItem('sentToServer', '0')
          window.localStorage.removeItem('currentToken', '')
          console.log(response)
          this.LOG_OUT_FROM_APP(appHomeUrl)
        }, fail => {
          console.log(fail)
        }, params)
      } else {
        this.LOG_OUT_FROM_APP(appHomeUrl)
      }
    }
  }
})
