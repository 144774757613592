import retailOrderHistoryApi from '@/api/retailOrderHistoryApis';
import errorHandler from '../api/apiUtils/errorHandler';
import { CancelToken } from 'axios';
import { AXIOS_REQUEST_CANCELLATION_ERROR } from '@/constant/contants';

export default {
  state: {
    isFetchingRetailOrderHistory: false,
    retailPaging: null,
    retailOrders: [],
    retailOrderDetail: {},
    shippingStatus: [],
    isFetchingShipping: false,
    pendingRetailOrders: [],
    pendingRetailPaging: null,
    isFetchingRetailPendingOrders: false
  },
  mutations: {
    setOrderHistory (state, response) {
      state.retailOrders = response;
    },
    setIsFetchingRetailOrderHistory (state, isFetching) {
      state.isFetchingRetailOrderHistory = isFetching;
    },
    setRetailPaging (state, paging) {
      state.retailPaging = paging
    },
    setRetailOrderDetail (state, retailOrderDetail) {
      state.retailOrderDetail = retailOrderDetail
    },
    setShippingStatus (state, shippingStatus) {
      state.shippingStatus = shippingStatus
    },
    setIsFetchingShipping (state, isFetching) {
      state.isFetchingShipping = isFetching;
    },
    setRetailPendingOrders (state, pendingOrders) {
      state.pendingRetailOrders = pendingOrders;
    },
    setPendingRetailPaging (state, paging) {
      state.pendingRetailPaging = paging;
    },
    setIsFetchingRetailPendingOrder (state, isFetching) {
      state.isFetchingRetailPendingOrders = isFetching;
    }
  },
  getters: {
    getOrderHistory (state) {
      return state.retailOrders;
    },
    isFetchingRetailOrderHistory (state) {
      return state.isFetchingRetailOrderHistory;
    },
    retailPaging (state) {
      return state.retailPaging;
    },
    getRetailOrderDetail (state) {
      return state.retailOrderDetail;
    },
    getShippingStatus (state) {
      return state.shippingStatus;
    },
    isFetchingShipping (state) {
      return state.isFetchingShipping;
    },
    getRetailPendingOrders (state) {
      return state.pendingRetailOrders;
    },
    getPendingRetailPaging (state) {
      return state.pendingRetailPaging;
    },
    isFetchingRetailPendingOrder (state) {
      return state.isFetchingRetailPendingOrders;
    }
  },
  actions: {
    RESET_ORDERS ({ commit }) {
      commit('setOrderHistory', [])
    },
    RESET_RETAIL_PENDING_ORDERS ({ commit }) {
      commit('setRetailPendingOrders', [])
    },
    GET_ORDER_HISTORY ({ commit, dispatch, state, rootGetters }, { payload, params, success, isNewPage } = {}) {
      // dispatch('orderHistory/CANCEL_ORDER_HISTORY_API_REQUEST', null, {root: true})

      if (params.orderStatus === 'PENDING_ORDER') {
        commit('setIsFetchingRetailPendingOrder', true);
      } else {
        commit('setIsFetchingRetailOrderHistory', true);
      }
      commit('orderHistory/setOrderHistoryCancelToken', CancelToken.source(), { root: true });

      retailOrderHistoryApi.getOrderHistory(
        response => {
          if (params.orderStatus === 'PENDING_ORDER') {
            commit('setIsFetchingRetailPendingOrder', false);
          } else {
            commit('setIsFetchingRetailOrderHistory', false);
          }
          if (response.data.code === 200) {
            if (response.data.errors === null || response.data.errors === undefined) {
              if (isNewPage) {
                if (params.orderStatus === 'PENDING_ORDER') {
                  commit('setRetailPendingOrders', response.data.data.orders);
                  commit('setPendingRetailPaging', response.data.paging);
                } else {
                  commit('setOrderHistory', response.data.data.orders);
                  commit('setRetailPaging', response.data.paging);
                }
              } else {
                if (params.orderStatus === 'PENDING_ORDER') {
                  commit('setRetailPendingOrders', [...state.pendingRetailOrders, ...response.data.data.orders]);
                  commit('setPendingRetailPaging', response.data.paging);
                } else {
                  commit('setOrderHistory', [...state.retailOrders, ...response.data.data.orders]);
                  commit('setRetailPaging', response.data.paging);
                }
              }
              success && success(response.data.data);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          if (error && error.message === AXIOS_REQUEST_CANCELLATION_ERROR) {
            console.log(error)
          } else {
            commit('setIsFetchingRetailOrderHistory', false);
            commit('setIsFetchingRetailPendingOrder', false);
            errorHandler.handleErrors(dispatch, error);
          }
        },
        params,
        payload,
        rootGetters['orderHistory/getOrderHistoryCancelToken'].token
      );
    },
    TRACK_ORDER_ITEM ({ commit, dispatch }, { pathVariables } = {}) {
      commit('setIsFetchingShipping', true)
      retailOrderHistoryApi.trackOrderItem(
        response => {
          commit('setIsFetchingShipping', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setShippingStatus', response.data.data.generalManifestResponses[0]);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingShipping', false);
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    REPAY_ORDER ({ commit, dispatch }, { pathVariables, success } = {}) {
      commit('setIsFetchingRetailOrderHistory', true)
      retailOrderHistoryApi.repayOrder(
        response => {
          commit('setIsFetchingRetailOrderHistory', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
            response.data.errors === undefined
            ) {
              success(response.data.data)
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingRetailOrderHistory', false);
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    GET_RETAIL_ORDER_DETAIL ({ commit, dispatch }, { pathVariables, success } = {}) {
      commit('setRetailOrderDetail', {})
      commit('setIsFetchingRetailOrderHistory', true);
      retailOrderHistoryApi.getOrderDetail(
        response => {
          commit('setIsFetchingRetailOrderHistory', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
            response.data.errors === undefined
            ) {
              commit('setRetailOrderDetail', response.data.data.orderHistory);
              success && success(response.data.data.orderHistory)
            } else {
              commit('setRetailOrderDetail', {});
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            commit('setRetailOrderDetail', {});
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setRetailOrderDetail', {});
          commit('setIsFetchingRetailOrderHistory', false);
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    }
  },
  namespaced: true
};
