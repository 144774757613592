<template>
  <div>
    <BliModal
      mobile-fullscreen
      :active="enablePopup"
      @blur="closePopup"
    >
      <BliModalHeader @close="closePopup">
        <template #title>
          {{ $i18n('TEMPORARILY_SUSPENDED_REASON.HEADING') }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        <p class="font-subtitle-1 font-16 neutral-text-high">
          {{ $i18n("TEMPORARILY_SUSPENDED_REASON.HEADING_1") }}
        </p>
        <p class="font-body-2 font-14 neutral-text-med">
          {{ $i18n("TEMPORARILY_SUSPENDED_REASON.HEADING_1_DESCRIPTION") }}
        </p>
        <p class="font-subtitle-1 font-16 neutral-text-high margin-top-32">
          {{ $i18n("TEMPORARILY_SUSPENDED_REASON.HEADING_2") }}
        </p>
        <ol class="font-body-2 font-14 neutral-text-med  padding-left-18 margin-top-12">
          <li>{{ $i18n("TEMPORARILY_SUSPENDED_REASON.LIST_1") }}</li>
          <li>{{ $i18n("TEMPORARILY_SUSPENDED_REASON.LIST_2") }}</li>
          <li>{{ $i18n("TEMPORARILY_SUSPENDED_REASON.LIST_3") }}</li>
          <li>{{ $i18n("TEMPORARILY_SUSPENDED_REASON.LIST_4") }}</li>
        </ol>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          prominence="primary"
          color="common"
          @click="redirectToHelpPage"
        >
          {{ $i18n("CONTACT_US") }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./wallet-suspension-popup.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
</style>
