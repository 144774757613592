import { homepageStore } from '@vue3/store/homepage-store'
import { mapState } from 'pinia'

export default {
  name: 'AutoReplenishment',
  components: {
  },
  props: {
    enablePopup: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$trackSectionView('MitraFlashImageImpression')
  },
  computed: {
    ...mapState(homepageStore, ['configs']),
    flashModal () {
      return this.configs?.flashMessageConfig?.modal
    },
    getImageUrl () {
      return this.flashModal?.imageUrl
    }
  },
  methods: {
    closePopup () {
      this.$emit('closeFlashImage')
    },
    handlePropogation (event) {
      event.stopPropagation()
    }
  }
}

