import { mapGetters } from 'vuex';
import ErrorNotifier from '@/components/web/ErrorNotifier.vue';
import NavigationBar from '@/components/web/NavigationBar.vue';
import OnboardingScreen from '@/components/OnBoardingScreen.vue';
import Alert from '@/components/web/Alert.vue';
import digitalConfigNames from '@/constant/digital-config-name'
import PageLoaderBouncingBox from '@/components/web/PageLoaderBouncingBox.vue'
import { getMemberID, getOnboardingStatus, setOnboardingStatus, setAppConfiguration, getAppConfiguration, getPWADisplayMode, isNewOnboardingEnabled } from '@/utils/helpers';
import Offline from '@/pages/Offline.vue'
import ErrorHandleNotifier from '@/components/web/ErrorHandleNotifier.vue';
import ServerOverload from '@/pages/ServerOverload.vue';
import ServerError from '@/pages/ServerError.vue';
import { setCookie, getCookie } from '@/utils/cookie';
import Loader from '@/components/web/Loader.vue'
import NewOnboarding from '@/components/NewOnboarding.vue';
import { listenEventFromPinaToVuex, removeEventListenerFromPiniaToVuex } from '@/utils/pinia-to-vuex-event'

export default {
  name: 'App',
  components: {
    ErrorNotifier,
    NavigationBar,
    OnboardingScreen,
    Alert,
    Offline,
    ErrorHandleNotifier,
    ServerOverload,
    ServerError,
    PageLoaderBouncingBox,
    Loader,
    NewOnboarding
  },
  computed: {
    ...mapGetters({
      errors: 'getErrorState',
      displayErrors: 'getErrorHandleState'
    }),
    ...mapGetters(['getServerOverload', 'getServerError', 'showBouncingBoxLoader', 'getPwaDisplayMode', 'getUserLoginStatus', 'getIsUnAuthenticatedUser']),
    ...mapGetters('profileStore', ['getMembersData']),
    ...mapGetters('homepageStore', ['getConfigs']),
    isAppLoaderVisible () {
      if (!this.getUserLoginStatus) {
        if (!this.getIsUnAuthenticatedUser) {
          // session api pending
          return true
        }
        // config api pending check(for logged out users)
        return !Object.keys(this.getConfigs).length
      }
      // config and member details api pending check(for logged in users)
      return !Object.keys(this.getConfigs).length || !Object.keys(this.getMembersData).length
    },
    displayNewOnboarding () {
      return isNewOnboardingEnabled()
    }
  },
  watch: {
    $route (to, from) {
      this.handleNavigationBarVisibility(to.path)
      if ((to.name !== from.name) && to.name === 'Home') {
        // d - query from android app. This will come as encrypted string.
        const deviceDetailsQueryFromAndroid = to.query.d || ''
        /*
          e.g. - decrypted string

          deviceId:d1b6ab893f92640e,
          deviceBrandName:samsung,
          deviceModelNumber:SM-A207F,
          androidOSVersionNumber:11,
          mitraAppVersion:1.2.0(120005)
        */
        if (deviceDetailsQueryFromAndroid && window.localStorage.getItem('d2Added') === null) {
          this.shouldSaveDeviceDetails = true
          window.localStorage.setItem('dDetails_V2', deviceDetailsQueryFromAndroid)
          if (window.localStorage.getItem('dDetails')) {
            window.localStorage.removeItem('dDetails')
          }
        }
        // made the trigger async, nextTick not working
        setTimeout(() => {
          this.$trackComponentImpression('MitraHomeImpression')
          this.$trackPageImpression('MitraHomeImpression')
        }, 100)
      }
    }
  },
  created () {
    sessionStorage.setItem('referrer', document.referrer)
    this.$store.dispatch('SET_PWA_DISPLAY_MODE', getPWADisplayMode())
    this.$store.dispatch('GET_MITRA_SESSION', { success: this.sessionFetched, fail: this.fetchBannersForLoggedOutUsers });
    this.$store.dispatch('homepageStore/GET_APP_CONFIGURATION', {
      typeOfConfiguration: 'mitraPwaConfiguration',
      success: this.onSuccessOfPWAConfig,
      fail: this.onFailOfPWAConfig
    });
    window.addEventListener('online', this.statusConnection);
    window.addEventListener('offline', this.statusConnection);
  },
  beforeDestroy () {
    window.removeEventListener('online', this.statusConnection);
    window.removeEventListener('offline', this.statusConnection);
  },
  mounted () {
    this.handleNavigationBarVisibility(this.$route.path);
    this.$store.dispatch('digitalConfig/getConfig', digitalConfigNames)
    listenEventFromPinaToVuex()
  },
  data () {
    return {
      showLoginSuccessAlert: false,
      showOnboarding: this.getStatusOfOnBoarding(),
      isOfflineNow: false,
      shouldTriggerDeviceAPI: false,
      shouldSaveDeviceDetails: false
    };
  },
  methods: {
    handleSkipOnboarding () {
      this.showOnboarding = false;
      setOnboardingStatus(true);
    },
    handleAlert () {
      this.showLoginSuccessAlert = false;
    },
    handleNavigationBarVisibility (path) {
      const paths = ['/order', '/account', '/retail-checkout', '/member-account']
      if (
        path.includes('home') ||
        path.includes('help') ||
        paths.some(element => element === path)
      ) {
        this.$store.dispatch('SHOW_NAVIGATION_TAB');
      } else {
        this.$store.dispatch('HIDE_NAVIGATION_TAB');
      }
    },
    getStatusOfOnBoarding () {
      if (getOnboardingStatus() === null) {
        return true;
      }
      return getOnboardingStatus() !== 'true';
    },
    isBlankHeaderVisible () {
      return this.$route.path === '/home'
    },
    statusConnection (status) {
      console.log('statusConnection', status.type);
      if (status.type === 'offline') {
        this.isOfflineNow = true
      }
      if (status.type === 'online') {
        this.isOfflineNow = false;
      }
    },
    checkMemberRegistration (data) {
      const { registrationStatus } = data
      const isRegisteredUser = registrationStatus === 'REGISTERED'
      const isRegistrationInProgressUser = registrationStatus === 'IN_PROGRESS'
      if (isRegisteredUser) {
        const { memberDetails: { verificationStatus } } = data
        if (!localStorage.getItem('homeOnboardingStatus') && (verificationStatus === 'APPROVED')) {
          this.$store.dispatch('profileStore/GET_MEMBER_ONBOARDING_STATUS', {
            payload: { memberId: getMemberID() },
            success: this.onGetHomePageOnboardingStatusResolve,
            fail: this.onGetHomePageOnboardingStatusResolve,
            params: {
              workFlow: 'HOME_REVAMP_1'
            }
          })
        }
        const homePageConfig = this.getConfigs.homePageConfig;
        const inActiveWalletForHomePage = homePageConfig.inActiveWallet;
        const memberVerifiedStatus = ['APPROVED', 'REJECTED', 'SUSPENDED']
        const isMemberUnderNewRegistration = data.memberDetails.newRegistration
        if (isMemberUnderNewRegistration && !inActiveWalletForHomePage && memberVerifiedStatus.includes(verificationStatus)) {
          this.$store.dispatch('homepageStore/GET_WALLET_REQUEST', {
            payload: {
              memberId: getMemberID()
            }
          });
        }
      }
      if (isRegisteredUser || isRegistrationInProgressUser) {
        this.setDeviceDetails()
      }
    },
    sessionFetched () {
      this.$store.dispatch('profileStore/GET_MEMBER_DETAILS', {
        payload: { memberId: getMemberID() },
        success: this.checkMemberRegistration
      });
    },
    fetchBannersForLoggedOutUsers () {
      this.$store.dispatch('homepageStore/GET_BANNERS_AND_SERVICES')
    },
    onGetHomePageOnboardingStatusResolve (isOnboardingComplete) {
      localStorage.setItem('homeOnboardingStatus', isOnboardingComplete)
    },
    updateConnectionState (state) {
      if (state) {
        this.isOfflineNow = false;
      }
    },
    isDeviceIDAvailable (deviceUUIDCookie) {
      return deviceUUIDCookie && deviceUUIDCookie.length === 36
    },
    async setDeviceDetails () {
      const platform = await import('platform')
      const userAgentDetails = this.getUserAgentDetails(platform)
      const deviceUUIDCookie = getCookie('deviceUUID')
      const deviceUUID = await this.getDeviceUUID(deviceUUIDCookie)
      setCookie('deviceUUID', deviceUUID, 3650)
      this.triggerDeviceAPI(deviceUUID, userAgentDetails)
    },
    triggerDeviceAPI (deviceUUID, userAgentDetails) {
      const deviceDetails = window.localStorage.getItem('dDetails_V2')
      if ((deviceUUID && userAgentDetails && this.shouldTriggerDeviceAPI) || this.shouldSaveDeviceDetails) {
        this.$store.dispatch('SET_DEVICE_DETAILS', {
          userAgentDetails,
          deviceDetails,
          memberId: getMemberID(),
          uuid: deviceUUID,
          pwaDisplayMode: this.getPwaDisplayMode
        })
      }
    },
    async getDeviceUUID (deviceUUIDCookie) {
      let deviceUUID = ''
      const isDeviceAdded = window.localStorage.getItem('dAdded')
      if (!isDeviceAdded) {
        this.shouldTriggerDeviceAPI = true
      } else {
        this.shouldTriggerDeviceAPI = false
      }
      if (this.isDeviceIDAvailable(deviceUUIDCookie)) {
        deviceUUID = deviceUUIDCookie
      } else {
        const { v4: uniqueId } = await import('uuid')
        deviceUUID = await uniqueId()
        this.shouldTriggerDeviceAPI = true
      }
      return deviceUUID
    },
    getUserAgentDetails (platform) {
      let { os, name, product } = platform;
      if (!product) {
        if (os.family === 'OS X') {
          product = 'Apple Macintosh'
        }
        if (os.family.indexOf('Windows') > -1) {
          product = 'Desktop'
        }
      }
      return {
        os: os.family,
        deviceModal: product,
        browser: name
      }
    },
    onSuccessOfPWAConfig (configs) {
      setAppConfiguration('mitraPwaConfiguration', (configs))
    },
    onFailOfPWAConfig () {
      const appConfiguration = getAppConfiguration('mitraPwaConfiguration')
      this.$store.dispatch('homepageStore/SET_CONFIGS', {
        typeOfConfiguration: 'mitraPwaConfiguration',
        configs: appConfiguration ? JSON.parse(appConfiguration) : {}
      })
    }
  },
  beforeUnmount () {
    removeEventListenerFromPiniaToVuex()
  }
};
