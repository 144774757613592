
import BliLoaderGeneral from '@blibli/blue.loader-general/dist/loader-general/blue/LoaderGeneral'

export default {
  name: 'BouncingBoxLoader',
  components: {
    BliLoaderGeneral
  }
};

