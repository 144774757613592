import BliIconSiHome from '@blibli/blue-dls-library-icon/dist/Home'
import BliIconSiLocationWarehouse from '@blibli/blue-dls-library-icon/dist/LocationWarehouse'
import BliIconSiOrder from '@blibli/blue-dls-library-icon/dist/Order'
import BliIconSiCustomerService from '@blibli/blue-dls-library-icon/dist/CustomerService'
import BliIconSiPeopleProfile from '@blibli/blue-dls-library-icon/dist/PeopleProfile'
import BliOnboardingTooltip from '@blibli/blue-pattern.onboarding-tooltip'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import BliBadge from '@blibli/blue.badge/dist/badge/blue/Badge'
import Modals from '@vue3/components/home/modals/index.vue'
import FullScreenPopupWrapper from '@vue3/components/home/full-screen-popup-wrapper/index.vue'
import FeatureMixin from '@vue3/mixins/feature-mixin'
import RetailCheckoutMixin from '@vue3/mixins/retail-checkout-mixin'
import { homepageStore } from '@vue3/store/homepage-store'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'NavigationBar',
  components: {
    FullScreenPopupWrapper,
    BliIconSiHome,
    BliIconSiLocationWarehouse,
    BliIconSiOrder,
    BliIconSiCustomerService,
    BliIconSiPeopleProfile,
    BliOnboardingTooltip,
    BliButton,
    BliBadge,
    Modals
  },
  props: {
  },
  data () {
    return {
      navItems: [
        {
          url: '/mitra-home',
          label: 'HOME'
        },
        {
          url: '/retail-checkout',
          label: 'ISI_STOK'
        },
        {
          url: '/order',
          label: 'ORDER'
        },
        {
          url: '/help',
          label: 'HELP'
        },
        {
          url: '/member-account',
          label: 'ACCOUNT'
        }
      ],
      onboardingStep: 2
    }
  },
  mixins: [
    RetailCheckoutMixin,
    FeatureMixin
  ],
  computed: {
    ...mapState(homepageStore, ['onboardingStatus', 'configs']),
    path () {
      return this.$route.path
    },
    shouldDisplayModalsInNavigationBar () {
      return !this.path.includes('/mitra-home')
    },
    isStep2HomeOnBoardingStatus () {
      const status = this.onboardingStatus === 'STEP2'
      if (status) {
        this.$trackComponentImpression('MitraOnboarding_StockReplenishment_InstructionImpression')
        this.$trackPageImpression('MitraOnboarding_StockReplenishment_InstructionImpression')
      }
      return status
    },
    isIsiStokPage () {
      return this.$route.name === 'RetailCheckout'
    },
    isCartCountGreaterThan99 () {
      return this.getTotalQuantity > 99
    },
    numberOFCartItems () {
      return this.isCartCountGreaterThan99 ? '99+' : this.getTotalQuantity
    },
    isQuickOrderConfigEnabled () {
      return !!this.configs.shouldEnableQuickOrder
    }
  },
  methods: {
    ...mapActions(homepageStore, ['SET_HOME_ONBOARDING_STATUS', 'SET_POPUP_INFO', 'SET_MODAL_INFO']),
    selectThisTab (menuItem) {
      let route = menuItem.url
      if (this.path === route) {
        return
      }
      const isProduct = route === '/retail-checkout'
      this.onFeatureClick({ url: route }, isProduct)
    },
    isActive (v) {
      return this.path.indexOf(v.url) === 0
    },
    showSkipOnboardingModal (event) {
      event.stopPropagation();
      this.$trackClickEvent('MitraOnboarding_StockReplenishment_InstructionClose')
      this.$trackButtonClick('MitraOnboarding_StockReplenishment_InstructionClose')
      this.SET_MODAL_INFO({
        key: 'HOME_ONBOARDING_SKIP_MODAL',
        properties: {
          currentOnboardingStep: this.onboardingStatus
        }
      })
      this.SET_HOME_ONBOARDING_STATUS('SKIP')
    },
    goToOnboardingStep3 (event) {
      event.stopPropagation();
      this.$trackClickEvent('MitraOnboarding_StockReplenishment_InstructionContinue')
      this.$trackButtonClick('MitraOnboarding_StockReplenishment_InstructionContinue')
      this.SET_HOME_ONBOARDING_STATUS('TEMPORARY_PAUSE')
      setTimeout(() => {
        this.SET_HOME_ONBOARDING_STATUS('STEP3')
      }, 600)
    }
  }
};

