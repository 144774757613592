import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getBanksList (callback, fail) {
    apiCall.makeGetRequest(apiUrls.api.getBankList, callback, fail);
  },
  addMyBankAccount (callback, fail, payload, params) {
    apiCall.makePutRequest(
      apiUrls.api.getAddBankAccount(),
      callback,
      fail,
      payload,
      params
    );
  },
  cashoutMoney (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getCashoutApi(),
      callback,
      fail,
      payload,
      params
    );
  },
  showTransactions (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getTransactionHistory(),
      callback,
      fail,
      payload
    );
  },
  getMemberBanksList (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getMemberBankList(),
      callback,
      fail,
      payload,
      params
    );
  },
  deleteAccountOfMember (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getDeleteUpdateMemberBankAccount(),
      callback,
      fail,
      payload,
      params
    );
  },
  editBankAccount (callback, fail, payload, params) {
    apiCall.makePutRequest(
      apiUrls.api.getDeleteUpdateMemberBankAccount(
      ),
      callback,
      fail,
      payload,
      params
    );
  }
};
