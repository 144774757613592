import raffleApi from '@/api/raffleApi'
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    raffleDetails: null
  },
  mutations: {
    setRaffleDetails (state, raffleDetails) {
      state.raffleDetails = raffleDetails
    }
  },
  getters: {
    getRaffleDetails (state) {
      return state.raffleDetails
    }
  },
  actions: {
    GET_RAFFLE_DETAILS ({ commit, dispatch }, { success, payload } = {}) {
      raffleApi.getRaffleDetails(
        response => {
          if (response.data.errors) {
            commit('setRaffleDetails', null)
          } else {
            commit('setRaffleDetails', response.data.data)
            success && success(response.data.data)
          }
        },
        error => {
          console.log('[error]', error)
          errorHandler.handleErrors(dispatch, error)
        },
        payload
      )
    }
  },
  namespaced: true
}
