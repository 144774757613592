export default {
  currencyInUnits (amount) {
    const ONE_THOUSAND = 1000
    const ONE_MILLION = 1000000
    if (amount >= ONE_MILLION) {
      return `Rp${Number((amount / ONE_MILLION).toFixed(2))} jt`.replace('.', ',')
    } else if (amount >= ONE_THOUSAND) {
      return `Rp${Number((amount / ONE_THOUSAND).toFixed(2))} rb`.replace('.', ',')
    } else {
      return `Rp${amount}`
    }
  }
}
