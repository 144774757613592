import digitalApis from '@/api/digitalApis';

export default {
  state: {
    products: []
  },
  mutations: {
    setProduct (state, products) {
      state.products = products;
    }
  },
  getters: {
    products (state) {
      return state.products;
    }
  },
  actions: {
    GET_PRODUCT ({ commit }, { payload }) {
      digitalApis.getProducts(
        response => commit('setProduct', response.data.data.products),
        error => console.log(error),
        'WALLET_BALANCE',
        payload
      );
    },
    ADD_CART (store, { payload, success }) {
      digitalApis.addCart(
        response => success(response),
        error => console.log(error),
        payload
      );
    }
  },
  namespaced: true
};
