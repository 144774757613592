import { mapGetters } from 'vuex'
import { getBothLanguageCopyText } from '@/i18n/lang'

export default {
  name: 'ErrorNotifier',
  props: {
    isErrorPopupVisible: {
      required: false
    },
    errorMessage: {
      required: false
    }
  },
  data () {
    return {
      errorsToShow: [],
      showErrorMessage: false,
      showAlreadyRegisteredError: false,
      errorPopupControls: [{
        label: this.$i18n('TRAIN.OKAY_TEXT'),
        handler: this.handleModalClose,
        color: 'secondary',
        outline: !0
      }]
    };
  },
  computed: {
    ...mapGetters(['getErrorPopupData', 'isMemberAlreadyRegisterd'])
  },
  beforeDestroy () {
    this.showErrorMessage = false;
    this.errorsToShow = [];
  },
  watch: {
    isErrorPopupVisible (newValue) {
      console.log('watch: isErrorPopupVisible', newValue);
      this.showErrorMessage = !!newValue;
    },
    errorMessage (newValue, oldValue) {
      console.log('errorMessage', newValue, oldValue);
      this.errorsToShow = [];
      if (newValue.errorList) {
        this.errorsToShow = Object.entries(newValue.errorList);
      }
    },
    isMemberAlreadyRegisterd (newValue) {
      if (newValue === true) {
        console.log('isMemberAlreadyRegisterd_visible');
        this.showAlreadyRegisteredError = true;
      } else {
        this.showAlreadyRegisteredError = false;
      }
    },
    showErrorMessage (newVal) {
      if (newVal) {
        this.triggerErrorImpression()
      }
    }
  },
  methods: {
    handleModalClose () {
      this.showErrorMessage = false;
      this.$trackErrorClickEvent('COMMON_ERROR_MODAL', 'TRAIN.OKAY_TEXT')
      this.$trackButtonClickHandler('COMMON_ERROR_MODAL', 'TRAIN.OKAY_TEXT')
      this.$store.dispatch('REMOVE_ERROR_POPUP');
    },
    closeAlreadyRegistered () {
      this.$store.dispatch('REMOVE_ALREADY_REGISTERED_MESSAGE');
      this.$router.push('/mitra-home');
    },
    triggerErrorImpression () {
      let error_type = ''
      const errorMessage = {
        copyTextInBahasa: '',
        copyTextInEnglish: ''
      }
      let firstHalfErrorCopy = {}
      let secondHalfErrorCopy = {}
      if (this.getErrorPopupData.title) {
        error_type = this.getErrorPopupData.title
      } else {
        error_type = 'COMMON_ERROR_MODAL'
      }
      if (this.getErrorPopupData.body) {
        errorMessage.copyTextInBahasa = this.getErrorPopupData.body
        errorMessage.copyTextInEnglish = this.getErrorPopupData.body
      } else {
        this.errorsToShow.forEach(item => {
          firstHalfErrorCopy = getBothLanguageCopyText(`ERRORS_CODES.${item[0]}`)
          secondHalfErrorCopy = getBothLanguageCopyText(`ERRORS_CODES.${item[1]['0']}`)
          errorMessage.copyTextInBahasa = `${firstHalfErrorCopy.copyTextInBahasa} ${secondHalfErrorCopy.copyTextInBahasa}`
          errorMessage.copyTextInEnglish = `${firstHalfErrorCopy.copyTextInEnglish} ${secondHalfErrorCopy.copyTextInEnglish}`
        })
      }
      this.trackErrorNotifierClickEvent(error_type, errorMessage)
    },
    trackErrorNotifierClickEvent (error_type, errorMessage) {
      const eventType = 'errorPopupButtonClick'
      const componentKeyName = 'MitraErrorClick'
      const eventDetailObj = {
        cd1: error_type,
        cd2: errorMessage.copyTextInBahasa,
        cd3: errorMessage.copyTextInEnglish
      }
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, eventDetailObj)
    }
  }
};
