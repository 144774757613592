import { mapState, mapActions } from 'pinia'
import { homepageStore } from '@vue3/store/homepage-store'
import { userAuthStore } from '@vue3/store/auth-store'
import { getAppConfiguration } from '@vue3/utils/helpers'
import { formatDate } from '@vue3/utils/date'
import MemberMixins from '@vue3/mixins/member-mixins'
import { profileStore } from '@vue3/store/profile-store'
import { EVENTES_MAPPING_BY_ROUTES } from '@vue3/constant/contants'
import { utcToZonedTime } from 'date-fns-tz'

const routesAccessibleForLoggedOutUsers = ['/home', '/help']
const routesAccessibleForAllLoggedInUsers = [
  '/order', '/help', '/mitra-home', '/notifications', '/paylater',
  '/pnl', '/member-account', '/products'
]

export default {
  mixins: [
    MemberMixins
  ],
  computed: {
    ...mapState(homepageStore, ['configs', 'walletRequest']),
    ...mapState(userAuthStore, ['isUserLoggedIn']),
    ...mapState(profileStore, ['membersData']),
    isDropshipAvailForThisMember () {
      return this.configs.dropshipAvailableZones.includes(this.membersData.zoneId)
    },
    isBetaUser () {
      return this.configs.BETA_MITRA_IDS.includes(this.memberDetails.memberId)
    },
    isOneHourSuspended () {
      return this.isMemberSuspendedFromWallet && this.walletDetails.suspensionTime === 1
    },
    isTwentyFourHourSuspended () {
      return this.isMemberSuspendedFromWallet && this.walletDetails.suspensionTime === 24
    },
    showGalleryUploadTicker () {
      return this.configs?.uploadImageConfig.enableGalleryUpload
    }
  },
  methods: {
    ...mapActions(homepageStore, ['SET_POPUP_INFO', 'SET_MODAL_INFO', 'GET_APP_CONFIGURATION']),
    ...mapActions(userAuthStore, ['GO_TO_LOGIN_PAGE']),
    onFeatureClick (feature, isProduct, featureCallback) {
      const { url } = feature
      this.trackFeatureClick(url)

      if (this.isUserLoggedIn) {
        if (routesAccessibleForAllLoggedInUsers.includes(feature.url)) {
          if (url === '/order') {
            this.checkIfMaintenanceConfigAvailable(url)
            return
          }
          this.$router.push(url)
          return
        }

        if (this.isMemberApproved) {
          if (!this.isMemberUnderNewRegistration && this.walletRequest.pinRegistered === false) {
            this.$router.push('/setting-pin')
            return
          }
          if (feature.beta && !this.isBetaUser) {
            this.SET_POPUP_INFO({ key: 'COMING_SOON_POPUP' })
            return
          }
          if (this.walletRequest.suspended && feature.url !== '/credit-ledger') {
            this.showNoAccessModals()
            return
          }

          if (!isProduct) {
            if (featureCallback) {
              featureCallback()
            } else {
              this.$router.push(feature.url)
            }
            return
          }

          // checks for product features
          const isDropship = url === '/dropship'
          const isRetailProduct = url === '/retail-checkout' || isDropship
          if (isRetailProduct) {
            const hasRetailAccess = this.checkIfUserHasRetailAccessElseShowModals()
            if (hasRetailAccess) {
              if (isDropship) {
                if (!this.isDropshipAvailForThisMember) {
                  this.SET_MODAL_INFO({ key: 'DROPSHIP_NOT_AVAILABLE_MODAL' })
                  return
                }
              }
              this.checkIfMaintenanceConfigAvailable(url)
            }
          } else {
            this.checkIfMaintenanceConfigAvailable(url)
          }
        } else {
          this.showNoAccessModals()
        }
      } else {
        if (routesAccessibleForLoggedOutUsers.includes(feature.url)) {
          this.$router.push(feature.url)
        } else {
          this.goToLoginPage()
        }
      }
    },
    checkIfMaintenanceConfigAvailable (url) {
      if (!getAppConfiguration('maintenanceConfig')) {
        this.GET_APP_CONFIGURATION({
          typeOfConfiguration: 'mitraMaintenanceConfig',
          success: this.checkIfMaintenanceElseGoToFeature(url)
        })
      } else {
        this.checkIfMaintenanceElseGoToFeature(url)
      }
    },
    showNoAccessModals () {
      if (this.isMemberRejected) {
        this.SET_MODAL_INFO({ key: 'NO_ACCESS_MODAL_FOR_REJECTED_USER' })
      } else if (this.isMemberSuspendedFromCenter) {
        this.SET_MODAL_INFO({ key: 'NO_ACCESS_MODAL_FOR_SUSPENDED_USER' })
      } else if (this.isMemberSuspendedFromWallet) {
        if (this.isOneHourSuspended) {
          this.SET_MODAL_INFO({ key: 'ONE_HOUR_WALLET_SUSPENDED_USER' })
        } else {
          this.SET_MODAL_INFO({ key: 'ONE_DAY_WALLET_SUSPENDED_USER' })
        }
      } else {
        this.SET_MODAL_INFO({ key: 'NO_ACCESS_MODAL' })
      }
    },
    trackFeatureClick (featureUrl) {
      const eventDetails = EVENTES_MAPPING_BY_ROUTES[featureUrl]
      if (!eventDetails) {
        return;
      }
      this.$trackButtonClick(eventDetails.gaEventName)
      const { appsflyerEventName } = eventDetails;
      if (appsflyerEventName) {
        this.$sendEventsToAppsFlyer(appsflyerEventName)
      }

    },
    goToLoginPage () {
      this.GO_TO_LOGIN_PAGE(this.configs)
    },
    checkIfUserHasRetailAccessElseShowModals () {
      const memberData = this.membersData
      const serviceAccess = this.membersData.services || []
      if (this.isStoreUpdateRequestUnderReview) {
        this.$trackSectionView('MitraStoreRequestReviewImpression')
        this.showToast(this.$i18n('STORE_REQUEST_UNDER_REVIEW'), '')
        return false
      }
      if (this.isMemberOutsideZone) {
        if (memberData.addressDetails === null) {
          this.SET_MODAL_INFO({ key: 'MEMBER_WITH_NO_STORE_MODAL' })
          return false
        } else {
          this.SET_MODAL_INFO({ key: 'OUTSIDE_ZONE_MODAL' })
          return false
        }
      }
      if (this.isMemberPartiallyInsideZone) {
        if (this.isMitraIdOrDrpNumberAvailable) {
          this.SET_MODAL_INFO({ key: 'NO_DRP_AND_MITRA_ID_MODAL' })
        } else {
          this.SET_MODAL_INFO({ key: 'NO_DRP_AND_MITRA_ID_MODAL' })
        }
        return false
      }
      if (!serviceAccess.includes('replenishment_products')) {
        this.showNoAccessModals()
        return false
      }
      return true
    },
    getMaintainencePopupData (productConfig) {
      const maintainencePopup = {
        isMaintainencePopupVisible: true,
        date: productConfig.date,
        time: productConfig.timeSpan
      }
      return maintainencePopup
    },
    getDate (date) {
      return formatDate(new Date(date), 'dd MMM yyyy')
    },
    isMaintenanceAvailableForCurrentDate (date, timeSpan) {
      let dates = date.split('-')
      let maintenanceStartDate = new Date(dates[0])
      let maintenanceEndDate = new Date(dates[1])
      if (timeSpan) {
        const time = timeSpan.split('-')
        maintenanceStartDate = new Date(dates[0] + ' ' + time[0])
        maintenanceEndDate = new Date(dates[1] + ' ' + time[1])
      }
      const timeZone = 'Asia/Jakarta'
      const currentDate = new Date()
      const jktCurrentDate = utcToZonedTime(currentDate, timeZone)
      return jktCurrentDate >= maintenanceStartDate && jktCurrentDate <= maintenanceEndDate
    },
    checkIfMaintenanceElseGoToFeature (url) {
      const maintenanceConfig = JSON.parse(getAppConfiguration('maintenanceConfig')) || [];
      const productConfig = maintenanceConfig.find(item => item.url === url) || {}

      if (productConfig.maintenance) {
        if (productConfig.date) {
          if (this.isMaintenanceAvailableForCurrentDate(productConfig.date, productConfig.timeSpan)) {
            const showModal = this.getMaintainencePopupData(productConfig)
            const maintenanceDate = showModal.date.split('-')
            const maintenanceTime = showModal.time.split('-')
            const endDateWithTime = `${this.getDate(maintenanceDate[1])} (${maintenanceTime[1]} WIB)`
            const popupInfo = {
              headerText: this.$i18n('HOME_PAGE.MAINTENANCE_HEADING_WITH_TIME'),
              bodyText: this.$i18n('HOME_PAGE.MAINTENANCE_BODY_WITH_TIME', endDateWithTime)
            }
            this.SET_MODAL_INFO({ key: 'MAINTENANCE_MODAL', properties: popupInfo })
            this.$trackSectionView('MitraMaintenancePopup')
          } else {
            this.$router.push(url)
          }
        } else {
          this.SET_MODAL_INFO({ key: 'MAINTENANCE_MODAL', properties: {
            headerText: this.$i18n('HOME_PAGE.MAINTENANCE_HEADING'),
            bodyText: this.$i18n('HOME_PAGE.MAINTENANCE_BODY')
          } })
          this.$trackSectionView('MitraMaintenancePopup')
        }
      } else {
        this.$router.push(url)
      }
    }
  }
}
