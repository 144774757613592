import { clearStoreNamePopupInfo } from '../../utils/helpers';

export default {
  // eslint-disable-next-line no-empty-pattern
  handleErrors (dispatch, err, shouldNotDisplayErrorInfo) {
    const errObject = err.response ? err.response.data : {};
    const canDisplayErrorInfo = !shouldNotDisplayErrorInfo
    console.log('...err:', err);
    console.log('...ERR:', err.response);
    if (err.response) {
      if (
        err.response.status === 401 ||
        err.response.status === '401' ||
        errObject.status === 401 ||
        errObject.code === '401' ||
        errObject.code === 401
      ) {
        clearStoreNamePopupInfo()
        dispatch('SET_LOGIN_STATUS', false, { root: true });
        const maintenanceLink = err.response.headers.maintenance || '';
        if (!maintenanceLink) {
          dispatch('GO_TO_HOME_PAGE', { path: '/mitra-home' }, { root: true });
        }
      } else if (canDisplayErrorInfo) {
        if (
          err.response.status === 503 ||
          err.response.status === '503' ||
          errObject.status === 503 ||
          errObject.code === '503' ||
          errObject.code === 503
        ) {
          dispatch('SET_SERVER_OVERLOAD', true, { root: true });
        }
        else if (
          err.response.status === 500 ||
          err.response.status === '500' ||
          errObject.status === 500 ||
          errObject.code === '500' ||
          errObject.code === 500
        ) {
          dispatch('SET_SERVER_ERROR', true, { root: true });
        }
      }
    }
  }
};
