export default [
  'mitrapay_max_topup_amount',
  'mitrapay_min_topup_amount',
  'minimum_number_length_phone_credit',
  'maximum_number_length_phone_credit',
  'minimum_number_length_water_bill',
  'maximum_number_length_water_bill',
  'minimum_number_length_bpjs',
  'maximum_number_length_bpjs',
  'minimum_number_length_electricity_credit',
  'maximum_number_length_electricity_credit',
  'minimum_number_length_electricity_postpaid',
  'maximum_number_length_electricity_postpaid',
  'zakat_fitrah_1kg_rice_price',
  'minimum_amount_money_transfer',
  'maximum_amount_money_transfer'
]
