import retailCheckoutApi from '@/api/retailCheckoutApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    cartDetails: {},
    productList: [],
    categories: [],
    paging: null,
    isFetchingList: true,
    subCategories: [],
    lastOrderItems: [],
    subCategoryList: [],
    isAddingItemToCart: false,
    orderDetail: {},
    vouchersList: [],
    voucherDetails: {},
    isFetchingProductList: false,
    cartItemsBySKU: {},
    wholesaleOnboardingSku: '',
    headerInfo: {
      title: '',
      checkoutSteps: null
    },
    retailCartOnboardingStatus: '',
    isRetailCartOnboardingInProgress: false,
    itemToScroll: '',
    isPaymentScreen: false,
    isUserFromPaymentScreen: false,
    checkoutTab: '',
    isCancelAllVoucherInProgress: false,
    isShippingMethodUpdateInProgress: false,
    isCartApiCalledFromHomePage: false,
    maxDiscountedValue: 0,
    showMFDProductModal: false,
    popupInfo: {}
  },
  mutations: {
    setProductsList (state, productListResponse) {
      state.productList = productListResponse;
    },
    setCartDetails (state, cartResponse) {
      window.dispatchEvent(new CustomEvent('update.pinia.state.from.vuex', { detail: { eventType: 'RETAIL_CART_DETAILS', data: cartResponse } }))
      state.cartDetails = cartResponse;
      let cartItems = {}
      if (cartResponse.items) {
        cartResponse.items.reduce((accumulator, item) => {
          accumulator[item.itemSku] = item
          return accumulator
        }, cartItems);
      }
      state.cartItemsBySKU = cartItems;
    },
    setCategories (state, categories) {
      state.categories = categories
      if (categories) {
        state.subCategoryList = []
        categories.forEach(category => {
          if (category.subCategoryInfoResponseList && category.subCategoryInfoResponseList.length) {
            category.subCategoryInfoResponseList.forEach(subCat => {
              state.subCategoryList.push(subCat)
            })
          }
        })
      }
    },
    setPaging (state, paging) {
      state.paging = paging
    },
    setIsFetchingList (state, isFetchingList) {
      state.isFetchingList = isFetchingList
    },
    setSubCategories (state, subCategories) {
      state.subCategories = subCategories
    },
    setLastOrder (state, lastOrderItems) {
      state.lastOrderItems = lastOrderItems
    },
    setSubCategoryList (state, value) {
      state.subCategoryList = value;
    },
    setIsAddingItemToCart (state, value) {
      state.isAddingItemToCart = value
    },
    setOrderDetail (state, value) {
      state.orderDetail = value
    },
    setVouchersList (state, value) {
      state.vouchersList = value
    },
    setVoucherDetails (state, value) {
      state.voucherDetails = value
    },
    setIsFetchingProductList (state, value) {
      state.isFetchingProductList = value
    },
    setWholesaleOnboardingSku (state, value) {
      state.wholesaleOnboardingSku = value
    },
    setHeaderInfo (state, value) {
      state.headerInfo = value
    },
    setRetailCartOnboardingStatus (state, value) {
      state.retailCartOnboardingStatus = value
    },
    setIsRetailCartOnboardingInProgress (state, value) {
      state.isRetailCartOnboardingInProgress = value
    },
    setItemToScroll (state, value) {
      state.itemToScroll = value
    },
    setIsPaymentScreen (state, value) {
      state.isPaymentScreen = value
    },
    setIsUserFromPaymentScreen (state, value) {
      state.isUserFromPaymentScreen = value
    },
    setCheckoutTab (state, value) {
      state.checkoutTab = value
    },
    setIsCancelAllVoucherInProgress (state, value) {
      state.isCancelAllVoucherInProgress = value
    },
    seIsShippingMethodUpdateInProgress (state, value) {
      state.isShippingMethodUpdateInProgress = value
    },
    setIsCartApiCalledFromHomePage (state, value) {
      state.isCartApiCalledFromHomePage = value
    },
    setMaxDiscountedValue (state, value) {
      state.maxDiscountedValue = Number(value)
    },
    setMFDProductModal (state, value) {
      state.showMFDProductModal = value
    },
    setPopupInfo (state, popupInfo) {
      const { key } = popupInfo
      state.popupInfo = {
        key
      }
    }
  },
  getters: {
    getProductsList (state) {
      return state.productList;
    },
    getCartDetails (state) {
      return state.cartDetails;
    },
    getCategories (state) {
      return state.categories;
    },
    paging (state) {
      return state.paging;
    },
    getIsFetchingList (state) {
      return state.isFetchingList;
    },
    getSubCategories (state) {
      return state.subCategories;
    },
    getLastOrder (state) {
      return state.lastOrderItems;
    },
    getSubCategoryList (state) {
      return state.subCategoryList;
    },
    isAddingItemToCart (state) {
      return state.isAddingItemToCart;
    },
    getOrderDetail (state) {
      return state.orderDetail;
    },
    getVouchersList (state) {
      return state.vouchersList;
    },
    getVoucherDetails (state) {
      return state.voucherDetails;
    },
    getIsFetchingProductList (state) {
      return state.isFetchingProductList
    },
    getWholesaleOnboardingSku (state) {
      return state.wholesaleOnboardingSku
    },
    getCartItemsBySKU (state) {
      return state.cartItemsBySKU
    },
    getHeaderInfo (state) {
      return state.headerInfo
    },
    getRetailCartOnboardingStatus (state) {
      return state.retailCartOnboardingStatus
    },
    getIsRetailCartOnboardingInProgress (state) {
      return state.isRetailCartOnboardingInProgress
    },
    getItemToScroll (state) {
      return state.itemToScroll
    },
    getIsPaymentScreen (state) {
      return state.isPaymentScreen
    },
    getCheckoutTab (state) {
      return state.checkoutTab
    },
    getIsCancelAllVoucherInProgress (state) {
      return state.isCancelAllVoucherInProgress
    },
    getIsShippingMethodUpdateInProgress (state) {
      return state.isShippingMethodUpdateInProgress
    },
    getIsCartApiCalledFromHomePage (state) {
      return state.isCartApiCalledFromHomePage
    },
    getMaxDiscountedValue (state) {
      return state.maxDiscountedValue
    },
    getIsUserFromPaymentScreen (state) {
      return state.isUserFromPaymentScreen
    },
    showMFDProductModal (state) {
      return state.showMFDProductModal
    },
    getPopupInfo (state) {
      return state.popupInfo
    }
  },
  actions: {
    GET_CART_ITEMS ({ commit, dispatch, state }, { params, success, fail } = {}) {
      commit('setIsFetchingList', true)
      retailCheckoutApi.getCartItems(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success && success(response.data.data);
            } else {
              if (fail) {
                fail(response.data.errors);
              } else {
                dispatch('SET_ERROR_HANDLE_POPUP', {
                  isErrorHandleVisible: true,
                  errorList: response.data.errors
                }, { root: true });
              }
            }
          }
        },
        error => {
          commit('setIsFetchingList', false)
          if (!state.isCartApiCalledFromHomePage) {
            errorHandler.handleErrors(dispatch, error);
          }
          commit('setIsCartApiCalledFromHomePage', false)
        },
        params
      );
    },
    RESET_SUB_CATEGORIES ({ commit }) {
      commit('setSubCategoryList', null);
    },
    DEL_CART_ITEM ({ commit, dispatch }, { payload, params, success } = {}) {
      commit('setIsAddingItemToCart', true)
      retailCheckoutApi.deleteCartItem(
        response => {
          commit('setIsAddingItemToCart', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success && success(response.data.data);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsAddingItemToCart', false)
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    UPDATE_ITEM_QUANTITY (
      { commit, dispatch },
      { payload, params, success, fail } = {}
    ) {
      commit('setIsAddingItemToCart', true)
      retailCheckoutApi.updateItemQuantity(
        response => {
          commit('setIsAddingItemToCart', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success(response.data.data);
            } else {
              fail(response.data.errors);
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            fail(response.data.errors);
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsAddingItemToCart', false);
          fail(error);
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    ADD_TO_CART ({ commit, dispatch }, { payload, params, success, fail } = {}) {
      commit('setIsAddingItemToCart', true)
      retailCheckoutApi.addItemToCart(
        response => {
          commit('setIsAddingItemToCart', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success(response.data.data)
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            fail && fail()
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          fail && fail()
          commit('setIsAddingItemToCart', false)
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    CREATE_ORDER ({ commit, dispatch }, { success, fail, payload, params } = {}) {
      commit('setIsAddingItemToCart', true)
      retailCheckoutApi.createOrder(
        response => {
          commit('setIsAddingItemToCart', false)
          let errorList = null
          if (response.data && response.data.errors) {
            errorList = response.data.errors
          }
          if (response.data.code === 200) {
            if (fail && errorList && (errorList.pin || errorList.memberId || (errorList.payment && errorList.payment.length && errorList.payment.includes('DIFFERENT_PRICE')))) {
              fail(errorList)
              return;
            }
            if (
              errorList === null
            ) {
              success(response.data.data);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_ORDER_BY_ORDERID (
      { dispatch, commit },
      { success, params } = {}
    ) {
      retailCheckoutApi.getOrderByOrderId(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success(response.data.data);
              commit('setOrderDetail', response.data.data);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_CATEGORIES (
      { commit, dispatch },
      { success, params, fail } = {}
    ) {
      commit('setIsFetchingList', true)
      commit('setCategories', null)
      retailCheckoutApi.getCategories(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCategories', response.data.data)
              success && success()
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_PRODUCTS_LIST (
      { commit, dispatch, state },
      { success, params, payload, pathVariables, fail } = {}
    ) {
      commit('setIsFetchingProductList', true)
      retailCheckoutApi.getProductsList(
        response => {
          commit('setIsFetchingProductList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (params.page === 0) {
                commit('setProductsList', response.data.data)
              } else {
                commit('setProductsList', [...state.productList, ...response.data.data])
              }
              commit('setPaging', response.data.paging)
              if (success) {
                success(response.data.data);
              }
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingProductList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params,
        payload
      );
    },
    GET_LAST_ORDER (
      { commit, dispatch },
      { params } = {}
    ) {
      commit('setLastOrder', null);
      commit('setIsFetchingList', true);
      retailCheckoutApi.getLastOrder(
        response => {
          commit('setIsFetchingList', false);
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setLastOrder', response.data.data);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    RESET_PRODUCT_LIST ({ commit }) {
      commit('setProductsList', [])
      commit('setPaging', 0)
    },
    SET_FETCHING ({ commit }, value) {
      commit('setIsFetchingList', value)
    },
    SET_PRODUCT_FETCHING ({ commit }, value) {
      commit('setIsFetchingProductList', value)
    },
    SET_MFD_PRODUCT_MODAL ({ commit }, value) {
      commit('setMFDProductModal', value)
    },
    CLEAR_CART ({ commit, dispatch }, { payload, params, success } = {}) {
      commit('setIsFetchingList', true)
      retailCheckoutApi.clearCart(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success(response.data.data)
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    RESET_CART ({ commit }) {
      commit('setCartDetails', {})
    },
    GET_VOUCHERS_LIST (
      { commit, dispatch },
      { pathVariables, fail } = {}
    ) {
      commit('setVouchersList', [])
      commit('setIsFetchingList', true)
      retailCheckoutApi.getVouchersList(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              let vouchersData = response.data.data;
              if (!vouchersData || (vouchersData && vouchersData[0] === null)) {
                vouchersData = []
              }
              commit('setVouchersList', vouchersData)
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    GET_VOUCHERS_DETAILS (
      { commit, dispatch },
      { params, pathVariables, fail } = {}
    ) {
      commit('setIsFetchingList', true)
      retailCheckoutApi.getVouchersDetails(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setVoucherDetails', response.data.data)
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        pathVariables
      );
    },
    APPLY_VOUCHER (
      { commit, dispatch },
      { params, success, fail } = {}
    ) {
      retailCheckoutApi.applyVoucher(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (response.data.data.availableVouchers.length) {
                commit('setVouchersList', response.data.data.availableVouchers)
              }
              success && success(response.data.data.availableVouchers)
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    REMOVE_VOUCHER (
      { commit, dispatch },
      { success, params, fail } = {}
    ) {
      retailCheckoutApi.removeVoucher(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (params.isCheckout) {
                commit('setCartDetails', response.data.data);
              } else {
                commit('setVouchersList', response.data.data.availableVouchers);
              }
              success && success(response.data);
            } else {
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
              fail && fail()
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    SUBSCRIBE_NOTIFICATION (
      { dispatch },
      { success, params, payload, pathVariables } = {}
    ) {
      retailCheckoutApi.subscribeForNotification(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success(response.data.data);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params,
        payload
      );
    },
    MARK_AS_READ (
      { dispatch },
      { success, params, payload } = {}
    ) {
      retailCheckoutApi.markAsRead(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success && success(response.data.data);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    CHANGE_PAYMENT_METHOD ({ dispatch, commit }, { success, params, payload, pathVariables, fail } = {}) {
      retailCheckoutApi.changePaymentMethod(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
                  response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success && success(response.data.data);
            }
          } else {
            fail();
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          fail();
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params,
        payload
      );
    },
    CANCEL_ORDER (
      { dispatch },
      { params, payload, success } = {}
    ) {
      retailCheckoutApi.cancelOrder(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data)
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    UPDATE_CART_ITEM ({ dispatch, commit }, { success, params, payload, pathVariables, fail } = {}) {
      commit('setIsFetchingList', true)
      retailCheckoutApi.itemCheckboxUpdate(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
                  response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success && success(response.data.data);
            }
          } else {
            fail();
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          fail && fail();
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params,
        payload
      )
    },
    SET_WHOLESALE_ONBOARDING_SKU ({ commit }, itemSku) {
      commit('setWholesaleOnboardingSku', itemSku);
    },
    SET_HEADER_INFO ({ commit }, retailCheckoutHeaderInfo) {
      commit('setHeaderInfo', retailCheckoutHeaderInfo)
    },
    SET_RETAIL_CART_ONBOARDING_STEP_STATUS ({ commit }, status) {
      commit('setRetailCartOnboardingStatus', status)
    },
    SET_RETAIL_CART_ONBOARDING_PROGRESS_STATUS ({ commit }, status) {
      commit('setIsRetailCartOnboardingInProgress', status)
    },
    SET_PAYMENT_SCREEN_STATUS ({ commit }, status) {
      commit('setIsPaymentScreen', status)
    },
    SET_USER_FROM_PAYMENT_SCREEN ({ commit }, status) {
      commit('setIsUserFromPaymentScreen', status)
    },
    SET_CHECKOUT_TAB_TYPE ({ commit }, status) {
      commit('setCheckoutTab', status)
    },
    SET_CANCEL_ALL_VOUCHER_STATUS ({ commit }, status) {
      commit('setIsCancelAllVoucherInProgress', status)
    },
    UPDATE_SHIPPING_OPTION (
      { commit, dispatch },
      { payload, params, success, fail } = {}
    ) {
      commit('seIsShippingMethodUpdateInProgress', true)
      retailCheckoutApi.updateShippingOption(
        response => {
          commit('seIsShippingMethodUpdateInProgress', false)
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setCartDetails', response.data.data);
              success(response.data.data);
            } else {
              fail(response.data.errors);
              dispatch('SET_ERROR_HANDLE_POPUP', {
                isErrorHandleVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            fail(response.data.errors);
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('seIsShippingMethodUpdateInProgress', false)
          fail(error);
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_MAX_DISCOUNT_VALUE ({ commit, dispatch }) {
      retailCheckoutApi.getRetailMaxDiscountedValue(
        response => {
          if (response.data.code === 200) {
            commit('setMaxDiscountedValue', response.data.data)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true);
        }
      )
    },
    DELETE_PAYMENT_SPECIFIC_VOCUHERS ({ dispatch }, { success, fail } = {}) {
      retailCheckoutApi.deletePaymentSpecificVouchers(
        response => {
          if (response.data.code === 200 && response.data.data) {
            success()
          } else {
            fail()
          }
        },
        error => {
          fail()
          errorHandler.handleErrors(dispatch, error, true);
        }
      )
    },
    SET_POPUP_INFO ({ commit }, popupInfo) {
      commit('setPopupInfo', popupInfo)
    }
  },
  namespaced: true
};
