import surveyApi from '@/api/surveyApi'
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    surveyDetails: [],
    surveyIndex: 0,
    surveyTotalRewardPoints: 0,
    surveyEndDateDesc: '',
    surveyExpired: false
  },
  mutations: {
    setSurveyDetails (state, surveyDetails) {
      state.surveyDetails = surveyDetails
    },
    setSurveyIndex (state, value) {
      state.surveyIndex = value
    },
    setSurveyTotalRewardPoints (state, value) {
      state.surveyTotalRewardPoints = value
    },
    setSurveyEndDateDesc (state, value) {
      state.surveyEndDateDesc = value
    },
    setSurveyExpired (state, value) {
      state.surveyExpired = value
    }
  },
  getters: {
    getSurveyDetails (state) {
      return state.surveyDetails
    },
    getSurveyIndex (state) {
      return state.surveyIndex
    },
    getSurveyTotalRewardPoints (state) {
      return state.surveyTotalRewardPoints
    },
    getSurveyEndDateDesc (state) {
      return state.surveyEndDateDesc
    },
    getSurveyExpired (state) {
      return state.surveyExpired
    }
  },
  actions: {
    GET_SURVEY_DETAILS ({ commit }, { success, payload, fail } = {}) {
      surveyApi.getSurveyDetails(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            commit('setSurveyDetails', response.data.data)
            success && success(response.data.data)
          } else {
            commit('setSurveyDetails', [])
          }
        },
        error => {
          fail && fail()
          console.log('[error]', error)
        },
        payload
      )
    },
    SUBMIT_SURVEY ({ dispatch }, { success, fail, payload } = {}) {
      surveyApi.submitSurvey(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            success && success(response.data.data)
          } else {
            fail(response.data.errors)
            errorHandler.handleErrors(dispatch, response.data.errors)
          }
        },
        error => {
          fail(error)
          errorHandler.handleErrors(dispatch, error)
        },
        payload
      )
    },
    SET_SURVEY_INDEX ({ commit }, value) {
      commit('setSurveyIndex', value)
    },
    SET_SURVEY_TOTAL_REWARD_POINTS ({ commit }, value) {
      commit('setSurveyTotalRewardPoints', value)
    },
    SET_SURVEY_END_DATE_DESC ({ commit }, value) {
      commit('setSurveyEndDateDesc', value)
    },
    SET_SURVEY_EXPIRED ({ commit }, value) {
      commit('setSurveyExpired', value)
    }
  },
  namespaced: true
}
