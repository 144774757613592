import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  sendEvent (payload, callback, fail) {
    apiCall.makePostRequest(
      apiUrls.api.appsFlyerApi(),
      callback,
      fail,
      payload
    );
  }
}
