import api from '../api/inAppNotificationApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    allNotifications: [],
    paging: null,
    isFetchingNotifications: false,
    notificationsDashboard: {}
  },
  getters: {
    getNotifications (state) {
      return state.allNotifications;
    },
    paging (state) {
      return state.paging;
    },
    getIsFetchingNotifications (state) {
      return state.isFetchingNotifications;
    },
    getNotificationsDashboard (state) {
      return state.notificationsDashboard;
    }
  },
  mutations: {
    setNotifications (state, value) {
      state.allNotifications = value;
    },
    setPaging (state, value) {
      state.paging = value;
    },
    setIsFetchingNotifications (state, value) {
      state.isFetchingNotifications = value;
    },
    setNotificationsDashboard (state, value) {
      state.notificationsDashboard = value;
    }
  },
  actions: {
    RESET_NOTIFICATION_LIST ({ commit }) {
      commit('setNotifications', []);
    },
    GET_NOTIFICATIONS ({ commit, dispatch, state }, { success, params, payload, fail, isNewPage } = {}) {
      commit('setIsFetchingNotifications', true)
      api.getAllNotifications(
        response => {
          commit('setIsFetchingNotifications', false)
          if (response.data.code === 200) {
            if (isNewPage) {
              commit('setNotifications', response.data.data)
              commit('setPaging', response.data.paging)
            } else {
              commit('setNotifications', [...state.allNotifications, ...response.data.data])
              commit('setPaging', response.data.paging)
            }
            if (success) {
              success(response.data.data);
            }
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingNotifications', false)
          if (fail) {
            fail(error.response)
          }
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    UPDATE_NOTIFICATION_STATUS ({ dispatch }, { success, params, payload, fail } = {}) {
      api.updateStatusOfNotification(
        response => {
          if (response.data.code === 200) {
            if (success) {
              success(response.data.data);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
            fail(response.data.errors);
            if (fail) {
              fail(response.data.errors);
            }
          }
        },
        error => {
          if (fail) {
            fail(error);
          }
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_NOTIFICATION_DASHBOARD ({ commit, dispatch }, { payload }) {
      api.getNotificationDashboard(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            commit('setNotificationsDashboard', response.data.data);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true)
        },
        payload
      );
    }
  },
  namespaced: true
};
