import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getMemberPaylaterDetail (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.getMemberPaylaterDetail, callback, fail, payload, params)
  },
  submitPaylaterApplication (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.submitPaylaterApplication, callback, fail, payload)
  },
  getPaylaterTransactionHistory (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.getPaylaterTransactionHistory, callback, fail, payload, params)
  },
  getPaylaterSubscriptionDetails (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.getPaylaterSubscriptionDetails, callback, fail, payload)
  },
  reuploadPaylaterDocuments (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.paylaterDocumentsReupload(), callback, fail, payload)
  },
  uploadPaylaterDocuments (callback, fail, params, payload) {
    apiCall.uploadFile(apiUrls.api.paylaterDocumentsUpload(), callback, fail, payload, params)
  },
  getPaylaterDocuments (callback, fail, payload, params) {
    apiCall.imageFromBlob(apiUrls.api.paylaterDocumentsDownload(), callback, fail, payload, params)
  }
}
