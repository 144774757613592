import dropshipApis from '../api/dropshipApis'
import errorHandler from '../api/apiUtils/errorHandler'

export default {
  state: {
    activeDropshipProduct: null,
    showProductDetailsScreen: false,
    showUnbuyableErrorPopup: false
  },
  getters: {
    activeDropshipProduct (state) {
      return state.activeDropshipProduct
    },
    showProductDetailsScreen (state) {
      return state.showProductDetailsScreen
    },
    showUnbuyableErrorPopup (state) {
      return state.showUnbuyableErrorPopup
    }
  },
  mutations: {
    setActiveDropshipProduct (state, value) {
      state.activeDropshipProduct = value
    },
    setShowProductDetailsScreen (state, value) {
      state.showProductDetailsScreen = value
    },
    setShowUnbuyableErrorPopup (state) {
      state.showUnbuyableErrorPopup = !state.showUnbuyableErrorPopup
    }
  },
  actions: {
    GET_SHARED_PRODUCT (
      { commit, dispatch },
      { success, pathVariables, params, fail } = {}
    ) {
      dropshipApis.getSharedProductDetails(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              commit('setActiveDropshipProduct', response.data.data)
              success && success(response.data.data);
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params
      );
    },
    SAVE_SHARED_PRODUCT (
      { dispatch },
      { success, pathVariables, payload, fail } = {}
    ) {
      dropshipApis.saveSharedProductDetails(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success && success(response.data.data);
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        payload
      );
    },
    GET_SHARED_PRODUCT_LIST (
      { dispatch },
      { success, pathVariables, fail } = {}
    ) {
      dropshipApis.getSharedProductsList(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              if (success) {
                success(response.data.data);
              }
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    SET_ACTIVE_DROPSHIP_PRODUCT ({ commit }, { product }) {
      commit('setActiveDropshipProduct', product)
    },
    TOGGLE_PRODUCT_DETAILS_POPUP ({ commit }, value) {
      commit('setShowProductDetailsScreen', value)
    },
    RESET_DROPSHIP_PRODUCT_DETAILS ({ commit }) {
      commit('setShowProductDetailsScreen', false)
      commit('setActiveDropshipProduct', null)
    },
    TOGGLE_UNBUYABLE_ERROR_POPUP ({ commit }) {
      commit('setShowUnbuyableErrorPopup')
    }
  },
  namespaced: true
}
