import Overlay from '@/components/web/Overlay.vue';
import Transition from '@/components/web/Transition.vue';

export default {
  name: 'Alert',
  props: {
    showAlert: {
      type: Boolean,
      default: false
    },
    alertMessage: {
      type: String,
      default: ''
    },
    hideAlert: {
      type: Function
    }
  },
  components: {
    Overlay,
    Transition
  },
  watch: {
    showAlert (newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hideAlert();
        }, 1500);
      }
    }
  }
};
