import { mapGetters } from 'vuex'
import { getMemberID } from '@/utils/helpers'

export const SameDayConfigMixin = {
  computed: {
    ...mapGetters('homepageStore', ['getConfigs']),
    sameDayShippingConfig () {
      const shippingConfig = this.getConfigs.shippingConfig
      return (shippingConfig && shippingConfig.sameDay) || {}
    },
    isMemberWhitelistedForSameDayDelivery () {
      const whitelistMembers = this.sameDayShippingConfig.whitelistUsers;
      if (!whitelistMembers || !whitelistMembers.length) {
        return true;
      }
      return whitelistMembers.includes(getMemberID())
    },
    sameDayMaxWeightLimit () {
      return this.sameDayShippingConfig.maxWeight || 0
    },
    sameDayShippingAdjustment () {
      return this.sameDayShippingConfig.shippingAdjustment || 0
    }
  }
}

export const InstantConfigMixin = {
  computed: {
    ...mapGetters('homepageStore', ['getConfigs']),
    instantShippingConfig () {
      const shippingConfig = this.getConfigs.shippingConfig
      return (shippingConfig && shippingConfig.instant) || {}
    },
    isMemberWhitelistedForInstantDelivery () {
      const whitelistMembers = this.instantShippingConfig.whitelistUsers;
      if (!whitelistMembers || !whitelistMembers.length) {
        return true;
      }
      return whitelistMembers.includes(getMemberID())
    },
    instantMaxWeightLimit () {
      return this.instantShippingConfig.maxWeight || 0
    },
    instantShippingAdjustment () {
      return this.instantShippingConfig.shippingAdjustment || 0
    }
  }
}

export const RegularConfigMixin = {
  computed: {
    ...mapGetters('homepageStore', ['getConfigs']),
    regularShippingConfig () {
      const shippingConfig = this.getConfigs.shippingConfig
      return (shippingConfig && shippingConfig.regular) || {}
    },
    regularMaxWeightLimit () {
      return this.regularShippingConfig.maxWeight || 0
    },
    regularShippingAdjustment () {
      return this.regularShippingConfig.shippingAdjustment || 0
    }
  }
}

export default {
  mixins: [SameDayConfigMixin, InstantConfigMixin, RegularConfigMixin],
  computed: {
    selectedShippingOption () {
      return this.merchantInfo.selectedShippingOption
    },
    availableShippingOptions () {
      return this.merchantInfo.availableShippingOptions || {}
    },
    shippedBySellerDetails () {
      return this.availableShippingOptions.SHIPPED_BY_SELLER
    },
    regularShippingDetails () {
      return this.availableShippingOptions.REGULAR
    },
    sameDayShippingDetails () {
      return this.availableShippingOptions.SAME_DAY
    },
    instantShippingDetails () {
      return this.availableShippingOptions.INSTANT
    },
    isShippingHandledBySeller () {
      return this.shippedBySellerDetails && this.shippedBySellerDetails.covered
    },
    isRegularShippingAvailable () {
      return this.regularShippingDetails && this.regularShippingDetails.covered
    },
    isSameDayShippingAvailable () {
      return this.sameDayShippingDetails && this.sameDayShippingDetails.covered
    },
    isInstantShippingAvailable () {
      return this.instantShippingDetails && this.instantShippingDetails.covered
    },
    isNoShippingOptionsAvailable () {
      return !this.isShippingHandledBySeller && !this.isRegularShippingAvailable && !this.isSameDayShippingAvailable && !this.isInstantShippingAvailable
    },
    shippingAmount () {
      return this.$options.filters.currency(this.merchantInfo.totalShipping + this.merchantInfo.totalShippingAdjustment)
    },
    isFreeShippingLimitNotMet () {
      return this.merchantInfo.totalShipping && this.merchantInfo.totalShipping + this.merchantInfo.totalShippingAdjustment > 0
    }

  },
  methods: {
    handleOnClick () {
      this.$emit('click')
    },
    handleOnClickOfShippingOption (shippingOption) {
      this.$emit('select', shippingOption)
    }
  }
}

