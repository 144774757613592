import { fromAppsFlyer } from '@/utils/helpers'
import { getBothLanguageCopyText } from '@/i18n/lang'
export default {
  computed: {
    $windowDataLayer () {
      return window.dataLayer || []
    },
    $pageType () {
      if (this.$route && this.$route.name) {
        return this.$route.name
      }
      return ''
    }
  },
  methods: {
    $trackAddToCartRetail (componentName, payload) {
      this.$pushEcommTracker('add_to_cart', componentName, payload)
    },
    $trackRemoveFromCartRetail (componentName, payload) {
      this.$pushEcommTracker('remove_from_cart', componentName, payload)
    },
    $trackSelectPromotion (componentName, payload) {
      this.$pushEcommTracker('select_promotion', componentName, payload)
    },
    $trackViewPromotion (componentName, payload) {
      this.$pushEcommTracker('view_promotion', componentName, payload)
    },
    $trackBeginCheckout (componentName, payload) {
      this.$pushEcommTracker('begin_checkout', componentName, payload)
    },
    $trackAddShippingInfo (componentName, payload) {
      this.$pushEcommTracker('add_shipping_info', componentName, payload)
    },
    $trackAddPaymentInfo (componentName, payload) {
      this.$pushEcommTracker('add_payment_info', componentName, payload)
    },
    $trackPurchase (componentName, payload) {
      this.$pushEcommTracker('purchase', componentName, payload)
    },
    $trackViewItemList (componentName, payload) {
      this.$pushEcommTracker('view_item_list', componentName, payload)
    },
    $trackSearchRetail (componentName, payload) {
      this.$pushCustomTracker('search', componentName, payload)
    },
    $trackButtonClickHandler (componentName, key, ...params) {
      const errorMessage = getBothLanguageCopyText(key, ...params)
      const eventDetailObj = {
        cp1: errorMessage.copyTextInBahasa,
        cp2: errorMessage.copyTextInEnglish || 'NA'
      }
      this.$trackButtonClick(componentName, eventDetailObj)
    },
    $trackButtonClick (componentName, payload) {
      this.$pushCustomTracker('button_click', componentName, payload)
    },
    $trackPageImpression (componentName, payload) {
      this.$pushCustomTracker('page_impression', componentName, payload)
    },
    $trackSectionView (componentName, payload) {
      this.$pushCustomTracker('section_view', componentName, payload)
    },
    $trackTrackErrorHandler (componentName, key, ...params) {
      const errorMessage = getBothLanguageCopyText(key, ...params)
      const eventDetailObj = {
        cp1: errorMessage.copyTextInBahasa,
        cp2: errorMessage.copyTextInEnglish || 'NA'
      }
      this.$trackTrackError(componentName, eventDetailObj)
    },
    $trackTrackError (componentName, payload) {
      this.$pushCustomTracker('track_error', componentName, payload)
    },
    $pushEcommTracker (eventName, componentName, payload = {}) {
      const trackerData = {
        event: eventName,
        params: {
          origin_screen: this.$pageType,
          component: componentName,
          event_category: 'Ecommerce',
          cart_category: 'Retail'
        },
        ecommerce: payload.ecommerce
      }
      if (payload.cp1) {
        trackerData.params.cp1 = payload.cp1
      }
      const isFromAppsFlyer = fromAppsFlyer() ;
      if (isFromAppsFlyer !== null) {
        const position = Object.keys(payload).filter(key => key.indexOf('cp') > -1).length + 1
        trackerData.params[`cp${position}`] = isFromAppsFlyer === 'true' ? 'yes' : 'no'
      }
      this.$pushClearTracker()
      this.$windowDataLayer.push(trackerData)
      console.log(trackerData)
    },
    $pushCustomTracker (eventName, componentName, payload = {}) {
      const trackerData = {
        event: eventName,
        params: {
          origin_screen: this.$pageType,
          component: componentName,
          ...payload
        }
      }
      const isFromAppsFlyer = fromAppsFlyer() ;
      if (isFromAppsFlyer !== null) {
        const position = Object.keys(payload).filter(key => key.indexOf('cp') > -1).length + 1
        trackerData.params[`cp${position}`] = isFromAppsFlyer === 'true' ? 'yes' : 'no'
      }
      this.$pushClearTracker()
      this.$windowDataLayer.push(trackerData)
      console.log(trackerData)
    },
    $pushClearTracker () {
      this.$windowDataLayer.push({ params: null, ecommerce: null });
    }
  }
}
