import id from './id.json';
import en from './en.json';
import langHelper from '../utils/language';

const formatTranslation = (text, data) => {
  const formatText = text.replace(/{(\d+)}/g, function (match, number) {
    return typeof data[number] !== 'undefined' ? data[number] : match;
  });
  return formatText;
};

const lang = {
  id: id,
  en: en
};

export const getTranslation = (source, key) => {
  if (!key) {
    return '';
  }

  if (!source) {
    return key;
  }

  return source[key] || key;
};

const findTranslation = (source, key) => {
  if (!key) {
    return '';
  }

  const matches = key.split('.');
  let i = 0;
  let str = source;

  while (i < matches.length) {
    str = getTranslation(str, matches[i]);
    i++;
  }

  return str;
};

// Example usage
// don't forget to add it on the [lang].json
// {$i18n('Hello {0} World {1} !', 'first number', 'second number')}

export const $i18n = (key, ...data) => {
  const userLang = langHelper.getUserLanguage();
  const currentLangFile = lang[userLang];

  // setting bahasa moment
  const translation = findTranslation(currentLangFile, key);

  return formatTranslation(translation, data);
};

export const doesTranslationExist = (i18nKey, ...data) => {
  const subKeys = i18nKey.split('.')
  const lastSubKey = subKeys[subKeys.length - 1]
  const translation = $i18n(i18nKey, ...data)
  if (translation === lastSubKey) {
    return false
  }
  return translation
}

export const getBothLanguageCopyText = (key, ...data) => {
  const englishLangFile = lang['en'];
  const bahasaLangFile = lang['id'];
  const englishTranslation = findTranslation(englishLangFile, key);
  const bahasaTranslation = findTranslation(bahasaLangFile, key);
  return {
    copyTextInEnglish: formatTranslation(englishTranslation, data) || 'NA',
    copyTextInBahasa: formatTranslation(bahasaTranslation, data) || 'NA'
  }
}
