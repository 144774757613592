import { formatCurrency } from '@vue3/utils/currency'

export default {
  name: 'HelperMixin',
  methods: {
    formatAmount (amount, currencySymbol) {
      return formatCurrency(amount, currencySymbol)
    },
    showToast (msg, status, isremovable = false) {
      this.$toast.open({
        removable: isremovable,
        text: msg,
        duration: 3000,
        status: status
      })
    }
  }
}
