export default {
  props: {
    currentProgress: {
      type: Number,
      default: 100
    },
    colorClass: {
      type: String,
      default: 'default'
    },
    backgroundStyle: {
      type: String,
      default: ''
    },
    animateProgress: {
      type: Boolean,
      default: true
    }
  }
}
