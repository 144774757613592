import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler'

export default {
  state: {
    operators: null,
    productDetails: {},
    minMaxAmountConfig: {}
  },
  getters: {
    getOperators (state) {
      return state.operators
    },
    getProductDetails (state) {
      return state.productDetails
    },
    getMinMaxAmountConfig (state) {
      return state.minMaxAmountConfig
    }
  },
  mutations: {
    setOperators (state, value) {
      state.operators = value
    },
    setProductDetails (state, value) {
      state.productDetails = value
    },
    setMinMaxAmount (state, value) {
      state.minMaxAmountConfig = value
    }
  },
  actions: {
    GET_OPERATORS ({ commit, dispatch }, productType) {
      digitalApi.getDigitalOperators(response => {
        commit('setOperators', response.data.data)
      },
      error => {
        errorHandler.handleErrors(dispatch, error)
      }, productType)
    },
    RESET_OPERATORS ({ commit }) {
      commit('setOperators', null)
    },
    FETCH_PRODUCT_DETAILS ({ commit, dispatch }, { payload, success, fail }) {
      commit('setProductDetails', null)
      digitalApi.fetchProductDetails(response => {
        if (response.data.code === 200 && !response.data.errors) {
          const responseData = response.data.data;
          commit('setProductDetails', responseData)
          let minMaxAmount = {}
          if (responseData.MAX_TRANSFER_AMOUNT && responseData.MIN_TRANSFER_AMOUNT) {
            minMaxAmount.minAmount = responseData.MIN_TRANSFER_AMOUNT
            minMaxAmount.maxAmount = responseData.MAX_TRANSFER_AMOUNT
          }
          commit('setMinMaxAmount', minMaxAmount)
          success && success(response.data.data)
        } else {
          commit('setProductDetails', {})
          commit('setMinMaxAmount', {})
          fail && fail(response.data.errors)
        }
      }, error => {
        commit('setProductDetails', {})
        commit('setMinMaxAmount', {})
        errorHandler.handleErrors(dispatch, error)
      }, payload)
    },
    POLL_MONEY_TRANSFER_STATUS ({ dispatch, commit }, { payload, success, fail } = {}) {
      digitalApi.pollMoneyTransferCurrentStatus(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            commit('setProductDetails', response.data.data)
            success && success(response.data.data)
          } else {
            commit('setProductDetails', {})
            fail && fail(response.data.errors)
          }
        },
        error => {
          commit('setProductDetails', {})
          errorHandler.handleErrors(dispatch, error)
        },
        payload
      )
    }
  },
  namespaced: true
}
