import router from '@/router';
import api from '@/api/authApis.js';
import commonAPI from '@/api/commonApis.js';
import { getFCMToken } from '@/utils/helpers';
import { clearStoreNamePopupInfo } from '../utils/helpers';

export default {
  state: {
    isUserLoggedIn: false,
    serverOverload: false,
    isServerError: false,
    isUnAuthenticatedUser: false
  },
  getters: {
    getUserLoginStatus (state) {
      return state.isUserLoggedIn;
    },
    getServerOverload (state) {
      return state.serverOverload;
    },
    getServerError (state) {
      return state.isServerError;
    },
    getIsUnAuthenticatedUser (state) {
      return state.isUnAuthenticatedUser
    }
  },
  mutations: {
    setUserLoginStatus (state, value) {
      Object.assign(state, { isUserLoggedIn: value });
    },
    setServerOverload (state, value) {
      state.serverOverload = value
    },
    setServerError (state, value) {
      state.isServerError = value
    },
    setIsUnAuthenticatedUser (state, value) {
      state.isUnAuthenticatedUser = value
    }
  },
  actions: {
    GO_TO_LOGIN_PAGE (_, payload) {
      console.log('GO_TO_LOGIN_PAGE PAYLOAD', payload);
      const loginUrl = payload.loginUrl;
      const callbackUrl = payload.callbackUrl;
      window.location.href = `${loginUrl}?callback=${callbackUrl}`;
    },
    SET_LOGIN_STATUS ({ commit }, payload) {
      commit('setUserLoginStatus', payload);
    },
    SET_SERVER_OVERLOAD ({ commit }, payload) {
      commit('setServerOverload', payload);
    },
    SET_SERVER_ERROR ({ commit }, payload) {
      commit('setServerError', payload);
    },
    GO_TO_HOME_PAGE (_, payload) {
      console.log('auth_stroe payload', payload);
      console.log('window.location', window.location.pathname);
      if (window.location.pathname !== payload.path) {
        router.push(payload.path);
      }
    },
    GET_MITRA_SESSION ({ commit }, { success, fail }) {
      api.getMitraSession(response => {
        if (response.data.code === 200) {
          const responseData = response.data.data
          if (typeof responseData === 'string' && JSON.parse(responseData).code >= 400) {
            commit('setIsUnAuthenticatedUser', true)
            clearStoreNamePopupInfo()
            window.sessionStorage.setItem('memberId', '')
            fail()
          } else {
            const member = response.data.data || '';
            commit('setIsUnAuthenticatedUser', false)
            window.sessionStorage.setItem('memberId', member.memberId);
            success();
          }
        } else {
          commit('setIsUnAuthenticatedUser', true)
          clearStoreNamePopupInfo()
          window.sessionStorage.setItem('memberId', '')
          fail()
        }
      }, err => {
        commit('setIsUnAuthenticatedUser', true)
        clearStoreNamePopupInfo()
        fail()
        console.log('error', err)
      })
    },
    LOG_OUT_FROM_APP (_, appHomeUrl) {
      console.log('appHomeUrl', appHomeUrl);
      window.localStorage.removeItem('tokenSetAt');
      window.localStorage.removeItem('loyaltyOnboarding');
      window.localStorage.setItem('dAdded', '');
      api.logoutApp(response => {
        console.log('response:', response);
        if (response.data.code === 200) {
          setTimeout(() => {
            window.location.href = appHomeUrl;
          }, 400)
        }
      }, fail => {
        console.log('error', fail)
      })
    },
    SEND_FCM_TOKEN_TO_SERVER (_, { payload, success }) {
      api.sendTokenToServer(response => {
        console.log('token_set_success:', response);
        success();
      }, fail => {
        console.log('error:', fail)
      }, payload)
    },
    DELETE_TOKEN_FROM_SERVER ({ dispatch }, { params, appHomeUrl }) {
      if (getFCMToken()) {
        console.log('token_is_present');
        api.deleteTokenFromServer(response => {
          // commenting deleteUserToken function to check the behaviour of token
          // global.deleteUserToken();
          window.localStorage.setItem('sentToServer', '0');
          window.localStorage.removeItem('currentToken', '');
          console.log('token_deleted_from_server:', response);
          dispatch('LOG_OUT_FROM_APP', appHomeUrl, { root: true });
        }, fail => {
          console.log('error:', fail)
        }, params)
      } else {
        console.log('token_is_not_present');
        dispatch('LOG_OUT_FROM_APP', appHomeUrl, { root: true });
      }
    },
    /**
     * The below action will get triggered only one time when the user loggedin
     * This action needs to be moved to a commonStore(no file now) when we have more actions to be added as common
     * */
    SET_DEVICE_DETAILS (_, { uuid, userAgentDetails, deviceDetails, memberId, pwaDisplayMode }) {
      const { browser, deviceModal, os } = userAgentDetails
      const payload = {
        memberId,
        browser,
        deviceId: uuid,
        deviceName: deviceModal,
        operatingSystem: os,
        deviceDetails,
        pwaDisplayMode
      }
      commonAPI.setDeviceDetails(response => {
        if (response && response.data && response.data.code === 200) {
          window.localStorage.setItem('dAdded', 'true')
          if (deviceDetails) {
            window.localStorage.setItem('d2Added', true)
          }
        }
      }, error => {
        console.log('error', error);
      }, payload);
    }
  },
  namespaced: false
};
