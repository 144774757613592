import apiUrls from './apiUrls'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getSharedProductDetails (callback, fail, pathVariables, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getSharedProductDetails(pathVariables.memberId),
      callback,
      fail,
      params
    );
  },
  saveSharedProductDetails (callback, fail, pathVariables, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getSharedProductDetails(pathVariables.memberId),
      callback,
      fail,
      payload
    );
  },
  getSharedProductsList (callback, fail, pathVariables) {
    apiCall.makeGetRequest(
      apiUrls.api.getSharedProductsList(pathVariables.memberId),
      callback,
      fail
    );
  }
}
