(function () {
  'use strict';
  // var workerScript = document.currentScript.dataset.serviceWorker;
  // adapted from create-react-app script

  let senderID = '';
  let fcmKey = '';
  let firebaseConfig = null;

  if (process.env.NODE_ENV === 'development') {
    console.log('mode_development');
    senderID = '673943947360';
    fcmKey = 'BEr_6dVKl6X3rsFIznubiW5N7Rm4STSeGsFdSviR8oty_8SfyxHdv5Ge9dadHlpcD_P34NyEVylQ6iGN_c4Zovg';
    firebaseConfig = {
      apiKey: 'AIzaSyAv3fnvVzLLWHXT_qD79LNSmc-pNrBzOUY',
      authDomain: 'fcm-demo-001.firebaseapp.com',
      databaseURL: 'https://fcm-demo-001.firebaseio.com',
      projectId: 'fcm-demo-001',
      storageBucket: 'fcm-demo-001.appspot.com',
      messagingSenderId: '673943947360',
      appId: '1:673943947360:web:1e7d929e6d810877385b05',
      measurementId: 'G-96VVCBMFDC'
    };
  } else {
    console.log('mode_production');
    senderID = window.VUE_APP_FCM_SENDER_ID;
    fcmKey = window.VUE_APP_FCM_KEY;
    const decoded = decodeURI(window.VUE_APP_MITRA_FCM_CONFIGS);
    console.log('decoded:', decoded);
    firebaseConfig = JSON.parse(decoded);
  }

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' }).then(serviceWorkerRegistration => {
        console.log('serviceWorkerRegistration:', serviceWorkerRegistration);
        navigator.serviceWorker.ready.then(registration => {
          console.log('registration:', registration);
          var options = {
            userVisibleOnly: true,
            applicationServerKey: fcmKey
          };
          // subscribe() method will trigger Notification.requestPermission() internally
          'Notification' in window && Notification.permission === 'granted' && serviceWorkerRegistration.pushManager.subscribe(options).then(data => {
            console.log('data:', data);
            serviceWorkerRegistration.pushManager.getSubscription().then(subscription => {
              // You have subscription.
              // Send data to service worker
              console.log('subscription:', subscription);
              console.log('active worker:', serviceWorkerRegistration.active);
              if (serviceWorkerRegistration.active !== null) {
                serviceWorkerRegistration.active.postMessage({
                  'senderId': senderID,
                  'firebaseConfig': firebaseConfig
                });
              }
            });
          });
        });
      });

      navigator.serviceWorker.register('/serviceworker.js', { scope: '/home' }).then(swRegistration => {
        console.log('swRegistration:', swRegistration)
      });

      if (navigator && navigator.serviceWorker) {
        console.log('ADD ON MESSAGE LISTENER.....');
        navigator.serviceWorker.onmessage = function (event) {
          console.log('EVENT:', event);
          if (event.data && event.data.type === 'CUSTOM_EVENT') {
            console.log('will_open_this_link:', event.data.linkTo);
            window.open(event.data.linkTo);
          }
        };
      }
    })
  }
})();
