import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import { homepageStore } from '@vue3/store/homepage-store'
import { mapActions } from 'pinia'

export default {
  name: 'WalletSuspensionPopup',
  components: {
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter,
    BliButton
  },
  props: {
    enablePopup: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$trackSectionView('MitraWalletDeactivatedReasoning')
  },
  methods: {
    ...mapActions(homepageStore, ['SET_POPUP_INFO']),
    closePopup () {
      this.$emit('closeWalletSuspensionPopup')
    },
    redirectToHelpPage () {
      this.SET_POPUP_INFO({ key: '' })
      this.closePopup()
      this.$router.replace('/help')
    }
  }
}

