import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getServicesPromotion (callback, fail) {
    apiCall.makeGetRequest(apiUrls.api.getServiceAndPromotion, callback, fail);
  },
  getWalletRequestData (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getWalletRequests(),
      callback,
      fail,
      payload
    );
  },
  getWalletTransaction (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getTransactionHistory(),
      callback,
      fail,
      payload
    );
  },
  updateMemberDetails (callback, fail, payload) {
    apiCall.makePutRequest(
      apiUrls.api.updateMemberDetails(),
      callback,
      fail,
      payload
    );
  },
  getNPS (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getNPS(),
      callback,
      fail,
      payload
    );
  },
  submitNPS (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.submitNPS,
      callback,
      fail,
      payload
    );
  },
  getAutoSuggestedProducts (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getAutoSuggestedProducts,
      callback,
      fail,
      params
    )
  },
  saveInstalledAppData (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.saveAppsData(), callback, fail, payload)
  },
  getMemberBanners (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.getMemberBanners(), callback, fail, payload);
  },
  getContestsDashboard (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getContestsDashboard(),
      callback,
      fail,
      payload
    );
  },
  getQuickOrderDetails (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getQuickOrderDetails,
      callback,
      fail,
      params
    );
  }
}
