// import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
// import 'hooper/dist/hooper.css'
import OverlayPopup from '@/components/web/OverlayPopup.vue'
import Transition from '@/components/web/Transition.vue'
import HelpAction from '@/components/HelpAction.vue'
import { getI18nText } from '@/utils/helpers';

export default {
  name: 'OnboardingScreen',
  props: {
    onSkipClick: {
      type: Function,
      default: () => { return {} }
    },
    showOnboardingScreen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OverlayPopup,
    Transition,
    HelpAction
  },
  data () {
    return {
      isHelpVisible: false,
      sliderData: [
        {
          heading: getI18nText("Let's increase business income", 'Ayo tingkatkan pendapatan usaha!'),
          text: getI18nText('Prove the easy selling credit, electricity tokens, data packages and merchandise others at the best prices.',
            'Buktikan mudahnya berjualan pulsa,\n' +
              'token listrik, paket data dan barang dagangan\n' +
              'lainnya dengan harga terbaik.')
        }
      ]
    };
  },
  methods: {
    handleMySlideClick (event) {
      console.log('Click Event', event.target);
    },
    skipOnboarding () {
      console.log('SKIP');
      this.onSkipClick();
    },
    showNextScreen () {
      console.log('show next screen');
      this.$refs.hooper.slideNext();
    },
    watchCurrentSlide (slide) {
      console.log('currentSlide:', slide);
    },
    toggleHelpVisible () {
      this.isHelpVisible = !this.isHelpVisible;
    }
  }
};
