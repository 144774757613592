import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import BliRating from '@blibli/blue.rating/dist/rating/blue/Rating'
import BliField from '@blibli/blue.field/dist/field/blue/Field'
import BliTextArea from '@blibli/blue.text-area/dist/text-area/blue/TextArea'
import { homepageStore } from '@vue3/store/homepage-store'
import { mapActions, mapState } from 'pinia'
import { getMemberID } from '@vue3/utils/helpers'

export default {
  name: 'NetPromoterScoreDetails',
  components: {
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter,
    BliButton,
    BliRating,
    BliField,
    BliTextArea
  },
  props: {
    enablePopup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedAnswers: {},
      ratingValue: 0,
      comment: '',
      disableSubmitBtn: false
    }
  },
  mounted () {
    this.ratingValue = this.npsRating
  },
  watch: {
    ratingValue (newVal) {
      this.handleRatingChange(newVal)
    },
    feedbackOptionAnswers () {
      if (this.selectedAnswersLength) {
        this.selectedAnswers = this.feedbackOptionAnswers.reduce((accumulator, answer) => {
          if (this.selectedAnswers[answer]) {
            accumulator[answer] = true
          }
          return accumulator
        }, {})
      }
    }
  },
  computed: {
    ...mapState(homepageStore, ['npsDetails', 'npsRating']),
    isCommentReachedMaxLimit () {
      return this.comment.length === 500
    },
    question () {
      return this.currentChoiceChip ? this.currentChoiceChip.question : ''
    },
    feedbackOptionAnswers () {
      return this.currentChoiceChip ? this.currentChoiceChip.choiceChipList : []
    },
    currentChoiceChip () {
      if (!this.npsDetails.choiceChipResponseMap) {
        return null;
      }
      return this.npsDetails.choiceChipResponseMap[this.npsRating]
    },
    selectedAnswersLength () {
      return Object.keys(this.selectedAnswers).length;
    }
  },
  methods: {
    ...mapActions(homepageStore, ['SET_NPS_RATING', 'SET_POPUP_INFO', 'SET_FULL_SCREEN_POPUP_INFO', 'SUBMIT_NPS']),
    doNpsLater () {
      this.SUBMIT_NPS({
        payload: {
          memberId: getMemberID(),
          addNpsRequest: {}
        },
        success: this.closeNpsPopup,
        fail: this.closeNpsPopup
      })
    },
    handleRatingChange () {
      if ((this.npsRating <= 3 && this.ratingValue > 3) || (this.npsRating > 3 && this.ratingValue <= 3)) {
        this.comment = ''
      }
      this.SET_NPS_RATING(this.ratingValue)
    },
    closeNpsPopup () {
      this.SET_FULL_SCREEN_POPUP_INFO({ key: '' })
    },
    submitNPS () {
      if (!this.selectedAnswersLength) {
        this.showToast(this.$i18n('NPS.SELECT_ONE_REASON'), 'error')
        return
      }
      this.disableSubmitBtn = true;
      this.SUBMIT_NPS({
        payload: {
          memberId: getMemberID(),
          addNpsRequest: {
            starRating: this.npsRating,
            choiceChipList: Object.keys(this.selectedAnswers),
            comment: this.comment
          }
        },
        pathVariables: { memberId: getMemberID() },
        success: this.submitNPSsuccess,
        fail: this.enableSubmitBtn
      })
    },
    submitNPSsuccess (res) {
      this.$trackButtonClick('MitraNPSSubmit', { cp1: this.npsRating })
      this.closeNpsPopup()
      if (res.isEligible) {
        this.SET_POPUP_INFO({ key: '' })
        setTimeout(() => {
          this.SET_POPUP_INFO({ key: 'PLAYSTORE_RATING_POPUP' })
        }, 150)
      } else {
        this.showToast(this.$i18n('NPS.SUCCESS_MSG'), '')
      }
    },
    enableSubmitBtn () {
      this.disableSubmitBtn = false;
    },
    toggleAnswerSelect (answer) {
      if (this.selectedAnswers[answer]) {
        delete this.selectedAnswers[answer];
      } else {
        this.selectedAnswers[answer] = true;
      }
      this.selectedAnswers = { ...this.selectedAnswers }
    }
  }
}

