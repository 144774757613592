import { defineStore } from 'pinia'

export const root = defineStore('root', {
  state: () => {
    return {
      showLoader: false,
      pwaDisplayMode: '',
      errorToastInfo: {
        showToast: false,
        errorMessage: ''
      },
      showPageNotFoundInfo: false
    }
  },
  actions: {
    SET_LOADER (value) {
      this.showLoader = value
    },
    SET_PWA_DISPLAY_MODE (pwaDisplayMode) {
      this.pwaDisplayMode = pwaDisplayMode
    },
    TRIGGER_ERROR_TOAST (error) {
      this.errorToastInfo = {
        showToast: true,
        errorMessage: error
      }
    },
    RESET_ERROR_TOAST () {
      this.errorToastInfo = {
        showToast: false,
        errorMessage: ''
      }
    },
    SET_PAGE_NOT_FOUND_INFO (value) {
      this.showPageNotFoundInfo = value
    }
  }
})
