import digitalApi from '@/api/digitalApis';

export default {
  state: {
    packages: [],
    packageErrors: null
  },
  mutations: {
    setPackages (state, packages) {
      state.packages = packages;
    },
    resetPackages (state) {
      state.packages = []
    },
    setPackageErrors (state, errors) {
      state.packageErrors = errors
    }
  },
  getters: {
    packages (state) {
      return state.packages
    },
    packageErrors (state) {
      return state.packageErrors
    }
  },
  actions: {
    GET_PACKAGES ({ commit }, payload) {
      digitalApi.getIndihomePackages(
        response => {
          if (response.data.errors) {
            commit('setPackageErrors', response.data.errors)
          } else {
            commit('setPackages', response.data.data.products);
            commit('setPackageErrors', null)
          }
        },
        error => console.log(error),
        payload
      );
    },
    RESET_PACKAGES ({ commit }) {
      commit('resetPackages')
    },
    RESET_PACKAGE_ERROR ({ commit }) {
      commit('setPackageErrors', null)
    }
  },
  namespaced: true
};
