const monthsMapping = {
  1: 'Januari',
  2: 'Februari',
  3: 'Maret',
  4: 'April',
  5: 'Mei',
  6: 'Juni',
  7: 'Juli',
  8: 'Agustus',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Desember'
};

const shortMonthNames = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'Mei',
  6: 'Jun',
  7: 'Jul',
  8: 'Agu',
  9: 'Sep',
  10: 'Okt',
  11: 'Nov',
  12: 'Des'
}

export function getMonthDescription (month) {
  return monthsMapping[month];
}
export function getDateMonthAndYearFromCustomFormat (customDate, format = 'yyyy-mm-dd') {
  if (format === 'yyyy-mm-dd') {
    const date = new Date(customDate)
    return `${date.getDate()} ${shortMonthNames[date.getMonth() + 1]} ${date.getFullYear()}`
  }
}
export function getDateAndTimeFromTimestamp (timestamp) {
  const date = new Date(timestamp)
  return `${date.getDate()} ${monthsMapping[date.getMonth() + 1]} ${date.getFullYear()} ${date.getHours()}.${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`
}

export function getMonthAndYearFromTimestamp (timestamp) {
  const date = new Date(new Date(timestamp).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }))
  return `${monthsMapping[date.getMonth() + 1]} ${date.getFullYear()}`
}

export function getDateAndMonthFromTimestamp (timestamp) {
  const date = new Date(new Date(timestamp).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }))
  return `${date.getDate()} ${monthsMapping[date.getMonth() + 1]}`
}

export function getDateRange (startDate, endDate) {
  if (startDate.getFullYear() == endDate.getFullYear()) {
    return `${startDate.getDate()} ${shortMonthNames[startDate.getMonth() + 1]} - ${endDate.getDate()} ${shortMonthNames[endDate.getMonth() + 1]} ${endDate.getFullYear()}`
  } else {
    return `${startDate.getDate()} ${shortMonthNames[startDate.getMonth() + 1]} ${startDate.getFullYear()} - ${endDate.getDate()} ${shortMonthNames[endDate.getMonth() + 1]} ${endDate.getFullYear()}`
  }
}
export function getShortDate (timestamp) {
  const date = new Date(timestamp)
  return `${date.getDate()} ${shortMonthNames[date.getMonth() + 1]} ${date.getFullYear()}`
}
export function getParsedDate (date) {
  let day = date.getDate();
  let month = date.getMonth() + 1; // January is 0
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}
