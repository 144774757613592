<template>
  <div class="b-scrim">
    <BliLoaderGeneral size="l" />
  </div>
</template>
<script src="./loader.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
@import '@blibli/blue-dls-utils/dist/scss/scrim-helper';
</style>
