<template>
  <div>
    <BliModal
      :active="isServerError"
      @blur="removeServerErrorScreen()"
    >
      <BliModalHeader @close="removeServerErrorScreen()" />
      <BliModalBody>
        <div class="server-error">
          <img
            src="@vue3/assets/server-errors-icons/server-maintenance.svg"
            alt="Offline"
          >
          <div class="header">
            {{ $i18n('SERVER_ERROR.HEADER') }}
          </div>
          <div class="content">
            {{ $i18n('SERVER_ERROR.DESCRIPTION') }}
          </div>
        </div>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          full-width
          @click="removeServerErrorScreen()"
        >
          {{ $i18n('SERVER_ERROR.OK') }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./server-error.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.server-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  .header {
    font-size: $blu-font-size-50;
    color: $blu-color-neutral-text-high;
    font: $blu-text-subtitle-2;
    line-height: 24px;
  }
  .content {
    text-align: center;
    margin-bottom: 24px;
    font-size: $blu-font-size-30;
    font: $blu-text-body-2;
    line-height: 18px;
  }
}

</style>
