import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'

export default {
  name: 'ServerOverload',
  components: {
    BliButton,
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter
  },
  props: {
    isServerOverloaded: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.$trackTrackError('MitraServerOverloadedError')
  },
  methods: {
    checkAgain () {
      window.location.reload()
    }
  }
}
