// this file contains all home page modals

import { mapGetters } from 'vuex'
import { getMemberID } from '@/utils/helpers'

const WhatsNewModalContent = () => import('@/components/home/custom-modals/WhatsNew.vue')
const AutoReplenishmentModalContent = () => import('@/components/home/custom-modals/AutoReplenishment.vue')

export default {
  name: 'HomeModals',
  data () {
    return {
      isModalVisible: false,
      currentModalProperties: {}
    }
  },
  components: {
    WhatsNewModalContent,
    AutoReplenishmentModalContent
  },
  created () {
    this.modalList = {
      NO_ACCESS_MODAL: {
        maskClick: this.closeNoAccessModal,
        close: this.closeNoAccessModal,
        controls: [{ label: this.$i18n('OK') }],
        headerText: this.isMemberUnderNewRegistration ? this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_TITLE') : this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_TITLE'),
        bodyText: this.isMemberUnderNewRegistration ? this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_DESC') : this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_DESC')
      },
      NO_ACCESS_MODAL_WITH_HOME_REDIRECTION: {
        maskClick: this.closeNoAccessModal,
        close: this.closeNoAccessModal,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.goToHomePage
          }
        ],
        headerText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_TITLE'),
        bodyText: this.$i18n('HOME_PAGE.NO_FEATURE_ACCESS_MODAL_DESC')
      },
      INCOMPLETE_REGISTRATION_MODAL: {
        type: 'warning',
        maskClick: this.onIncompleteRegistrationMaskClick,
        close: this.onIncompleteRegistrationMaskClick,
        controls: [
          {
            label: this.$i18n('HOME_PAGE.DATA_INCOMPLETE_BTN_TEXT'),
            handler: this.handleIncompleteRegistrationPopupBtnClick,
            color: 'secondary',
            outline: false
          }
        ],
        headerText: this.$i18n('HOME_PAGE.DATA_INCOMPLETE_HEADING'),
        bodyText: this.$i18n('HOME_PAGE.DATA_INCOMPLETE_MSG_TEXT')
      },
      NEEDS_APPROVAL_MODAL: {
        maskClick: this.closeUnderVerificationPopup,
        close: this.closeUnderVerificationPopup,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.closeUnderVerificationPopup,
            color: 'secondary',
            outline: false
          }
        ],
        headerText: this.$i18n('VERIFICATION_HEADER'),
        bodyText: this.$i18n('VERIFICATION_MSG')
      },
      DROPSHIP_NOT_AVAILABLE_MODAL: {
        maskClick: this.closeDropshipNotAvailablePopup,
        close: this.closeDropshipNotAvailablePopup,
        controls: [
          {
            label: this.$i18n('OK'),
            handler: this.closeDropshipNotAvailablePopup,
            color: 'secondary',
            outline: false
          }
        ],
        headerText: this.$i18n('DROPSHIP_NOT_AVAILABLE_HEADER'),
        bodyText: this.$i18n('DROPSHIP_NOT_AVAILABLE_BODY')
      },
      NO_STORE_MODAL: {
        type: 'info',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('BACK'),
            handler: this.closeModal,
            outline: !0
          },
          {
            label: this.$i18n('HOME_PAGE.ADD_BUSINESS_ADDRESS'),
            handler: this.handleCompleteRegistrationWithoutStorePopupBtnClick
          }
        ],
        headerText: this.$i18n('HOME_PAGE.UNDER_STORE_REVIEW_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.EDIT_ADDRESS_WARNING_BODY_NO_STORE')
      },
      MEMBER_WITH_NO_STORE_MODAL: {
        type: 'info',
        maskClick: this.closeModal,
        close: this.closeModal,
        controls: [
          {
            label: this.$i18n('BACK'),
            handler: this.closeModal,
            outline: !0
          },
          {
            label: this.$i18n('HOME_PAGE.ADD_BUSINESS_ADDRESS'),
            handler: this.handleCompleteRegistrationWithoutStorePopupBtnClick
          }
        ],
        headerText: this.$i18n('HOME_PAGE.UNDER_STORE_REVIEW_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.NOT_REGISTERED_FOR_STORE')
      },
      STORE_UNDER_REVIEW_MODAL: {
        maskClick: this.closeModal,
        close: this.closeModal,
        headerText: this.$i18n('HOME_PAGE.UNDER_STORE_REVIEW_HEADER'),
        bodyText: this.$i18n('HOME_PAGE.UNDER_STORE_REVIEW_BODY')
      }
    }
    this.addAdditionalModals()
    this.addMoreAdditionalModals()
  },
  computed: {
    ...mapGetters('homepageStore', ['getModalInfo']),
    modalKey () {
      return this.getModalInfo.key
    }
  },
  methods: {
    addAdditionalModals () {
      const additionModals = {
        DRP_OR_MITRA_ID_UNDER_REVIEW_MODAL: {
          maskClick: this.closeModal,
          close: this.closeModal,
          controls: [
            { label: this.$i18n('OK') }
          ],
          headerText: this.$i18n('HOME_PAGE.MITRA_ID_DRP_NUMBER_UNDER_REVIEW'),
          bodyText: this.$i18n('HOME_PAGE.MITRA_ID_DRP_NUMBER_UNDER_REVIEW_DESC')
        },
        NO_DRP_AND_MITRA_ID_MODAL: {
          maskClick: this.closeNoDrpAndMitraIdModal,
          close: this.closeNoDrpAndMitraIdModal,
          controls: [
            {
              label: this.$i18n('BACK'),
              handler: this.closeNoDrpAndMitraIdModal,
              outline: !0
            },
            {
              label: this.$i18n('CHANGE_ADDRESS_DETAIL'),
              handler: () => this.goToEditStoreDetailsPage('CHANGE_ADDRESS_DETAIL')
            }
          ],
          headerText: this.$i18n('HOME_PAGE.UNDER_STORE_REVIEW_HEADER'),
          bodyText: `${this.$i18n('EDIT_MITRAID_DRP_MODAL_INFO_1')}\n${this.$i18n('EDIT_MITRAID_DRP_MODAL_INFO_2')}`,
          class: 'pre-line'
        },
        OUTSIDE_ZONE_MODAL: {
          maskClick: this.closeOutsideZoneModal,
          close: this.closeOutsideZoneModal,
          controls: [
            {
              label: this.$i18n('BACK'),
              handler: this.closeOutsideZoneModal,
              outline: !0
            },
            {
              label: this.$i18n('CHANGE_BUSINESS_ADDRESS'),
              handler: () => this.goToEditStoreDetailsPage('CHANGE_BUSINESS_ADDRESS')
            }
          ],
          headerText: this.$i18n('HOME_PAGE.EDIT_ADDRESS_WARNING_HEADER'),
          bodyText: this.$i18n('HOME_PAGE.EDIT_ADDRESS_WARNING_BODY')
        },
        MAINTENANCE_MODAL: {
          maskClick: this.closeMaintenanceModal,
          close: this.closeMaintenanceModal,
          controls: [
            {
              label: this.$i18n('OK'),
              handler: this.closeMaintenanceModal
            }
          ]
        }
      }
      this.modalList = { ...this.modalList, ...additionModals }
    },
    addMoreAdditionalModals () {
      const moreModals = {
        HOME_ONBOARDING_SKIP_MODAL: {
          maskClick: this.continueHomeOnboarding,
          close: this.continueHomeOnboarding,
          controls: [
            {
              label: this.$i18n('HOME_PAGE.ONBOARDING.CONTINUE_ONBOARDING'),
              handler: this.continueHomeOnboarding,
              outline: !0
            },
            {
              label: this.$i18n('HOME_PAGE.ONBOARDING.CLOSE'),
              handler: this.skipHomeOnboarding
            }
          ],
          headerText: this.$i18n('HOME_PAGE.ONBOARDING.SKIP_ONBOARDING_TITLE'),
          bodyText: this.$i18n('HOME_PAGE.ONBOARDING.SKIP_ONBOARDING_DESC')
        },
        WHATS_NEW_MODAL: {
          class: 'whats-new',
          isCustomModal: true,
          maskClick: this.updateWhatsNewVersion,
          close: this.updateWhatsNewVersion,
          controls: [
            {
              handler: this.updateWhatsNewVersion,
              label: this.$i18n('GOT_IT')
            }
          ]
        },
        AUTO_REPLENISHMENT_MODAL: {
          class: 'auto-replenishment',
          isCustomModal: true,
          maskClick: this.closeModal,
          clos: this.closeModal,
          controls: []
        }
      }
      this.modalList = { ...this.modalList, ...moreModals }
    },
    closeNoAccessModal (buttonText) {
      this.closeModal()
      this.$trackErrorClickEvent('NO_ACCESS_TO_THIS_PRODUCT_OR_FEATURE', buttonText)
      this.$trackButtonClickHandler('NO_ACCESS_TO_THIS_PRODUCT_OR_FEATURE', buttonText)
    },
    handleIncompleteRegistrationPopupBtnClick () {
      this.$trackErrorClickEvent('COMPLETE_ACCOUNT_DATA_WARNING', 'HOME_PAGE.DATA_INCOMPLETE_BTN_TEXT')
      this.$trackButtonClickHandler('COMPLETE_ACCOUNT_DATA_WARNING', 'HOME_PAGE.DATA_INCOMPLETE_BTN_TEXT')
      this.$router.push('/mitra-registration')
    },
    handleCompleteRegistrationWithoutStorePopupBtnClick () {
      this.$router.push({
        name: 'StoreDetail'
      })
    },
    onIncompleteRegistrationMaskClick () {
      this.closeModal()
      this.$trackErrorClickEvent('COMPLETE_ACCOUNT_DATA_WARNING', 'NA')
      this.$trackButtonClickHandler('COMPLETE_ACCOUNT_DATA_WARNING', 'NA')
    },
    closeUnderVerificationPopup (buttonText = 'OK') {
      this.closeModal()
      this.$trackErrorClickEvent('UNDER_VERIFICATION', buttonText)
      this.$trackButtonClickHandler('UNDER_VERIFICATION', buttonText)
    },
    closeDropshipNotAvailablePopup (buttonText = 'OK') {
      this.closeModal()
      this.$trackErrorClickEvent('DROPSHIP_NOT_AVAILABLE_IN_YOUR_LOCATION', buttonText)
      this.$trackButtonClickHandler('DROPSHIP_NOT_AVAILABLE_IN_YOUR_LOCATION', buttonText)
    },
    closeModal () {
      this.$store.dispatch('homepageStore/SET_MODAL_INFO', { key: '' })
    },
    closeNoDrpAndMitraIdModal () {
      this.closeModal()
      this.$trackErrorClickEvent('OUT_OF_REACH_ADDRESS', 'BACK')
      this.$trackButtonClickHandler('OUT_OF_REACH_ADDRESS', 'BACK')
    },
    goToEditStoreDetailsPage (buttonText) {
      this.$trackErrorClickEvent('OUT_OF_REACH_ADDRESS', buttonText)
      this.$trackButtonClickHandler('OUT_OF_REACH_ADDRESS', buttonText)
      this.$nextTick(() => {
        this.$router.push({
          name: 'StoreDetail'
        })
      })
    },
    closeOutsideZoneModal () {
      this.closeModal()
      this.$trackErrorClickEvent('OUT_OF_REACH_ADDRESS', 'BACK')
      this.$trackButtonClickHandler('OUT_OF_REACH_ADDRESS', 'BACK')
    },
    closeMaintenanceModal () {
      this.closeModal()
      this.$trackErrorClickEvent('UNDER_MAINTENANCE', 'OK')
      this.$trackButtonClickHandler('UNDER_MAINTENANCE', 'OK')
    },
    continueHomeOnboarding () {
      const currentOnboardingStep = this.currentModalProperties.currentOnboardingStep
      this.$trackClickEvent('MitraHomeOnboardingContinueClick', {
        cd1: currentOnboardingStep
      })
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.$store.dispatch('homepageStore/SET_HOME_ONBOARDING_STATUS', currentOnboardingStep)
        this.closeModal()
        if (currentOnboardingStep === 'STEP0') {
          this.$store.dispatch('homepageStore/SET_POPUP_INFO', { key: 'HOME_ONBOARDING_POPUP' })
        }
      }, 20)
    },
    skipHomeOnboarding () {
      this.$store.dispatch('homepageStore/SET_HOME_ONBOARDING_STATUS', '')
      this.closeModal()
      let skipCount
      if (!localStorage.getItem('homeOnboardingSkipTime')) {
        skipCount = 1
        localStorage.setItem('homeOnboardingSkipTime', new Date().getTime())
      } else {
        skipCount = 2
        this.updateHomeOnboardingStatus()
      }
      this.$trackClickEvent('MitraHomeOnboardingSkipClick', {
        cd1: this.currentModalProperties.currentOnboardingStep,
        cd2: skipCount
      })
    },
    updateHomeOnboardingStatus () {
      this.$store.dispatch('profileStore/UPDATE_MEMBER_ONBOARDING_STATUS', {
        payload: { memberId: getMemberID() },
        success: this.onSuccessOfUpdateHomeOnboardingStatus,
        params: {
          workFlow: 'HOME_REVAMP_1'
        }
      })
    },
    onSuccessOfUpdateHomeOnboardingStatus () {
      localStorage.setItem('homeOnboardingStatus', true)
    },
    closeAddStoreNameModal () {
      this.closeModal()
      this.$trackErrorClickEvent('ADD_STORE_NAME', 'LATER')
      this.$trackButtonClickHandler('ADD_STORE_NAME', 'LATER')
    },
    updateWhatsNewVersion () {
      this.closeModal()
      this.$store.dispatch('homepageStore/UPDATE_MEMBER_DETAILS', {
        payload: {
          memberId: getMemberID(),
          whatsNewVersionNumber: this.currentModalProperties.whatsNewVersionNumber
        },
        success: this.updateMemberSuccess
      })
    },
    updateMemberSuccess () {
      this.$store.dispatch('profileStore/GET_MEMBER_DETAILS', {
        payload: {
          memberId: getMemberID()
        }
      })
    },
    goToHomePage () {
      this.$store.dispatch('profileStore/GET_MEMBER_DETAILS', {
        payload: {
          memberId: getMemberID()
        }
      })
      this.$router.push('/mitra-home')
    }
  },
  watch: {
    getModalInfo (modalInfo) {
      if (modalInfo.key) {
        this.isModalVisible = true
        this.currentModalProperties = {
          type: 'info',
          class: '',
          ...this.modalList[modalInfo.key],
          ...modalInfo.properties
        }
      } else {
        this.isModalVisible = false
        setTimeout(() => {
          if (!this.isModalVisible) {
            // if no other modal is shown within 500ms
            this.currentModalProperties = {}
          }
          // 500ms timeout to sync with BliModal's 500ms fade-out effect
        }, 500)
      }
    }
  }
}
