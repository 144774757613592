import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    operators: [],
    products: [],
    isFetchingProducts: false,
    productErrors: []
  },
  mutations: {
    setOperator (state, operators) {
      state.operators = operators;
    },
    setProducts (state, products) {
      state.products = products;
    },
    setIsFetchingProducts (state, value) {
      state.isFetchingProducts = value;
    },
    setProductErrors (state, productErrors) {
      state.productErrors = productErrors
    }
  },
  getters: {
    getOperators (state) {
      return state.operators;
    },
    getProducts (state) {
      return state.products;
    },
    isFetchingProductDetails (state) {
      return state.isFetchingProducts;
    },
    productErrors (state) {
      return state.productErrors
    }
  },
  actions: {
    GET_OPERATORS ({ commit, dispatch }) {
      digitalApi.getDigitalOperators(
        response => {
          commit('setOperator', response.data.data);
        },
        error => {
          console.log('[error]', error);
          errorHandler.handleErrors(dispatch, error);
        },
        'EMONEY'
      );
    },
    GET_PRODUCTS ({ commit }, { payload, success, fail }) {
      commit('setIsFetchingProducts', true);
      digitalApi.getProducts(
        response => {
          commit('setIsFetchingProducts', false);
          if (response.data.errors) {
            fail && fail(response.data.errors)
            commit('setProductErrors', response.data.errors);
            commit('setProducts', null);
          } else {
            success && success(response.data.data)
            commit('setProducts', response.data.data);
            commit('setProductErrors', null);
          }
        },
        error => {
          fail && fail()
          console.log(error)
        },
        'EMONEY',
        payload
      );
    },
    RESET_PRODUCT ({ commit }) {
      commit('setProducts', null);
    },
    RESET_PRODUCT_ERROR ({ commit }) {
      commit('setProductErrors', null)
    }
  },
  namespaced: true
};
