import Vue from 'vue2'
import Vuex from 'vuex';
import root from './root';
import profileStore from './profileStore';
import homepageStore from './homepage-store';
import authStore from './authStore';
import notificationStore from './notificationStore';
import pulsaStore from './pulsa-store';
import packageData from './package-data-store';
import pln from './pln-store';
import digitalCart from './digital-cart-store';
import blipay from './blipay-store';
import pdam from './pdam-store';
import gameVoucher from './game-voucher';
import digitalOrder from './digital-order';
import cashoutStore from './blipay-cashout-store';
import orderHistory from './order-history';
import retailCheckoutStore from './retail-checkout-store';
import retailOrderHistory from './retail-order-history-store';
import digitalConfig from './digital-config';
import multiFinance from './multi-finance';
import zakat from './zakat-store'
import tvKabel from './tv-kabel-store'
import telkom from './telkom-store'
import inAppNotification from './in-app-notification-store';
import keretaStore from './kereta-store';
import gamificationStore from './gamificationStore'
import profitAndLossStore from './profit-and-loss-store'
import xRegionStore from './x-region-store'
import loyaltyStore from './loyalty-store'
import paylaterStore from './paylater-store'
import dropshipStore from './dropship-store'
import ewalletTopup from './ewallet-topup-store'
import digitalProductStore from './digital-product-store'
import raffleStore from './raffle-store'
import surveyStore from './survey-store'
import flipStore from './flip-store'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    root,
    authStore,
    notificationStore,
    profileStore,
    gamificationStore,
    homepageStore,
    pulsa: pulsaStore,
    digitalCart,
    packageData,
    pln,
    blipay,
    pdam,
    gameVoucher,
    digitalOrder,
    cashoutStore,
    orderHistory,
    retailCheckoutStore,
    retailOrderHistory,
    digitalConfig,
    multiFinance,
    inAppNotification,
    zakat,
    tvKabel,
    telkom,
    keretaStore,
    profitAndLossStore,
    xRegionStore,
    loyaltyStore,
    paylaterStore,
    dropshipStore,
    ewalletTopup,
    digitalProductStore,
    raffleStore,
    surveyStore,
    flipStore
  }
});
