import digitalApi from '@/api/digitalApis'

export default {
  state: {
    config: {
      mitrapay_max_topup_amount: 10000000,
      mitrapay_min_topup_amount: 10000,
      minimum_number_length_phone_credit: 10,
      maximum_number_length_phone_credit: 15,
      minimum_number_length_water_bill: 4,
      maximum_number_length_water_bill: 20,
      minimum_number_length_bpjs: 4,
      maximum_number_length_bpjs: 16,
      minimum_number_length_electricity_credit: 10,
      maximum_number_length_electricity_credit: 12,
      minimum_number_length_electricity_postpaid: 10,
      maximum_number_length_electricity_postpaid: 12,
      zakat_fitrah_1kg_rice_price: '16000',
      minimum_amount_money_transfer: 10000,
      maximum_amount_money_transfer: 100000000
    }
  },
  getters: {
    config (state) {
      return state.config
    }
  },
  mutations: {
    setConfig (state, config) {
      state.config = {
        ...state.config,
        ...config
      }
    }
  },
  actions: {
    getConfig ({ commit, dispatch }, configNames) {
      digitalApi.getConfig(
        response => {
          const { data } = response.data;
          if (typeof data === 'string') {
            let responseObj = {};
            try {
              responseObj = data && typeof data === 'string' ? JSON.parse(data) : responseObj
            } catch (e) {
              console.log(e)
            }
            if (responseObj.code === 401) {
              const maintenanceLink = response.headers.maintenance || '';
              if (!maintenanceLink) {
                dispatch('GO_TO_HOME_PAGE', { path: '/mitra-home' }, { root: true });
              }
            }
          } else {
            commit('setConfig', response.data.data.configData)
          }
        },
        error => {
          if (error.response.data && error.response.data.code === 401) {
            const maintenanceLink = error.response.headers.maintenance || '';
            if (!maintenanceLink) {
              dispatch('GO_TO_HOME_PAGE', { path: '/mitra-home' }, { root: true });
            }
          }
        },
        configNames
      )
    }
  },
  namespaced: true
}
