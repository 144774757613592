<template>
  <div :class="isStep2HomeOnBoardingStatus ? 'b-scrim' : ''">
    <div :class="['bottom-navbar', 'slide-up', isStep2HomeOnBoardingStatus ? '' : 'navbar-border']">
      <div
        v-for="(item, index) in navItems"
        :key="index"
        :class="['nav-item', isActive(item) ? 'active': 'in-active']"
        @click="selectThisTab(item)"
      >
        <BliIconSiHome
          v-if="item.label === 'HOME'"
          :fill=" isActive(item) ? '#0072FF' : '#797F85'"
          width="24"
          height="24"
        />
        <div v-else-if="isStep2HomeOnBoardingStatus && item.label === 'ISI_STOK'">
          <BliOnboardingTooltip
            :is-active="isStep2HomeOnBoardingStatus"
            with-mask
          >
            <template
              #onboarding-tooltip-highlight
            >
              <div class="onboarding-retail-section">
                <BliIconSiLocationWarehouse
                  fill="#797F85"
                  width="24"
                  height="24"
                />
                <div
                  class="font-14 text text_inactive"
                >
                  {{ $i18n('ISI_STOK') }}
                </div>
              </div>
            </template>
            <template #onboarding-tooltip-title>
              {{ $i18n("HOME_PAGE_ONBOARDING.STEP2.TITLE") }}
            </template>
            <template #onboarding-tooltip-desc>
              {{ $i18n("HOME_PAGE_ONBOARDING.STEP2.DESCRIPTION") }}
            </template>
            <template #onboarding-tooltip-action>
              <div class="flex">
                <div class="onboarding-stepper">
                  <span class="font-subtitle-2">{{ `${onboardingStep} ` }}</span>
                  <span class="neutral-text-med">{{ $i18n('HOME_PAGE_ONBOARDING.TOTAL_STEPS') }}</span>
                </div>
                <BliButton
                  size="small"
                  prominence="tertiary"
                  class="margin-right-2"
                  @click="(event) => showSkipOnboardingModal(event)"
                >
                  {{ $i18n('HOME_PAGE_ONBOARDING.CLOSE') }}
                </BliButton>
                <BliButton
                  size="small"
                  @click="(event) => goToOnboardingStep3(event)"
                >
                  {{ $i18n('HOME_PAGE_ONBOARDING.CONTINUE') }}
                </BliButton>
              </div>
            </template>
          </BliOnboardingTooltip>
        </div>
        <div
          v-else-if="item.label === 'ISI_STOK' && !isStep2HomeOnBoardingStatus"
          class="flex"
        >
          <BliBadge
            v-if="getTotalQuantity && isQuickOrderConfigEnabled"
            notification
            class="cart-count"
          >
            {{ numberOFCartItems }}
          </BliBadge>
          <BliIconSiLocationWarehouse
            :fill=" isActive(item) ? '#0072FF' : '#797F85'"
            width="24"
            height="24"
          />
        </div>
        <BliIconSiOrder
          v-else-if="item.label === 'ORDER'"
          :fill=" isActive(item) ? '#0072FF' : '#797F85'"
          width="24"
          height="24"
        />
        <BliIconSiCustomerService
          v-else-if="item.label === 'HELP'"
          :fill=" isActive(item) ? '#0072FF' : '#797F85'"
          width="24"
          height="24"
        />
        <BliIconSiPeopleProfile
          v-else
          :fill=" isActive(item) ? '#0072FF' : '#797F85'"
          width="24"
          height="24"
        />
        <div
          v-if="(isStep2HomeOnBoardingStatus && item.label !== 'ISI_STOK') || !isStep2HomeOnBoardingStatus"
          :class="['font-14', 'text', isActive(item) ? 'text_active' : 'text_inactive' ]"
        >
          {{ $i18n(item.label) }}
        </div>
      </div>
    </div>
    <Modals v-if="shouldDisplayModalsInNavigationBar" />
    <FullScreenPopupWrapper v-if="shouldDisplayModalsInNavigationBar" />
  </div>
</template>
<script src="./navigation-bar.js"></script>
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";

.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  background-color: $blu-color-neutral-background-default;
  display: flex;
  padding: 0 12px;
  border-bottom: 1px solid $blu-color-neutral-border-low;
  .nav-item {
    padding: 10px 0px;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      margin-top: 2px;
      line-height: 15px;
      &_active {
        color: $blu-color-info-text-default;
        font: $blu-text-subtitle-2;
      }
      &_inactive {
        color: $blu-color-neutral-text-med;
        font: $blu-text-body-2;
      }
    }
  }
  .nav-item.active {
    background-color: #F3F9FF;
    border-top: 4px solid #1D8FFF;
  }
  .nav-item.in-active {
   border-top: 4px solid #ffff;
  }
}

.navbar-border {
  border-top: 1px solid $blu-color-neutral-border-low;
}

.onboarding-retail-section {
  background-color: $blu-color-neutral-background-default;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 10px;
  height: 60px;
  margin-top: -10px;
}

.onboarding-stepper {
  position: absolute;
  left: 16px;
  bottom: 24px;
  font-size: 14px;
}

@media only screen and (min-width: 500px) {
  .bottom-navbar  {
    max-width: 420px;
    margin: 0 auto;
    left: auto;
  }
}

:deep .onboarding-tooltip-wrapper {
  :deep .onboarding-tooltip {
    position: fixed;
    bottom: 80px;
  }
}

.cart-count {
  position: absolute;
  margin-left: -10px;
}
</style>
