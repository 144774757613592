import BliModal from '@blibli/blue.modal/dist/modal/blue/Modal'
import BliModalHeader from '@blibli/blue.modal/dist/modal/blue/ModalHeader'
import BliModalBody from '@blibli/blue.modal/dist/modal/blue/ModalBody'
import BliModalFooter from '@blibli/blue.modal/dist/modal/blue/ModalFooter'
import BliButton from '@blibli/blue.button/dist/button/blue/Button'
import { homepageStore } from '@vue3/store/homepage-store'
import { mapState } from 'pinia'

export default {
  name: 'AutoReplenishment',
  components: {
    BliModal,
    BliModalHeader,
    BliModalBody,
    BliModalFooter,
    BliButton
  },
  props: {
    enablePopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(homepageStore, ['autoSuggestedProducts'])
  },
  created () {
    this.$trackSectionView('MitraAutoReplenishmentImpression')
  },
  methods: {
    goToCart () {
      this.$router.push({ path: '/confirm-order', query: { isAutoReplenishment: true } })
      this.closePopup()
    },
    closePopup () {
      this.$emit('closeAutoReplenishmentPopup')
    }
  }
}

