<template>
  <div v-if="currentModalProperties.status">
    <BliModal
      type="status"
      :active="isModalVisible"
      :status="currentModalProperties.status"
      @blur="currentModalProperties.maskClick"
    >
      <BliModalHeader @close="currentModalProperties.maskClick">
        <template #title>
          {{ currentModalProperties.headerText }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        {{ currentModalProperties.bodyText }}
      </BliModalBody>
      <div class="modal-footer">
        <div
          v-for="(control, index) in currentModalProperties.controls"
          :key="index"
        >
          <BliButton
            :prominence="control.prominence"
            color="common"
            full-width
            @click="control.handler"
          >
            {{ control.label }}
          </BliButton>
        </div>
      </div>
    </BliModal>
  </div>
</template>
<script src="./modals.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
.modal-footer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
}
</style>
