import { defineStore } from 'pinia'
import api from '@vue3/api/profileApis'
import errorHandler from '@vue3/api/apiUtils/errorHandler';
import { root } from '@vue3/store/root'

export const profileStore = defineStore('profileStore', {
  state: () => {
    return {
      membersData: {},
      isMemberFetched: false,
      inProgressMemberData: {},
      userPin: '',
      ocrDetails: {
        firstName: '',
        lastName: '',
        fullName: '',
        placeOfBirth: '',
        dateOfBirth: '',
        gender: '',
        ktpNumber: ''
      },
      ktpImage: '',
      profileImage: '',
      ktpLoader: false,
      profileLoader: false,
      storeImageLoader: false,
      storeImageUrl: '',
      storeImageFileName: '',
      storeImageChanged: false,
      rejectionTitleAndReasons: [],
      imageCaptureProps: {},
      isStoreImageUploadedNow: false,
      referralData: {},
      rewardDetails: {},
      referralHistoryList: [],
      isFetchingReferralDetails: false,
      isFetchingRewardDetails: false,
      referralHistoryPaging: null,
      disableAccountDeletion: false
    }
  },
  actions: {
    GET_MEMBER_DETAILS ({ success, payload }) {
      this.isMemberFetched = false
      api.getMemberDetails(
        response => {
          this.isMemberFetched = true
          if (response.data.code === 200) {
            this.membersData = response.data.data
            window.dispatchEvent(new CustomEvent('update.vuex.state.from.pinia', { detail: { eventType: 'MEMBER_DATA', data: this.membersData } }))
            if (response.data.data.memberDetails) {
              const { verificationStatus, memberId, mitraPayRegistrationStatus } = response.data.data.memberDetails;
              if (verificationStatus === 'REJECTED' || mitraPayRegistrationStatus === 'REJECTED') {
                this.GET_REJECTED_REASONS({
                  payload: {
                    memberId
                  }
                })
              }
              success && success(response.data.data);
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          this.isMemberFetched = true
          errorHandler.handleErrors(error);
        },
        payload
      )
    },
    GET_STORE_TYPE ({ params, payload, success }) {
      api.getStoreTypeList(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        params,
        payload
      );
    },
    REGISTER_MEMBER_DETAIL ({ success, fail, params, payload }) {
      api.memberRegistration(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail && fail(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        payload,
        params
      );
    },
    GET_MEMBER_IN_PROGRESS ({ success, params, payload } = {}) {
      api.getMemberInProgress(
        response => {
          if (response.data.code === 200) {
            this.inProgressMemberData = response.data.data
            success && success(response.data.data);
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        payload,
        params
      );
    },
    SET_IMAGE_CAPTURE_PROPS ({ callbacks }) {
      this.imageCaptureProps = callbacks
    },
    SET_KTP_IMAGE (value) {
      this.ktpImage = value
    },
    SET_PROFILE_IMAGE (value) {
      this.profileImage = value
    },
    SET_KTP_LOADER (value) {
      this.ktpLoader = value
    },
    SET_PROFILE_LOADER (value) {
      this.profileLoader = value
    },
    UPLOAD_KTP_PROFILE ({ success, params, payload, fail } = {}) {
      api.uploadKTP(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response)
          }
        },
        error => {
          errorHandler.handleErrors(error)
        },
        params,
        payload
      );
    },
    SET_OCR_DETAILS (value) {
      this.ocrDetails = value
    },
    DOWNLOAD_KTP_PROFILE ({ success, payload, params } = {}) {
      api.downloadKTP(
        response => {
          // btoa for converting byte stream to string
          var base64 = btoa(new Uint8Array(new Uint8Array(response.data)).reduce(
            function (data, byte) {
              return data + String.fromCharCode(byte);
            },
            ''
          ));
          const base64encoded = `data:${response.headers['content-type']};base64,${base64}`;
          success(base64encoded);
        },
        error => {
          errorHandler.handleErrors(error)
        },
        payload,
        params
      );
    },
    WALLET_REGISTRATION ({ success, fail, params, payload } = {}) {
      api.walletRegistration(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail && fail(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(error)
        },
        payload,
        params
      );
    },
    GET_REJECTED_REASONS ({ payload, fail } = {}) {
      api.getRejectedReasons(
        response => {
          if (response.data.code === 200) {
            let questionsList = response.data.data;
            const rejectedReasons = questionsList.filter(question => question.answerDetails && question.answerDetails.answerText === 'NO')
              .map(reason => {
                const { answerDetails, questionPwaText, questionPwaTextBahasa, id } = reason;
                return { answerDetails, questionPwaText, questionPwaTextBahasa, id }
              });
            this.rejectionTitleAndReasons = rejectedReasons
          } else {
            root().TRIGGER_ERROR_TOAST(response.data.message)
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        }, error => {
          fail && fail(error)
          errorHandler.handleErrors(error);
        },
        payload
      )
    },
    GENERATE_OTP ({ success, params, payload, fail } = {}) {
      api.generateOtp(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response.data.errors);
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          fail(error);
          errorHandler.handleErrors(error);
        },
        payload,
        params
      );
    },
    UPDATE_PIN ({ success, params, payload } = {}) {
      api.updatePin(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.error === null ||
              response.data.error === undefined
            ) {
              success(response.data);
            } else {
              root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
              setTimeout(() => {
                root().RESET_ERROR_TOAST()
              }, 3000)
            }
          } else {
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        params,
        payload
      );
    },
    VERIFY_OTP ({ success, params, payload, fail } = {}) {
      api.verifyOtp(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            fail(response.data.data);
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        params,
        payload
      );
    },
    ONGOING_MISSION_NOTIFICATIONS_FOR_NEW_USER ({ success, error, payload } = {}) {
      api.registerForOnGoingMissionAndNotifications(
        success,
        error,
        payload
      );
    },
    GET_MEMBER_ONBOARDING_STATUS ({ success, fail, payload, params }) {
      api.getMemberOnboardingStatus((response) => {
        if (response.data.code === 200) {
          success(response.data.data)
        } else {
          fail()
        }
      }, fail, payload, params)
    },
    UPDATE_MEMBER_ONBOARDING_STATUS ({ success, fail, payload, params }) {
      api.updateMemberOnboardingStatus((response) => {
        if (response.data.code === 200) {
          success && success()
        } else {
          fail && fail()
        }
      }, () => {
        fail && fail()
      }, payload, params)
    },
    ACCOUNT_DELETION_VERIFY_PIN ({ success, error, payload }) {
      api.verifyPinForAccountDeletion(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else {
            error && error(response.data.errors)
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        payload
      );
    },
    GET_ACCOUNT_DELETION_DETAILS ({ payload, success, fail }) {
      api.accountDeletionDetails(response => {
        if (response.status === 200) {
          success && success(response.data.data)
        } else {
          fail && fail(response.data)
        }
      }, error => {
        fail && fail()
        errorHandler.handleErrors(error);
      }, payload)
    },
    ACCOUNT_DELETION_REQUEST ({ payload, success, fail }) {
      this.disableAccountDeletion = true
      api.accountDeletionRequest(response => {
        if (response.data.code === 200) {
          if (!response.data.errors) {
            success && success(response.data.data)
          }
        } else {
          fail && fail(response.data)
        }
      }, error => {
        fail && fail()
        errorHandler.handleErrors(error, true);
      }, payload)
    },
    GENERATE_OTP_DELETE_ACCOUNT ({ success, params, payload, fail }) {
      api.generateOtpForAccountDeletion(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response.data.errors);
            root().TRIGGER_ERROR_TOAST('SYSTEM_ERROR')
            setTimeout(() => {
              root().RESET_ERROR_TOAST()
            }, 3000)
          }
        },
        error => {
          fail && fail(error);
          errorHandler.handleErrors(error);
        },
        payload,
        params
      );
    },
    VERIFY_KTP ({ success, fail, params, payload }) {
      api.verifyKtp(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.error === null ||
              response.data.error === undefined
            ) {
              success(response.data.data);
            } else {
              fail && fail(response.data)
            }
          }
        },
        error => {
          errorHandler.handleErrors(error);
        },
        params,
        payload
      );
    },
    SET_DISABLE_ACCOUNT_DELETION_FALSE () {
      this.disableAccountDeletion = false;
    }
  }
})
