import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getMitraSession (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getMitraSessionInfo,
      callback,
      fail,
      params
    );
  },
  logoutApp (callback, fail) {
    apiCall.makePostRequest(apiUrls.api.logoutUrl, callback, fail)
  },
  sendTokenToServer (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.sendFcmTokenToServer(), callback, fail, payload)
  },
  deleteTokenFromServer (callback, fail, params) {
    apiCall.makeDeleteRequest(apiUrls.api.deleteToken(), callback, fail, params)
  }
};
