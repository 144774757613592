import api from '@/api/homepageApis';
import errorHandler from '../api/apiUtils/errorHandler';
import cmsApi from '@/api/cms-api';
import { isEmpty, checkIfConfigNotAvailable, setAppConfiguration,
  getAppConfiguration, getCondensedBannersConfigResponse,
  getCondensedProductsConfigResponse } from '@/utils/helpers';

export default {
  state: {
    banners: [],
    products: [],
    walletRequest: {},
    isWalletRequestFetched: false,
    configs: {},
    walletTransactions: [],
    whatsNewInfo: {},
    npsDetails: {},
    autoSuggestedProducts: [],
    frequentlyAskedQuestions: null,
    avatarsConfig: null,
    memberBanners: null,
    vaInstruction: null,
    vaPaymentInstruction: null,
    referralFAQ: null,
    twaVersion: null,
    onboardingStatus: '',
    modalInfo: {},
    popupInfo: {},
    fullScreenPopupInfo: {},
    isRaffleSpecialProgramSectionDisplayed: false,
    createObserverIfApplicable: false,
    npsRating: 0,
    contestsDashboard: {},
    maintainencePopup: {},
    walletRequestError: false,
    walletRequestLoader: false,
    quickOrderDetails: [],
    displayWalletInfoPopup: false,
    deleteAccountConfig: {}
  },
  mutations: {
    setProducts (state, product) {
      state.products = product;
    },
    setBanners (state, banners) {
      state.banners = banners;
    },
    setWalletRequest (state, walletRequest) {
      window.dispatchEvent(new CustomEvent('update.pinia.state.from.vuex', { detail: { eventType: 'WALLET_REQUEST', data: walletRequest } }))
      state.walletRequest = walletRequest;
    },
    setWalletRequestFromPinia (state, walletRequest) {
      state.walletRequest = walletRequest;
    },
    setConfigs (state, configs) {
      state.configs = Object.assign({}, state.configs, configs)
    },
    setWalletTransactions (state, transactions) {
      state.walletTransactions = transactions;
    },
    setWhatsNewInfo (state, whatsNewInfo) {
      state.whatsNewInfo = whatsNewInfo;
    },
    setNPSdetails (state, npsDetails) {
      state.npsDetails = npsDetails;
    },
    setAutoSuggestedProducts (state, products) {
      state.autoSuggestedProducts = products
    },
    resetAutoSuggestedProducts (state) {
      state.autoSuggestedProducts = []
    },
    setFrequentlyAskedQuestions (state, faqList) {
      state.frequentlyAskedQuestions = faqList
    },
    setAvatarsConfig (state, avatarsConfig) {
      state.avatarsConfig = avatarsConfig
    },
    setMemberBanners (state, memberBanners) {
      state.memberBanners = memberBanners
    },
    setVaInstruction (state, vaInstruction) {
      state.vaInstruction = vaInstruction
    },
    setVaPaymentInstruction (state, vaPaymentInstruction) {
      state.vaPaymentInstruction = vaPaymentInstruction
    },
    setReferralFAQ (state, referralFAQ) {
      state.referralFAQ = referralFAQ
    },
    setTwaVersion (state, version) {
      state.twaVersion = version
    },
    setOnboardingStatus (state, value) {
      state.onboardingStatus = value
    },
    setWalletInfo (state, value) {
      state.displayWalletInfoPopup = value
    },
    setModalInfo (state, modalInfo) {
      const { key, properties = {} } = modalInfo
      state.modalInfo = {
        key,
        properties
      }
    },
    setPopupInfo (state, popupInfo) {
      const { key, properties = {} } = popupInfo
      state.popupInfo = {
        key,
        class: '',
        title: '',
        ...properties
      }
    },
    setFullScreenPopupInfo (state, fullScreenPopupInfo) {
      const { key, properties = {} } = fullScreenPopupInfo
      state.fullScreenPopupInfo = {
        key,
        class: '',
        title: '',
        ...properties
      }
    },
    setIsRaffleSpecialProgramSectionDisplayed (state, value) {
      state.isRaffleSpecialProgramSectionDisplayed = value
    },
    setCreateObserverIfApplicable (state, value) {
      state.createObserverIfApplicable = value
    },
    setNpsRating (state, value) {
      state.npsRating = value
    },
    setContestsDashboard (state, value) {
      state.contestsDashboard = value;
    },
    setMaintainencePopup (state, maintainencePopup) {
      const { isMaintainencePopupVisible, date, time } = maintainencePopup
      state.maintainencePopup = {
        isMaintainencePopupVisible,
        date,
        time
      }
    },
    setWalletRequestError (state, value) {
      state.walletRequestError = value;
    },
    setWalletRequestLoader (state, value) {
      state.walletRequestLoader = value;
    },
    setQuickOrderDetails (state, value) {
      state.quickOrderDetails = value
    },
    setIsWalletRequestFetched (state, value) {
      state.isWalletRequestFetched = value
    },
    setDeleteAccountConfig (state, value) {
      state.deleteAccountConfig = value
    }
  },
  getters: {
    getBanners (state) {
      return state.banners;
    },
    getProducts (state) {
      return state.products;
    },
    getWalletRequest (state) {
      return state.walletRequest;
    },
    getConfigs (state) {
      return state.configs;
    },
    getWalletTransactions (state) {
      return state.walletTransactions;
    },
    getWhatsNewInfo (state) {
      return state.whatsNewInfo;
    },
    getNPSdetails (state) {
      return state.npsDetails;
    },
    getAutoSuggestedProducts (state) {
      return state.autoSuggestedProducts
    },
    getFrequentlyAskedQuestions (state) {
      return state.frequentlyAskedQuestions
    },
    getAvatarsConfig (state) {
      return state.avatarsConfig
    },
    getMemberBanners (state) {
      return state.memberBanners;
    },
    vaInstruction (state) {
      return state.vaInstruction
    },
    vaPaymentInstruction (state) {
      return state.vaPaymentInstruction
    },
    getTwaVersion (state) {
      return state.twaVersion
    },
    getOnboardingStatus (state) {
      return state.onboardingStatus
    },
    getModalInfo (state) {
      return state.modalInfo
    },
    getWalletInfo (state) {
      return state.displayWalletInfoPopup
    },
    getPopupInfo (state) {
      return state.popupInfo
    },
    getFullScreenPopupInfo (state) {
      return state.fullScreenPopupInfo
    },
    getIsRaffleSpecialProgramSectionDisplayed (state) {
      return state.isRaffleSpecialProgramSectionDisplayed
    },
    getCreateObserverIfApplicable (state) {
      return state.createObserverIfApplicable
    },
    getNpsRating (state) {
      return state.npsRating
    },
    getContestsDashboard (state) {
      return state.contestsDashboard;
    },
    getMaintainencePopup (state) {
      return state.maintainencePopup
    },
    isWalletRequestError (state) {
      return state.walletRequestError
    },
    getWalletRequestLoader (state) {
      return state.walletRequestLoader
    },
    getQuickOrderDetails (state) {
      return state.quickOrderDetails
    },
    getReferralFAQ (state) {
      return state.referralFAQ
    },
    isWalletRequestFetched (state) {
      return state.isWalletRequestFetched
    },
    getDeleteAccountConfig (state) {
      return state.deleteAccountConfig
    }
  },
  actions: {
    GET_MEMBER_BANNERS ({ commit, dispatch }, { payload }) {
      api.getMemberBanners(
        response => {
          if (response.data.code === 200) {
            commit('setMemberBanners', response.data.data);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_BANNERS_AND_SERVICES ({ commit, dispatch }) {
      api.getServicesPromotion(
        response => {
          if (response.data.code === 200) {
            const mainResponse = response.data.data[0];
            const bannerConfig = getCondensedBannersConfigResponse(mainResponse.blocks[0].components)
            const productConfig = getCondensedProductsConfigResponse(mainResponse.blocks[1].components)
            commit('setBanners', bannerConfig);
            commit('setProducts', productConfig);
            setAppConfiguration('mitraProductsConfig', JSON.stringify(productConfig))
            setAppConfiguration('mitraBannersConfig', JSON.stringify(bannerConfig))
          } else {
            const getProductsConfig = getAppConfiguration('mitraProductsConfig')
            const getBannersConfig = getAppConfiguration('mitraBannersConfig')
            if (!getProductsConfig || !getBannersConfig) {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            } else {
              dispatch('SET_CONFIGS', { typeOfConfiguration: 'mitraBannersConfig', configs: JSON.parse(getBannersConfig) })
              dispatch('SET_CONFIGS', { typeOfConfiguration: 'mitraProductsConfig', configs: JSON.parse(getProductsConfig) })
            }
          }
        },
        error => {
          const getProductsConfig = getAppConfiguration('mitraProductsConfig')
          const getBannersConfig = getAppConfiguration('mitraBannersConfig')
          if (!getProductsConfig || !getBannersConfig) {
            errorHandler.handleErrors(dispatch, error);
          } else {
            dispatch('SET_CONFIGS', { typeOfConfiguration: 'mitraBannersConfig', configs: JSON.parse(getBannersConfig) })
            dispatch('SET_CONFIGS', { typeOfConfiguration: 'mitraProductsConfig', configs: JSON.parse(getProductsConfig) })
          }
        }
      );
    },
    SET_WALLET_REQUEST ({ commit }, walletResponse) {
      commit('setWalletRequest', walletResponse)
    },
    GET_WALLET_REQUEST ({ state, commit, dispatch }, { payload }) {
      commit('setWalletRequestLoader', true);
      commit('setIsWalletRequestFetched', false);
      api.getWalletRequestData(
        response => {
          commit('setWalletRequestError', false);
          commit('setWalletRequestLoader', false);
          commit('setIsWalletRequestFetched', true);
          if (response.data.code === 200) {
            commit('setWalletRequest', response.data.data);
          } else if (response.data.code !== 200) {
            commit('setWalletRequestError', true);
          } else {
            if (isEmpty(state.walletRequest)) {
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          }
        },
        error => {
          commit('setIsWalletRequestFetched', true);
          commit('setWalletRequestLoader', false);
          commit('setWalletRequest', {});
          commit('setWalletRequestError', true);
          errorHandler.handleErrors(dispatch, error, true);
        },
        payload
      );
    },
    GET_WALLET_TRANSACTIONS ({ commit, dispatch }, { payload, success, fail }) {
      api.getWalletTransaction(response => {
        if (response.data.code === 200) {
          commit('setWalletTransactions', response.data.data);
          success()
        } else {
          if (fail) {
            fail()
          }
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      }, error => {
        fail();
        errorHandler.handleErrors(dispatch, error);
      }, payload)
    },
    GET_APP_CONFIGURATION ({ commit, dispatch }, { typeOfConfiguration, success, fail }) {
      cmsApi.getConfig(response => {
        if (response.data.code === 200) {
          if (typeOfConfiguration === 'mitraWhatsNew') {
            commit('setWhatsNewInfo', JSON.parse(response.data.data));
          } else if (typeOfConfiguration === 'mitraOrdersList') {
            window.localStorage.setItem('orderList', JSON.stringify(response.data.data));
          } else if (typeOfConfiguration === 'mitraFaqList') {
            commit('setFrequentlyAskedQuestions', response.data.data)
          } else if (typeOfConfiguration === 'mitraAvatarsConfig') {
            commit('setAvatarsConfig', JSON.parse(response.data.data))
          } else if (typeOfConfiguration === 'mitraMaintenanceConfig') {
            window.localStorage.setItem('maintenanceConfig', response.data.data);
          } else if (typeOfConfiguration === 'mitraVaInstruction') {
            commit('setVaInstruction', JSON.parse(response.data.data))
          } else if (typeOfConfiguration === 'mitraPwaConfiguration' || typeOfConfiguration === 'mitraCashoutTerms') {
            commit('setConfigs', JSON.parse(response.data.data));
          } else if (typeOfConfiguration === 'mitraVaPaymentInstruction') {
            commit('setVaPaymentInstruction', JSON.parse(response.data.data))
          } else if (typeOfConfiguration === 'mitraReferralFAQ') {
            commit('setReferralFAQ', JSON.parse(response.data.data))
          } else if (typeOfConfiguration === 'mitraReferralConfig') {
            setAppConfiguration('referralConfig', (response.data.data))
          } else if (typeOfConfiguration === 'mitraAccountDeleteConfig') {
            commit('setDeleteAccountConfig', JSON.parse(response.data.data))
          }
          success && success(response.data.data)
        }
      },
      error => {
        if (checkIfConfigNotAvailable(typeOfConfiguration)) {
          errorHandler.handleErrors(dispatch, error);
        }
        fail && fail()
      },
      typeOfConfiguration
      )
    },
    UPDATE_MEMBER_DETAILS ({ dispatch }, { payload, success }) {
      api.updateMemberDetails(
        response => {
          if (response.data.code === 200) {
            success(response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    UPDATE_MEMBER_DETAILS_NEW_REGISTRATION ({ dispatch }, { payload, success, fail }) {
      api.updateMemberDetailsForNewRegistration(
        response => {
          const responseData = response.data
          const responseError = response.data.errors
          if (responseData.code === 200) {
            success(responseData.data);
          } else if (responseError?.verificationStatus || responseError?.mitraId[0] === 'EXISTS') {
            fail && fail(responseError)
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: responseError
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_NPS ({ commit, dispatch }, { payload }) {
      api.getNPS(
        response => {
          if (response.data.code === 200) {
            commit('setNPSdetails', response.data.data);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    SUBMIT_NPS ({ dispatch }, { payload, success, fail }) {
      api.submitNPS(
        response => {
          if (response.data.code === 200) {
            if (
              response.data.errors === null ||
              response.data.errors === undefined
            ) {
              success && success(response.data.data);
            } else {
              fail && fail()
              dispatch('SET_ERROR_POPUP', {
                isErrorPopupVisible: true,
                errorList: response.data.errors
              }, { root: true });
            }
          } else {
            fail && fail()
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
          fail && fail()
        },
        payload
      );
    },
    GET_AUTO_SUGGESTED_PRODUCTS ({ dispatch, commit }, params) {
      api.getAutoSuggestedProducts(
        response => {
          if (response.data.code === 200) {
            commit('setAutoSuggestedProducts', response.data.data.orderItems);
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true);
        },
        params
      );
    },
    RESET_AUTO_SUGGESTED_PRODUCTS ({ commit }) {
      commit('resetAutoSuggestedProducts');
    },
    SAVE_INSTALLED_APPS_DATA (_, { payload, success }) {
      api.saveInstalledAppData(response => {
        if (response.data.code === 200) {
          success(response.data.data)
        }
      }, error => {
        console.log('err:', error)
      },
      payload
      );
    },
    SET_TWA_VERSION ({ commit }, version) {
      commit('setTwaVersion', version)
    },
    SET_CONFIGS ({ commit }, { typeOfConfiguration, configs }) {
      if (typeOfConfiguration === 'mitraPwaConfiguration' || typeOfConfiguration === 'mitraCashoutTerms') {
        commit('setConfigs', configs)
      } else if (typeOfConfiguration === 'mitraVaInstruction') {
        commit('setVaInstruction', configs)
      } else if (typeOfConfiguration === 'mitraProductsConfig') {
        commit('setProducts', configs);
      } else if (typeOfConfiguration === 'mitraBannersConfig') {
        commit('setBanners', configs);
      } else if (typeOfConfiguration === 'mitraVaPaymentInstruction') {
        commit('setVaPaymentInstruction', configs)
      } else if (typeOfConfiguration === 'mitraAccountDeleteConfig') {
        commit('setDeleteAccountConfig', configs)
      }
    },
    SET_HOME_ONBOARDING_STATUS ({ commit }, status) {
      commit('setOnboardingStatus', status)
    },
    SET_MODAL_INFO ({ commit }, modalInfo) {
      commit('setModalInfo', modalInfo)
    },
    SET_POPUP_INFO ({ commit }, popupInfo) {
      commit('setPopupInfo', popupInfo)
    },
    SET_FULL_SCREEN_POPUP_INFO ({ commit }, fullScreenPopupInfo) {
      commit('setFullScreenPopupInfo', fullScreenPopupInfo)
    },
    SET_RAFFLE_SPECIAL_PROGRAM_SECTION_DISPLAYED ({ commit }) {
      commit('setIsRaffleSpecialProgramSectionDisplayed', true)
    },
    CREATE_OBSERVER ({ commit }, value) {
      commit('setCreateObserverIfApplicable', value)
    },
    SET_NPS_RATING ({ commit }, value) {
      commit('setNpsRating', value)
    },
    GET_CONTESTS_DASHBOARD ({ commit, dispatch }, { payload }) {
      api.getContestsDashboard(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            commit('setContestsDashboard', response.data.data);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true)
        },
        payload
      );
    },
    GET_QUICK_ORDER_DETAILS ({ commit, dispatch }, params) {
      api.getQuickOrderDetails(
        response => {
          if (response.data.code === 200 && !response.data.errors) {
            commit('setQuickOrderDetails', response.data.data.orders)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true);
        },
        params
      );
    }
  },
  namespaced: true
};
