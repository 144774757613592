import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getAllPopularStations (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getAllPopularStation,
      callback,
      fail,
      params
    );
  },
  getAllStations (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getAllStation,
      callback,
      fail,
      params
    );
  },
  searchTrains (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.searchTrains,
      callback,
      fail,
      params
    );
  },
  addTrainToCart (callback, fail, params, payload) {
    apiCall.makePutRequest(
      apiUrls.api.addTrainsToCart,
      callback,
      fail,
      payload,
      params
    );
  },
  getTrainCartData (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getTrainCart,
      callback,
      fail,
      params
    );
  },
  addPassengerToTrainCart (callback, fail, params, payload) {
    apiCall.makePutRequest(
      apiUrls.api.addPassengerToCart,
      callback,
      fail,
      payload,
      params
    );
  },
  checkoutCurrentCart (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.checkoutCart,
      callback,
      fail,
      payload,
      params
    );
  },
  bookCurrentCart (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.bookCart,
      callback,
      fail,
      payload,
      params
    );
  },
  checkoutOrder (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.checkoutCurrentOrder,
      callback,
      fail,
      payload,
      params
    );
  },
  getOrderDetails (callback, fail, params) {
    apiCall.makeGetRequest(apiUrls.api.trainOrderHistoryAndDetail, callback, fail, params);
  },
  getOrderHistoryOfKereta (callback, fail, params) {
    apiCall.makeGetRequest(apiUrls.api.trainOrderHistoryAndDetail, callback, fail, params);
  },
  payTrainOrder (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.trainPayOrder,
      callback,
      fail,
      payload,
      params
    );
  },
  pollCurrentOrderStatus (callback, fail, pathVariables) {
    apiCall.makeGetRequest(apiUrls.api.pollTrainOrderStatus(pathVariables.orderId), callback, fail);
  },
  getOrderDetailsWithBookingCode (callback, fail, pathVariables) {
    apiCall.makeGetRequest(apiUrls.api.trainOrderDetailWithBookingCode(pathVariables.orderId), callback, fail);
  },
  cancelCurrentOrder (callback, fail, pathVariables) {
    apiCall.makePostRequest(
      apiUrls.api.trainCancelOrder(pathVariables.orderId),
      callback,
      fail
    );
  },
  getSeatLayout (callback, fail, pathVariables) {
    apiCall.makeGetRequest(apiUrls.api.trainGetSeatLayout(pathVariables.orderId, pathVariables.railIndex),
      callback,
      fail
    )
  },
  changeSeat (callback, fail, payload, pathVariables) {
    apiCall.makePutRequest(apiUrls.api.trainChangeSeat(pathVariables.orderId, pathVariables.railIndex),
      callback,
      fail,
      payload
    )
  },
  getPromotionList (callback, fail, pathVariables) {
    apiCall.makeGetRequest(apiUrls.api.getTrainPromoList(pathVariables.orderId),
      callback,
      fail
    )
  },
  getPromotionDetails (callback, fail, params) {
    apiCall.makeGetRequest(apiUrls.api.getTrainPromoDetails,
      callback,
      fail,
      params
    )
  },
  applyTrainPromo (callback, fail, payload, pathVariables, params) {
    apiCall.makePutRequest(apiUrls.api.trainApplyPromo(pathVariables.orderId),
      callback,
      fail,
      payload,
      params
    )
  },
  unApplyTrainPromo (callback, fail, payload, pathVariables, params) {
    apiCall.makePutRequest(apiUrls.api.trainUnapplyPromo(pathVariables.orderId),
      callback,
      fail,
      payload,
      params
    )
  }
}
