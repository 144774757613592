import { getBothLanguageCopyText } from '@/i18n/lang'

export default {
  name: 'ErrorHandleNotifier',
  props: {
    isErrorHandleVisible: {
      required: false
    },
    errorHandleMessage: {
      required: false
    }
  },
  data () {
    return {
      errorsToShow: [],
      showErrorMessage: false,
      customControls: [{
        label: this.$i18n('OK'),
        handler: this.handleModalClose,
        outline: false
      }]
    };
  },
  beforeDestroy () {
    this.showErrorMessage = false;
    this.errorsToShow = [];
  },
  watch: {
    isErrorHandleVisible (newValue) {
      console.log('watch: isErrorPopupVisible', newValue);
      this.showErrorMessage = !!newValue;
    },
    errorHandleMessage (newValue, oldValue) {
      console.log('errorMessage', newValue, oldValue);
      this.errorsToShow = [];
      if (newValue.errorList) {
        this.errorsToShow = Object.entries(newValue.errorList);
      }
    },
    showErrorMessage (newVal) {
      if (newVal) {
        this.triggerErrorImpression()
      }
    }
  },
  methods: {
    handleModalClose () {
      console.log('******:', 'handleModalClose');
      this.showErrorMessage = false;
      this.$trackErrorClickEvent('COMMON_ERROR_MODAL', 'OK')
      this.$trackButtonClickHandler('COMMON_ERROR_MODAL', 'OK')
      this.$store.dispatch('REMOVE_ERROR_HANDLE_POPUP');
    },
    triggerErrorImpression () {
      let errorMessage = {
        copyTextInBahasa: '',
        copyTextInEnglish: ''
      }
      let tempErrorCopy = {}
      if (!this.errorsToShow.length) {
        errorMessage = getBothLanguageCopyText('ERR_MODAL_META')
      } else {
        this.errorsToShow.forEach(item => {
          tempErrorCopy = getBothLanguageCopyText(item[1][0])
          errorMessage.copyTextInBahasa += tempErrorCopy.copyTextInBahasa
          errorMessage.copyTextInEnglish += tempErrorCopy.copyTextInEnglish
        })
      }
      this.trackErrorNotifierClickEvent('COMMON_ERROR_MODAL', errorMessage)
    },
    trackErrorNotifierClickEvent (error_type, errorMessage) {
      const eventType = 'errorPopupImpression'
      const componentKeyName = 'MitraErrorImpression'
      const eventDetailObj = {
        error_type,
        error_message_bahasa: errorMessage.copyTextInBahasa,
        error_message_english: errorMessage.copyTextInEnglish
      }
      this.$pushTrackerInfoToDataLayer(eventType, componentKeyName, eventDetailObj)
    }
  }
};
