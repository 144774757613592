import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getPointsInfo (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getPointInfo,
      callback,
      fail,
      params
    );
  },
  getLevelBenefit (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.levelBenefits,
      callback,
      fail,
      params
    );
  },
  getLoyaltyMemberInfo (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getLoyaltyMember(),
      callback,
      fail,
      payload
    );
  },
  getAllAvailableGifts (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.getAllGifts, callback, fail, payload, params)
  },
  getLoyaltyPointHistory (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.loyaltyPointsHistory, callback, fail, payload, params)
  },
  getStockAvailability (callback, fail, payload) {
    apiCall.makePostRequest(apiUrls.api.checkStockAvailability, callback, fail, payload)
  },
  redeemGift (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.redeemGift, callback, fail, payload, params)
  },
  getRedeemedGiftsList (callback, fail, payload, params) {
    apiCall.makePostRequest(apiUrls.api.getAllRedeemedGifts, callback, fail, payload, params)
  }
}
