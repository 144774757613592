<template>
  <div>
    <BliModal
      mobile-fullscreen
      :active="enablePopup"
      @blur="closePopup"
    >
      <BliModalHeader @close="closePopup">
        <template #title>
          {{ $i18n('ACCOUNT_SUSPENDED_HEADING') }}
        </template>
      </BliModalHeader>
      <BliModalBody>
        <p class="font-subtitle-1 font-16 neutral-text-high">
          {{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT1') }}
        </p>
        <p class="font-body-2 font-14 neutral-text-med">
          {{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT2') }}
        </p>
        <ol class="font-body-2 font-14 neutral-text-med  padding-left-18 margin-top-0">
          <li>{{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT3') }}</li>
          <li>{{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT4') }}</li>
          <li>{{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT5') }}</li>
        </ol>
        <p class="font-subtitle-1 font-16 neutral-text-high margin-top-32">
          {{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT6') }}
        </p>
        <ol class="font-body-2 font-14 neutral-text-med  padding-left-18 margin-top-12">
          <li>{{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT7') }}</li>
          <li>{{ $i18n('HOME_PAGE.SUSPENSION_POPUP.TEXT8') }}</li>
        </ol>
      </BliModalBody>
      <BliModalFooter>
        <BliButton
          prominence="primary"
          color="common"
          @click="redirectToHelpPage"
        >
          {{ $i18n("CONTACT_US") }}
        </BliButton>
      </BliModalFooter>
    </BliModal>
  </div>
</template>
<script src="./suspension-popup.js" />
<style lang="scss" scoped>
@import "@vue3/assets/scss/index";
</style>
