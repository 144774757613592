import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getCartItems (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getCartItems, callback, fail, params);
  },
  deleteCartItem (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.deleteCartItem,
      callback,
      fail,
      payload,
      params
    );
  },
  updateItemQuantity (callback, fail, payload, params) {
    apiCall.makePutRequest(
      apiUrls.api.updateItemQuantity,
      callback,
      fail,
      payload,
      params
    );
  },
  createOrder (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.createOrder,
      callback,
      fail,
      payload
    );
  },
  getOrderByOrderId (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getOrderByOrderId,
      callback,
      fail,
      params
    );
  },
  getCategories (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getCategories,
      callback,
      fail,
      params
    );
  },
  getProductsList (callback, fail, pathVariables, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getProductsList(pathVariables.memberId),
      callback,
      fail,
      payload,
      params
    );
  },
  addItemToCart (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.addItemToCart,
      callback,
      fail,
      params,
      payload
    );
  },
  getLastOrder (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getLastOrder,
      callback,
      fail,
      params
    );
  },
  clearCart (callback, fail, params) {
    apiCall.makeDeleteRequest(
      apiUrls.api.clearCart,
      callback,
      fail,
      params
    );
  },
  getVouchersList (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.getVouchersList,
      callback,
      fail,
      params
    );
  },
  getVouchersDetails (callback, fail, params, pathVariables) {
    apiCall.makeGetRequest(
      apiUrls.api.getVouchersDetails(pathVariables),
      callback,
      fail,
      params
    );
  },
  applyVoucher (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.applyVoucher,
      callback,
      fail,
      params
    );
  },
  removeVoucher (callback, fail, params) {
    apiCall.makeGetRequest(
      apiUrls.api.removeVoucher,
      callback,
      fail,
      params
    );
  },
  subscribeForNotification (callback, fail, pathVariables, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.subscribeForNotification(pathVariables.memberId),
      callback,
      fail,
      payload,
      params
    );
  },
  markAsRead (callback, fail, params, payload) {
    apiCall.makePostRequest(
      apiUrls.api.markAsRead(),
      callback,
      fail,
      payload,
      params
    );
  },
  changePaymentMethod (callback, fail, pathVariables, params, payload) {
    apiCall.makePutRequest(
      apiUrls.api.changePaymentType,
      callback,
      fail,
      payload,
      params
    );
  },
  itemCheckboxUpdate (callback, fail, pathVariables, params, payload) {
    apiCall.makePutRequest(
      apiUrls.api.checkboxUpdate,
      callback,
      fail,
      payload,
      params
    )
  },
  updateShippingOption (callback, fail, payload, params) {
    apiCall.makePutRequest(
      apiUrls.api.updateShippingOption,
      callback,
      fail,
      payload,
      params
    );
  },
  cancelOrder (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.cancelOrder,
      callback,
      fail,
      payload,
      params
    );
  },
  getRetailMaxDiscountedValue (callback, fail) {
    apiCall.makeGetRequest(
      apiUrls.api.getRetailMaxDiscountedValue,
      callback,
      fail
    );
  },
  deletePaymentSpecificVouchers (callback, fail) {
    apiCall.makeDeleteRequest(
      apiUrls.api.deletePaymentSpecificVouchers,
      callback,
      fail
    );
  }
};
