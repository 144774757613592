export default {
  name: 'Offline',
  props: {
    onConnectionChange: {
      type: Function
    }
  },
  methods: {
    checkIfOnline () {
      console.log('checkIfOnline...');
      this.onConnectionChange(window.navigator.onLine);
    }
  }
}
