import { getAppConfiguration, getMemberID } from '@/utils/helpers'
import filterDate from '@/filter/date'
import store from '@/store/index.js'
import { utcToZonedTime } from 'date-fns-tz'

const productMappingWithRouteName = {
  Pulsa: 'PHONE_CREDIT',
  PackageData: 'DATA_PACKAGE',
  EWalletTopup: 'EMONEY'
}


export function checkAccessOfUser (to, from, next) {
  const isMemberFetched = store._vm['profileStore/isMemberFetched'];
  if (isMemberFetched) {
    const member = store._vm['profileStore/getMembersData'];
    console.log('Member Detail:', member);
    const memberDetails = member.memberDetails
    if ((member && member.registrationStatus === 'REGISTERED' && memberDetails) && (memberDetails.verificationStatus === 'APPROVED' || memberDetails.verificationStatus === 'SUSPENDED' || memberDetails.verificationStatus === 'REJECTED')) {
      next()
    } else {
      next({ path: '/home' });
    }
  } else {
    setTimeout(() => checkAccessOfUser(to, from, next), 400)
  }
}

export function checkIfMaintenanceElseGoToFeature (to, from, next) {
  const url = to.path
  const maintenanceConfig = JSON.parse(getAppConfiguration('maintenanceConfig')) || [];
  const productConfig = maintenanceConfig.find(item => item.url === url) || {}
  if (productConfig.maintenance) {
    if (productConfig.date) {
      if (isMaintenanceAvailableForCurrentDate(productConfig.date, productConfig.timeSpan)) {
        store.commit('homepageStore/setMaintainencePopup', getMaintainencePopupData(productConfig))
        goToMitraHomePage()
      } else {
        checkAccessOfUser(to, from, next)
      }
    } else {
      store.commit('homepageStore/setMaintainencePopup', getMaintainencePopupData(productConfig))
      goToMitraHomePage()
    }
  } else {
    checkAccessOfUser(to, from, next)
  }
}

function getMaintainencePopupData (productConfig) {
  const date = productConfig.date
  let maintainencePopup = {}
  if (date) {
    const startDate = getDateForPopup(date.split('-')[0])
    const endDate = getDateForPopup(date.split('-')[1])
    maintainencePopup = {
      isMaintainencePopupVisible: true,
      date: startDate + '-' + endDate,
      time: productConfig.timeSpan
    }
  } else {
    maintainencePopup = {
      isMaintainencePopupVisible: true,
      date: date,
      time: productConfig.timeSpan
    }
  }
  return maintainencePopup
}

function getDateForPopup (date) {
  return filterDate.date(new Date(date), 'dd MMM yyyy')
}

function isMaintenanceAvailableForCurrentDate (date, timeSpan) {
  let dates = date.split('-')
  let maintenanceStartDate = new Date(dates[0])
  let maintenanceEndDate = new Date(dates[1])
  if (timeSpan) {
    const time = timeSpan.split('-')
    maintenanceStartDate = new Date(dates[0] + ' ' + time[0])
    maintenanceEndDate = new Date(dates[1] + ' ' + time[1])
  }
  const currentDate = new Date()
  const timeZone = 'Asia/Jakarta'
  const jktCurrentDate = utcToZonedTime(currentDate, timeZone)
  return jktCurrentDate >= maintenanceStartDate && jktCurrentDate <= maintenanceEndDate
}

export function checkAccessForProfilePage (to, from, next) {
  const isMemberFetched = store._vm['profileStore/isMemberFetched'];
  if (isMemberFetched) {
    const member = store._vm['profileStore/getMembersData'];
    const memberDetails = member.memberDetails
    if (memberDetails && memberDetails.verificationStatus === 'APPROVED') {
      next()
    } else {
      next({ path: '/home' })
    }
  } else {
    setTimeout(() => checkAccessForProfilePage(to, from, next), 400)
  }
}

export function checkAccessForDeletePage (to, from, next) {
  const isMemberFetched = store._vm['profileStore/isMemberFetched'];
  if (isMemberFetched) {
    const member = store._vm['profileStore/getMembersData'];
    const isEligibleForAccountDeletion = store._vm['profileStore/isEligibleforDeleteAccount']
    const memberDetails = member.memberDetails
    if (memberDetails && memberDetails.verificationStatus !== 'NOT_REGISTERED' && isEligibleForAccountDeletion) {
      next()
    } else {
      next({ path: '/member-account' })
    }
  } else {
    setTimeout(() => checkAccessForDeletePage(to, from, next), 400)
  }
}

export function checkAccessForWalletUrls (to, from, next) {
  const isMemberFetched = store._vm['profileStore/isMemberFetched']
  const isWalletApiResolved = store._vm['homepageStore/isWalletRequestFetched']
  const isLoggedinUser = getMemberID();
  if (!isLoggedinUser) {
    next({ path: '/home' })
    return
  }
  if (!isMemberFetched) {
    setTimeout(() => checkAccessForWalletUrls(to, from, next), 400)
    return
  }
  const member = store._vm['profileStore/getMembersData']
  const memberDetails = member.memberDetails
  if (!memberDetails || memberDetails.verificationStatus !== 'APPROVED' || memberDetails.mitraPayRegistrationStatus !== 'APPROVED') {
    next({ path: '/home' })
    return
  }
  if (!isWalletApiResolved) {
    setTimeout(() => checkAccessForWalletUrls(to, from, next), 400)
    return
  }
  const walletDetails = store._vm['homepageStore/getWalletRequest'];
  const isPinSettingUrl = to.name === 'SettingPin' || to.name === 'PinCreationInfo'
  if ((isPinSettingUrl && walletDetails.pinRegistered) || (!isPinSettingUrl && !walletDetails.pinRegistered)) {
    next({ path: '/home' })
    return
  }
  next()
}

export function checkAccessForReferral (to, from, next) {
  const isMemberFetched = store._vm['profileStore/isMemberFetched']
  if (!isMemberFetched) {
    setTimeout(() => checkAccessForReferral(to, from, next), 400)
    return
  }
  const pwaConfig = JSON.parse(window.localStorage.mitraPwaConfiguration)
  const isReferralEnabled = pwaConfig.referralFeature === 'ACTIVE'
  if (!isReferralEnabled) {
    next({ path: '/home' });
    return
  }
  const member = store._vm['profileStore/getMembersData'];
  if (member && member.registrationStatus === 'NOT_REGISTERED') {
    next({ path: '/home' });
    return
  }
  next()
}

export function validateUrlForDigitalProducts (to, from, next) {
  const { name, path } = to
  try {
    const pwaConfig = window.localStorage.getItem('mitraPwaConfiguration')
    const parsedPwaConfig = JSON.parse(pwaConfig)
    const configuredPath = parsedPwaConfig?.blueDlsProperties?.productDetails?.[productMappingWithRouteName[name]]?.url
    if (path !== configuredPath) {
      window.location.href = configuredPath
      return
    }
    checkIfMaintenanceElseGoToFeature(to, from, next)
  } catch (error) {
    console.log(error)
    next()
  }
}

function goToMitraHomePage () {
  window.location.href = '/mitra-home';
}
