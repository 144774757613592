import apiUrls from './apiUrls';
import apiCall from './apiUtils/makeApiCall.js';

export default {
  getAllNotifications (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.getAllInAppNotification(),
      callback,
      fail,
      payload,
      params
    );
  },
  getNotificationDashboard (callback, fail, payload) {
    apiCall.makePostRequest(
      apiUrls.api.getNotificationDashboard(),
      callback,
      fail,
      payload
    );
  },
  updateStatusOfNotification (callback, fail, payload, params) {
    apiCall.makePostRequest(
      apiUrls.api.updateStatusOfInAppNotification(),
      callback,
      fail,
      payload,
      params
    );
  }
};
