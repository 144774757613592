import { retailCheckoutStore } from '@vue3/store/retail-checkout-store'
import { homepageStore } from '@vue3/store/homepage-store'

export function listenEventFromVuexToPinia () {
  window.addEventListener('update.pinia.state.from.vuex', ({ detail }) => {
    const { eventType, data } = detail
    if (eventType === 'RETAIL_CART_DETAILS') {
      retailCheckoutStore().SET_CART_DETAILS(data)
    } else if (eventType === 'WALLET_REQUEST') {
      homepageStore().SET_WALLET_REQUEST_FROM_VUEX(data)
    }
  })
}

export function removeEventListenerFromVuexToPinia () {
  window.removeEventListener('update.pinia.state.from.vuex', eventListenerStopped)
}

function eventListenerStopped () {
  console.log('event listerning ended...')
}
