import appsFlyerApis from '../api/appsFlyerApis'
import { getMemberID } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import { parseISO, addDays, startOfDay, endOfDay, isBefore } from 'date-fns';
import memberMixins from './member-mixins';

const EVENTS_EXCLUDED_FROM_APPROVED_USER = ['af_registration_start', 'af_registration_completed', 'af_registration_status']

export default {
  computed: {
    ...mapGetters(['getIsUnAuthenticatedUser', 'getUserLoginStatus']),
    ...mapGetters('homepageStore', ['getConfigs']),
    $getUserId () {
      if (this.getIsUnAuthenticatedUser) {
        return 'LOGGED_OUT_USER'
      }
      return getMemberID()
    },
    appsFlyerConfig () {
      return this.getConfigs.appsFlyerConfig || {}
    },
    isAppsFlyerTrackerEnabled () {
      return this.appsFlyerConfig.enableTracker
    }
  },
  mixins: [memberMixins],
  methods: {
    $sendEventsToAppsFlyer (eventName, eventObject = {}) {
      if (!this.isAppsFlyerTrackerEnabled) {
        return
      }
      const advertisingId = localStorage.getItem('advertising_id');
      const appsflyerId = localStorage.getItem('appsflyer_id');
      const installType = localStorage.getItem('install_type');

      /**
       *  Track events only for non-organic users.
       *
       *  InstallType - It can be one of the following value.
       *
       *  Organic -> User themself manually install the app from PlayStore.
       *  Non-Organic -> User install the app from ad's.
       */
      if (!advertisingId || !appsflyerId || !installType || installType.toLocaleLowerCase() !== 'non-organic') {
        return;
      }

      let sendEvent = false;
      if (EVENTS_EXCLUDED_FROM_APPROVED_USER.includes(eventName)) {
        sendEvent = true;
      } else if (this.isVerificationStatusApproved) {
        try {
          const { createdDate } = this.memberDetails;
          const noOfDaysToTrackFromApprovedDate = this.appsFlyerConfig.noOfDaysToTrack || 30;
          const endDateToTrack = startOfDay(addDays(parseISO(createdDate), noOfDaysToTrackFromApprovedDate))
          const curentDate = endOfDay(new Date())
          sendEvent = isBefore(curentDate, endDateToTrack)
        } catch (e) {
          console.error(e)
        }
      }

      if (!sendEvent) {
        return;
      }
      const userId = this.$getUserId
      const isProd = location && location.host === 'mitra.blibli.com'
      let env = 'LOWER'
      if (isProd) {
        env = 'PROD'
      }
      const deviceId = window.localStorage.getItem('dDetails_V2')
      if (deviceId) {
        eventObject.deviceId = deviceId
      }
      eventObject.env = env
      eventObject.userId = userId
      const eventValue = JSON.stringify(eventObject)
      let payload = {
        memberId: userId,
        advertisingId,
        appsflyerId,
        userId,
        eventValue: eventValue || '',
        eventName
      }
      console.log('appsflyer event : ' + eventName)
      console.log(eventObject)
      appsFlyerApis.sendEvent(
        payload,
        data => {
          console.log(data)
        },
        error => {
          console.error(error)
        })
    }
  }
}
