import { updateMain } from '@/router/helpers/main-router'
import vars from '@/router/helpers/vars'
import { futurePathReplace } from '@/router/helpers/post-change'
import { compareVueRoutes } from '@/router/routeUtil.js'
export function propagateToCollabs (to) {
  vars.isHere = false
  for (const i in vars.collabRouters) {
    if (compareVueRoutes(vars.collabRouters[i].currentRoute.fullPath, to.fullPath)) {
      vars.isHere = true
      continue
    }
    futurePathReplace(vars.collabRouters[i], to.fullPath)
  }
  updateMain(to)
}
export function addCollab (r) {
  vars.collabRouters.push(r)
}
