import BliLoaderGeneral from '@blibli/blue.loader-general/dist/loader-general/blue/LoaderGeneral'
import NavigationBar from '@vue3/components/web/navigation-bar/index.vue'
import Loader from '@vue3/components/web/loader/index.vue'
import ServerOverloadedPopup from '@vue3/components/web/server-overload/index.vue'
import ServerErrorPopup from '@vue3/components/web/server-error/index.vue'
import PageNotFound from '@vue3/pages/page-not-found/index.vue'
import { mapActions, mapState } from 'pinia'
import { homepageStore } from '@vue3/store/homepage-store'
import { userAuthStore } from '@vue3/store/auth-store'
import { profileStore } from '@vue3/store/profile-store'
import { digitalConfig } from '@vue3/store/digital-config-store'
import { root } from '@vue3/store/root'
import { getMemberID, setAppConfiguration, getAppConfiguration, getPWADisplayMode } from '@vue3/utils/helpers'
import { doesTranslationExist } from '@vue3/i18n/lang'
import trackerMixin from '@vue3/mixins/tracker-mixins'
import GA4trackerMixin from '@vue3/mixins/gafour-tracker-mixins'
import digitalConfigNames from '@vue3/constant/digital-config-name'
import { listenEventFromVuexToPinia, removeEventListenerFromVuexToPinia } from '@vue3/utils/vuex-to-pinia-event'

const navPaths = ['/order', '/retail-checkout', '/member-account']

export default {
  name: 'App3',
  components: {
    BliLoaderGeneral,
    PageNotFound,
    NavigationBar,
    ServerOverloadedPopup,
    ServerErrorPopup,
    Loader
  },
  data () {
    return {
      shouldSaveDeviceDetails: false
    };
  },
  mixins: [
    trackerMixin,
    GA4trackerMixin
  ],
  watch: {
    errorToastInfo (val) {
      if (val.showToast) {
        this.showErrorToast(val.errorMessage)
      }
    },
    $route (to, from) {
      if ((to.name !== from.name) && to.name === 'HomePage') {
        // d - query from android app. This will come as encrypted string.
        const deviceDetailsQueryFromAndroid = to.query.d || ''
        /*
          e.g. - decrypted string

          deviceId:d1b6ab893f92640e,
          deviceBrandName:samsung,
          deviceModelNumber:SM-A207F,
          androidOSVersionNumber:11,
          mitraAppVersion:1.2.0(120005)
        */
        if (deviceDetailsQueryFromAndroid && window.localStorage.getItem('d2Added') === null) {
          this.shouldSaveDeviceDetails = true
          window.localStorage.setItem('dDetails_V2', deviceDetailsQueryFromAndroid)
          if (window.localStorage.getItem('dDetails')) {
            window.localStorage.removeItem('dDetails')
          }
        }
        // made the trigger async, nextTick not working
        setTimeout(() => {
          this.$trackPageImpression('MitraHomeImpression')
        }, 200)
      }
    }
  },
  created () {
    this.SET_PWA_DISPLAY_MODE(getPWADisplayMode())
    this.checkValuesAndMakeAppMountApis()
  },
  mounted () {
    listenEventFromVuexToPinia()
    this.GET_DIGITAL_CONFIG(digitalConfigNames)
  },
  computed: {
    ...mapState(homepageStore, ['configs']),
    ...mapState(userAuthStore, ['isUnAuthenticatedUser', 'isUserLoggedIn', 'isServerOverloaded', 'isServerError']),
    ...mapState(profileStore, ['membersData']),
    ...mapState(root, ['errorToastInfo', 'showLoader', 'showPageNotFoundInfo']),
    isAppLoaderVisible () {
      if (!this.isUserLoggedIn) {
        if (!this.isUnAuthenticatedUser) {
          // session api pending
          return true
        }
        // config api pending check(for logged out users)
        return !Object.keys(this.configs).length
      }
      // config and member details api pending check(for logged in users)
      return !Object.keys(this.configs).length || !Object.keys(this.membersData).length
    },
    showNavigationTab () {
      const path = this.$route.path
      return path.includes('home') || path.includes('help') || navPaths.some(element => element === path)
    }
  },
  methods: {
    ...mapActions(homepageStore, ['GET_APP_CONFIGURATION', 'GET_BANNERS_AND_SERVICES', 'SET_CONFIGS', 'GET_WALLET_REQUEST']),
    ...mapActions(userAuthStore, ['GET_MITRA_SESSION']),
    ...mapActions(profileStore, ['GET_MEMBER_DETAILS', 'GET_MEMBER_ONBOARDING_STATUS']),
    ...mapActions(root, ['SET_PWA_DISPLAY_MODE']),
    ...mapActions(digitalConfig, ['GET_DIGITAL_CONFIG']),
    checkMemberRegistration (data) {
      const { registrationStatus } = data
      const isRegisteredUser = registrationStatus === 'REGISTERED'
      if (isRegisteredUser) {
        const { memberDetails: { verificationStatus } } = data
        if (!localStorage.getItem('homeOnboardingStatus') && (verificationStatus === 'APPROVED')) {
          this.GET_MEMBER_ONBOARDING_STATUS({
            payload: { memberId: getMemberID() },
            success: this.onGetHomePageOnboardingStatusResolve,
            fail: this.onGetHomePageOnboardingStatusResolve,
            params: {
              workFlow: 'HOME_REVAMP_1'
            }
          })
        }
        const homePageConfig = this.configs?.homePageConfig;
        const inActiveWalletForHomePage = homePageConfig?.inActiveWallet;
        const memberVerifiedStatus = ['APPROVED', 'REJECTED', 'SUSPENDED']
        const isMemberUnderNewRegistration = data.memberDetails.newRegistration
        if (isMemberUnderNewRegistration && !inActiveWalletForHomePage && memberVerifiedStatus.includes(verificationStatus)) {
          this.GET_WALLET_REQUEST({
            payload: {
              memberId: getMemberID()
            }
          })
        }
      }
      // if (isRegisteredUser || isRegistrationInProgressUser) {
      //   this.setDeviceDetails()
      // }
    },
    sessionFetched () {
      this.GET_MEMBER_DETAILS({
        payload: { memberId: getMemberID() },
        success: this.checkMemberRegistration
      })
    },
    fetchBannersForLoggedOutUsers () {
      this.GET_BANNERS_AND_SERVICES()
    },
    onGetHomePageOnboardingStatusResolve (isOnboardingComplete) {
      localStorage.setItem('homeOnboardingStatus', isOnboardingComplete)
    },
    onSuccessOfPWAConfig (configs) {
      setAppConfiguration('mitraPwaConfiguration', (configs))
    },
    onFailOfPWAConfig () {
      const appConfiguration = getAppConfiguration('mitraPwaConfiguration')
      this.SET_CONFIGS({
        typeOfConfiguration: 'mitraPwaConfiguration',
        configs: appConfiguration ? JSON.parse(appConfiguration) : {}
      })
    },
    showErrorToast (errorMessage) {
      const error = doesTranslationExist(errorMessage) || errorMessage
      this.showToast(error, '', true)
    },
    checkValuesAndMakeAppMountApis () {
      this.GET_MITRA_SESSION({
        success: this.sessionFetched,
        fail: this.fetchBannersForLoggedOutUsers
      })
      this.GET_APP_CONFIGURATION({
        typeOfConfiguration: 'mitraPwaConfiguration',
        success: this.onSuccessOfPWAConfig,
        fail: this.onFailOfPWAConfig
      })
    }
  },
  beforeUnmount () {
    removeEventListenerFromVuexToPinia()
  }
}
