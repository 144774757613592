import loyaltyApis from '../api/loyaltyApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    pointsInfo: {},
    levelBenefits: [],
    loyaltyMemberInfo: {},
    availableGifts: [],
    loyaltyPointsHistory: [],
    historyPaging: null,
    redeemedGifts: [],
    fetchingPointHistory: false,
    redeemedGiftPaging: null,
    availableGiftPaging: null,
    isFetchingRedeemedGifts: false,
    isFetchingAvailableGifts: false
  },
  getters: {
    getPointsInfo (state) {
      return state.pointsInfo;
    },
    getLevelBenefits (state) {
      return state.levelBenefits;
    },
    getLoyaltyMemberInfo (state) {
      return state.loyaltyMemberInfo;
    },
    getAvailableGifts (state) {
      return state.availableGifts;
    },
    getPointsHistory (state) {
      return state.loyaltyPointsHistory;
    },
    getHistoryPaging (state) {
      return state.historyPaging
    },
    getRedeemedGifts (state) {
      return state.redeemedGifts
    },
    getFetchingPointHistory (state) {
      return state.fetchingPointHistory
    },
    getRedeemedGiftPaging (state) {
      return state.redeemedGiftPaging
    },
    getAvailableGiftPaging (state) {
      return state.availableGiftPaging
    },
    fetchingAvailableGifts (state) {
      return state.isFetchingAvailableGifts
    },
    fetchingRedeemedGifts (state) {
      return state.isFetchingRedeemedGifts
    }
  },
  mutations: {
    setPointsInfo (state, value) {
      state.pointsInfo = value;
    },
    setLevelBenefits (state, value) {
      state.levelBenefits = value;
    },
    setLoyaltyMemberInfo (state, value) {
      state.loyaltyMemberInfo = value;
    },
    setAvailableGifts (state, value) {
      state.availableGifts = value;
    },
    setPointsHistory (state, value) {
      state.loyaltyPointsHistory = value;
    },
    setHistoryPaging (state, value) {
      state.historyPaging = value;
    },
    setRedeemedGifts (state, value) {
      state.redeemedGifts = value;
    },
    setFetchingPointHistory (state, value) {
      state.fetchingPointHistory = value;
    },
    setRedeemedGiftPaging (state, value) {
      state.redeemedGiftPaging = value;
    },
    setAvailableGiftPaging (state, value) {
      state.availableGiftPaging = value;
    },
    setFetchingAvailableGifts (state, value) {
      state.isFetchingAvailableGifts = value;
    },
    setFetchingRedeemedGifts (state, value) {
      state.isFetchingRedeemedGifts = value;
    }
  },
  actions: {
    GET_POINTS_INFO ({ commit, dispatch }, { params, fail, success } = {}) {
      loyaltyApis.getPointsInfo(
        response => {
          if (response.data.code === 200) {
            success && success();
            commit('setPointsInfo', response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_LEVEL_BENEFIT ({ commit, dispatch }, { params, fail } = {}) {
      loyaltyApis.getLevelBenefit(
        response => {
          if (response.data.code === 200) {
            commit('setLevelBenefits', response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_LOYALTY_MEMBER_INFO ({ commit, dispatch }, { success, fail, payload } = {}) {
      loyaltyApis.getLoyaltyMemberInfo(
        response => {
          if (response.data.code === 200) {
            commit('setLoyaltyMemberInfo', response.data.data);
            success && success()
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_AVAILABLE_GIFTS ({ commit, dispatch, state }, { params, payload, fail, success, isNewPage } = {}) {
      loyaltyApis.getAllAvailableGifts(
        response => {
          commit('setFetchingAvailableGifts', false);
          if (response.data.code === 200) {
            if (isNewPage) {
              commit('setAvailableGifts', response.data.data);
              commit('setAvailableGiftPaging', response.data.paging);
            } else {
              commit('setAvailableGifts', [...state.availableGifts, ...response.data.data]);
              commit('setAvailableGiftPaging', response.data.paging);
            }
            success && success();
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    GET_POINTS_HISTORY ({ commit, dispatch, state }, { params, payload, fail, success, isNewPage, fetchSuccess } = {}) {
      loyaltyApis.getLoyaltyPointHistory(
        response => {
          commit('setFetchingPointHistory', false);
          if (response.data.code === 200) {
            if (isNewPage) {
              commit('setPointsHistory', response.data.data);
              commit('setHistoryPaging', response.data.paging);
            } else {
              commit('setPointsHistory', [...state.loyaltyPointsHistory, ...response.data.data]);
              commit('setHistoryPaging', response.data.paging);
            }
            success && success();
            fetchSuccess(response.data)
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    CHECK_LOYALTY_STOCK ({ dispatch }, { payload, success, fail }) {
      loyaltyApis.getStockAvailability(
        response => {
          if (response.data.code === 200) {
            success && success()
          } else {
            fail && fail()
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    GET_REDEEMED_GIFTS ({ commit, dispatch, state }, { params, payload, fail, success, isNewPage } = {}) {
      loyaltyApis.getRedeemedGiftsList(
        response => {
          commit('setFetchingRedeemedGifts', false);
          if (response.data.code === 200) {
            if (isNewPage) {
              commit('setRedeemedGifts', response.data.data);
            } else {
              commit('setRedeemedGifts', [...state.redeemedGifts, ...response.data.data]);
            }
            commit('setRedeemedGiftPaging', response.data.paging);
            success && success();
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    REDEEM_CURRENT_GIFT ({ dispatch }, { params, fail, success, payload } = {}) {
      loyaltyApis.redeemGift(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    SET_POINT_HISTORY_FETCHING ({ commit }, value) {
      commit('setFetchingPointHistory', value)
    },
    RESET_HISTORY ({ commit }) {
      commit('setPointsHistory', []);
      commit('setHistoryPaging', null)
    },
    RESET_GIFT_LIST ({ commit }) {
      commit('setRedeemedGifts', []);
      commit('setRedeemedGiftPaging', null);
      commit('setAvailableGifts', []);
      commit('setAvailableGiftPaging', null);
    },
    SET_AVAILABLE_GIFT_FETCHING ({ commit }, value) {
      commit('setFetchingAvailableGifts', value)
    },
    SET_REDEEMED_GIFT_FETCHING ({ commit }, value) {
      commit('setFetchingRedeemedGifts', value)
    }
  },
  namespaced: true
}
