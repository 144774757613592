function title (value) {
  let formattedString = ''
  value.trim().split(' ').forEach(word => {
    formattedString += `${word[0].toUpperCase()}${word.slice(1).toLowerCase()} `
  })
  return formattedString.trim()
}
function currencyTitle (value) {
  let newVal = value.trim()
  return `${newVal[0].toUpperCase()}${newVal.slice(1).toLowerCase().replace(',', '.')}`
}
export default {
  title,
  currencyTitle
}
