export function isDifferentUrl (oldPath, newPath) {
  const oldPaths = oldPath.split('?')
  const newPaths = newPath.split('?')
  return oldPaths[0] !== newPaths[0]
}
export function futurePathReplace (router, fullPath, resetScroll) {
  if (window.location.href !== window.location.origin + fullPath) {
    setTimeout(() => futurePathReplace(router, fullPath), 30)
    return
  }
  console.log(resetScroll)
  router.replace(fullPath)
}

