import { createRouter, createWebHistory } from 'vue-router'
import { checkAccessOfUser, checkAccessForMemberRegistration,
  checkAccessForWalletRegistration, checkAccessForWalletUrls, validateUrlForDigitalProducts, checkAccessForCheckinMission } from './routeMiddleware.js'
import { profileStore } from '@vue3/store/profile-store'

const routes = [
  {
    path: '/',
    redirect: '/mitra-home'
  },
  {
    path: '/home',
    redirect: '/mitra-home'
  },
  {
    path: '/mitra-pwa',
    redirect: '/mitra-home'
  },
  {
    path: '/mitra-home',
    name: 'HomePage',
    component: () => import('@vue3/pages/home-page/index.vue')
  },
  {
    path: '/mitra-registration',
    name: 'MitraRegistration',
    component: () => import('@vue3/pages/registration/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForMemberRegistration(to, from, next)
    }
  },
  {
    path: '/mitra-push-notification',
    name: 'MitraPushNotification',
    component: () => import('@vue3/pages/push-notification/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra-wallet-registration',
    name: 'MitraWalletRegistration',
    component: () => import('@vue3/pages/wallet-registration/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForWalletRegistration(to, from, next)
    }
  },
  {
    path: '/wallet-details',
    name: 'WalletDetails',
    component: () => import('@vue3/pages/wallet-details/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/mitra-setting-pin',
    name: 'MitraSettingPin',
    component: () => import('@vue3/pages/setting-pin/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/mitra-capture',
    name: 'MitraCapture',
    component: () => import('@vue3/pages/camera-engine/capture/index.vue'),
    children: [
      {
        path: 'instructions',
        name: 'MitraInstructions',
        component: () => import('@vue3/pages/camera-engine/instructions/index.vue')
      },
      {
        path: 'image',
        name: 'MitraCaptureImage',
        component: () => import('@vue3/components/camera-engine/index.vue')
      }
    ]
  },
  {
    path: '/mitra/all/products',
    name: 'AllProducts',
    component: () => import('@vue3/pages/all-products/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra/delete-account',
    name: 'DeleteAccount',
    component: () => import('@vue3/pages/delete-account/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForDeletePage(to, from, next)
    }
  },
  {
    path: '/mitra/digital/blipay',
    name: 'MitraBlipayTopup',
    component: () => import('@vue3/pages/digital/blipay-topup/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/mitra/digital/blipay/cashout',
    name: 'MitraBlipayCashout',
    component: () => import('@vue3/pages/digital/blipay-cashout/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForWalletUrls(to, from, next)
    }
  },
  {
    path: '/mitra/digital/cart/summary/:product',
    name: 'DigitalCartSummary',
    component: () => import('@vue3/pages/digital/cart-summary/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra/digital/promotion',
    name: 'PromotionPage',
    component: () => import('@vue3/pages/digital/promotion-page/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra/digital/order/thank-you/:orderId',
    name: 'DigitalThankyou',
    component: () => import('@vue3/pages/digital/digital-thankyou/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra/digital/pulsa',
    name: 'DigitalPulsa',
    component: () => import('@vue3/pages/digital/nominal/phone-credit/index.vue'),
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/mitra/digital/paket-data',
    name: 'DigitalDataPackage',
    component: () => import('@vue3/pages/digital/nominal/data-package/index.vue'),
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/mitra/order/payment/:orderId',
    name: 'MitraBlipayPin',
    component: () => import('@vue3/pages/blipay-pin/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessOfUser(to, from, next)
    }
  },
  {
    path: '/mitra/digital/ewallet-topup',
    name: 'DigitalEWalletTopup',
    component: () => import('@vue3/pages/digital/nominal/ewallet-topup/index.vue'),
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  },
  {
    path: '/checkin-mission',
    name: 'CheckinMission',
    component: () => import('@vue3/pages/checkin-mission/index.vue'),
    beforeEnter: (to, from, next) => {
      checkAccessForCheckinMission(to, from, next)
    }
  },
  {
    path: '/mitra/digital/token-listrik',
    name: 'DigitalTokenListrik',
    component: () => import('@vue3/pages/digital/nominal/electricity-token/index.vue'),
    beforeEnter: (to, from, next) => {
      validateUrlForDigitalProducts(to, from, next)
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.onError(error => {
  console.log('router on error hook:', error);
  const err = error.message; // 'Loading chunk WEBPACK_CHUNK_NAME failed.' eg: Loading chunk p-store failed.
  const msg = err.toLowerCase();
  const extractedKey = msg.split(' ').filter((e, i) => i !== 2).join('_');
  const pattern = /(failed_to_dynamically_imported_module|loading_chunk_failed)/i;

  if (pattern.test(extractedKey)) {
    window.location.reload();
  }
});

if (window.addCollabRouter) {
  window.addCollabRouter(router)
}
router.afterEach((to) => {
  // update main's current path from collab
  if (window.propagateToMain) {
    window.propagateToMain(to.fullPath)
  }
})

function checkAccessForDeletePage (to, from, next) {
  const isMemberFetched = profileStore().isMemberFetched
  if (isMemberFetched) {
    const member = profileStore().membersData
    const memberDetails = member.memberDetails
    if (memberDetails && memberDetails.verificationStatus !== 'NOT_REGISTERED') {
      next()
    } else {
      next({ path: '/member-account' })
    }
  } else {
    setTimeout(() => checkAccessForDeletePage(to, from, next), 400)
  }
}

export default router
