import { profileStore } from '@vue3/store/profile-store'
import { homepageStore } from '@vue3/store/homepage-store'
import { getAppConfiguration, getMemberID } from '@vue3/utils/helpers'
import { utcToZonedTime } from 'date-fns-tz'

const productMappingWithRouteName = {
  DigitalPulsa: 'PHONE_CREDIT',
  DigitalDataPackage: 'DATA_PACKAGE',
  DigitalEWalletTopup: 'EMONEY'
}

export function checkAccessOfUser (to, from, next) {
  const isMemberFetched = profileStore().isMemberFetched
  if (isMemberFetched) {
    const member = profileStore().membersData
    const memberDetails = member.memberDetails
    if ((member && member.registrationStatus === 'REGISTERED' && memberDetails) && (memberDetails.verificationStatus === 'APPROVED' || memberDetails.verificationStatus === 'SUSPENDED' || memberDetails.verificationStatus === 'REJECTED')) {
      next()
    } else {
      next({ path: '/mitra-home' });
    }
  } else {
    setTimeout(() => checkAccessOfUser(to, from, next), 400)
  }
}

export function checkAccessForMemberRegistration (to, from, next) {
  const isMemberFetched = profileStore().isMemberFetched
  if (isMemberFetched) {
    const member = profileStore().membersData
    const memberDetails = member.memberDetails
    if (member && (member.registrationStatus === 'NOT_REGISTERED' ||
          (member.registrationStatus === 'REGISTERED' &&
          memberDetails && memberDetails.verificationStatus === 'REJECTED'
          ))
    ) {
      next()
    } else {
      next({ path: '/mitra-home' })
    }
  } else {
    setTimeout(() => checkAccessForMemberRegistration(to, from, next), 400)
  }
}

export function checkAccessForWalletRegistration (to, from, next) {
  const isMemberFetched = profileStore().isMemberFetched
  if (isMemberFetched) {
    const member = profileStore().membersData
    const memberDetails = member.memberDetails
    if (memberDetails && memberDetails.verificationStatus === 'APPROVED' &&
          memberDetails.mitraPayRegistrationStatus !== 'APPROVED' &&
          memberDetails.mitraPayRegistrationStatus !== 'NEEDS_REVIEW'
    ) {
      next()
    } else {
      next({ path: '/mitra-home' })
    }
  } else {
    setTimeout(() => checkAccessForWalletRegistration(to, from, next), 400)
  }
}

export function checkAccessForWalletUrls (to, from, next) {
  const isMemberFetched = profileStore().isMemberFetched
  const isWalletApiResolved = homepageStore().isWalletRequestFetched
  const isLoggedinUser = getMemberID();
  if (!isLoggedinUser) {
    next({ path: '/mitra-home' })
    return
  }
  if (!isMemberFetched) {
    setTimeout(() => checkAccessForWalletUrls(to, from, next), 400)
    return
  }
  const member = profileStore().membersData
  const memberDetails = member.memberDetails
  if (!memberDetails || memberDetails.verificationStatus !== 'APPROVED' || memberDetails.mitraPayRegistrationStatus !== 'APPROVED') {
    next({ path: '/mitra-home' })
    return
  }
  if (!isWalletApiResolved) {
    setTimeout(() => checkAccessForWalletUrls(to, from, next), 400)
    return
  }
  const walletDetails = homepageStore().walletRequest
  const isPinSettingUrl = to.name === 'MitraSettingPin'
  if ((walletDetails.suspended) || (isPinSettingUrl && walletDetails.pinRegistered) || (!isPinSettingUrl && !walletDetails.pinRegistered)) {
    next({ path: '/mitra-home' })
    return
  }
  next()
}

export function checkIfMaintenanceElseGoToFeature (to, from, next) {
  const url = to.path
  const maintenanceConfig = JSON.parse(getAppConfiguration('maintenanceConfig')) || []
  const productConfig = maintenanceConfig.find(item => item.url === url) || {}
  if (productConfig.maintenance) {
    if (productConfig.date) {
      if (isMaintenanceAvailableForCurrentDate(productConfig.date, productConfig.timeSpan)) {
        goToMitraHomePage()
      } else {
        checkAccessOfUser(to, from, next)
      }
    } else {
      goToMitraHomePage()
    }
  } else {
    checkAccessOfUser(to, from, next)
  }
}

export function validateUrlForDigitalProducts (to, from, next) {
  const { name, path } = to
  try {
    const pwaConfig = window.localStorage.getItem('mitraPwaConfiguration')
    const parsedPwaConfig = JSON.parse(pwaConfig)
    const configuredPath = parsedPwaConfig.blueDlsProperties.productDetails[productMappingWithRouteName[name]].url
    if (path !== configuredPath) {
      window.location.href = configuredPath
      return
    }
    checkIfMaintenanceElseGoToFeature(to, from, next)
  } catch (error) {
    console.log(error)
    next()
  }
}

export function checkAccessForCheckinMission (to, from, next) {
  try {
    const pwaConfig = window.localStorage.getItem('mitraPwaConfiguration')
    const parsedPwaConfig = JSON.parse(pwaConfig)
    const isCheckinMissionEnabled = parsedPwaConfig.shouldEnableDailyCheckIn
    if (!isCheckinMissionEnabled) {
      next({ path: '/mitra-home' })
      return
    }
    checkAccessOfUser(to, from, next)
  } catch (error) {
    console.log(error)
    next()
  }
}

function isMaintenanceAvailableForCurrentDate (date, timeSpan) {
  let dates = date.split('-')
  let maintenanceStartDate = new Date(dates[0])
  let maintenanceEndDate = new Date(dates[1])
  if (timeSpan) {
    const time = timeSpan.split('-')
    maintenanceStartDate = new Date(dates[0] + ' ' + time[0])
    maintenanceEndDate = new Date(dates[1] + ' ' + time[1])
  }
  const currentDate = new Date()
  const timeZone = 'Asia/Jakarta'
  const jktCurrentDate = utcToZonedTime(currentDate, timeZone)
  return jktCurrentDate >= maintenanceStartDate && jktCurrentDate <= maintenanceEndDate
}

function goToMitraHomePage () {
  window.location.href = '/mitra-home'
}
