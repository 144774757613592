<template>
  <Teleport to="#app3Placeholder">
    <div id="appVue3">
      <div
        v-if="isAppLoaderVisible"
        class="app-loader"
      >
        <BliLoaderGeneral
          size="xl"
        />
      </div>
      <div v-else>
        <router-view v-slot="{Component}">
          <Transition
            name="fade"
            mode="out-in"
          >
            <component :is="Component" />
          </Transition>
        </router-view>
        <NavigationBar v-if="showNavigationTab" />
      </div>
      <ServerOverloadedPopup :is-server-overloaded="isServerOverloaded" />
      <ServerErrorPopup :is-server-error="isServerError" />
      <Loader v-if="showLoader" />
      <PageNotFound v-if="showPageNotFoundInfo && !isAppLoaderVisible" />
    </div>
  </Teleport>
</template>
<script src="./js/app.js" />
<style lang="scss" scoped>
.app-loader {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fade-enter-active {
  transition: opacity 700ms ease-in-out;
}
.fade-leave-active {
  transition: opacity 100ms ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 500px) {
  /* Styles */
  #appVue3 {
    max-width: 420px;
    margin: 0 auto;
    position: relative;
  }
}
</style>
