export default {
  state: {
    isErrorPopupVisible: false,
    errorMessage: {},
    isErrorHandleVisible: false,
    errorHandleMessage: {},
    showNavigationBar: true,
    isAlreadyRegistered: null,
    errorPopupData: {
      title: '',
      body: ''
    }
  },
  getters: {
    getErrorState (state) {
      return {
        isErrorPopupVisible: state.isErrorPopupVisible,
        errorMessage: state.errorMessage
      };
    },
    getNavigationBarStatus (state) {
      return {
        isNavigationBarVisible: state.showNavigationBar
      };
    },
    getErrorHandleState (state) {
      return {
        isErrorHandleVisible: state.isErrorHandleVisible,
        errorHandleMessage: state.errorHandleMessage
      };
    },
    isMemberAlreadyRegisterd (state) {
      return state.isAlreadyRegistered
    },
    getErrorPopupData (state) {
      return state.errorPopupData
    }
  },
  mutations: {
    setErrorState (state, value) {
      Object.assign(state, {
        isErrorPopupVisible: value.isErrorPopupVisible,
        errorMessage: value
      });
    },
    setErrorHandleState (state, value) {
      Object.assign(state, {
        isErrorHandleVisible: value.isErrorHandleVisible,
        errorHandleMessage: value
      });
    },
    removeErrorState (state) {
      state.isErrorPopupVisible = false;
      state.errorMessage = {};
    },
    removeErrorHandleState (state) {
      state.isErrorHandleVisible = false;
      state.errorHandleMessage = {};
    },
    showNavigationBar (state) {
      state.showNavigationBar = true;
    },
    hideNavigationBar (state) {
      state.showNavigationBar = false;
    },
    setAlreadyMemberMessage (state) {
      state.isAlreadyRegistered = true
    },
    removeAlreadyMemberMessage (state) {
      state.isAlreadyRegistered = null
    },
    setErrorPopupData (state, value) {
      state.errorPopupData = value
    }
  },
  actions: {
    SET_ERROR_POPUP ({ commit }, payload) {
      commit('setErrorState', payload);
      if (payload.popupData) {
        commit('setErrorPopupData', payload.popupData);
      }
    },
    REMOVE_ERROR_POPUP ({ commit }) {
      commit('removeErrorState');
      commit('setErrorPopupData', { title: '', body: '' });
    },
    HIDE_NAVIGATION_TAB ({ commit }) {
      commit('hideNavigationBar');
    },
    SHOW_NAVIGATION_TAB ({ commit }) {
      commit('showNavigationBar');
    },
    SET_ERROR_HANDLE_POPUP ({ commit }, payload) {
      commit('setErrorHandleState', payload);
    },
    REMOVE_ERROR_HANDLE_POPUP ({ commit }) {
      commit('removeErrorHandleState');
    },
    SET_ALREADY_REGISTERED_MESSAGE ({ commit }) {
      commit('setAlreadyMemberMessage');
    },
    REMOVE_ALREADY_REGISTERED_MESSAGE ({ commit }) {
      commit('removeAlreadyMemberMessage');
    }
  },
  namespaced: false
};
