import keretaApi from '../api/keretaApis';
import errorHandler from '../api/apiUtils/errorHandler';

export default {
  state: {
    allStations: [],
    popularStations: [],
    departureTrains: [],
    arrivalTrains: [],
    selectedTrain: {},
    checkoutCart: null,
    trainOrderDetail: {},
    trainOrders: [],
    trainPendingOrders: [],
    isFetchingTrainOrders: false,
    isFetchingTrainPendingOrders: false,
    seatLayoutMap: null,
    promotionList: [],
    promotionDetails: null
  },
  getters: {
    getStations (state) {
      return state.allStations;
    },
    getPopularStations (state) {
      return state.popularStations;
    },
    getSelectedTrain (state) {
      return state.selectedTrain;
    },
    getDepartureTrains (state) {
      return state.departureTrains;
    },
    getArrivalTrains (state) {
      return state.arrivalTrains;
    },
    getCheckoutCart (state) {
      return state.checkoutCart;
    },
    getTrainOrderDetail (state) {
      return state.trainOrderDetail;
    },
    getTrainOrders (state) {
      return state.trainOrders;
    },
    getTrainPendingOrders (state) {
      return state.trainPendingOrders;
    },
    isTrainOrdersFetching (state) {
      return state.isFetchingTrainOrders;
    },
    isTrainPendingOrdersFetching (state) {
      return state.isFetchingTrainPendingOrders;
    },
    getSeatLayoutMap (state) {
      return state.seatLayoutMap
    },
    getPromoList (state) {
      return state.promotionList
    },
    getPromoDetails (state) {
      return state.promotionDetails
    }
  },
  mutations: {
    setStations (state, value) {
      state.allStations = value;
    },
    setPopularStations (state, value) {
      state.popularStations = value;
    },
    setDepartureTrains (state, value) {
      state.departureTrains = value;
    },
    setArrivalTrains (state, value) {
      state.arrivalTrains = value;
    },
    selectTrain (state, value) {
      state.selectedTrain = value;
    },
    setCheckoutCart (state, value) {
      state.checkoutCart = value;
    },
    clearPreviousSearchResult (state, value) {
      state.departureTrains = value;
      state.arrivalTrains = value;
    },
    setTrainOrderDetail (state, value) {
      state.trainOrderDetail = value;
    },
    setTrainOrders (state, value) {
      state.trainOrders = value;
    },
    setTrainPendingOrders (state, value) {
      state.trainPendingOrders = value;
    },
    setIsFetchingTrainOrders (state, value) {
      state.isFetchingTrainOrders = value;
    },
    setIsFetchingTrainPendingOrders (state, value) {
      state.isFetchingTrainPendingOrders = value;
    },
    setSeatLayoutMap (state, value) {
      state.seatLayoutMap = [...value];
    },
    setPromoList (state, value) {
      state.promotionList = value
    },
    setPromoDetails (state, value) {
      state.promotionDetails = value
    },
    updatePromoStatus (state, promo) {
      let selectedPromo = state.promotionList.find(promoItem => promoItem.promoCode === promo.promoCode)
      selectedPromo.status = selectedPromo.status === 'APPLIED' ? 'USED' : 'APPLIED'
    }
  },
  actions: {
    GET_ALL_STATIONS ({ commit, dispatch }, { params, fail } = {}) {
      keretaApi.getAllStations(
        response => {
          if (response.data.code === 200) {
            const list = response.data.data ? response.data.data.cityList : [];
            commit('setStations', list);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_POPULAR_STATIONS ({ commit, dispatch }, { params, fail } = {}) {
      keretaApi.getAllPopularStations(
        response => {
          if (response.data.code === 200) {
            const list = response.data.data ? response.data.data.cityList : [];
            commit('setPopularStations', list);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    SEARCH_TRAINS ({ commit, dispatch }, { params, success, fail, tripType } = {}) {
      commit('clearPreviousSearchResult', []);
      keretaApi.searchTrains(
        response => {
          if (response.data.code === 200) {
            if (tripType === 'DEPARTURE') {
              commit('setDepartureTrains', response.data.data.departureRailList);
            } else {
              commit('setArrivalTrains', response.data.data.departureRailList);
            }
            success && success(response.data.data)
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    ADD_REMOVE_THIS_TRAIN ({ commit }, { payload }) {
      commit('selectTrain', payload)
    },
    ADD_SELECTED_TRAINS_TO_CART ({ dispatch }, { success, payload, params, fail }) {
      keretaApi.addTrainToCart(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    GET_CART_DETAILS_OF_TRAIN ({ commit, dispatch }, { success, fail } = {}) {
      keretaApi.getTrainCartData(
        response => {
          if (response.data.code === 200) {
            commit('setCheckoutCart', response.data.data);
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        }
      );
    },
    ADD_PASSENGERS_TO_TRAIN_CART ({ dispatch }, { success, payload, params, fail }) {
      keretaApi.addPassengerToTrainCart(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    CHECKOUT_CURRENT_CART ({ dispatch }, { success, payload, params, fail }) {
      keretaApi.checkoutCurrentCart(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    BOOK_CURRENT_CART ({ dispatch }, { success, params, fail }) {
      keretaApi.bookCurrentCart(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    CHECKOUT_ORDER ({ dispatch }, { success, payload, params, fail }) {
      keretaApi.checkoutOrder(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    GET_TRAIN_ORDER_DETAIL ({ commit, dispatch }, { params, success, fail } = {}) {
      keretaApi.getOrderDetails(
        response => {
          if (response.data.code === 200 && response.data.data) {
            commit('setTrainOrderDetail', response.data.data[0]);
            success && success(response.data.data[0]);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    GET_TRAIN_ORDER_HISTORY ({ commit, dispatch }, { params, success, fail } = {}) {
      commit('setIsFetchingTrainOrders', true);
      commit('setIsFetchingTrainPendingOrders', true);

      // todo: remove this sorter after pagination support added from backend
      let timeSorter = function (a, b) {
        const firstKey = new Date(a.orderDate).getTime();
        const secondKey = new Date(b.orderDate).getTime();
        return firstKey < secondKey ? 1 : firstKey > secondKey ? -1 : 0;
      };

      keretaApi.getOrderHistoryOfKereta(
        response => {
          if (response.data.code === 200) {
            if (params.orderStatus === 'PENDING') {
              commit('setTrainPendingOrders', [...response.data.data].sort(timeSorter));
              commit('setIsFetchingTrainPendingOrders', false);
            } else {
              commit('setTrainOrders', [...response.data.data].sort(timeSorter));
              commit('setIsFetchingTrainOrders', false);
            }
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          console.log('HISTORY_ERROR:', error);
          commit('setIsFetchingTrainOrders', false);
          commit('setIsFetchingTrainPendingOrders', false);
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    RESET_ORDERS ({ commit }) {
      commit('setTrainOrders', []);
      commit('setTrainPendingOrders', []);
    },
    TRAIN_PAY_ORDER ({ dispatch }, { success, payload, params, fail }) {
      keretaApi.payTrainOrder(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    POLL_ORDER_STATUS ({ dispatch }, { success, params, pathVariables, fail } = {}) {
      keretaApi.pollCurrentOrderStatus(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables,
        params
      );
    },
    GET_TRAIN_ORDER_DETAIL_WITH_BOOKING_CODE ({ commit, dispatch }, { pathVariables, success, fail } = {}) {
      keretaApi.getOrderDetailsWithBookingCode(
        response => {
          if (response.data.code === 200) {
            commit('setTrainOrderDetail', response.data.data);
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    CANCEL_ORDER ({ dispatch }, { success, fail, pathVariables }) {
      keretaApi.cancelCurrentOrder(
        response => {
          if (response.data.code === 200) {
            success && success(response.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    GET_SEAT_LAYOUT ({ dispatch }, { success, fail, pathVariables }) {
      keretaApi.getSeatLayout(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    UPDATE_SEAT_PREFERENCE ({ dispatch }, { success, fail, pathVariables, payload }) {
      keretaApi.changeSeat(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        pathVariables
      );
    },
    GET_PROMO_LIST ({ dispatch, commit }, { success, fail, pathVariables }) {
      keretaApi.getPromotionList(
        response => {
          if (response.data.code === 200) {
            commit('setPromoList', response.data.data);
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        pathVariables
      );
    },
    GET_PROMO_DETAILS ({ dispatch, commit }, { success, fail, params }) {
      keretaApi.getPromotionDetails(
        response => {
          if (response.data.code === 200) {
            commit('setPromoDetails', response.data.data);
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params
      );
    },
    APPLY_PROMO_TRAIN ({ dispatch }, { success, fail, pathVariables, payload, params }) {
      keretaApi.applyTrainPromo(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        pathVariables,
        params
      );
    },
    UNAPPLY_PROMO_TRAIN ({ dispatch }, { success, fail, pathVariables, payload, params }) {
      keretaApi.unApplyTrainPromo(
        response => {
          if (response.data.code === 200) {
            success && success(response.data.data);
          } else {
            fail && fail(response);
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        pathVariables,
        params
      );
    },
    UPDATE_PROMO_STATUS ({ commit }, { promo }) {
      commit('updatePromoStatus', promo)
    }
  },
  namespaced: true
};
