import api from '../api/flipApis';

const initialSupportDetails = {
  type: null,
  image: null,
  isSupportDocumentOrKtp: null,
  isTypeManuallyChanged: false
}

export default {
  state: {
    registrationDetails: {},
    province: [],
    city: [],
    subDistrict: [],
    newRegistrationDetails: null,
    supportDocumentDetails: { ...initialSupportDetails },
    imageDetails: {

    }
  },
  getters: {
    getRegistrationDetails (state) {
      return state.registrationDetails;
    },
    getNewRegistrationDetails (state) {
      return state.newRegistrationDetails;
    },
    isNewRegistration (state) {
      return state.registrationDetails.registrationStatus === 'NOT_REGISTERED' || state.registrationDetails.registrationStatus === 'REGISTRATION_FAILED'
    },
    isWaitingForApproval (state) {
      return state.registrationDetails.registrationStatus === 'REGISTRATION_IN_PROGRESS'
    },
    isRejected (state) {
      return state.registrationDetails.registrationStatus === 'REGISTRATION_REJECTED'
    },
    isVerified (state) {
      return state.registrationDetails.registrationStatus === 'REGISTRATION_SUCCESS'
    },
    isMaxRetryErrorOnRegistration (state) {
      return state.registrationDetails.uploadSupportDocument
    },
    getProvinceList (state) {
      return state.province
    },
    getCityList (state) {
      return state.city
    },
    getSubDistrict (state) {
      return state.subDistrict
    },
    getSupportDocumentDetails (state) {
      return state.supportDocumentDetails
    },
    getImageDetails (state) {
      return state.imageDetails
    }
  },
  mutations: {
    setRegistrationDetails (state, value) {
      state.registrationDetails = value
    },
    setNewRegistrationDetails (state, value) {
      state.newRegistrationDetails = value
    },
    setProvince (state, value) {
      state.province = value
    },
    setCity (state, value) {
      state.city = value
    },
    setSubDistrict (state, value) {
      state.subDistrict = value
    },
    setKtpProfileImage (state, value) {
      let newRegistrationDetails = state.newRegistrationDetails
      if (value.type === 'ktp') {
        newRegistrationDetails.ktpImage = value.dataUrl
      } else {
        newRegistrationDetails.profileImage = value.dataUrl
      }
    },
    setSupportDocumentDetails (state, value) {
      const existingDetails = state.supportDocumentDetails
      state.supportDocumentDetails = { ...existingDetails, ...value }
    },
    setImageDetails (state, value) {
      state.imageDetails = { ...state.imageDetails, ...value }
    },
    resetImageDetails (state) {
      state.imageDetails = {}
    }
  },
  actions: {
    SET_NEW_REGISTRATION_DETAILS ({ commit }, registrationDetails) {
      commit('setNewRegistrationDetails', registrationDetails)
    },
    GET_REGISTRATION_DETAILS ({ commit }, { payload, success, error }) {
      commit('setRegistrationDetails', {})
      commit('resetImageDetails')
      commit('setSupportDocumentDetails', { ...initialSupportDetails })
      api.getRegisterationDetails(
        payload,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            let data = responseData.data
            commit('setRegistrationDetails', data)
            success && success(responseData.data)
          } else {
            error && error()
          }
        },
        errorMsg => {
          error && error(errorMsg)
        }
      )
    },
    UPDATE_REGISTRATION_DETAILS (context, { success, error, payload }) {
      api.updateRegisterationDetails(
        payload,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            success && success(responseData.data)
          } else {
            error && error()
          }
        },
        errorMsg => {
          error && error(errorMsg)
        }
      )
    },
    SAVE_FLIP_DETAILS (context, { success, error, payload }) {
      api.saveRegisterationDetails(
        payload,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            success && success(responseData.data)
          } else {
            error && error()
          }
        },
        errorMsg => {
          error && error(errorMsg)
        }
      )
    },
    GET_OCR_DETAILS (context, { params, success, error, payload }) {
      api.getOcrDetails(
        payload,
        params,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            success(responseData.data)
          } else {
            error && error(response)
          }
        },
        errorMsg => {
          error && error(errorMsg)
        }
      )
    },
    GET_PROVINCE ({ commit }) {
      api.getProvince(
        null,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            let provinceList = responseData.data.provinces;
            provinceList = provinceList.map(province => ({ id: province.id, text: province.name, value: province.id + '' }))
            commit('setProvince', provinceList)
          }
        },
        error => {
          console.log(error)
        }
      )

    },
    GET_CITY_BY_PROVINCE_ID ({ commit }, { provinceId, success }) {
      commit('setCity', [])
      commit('setSubDistrict', [])
      api.getCity(
        null,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            let cityList = responseData.data.cities;
            cityList = cityList.map(city => ({ id: city.id, text: city.name, value: city.id + '' }))
            commit('setCity', cityList)
            success && success()
          }
        },
        error => {
          console.log(error)
        },
        provinceId
      )
    },
    GET_DISTRICTS_BY_CITY_ID ({ commit }, { cityId, success }) {
      commit('setSubDistrict', [])
      api.getDistricts(
        null,
        response => {
          const responseData = response.data;
          if (responseData.code === 200) {
            let districtList = responseData.data.districts;
            districtList = districtList.map(district => ({ id: district.id, text: district.name, value: district.id + '' }))
            commit('setSubDistrict', districtList)
            success && success()
          }
        },
        error => {
          console.log(error)
        },
        cityId
      )
    },
    SET_SUPPORT_DOCUMENT_DETAILS ({ commit }, details) {
      commit('setSupportDocumentDetails', details)
    },
    UPLOAD_SUPPORT_DOCUMENT (context, { success, error, payload }) {
      api.uploadSupportDocument(
        payload,
        response => {
          const responseData = response.data;
          success && success(responseData.data)
        },
        errorMsg => {
          error && error(errorMsg)
        }
      )
    },
    SET_IMAGE_DETAILS ({ commit }, imageDetail) {
      commit('setImageDetails', imageDetail)
    }
  },
  namespaced: true
}
