import digitalApi from '@/api/digitalApis';
import errorHandler from '../api/apiUtils/errorHandler'

export default {
  state: {
    cart: null,
    contactNumber: null,
    inquiryDetail: null,
    addCartErrors: null,
    payErrors: null,
    isAddingToCart: false,
    vouchersList: [],
    isFetchingList: true,
    isFetchingCart: false,
    voucherDetails: {},
    maxDiscountedValue: null
  },
  mutations: {
    setIsFetchingCart (state, flag) {
      state.isFetchingCart = flag
    },
    setCart (state, cart) {
      state.cart = cart;
    },
    setContactNumber (state, number) {
      state.contactNumber = number;
    },
    setInquiryDetail (state, inquiryDetail) {
      state.inquiryDetail = inquiryDetail;
    },
    setAddCartErrors (state, addCartErrors) {
      state.addCartErrors = addCartErrors
    },
    setPayErrors (state, payErrors) {
      state.payErrors = payErrors
    },
    setIsAddingToCart (state, addingToCart) {
      state.isAddingToCart = addingToCart
    },
    setVouchersList (state, value) {
      state.vouchersList = value
    },
    setIsFetchingList (state, isFetchingList) {
      state.isFetchingList = isFetchingList
    },
    toggleVoucherStatus (state, voucherPayload) {
      const filteredVoucher = state.vouchersList
        .find((voucher) => voucher.promoCode === voucherPayload.promoCode)
      filteredVoucher.status = filteredVoucher.status === 'APPLIED' ? 'USED' : 'APPLIED'
    },
    setVoucherDetails (state, value) {
      state.voucherDetails = value
    },
    setMaxDiscountedValue (state, value) {
      state.maxDiscountedValue = value
    }
  },
  getters: {
    getIsFetchingCart (state) {
      return state.isFetchingCart
    },
    getVoucherDetails (state) {
      return state.voucherDetails;
    },
    cart (state) {
      return state.cart;
    },
    inquiryDetail (state) {
      return state.inquiryDetail;
    },
    contactNumber (state) {
      return state.contactNumber
    },
    addCartErrors (state) {
      return state.addCartErrors
    },
    isAddingToCart (state) {
      return state.isAddingToCart
    },
    payErrors (state) {
      return state.payErrors
    },
    getVouchersList (state) {
      return state.vouchersList;
    },
    getIsFetchingList (state) {
      return state.isFetchingList;
    },
    getMaxDiscountedValue (state) {
      return state.maxDiscountedValue
    }
  },
  actions: {
    UPDATE_CART_VALUE ({ commit }, { success, fail } = {}) {
      commit('setIsFetchingCart', true)
      commit('setPayErrors', null)
      digitalApi.fetchDigitalCart(
        response => {
          commit('setIsFetchingCart', false)
          if (response.data.errors) {
            fail && fail(response.data.errors)
          } else {
            commit('setCart', response.data.data);
            success && success(response.data.data)
          }
        },
        error => {
          console.log(error)
          fail && fail()
          commit('setIsFetchingCart', false)
        }
      );
    },
    GET_MAX_DISCOUNT_VALUE ({ commit, dispatch }) {
      digitalApi.getDigitalMaxDiscountedValue(
        response => {
          if (response.data.code === 200) {
            commit('setMaxDiscountedValue', response.data.data)
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error, true);
        }
      )
    },
    TOGGLE_VOUCHERS_STATUS ({ commit }, { voucher }) {
      commit('toggleVoucherStatus', voucher)
    },
    GET_VOUCHERS_DETAILS ({ commit, dispatch }, { params, pathvariables, fail } = {}) {
      commit('setIsFetchingList', true)
      digitalApi.getVouchersDetails(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              !response.data.errors
            ) {
              commit('setVoucherDetails', response.data.data)
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        pathvariables
      );
    },
    GET_VOUCHERS_LIST (
      { commit, dispatch },
      { fail } = {}
    ) {
      commit('setVouchersList', [])
      commit('setIsFetchingList', true)
      digitalApi.getVouchersList(
        response => {
          commit('setIsFetchingList', false)
          if (response.data.code === 200) {
            if (
              !response.data.errors
            ) {
              commit('setVouchersList', response.data.data)
            } else {
              fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
          }
        },
        error => {
          commit('setIsFetchingList', false)
          errorHandler.handleErrors(dispatch, error);
        }
      );
    },
    APPLY_VOUCHER (
      { commit, dispatch },
      { payload, params, success, fail } = {}
    ) {
      digitalApi.applyVoucher(
        response => {
          if (response.data.code === 200) {
            if (
              !response.data.errors
            ) {
              if (response.data.data.length) {
                commit('setVouchersList', response.data.data)
              }
              success && success(!!response.data.data.length)
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
            fail && fail()
          }
        },
        error => {
          errorHandler.handleErrors(dispatch, error);
        },
        params,
        payload
      );
    },
    REMOVE_VOUCHER (
      { commit, dispatch },
      { success: callback, payload, params, isCustomErrorHandlerAvailable = false, fail } = {}
    ) {
      digitalApi.removeVoucher(
        response => {
          if (response.data.code === 200) {
            if (
              !response.data.errors
            ) {
              if (response.data.data.length) {
                commit('setVouchersList', response.data.data)
                callback && callback(!!response.data.data.length);
              } else {
                fail && fail();
              }
            } else {
              fail && fail(response.data.errors);
            }
          } else {
            dispatch('SET_ERROR_HANDLE_POPUP', {
              isErrorHandleVisible: true,
              errorList: response.data.errors
            }, { root: true });
            fail && fail()
          }
        },
        error => {
          if (isCustomErrorHandlerAvailable) {
            callback && callback()
            return
          }
          fail && fail(error);
          errorHandler.handleErrors(dispatch, error);
        },
        payload,
        params
      );
    },
    DELETE_PAYMENT_SPECIFIC_VOCUHERS ({ dispatch }, { success, fail } = {}) {
      digitalApi.deletePaymentSpecificVouchersdigital(
        response => {
          if (response.data.code === 200 && response.data.data) {
            success()
          } else {
            fail()
          }
        },
        error => {
          fail()
          errorHandler.handleErrors(dispatch, error, true);
        }
      )
    },
    ADD_CART ({ commit, dispatch }, { payload, success = () => { }, fail }) {
      commit('setCart', null)
      commit('setIsAddingToCart', true)
      commit('setPayErrors', null)
      digitalApi.setCustomerNumber(
        response => {
          commit('setIsAddingToCart', false)
          if (response.data.errors) {
            fail && fail()
            commit('setAddCartErrors', response.data.errors)
          } else {
            commit('setCart', response.data.data);
            commit('setAddCartErrors', null)
            success && success(response);
          }
        },
        error => {
          commit('setIsAddingToCart', false)
          console.log(error)
          fail && fail()
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    ADD_CART_NO_NUMBER ({ commit, dispatch }, { payload, success, fail }) {
      commit('setCart', null);
      commit('setPayErrors', null)
      digitalApi.addCart(
        response => {
          if (response.data.errors) {
            commit('setPayErrors', response.data.errors)
            fail && fail()
          } else {
            commit('setCart', response.data.data);
            success && success(response.data.data);
          }
        },
        error => {
          console.log(error)
          fail && fail()
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    PAY ({ commit, dispatch }, { success, payload, fail }) {
      digitalApi.pay(
        response => {
          const errors = response.data.errors;
          if (errors) {
            commit('setPayErrors', errors)
            if (errors.errorInfo) {
              success && success(errors)
            } else {
              // In absence of predefined error added else case to close the full screen loader
              fail && fail()
            }
          } else {
            success && success(response.data.data)
          }
        },
        error => {
          console.log(error)
          fail && fail()
          errorHandler.handleErrors(dispatch, error);
        },
        payload
      );
    },
    RESET_CART ({ commit }) {
      commit('setCart', null);
    },
    SET_CONTACT_NUMBER ({ commit }, { payload }) {
      commit('setContactNumber', payload);
    },
    GET_CART ({ commit, dispatch }) {
      digitalApi.getCart(response => {
        if (response.data.code === 200) {
          commit('setCart', response.data.data)
        } else {
          dispatch('SET_ERROR_POPUP', {
            isErrorPopupVisible: true,
            errorList: response.data.errors
          }, { root: true });
        }
      },
      error => {
        errorHandler.handleErrors(dispatch, error);
      });
    },
    CHANGE_PAYMENT ({ commit, dispatch }, { payload, success, shouldUpdateCart = true, fail }) {
      digitalApi.changePayment(
        response => {
          const errors = response.data.errors
          if (response.data.code === 200 && !errors) {
            if (shouldUpdateCart) {
              commit('setCart', response.data.data)
            }
            success && success(payload.paymentMethod)
          } else if (response.data.code === 200 && errors) {
            commit('setPayErrors', errors)
            success && success(errors)
          } else {
            dispatch('SET_ERROR_POPUP', {
              isErrorPopupVisible: true,
              errorList: response.data.errors
            }, { root: true });
            fail && fail()
          }
        },
        (e) => {
          console.error(e)
          errorHandler.handleErrors(dispatch, e);
          fail && fail()
        },
        payload
      )
    },
    RESET_CART_ERROR ({ commit }) {
      commit('setAddCartErrors', null)
    }
  },
  namespaced: true
};
