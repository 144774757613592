export default {
  name: 'transition-component',
  props: {
    effectName: {
      type: String,
      required: false,
      default: 'fade-effect'
    }
  }
};
