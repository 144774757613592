import digitalApi from '@/api/digitalApis';

export default {
  state: {
    productData: null,
    productErrors: null,
    isFetchingProducts: false,
    providerName: null,
    providerImageUrl: null
  },
  mutations: {
    setProduct (state, productResponse) {
      state.productData = productResponse;
    },
    setProductErrors (state, productErrors) {
      state.productErrors = productErrors
    },
    setIsFetchingProducts (state, flag) {
      state.isFetchingProducts = flag
    },
    setProviderName (state, providerName) {
      state.providerName = providerName
    },
    setProviderImageUrl (state, imageUrl) {
      state.providerImageUrl = imageUrl
    }
  },
  getters: {
    productData (state) {
      return state.productData;
    },
    productErrors (state) {
      return state.productErrors;
    },
    getIsFetchingProducts (state) {
      return state.isFetchingProducts;
    },
    getProviderName (state) {
      return state.providerName
    },
    getProviderImageUrl (state) {
      return state.providerImageUrl
    }
  },
  actions: {
    GET_PRODUCTS ({ commit }, { payload, success }) {
      commit('setIsFetchingProducts', true);
      digitalApi.getProducts(
        response => {
          commit('setIsFetchingProducts', false);
          if (response.data.errors) {
            commit('setProductErrors', response.data.errors);
            commit('setProduct', null);
            commit('setProviderName', null);
            commit('setProviderImageUrl', null);
            success && success(null)
          } else {
            const productDetails = response.data.data
            commit('setProduct', productDetails.products);
            if (productDetails.provider || productDetails.imageUrl) {
              commit('setProviderName', productDetails.provider);
              commit('setProviderImageUrl', productDetails.imageUrl);
            }
            commit('setProductErrors', null);
            success && success(productDetails.products)
          }
        },
        error => {
          commit('setIsFetchingProducts', false);
          console.log(error)
        },
        'DATA_PACKAGE',
        payload
      );
    },
    RESET_PRODUCT ({ commit }) {
      commit('setProduct', null);
    },
    RESET_PRODUCT_ERROR ({ commit }) {
      commit('setProductErrors', null)
    },
    RESET_PROVIDER ({ commit }) {
      commit('setProviderName', null);
    },
    RESET_PROVIDER_IMAGE ({ commit }) {
      commit('setProviderImageUrl', null);
    }
  },
  namespaced: true
};
